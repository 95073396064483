import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface EmailChipsValueProps {
  label: string;
  isValid: boolean;
  isExists?: boolean;
  isLoading: boolean;
}

export interface UseEmailChipsProps {
  values: EmailChipsValueProps[];
  onChange: (val: EmailChipsValueProps[]) => void;
}

export function useEmailChips({ values, onChange }: UseEmailChipsProps) {
  // stroring input value so that it can be used to edit chips on click
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();
  const valueLabels = values.map((val) => val.label);
  // additonally to the default behaviour (enter key or new line character), handling spaces so that chips are created on space
  const handleInputChange = (newInputValue: string) => {
    const options = newInputValue.split(/[,\s]+/);
    if (options.length > 1) {
      const uniqueOptions = options
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((x) => x.trim())
        .filter((x) => x && !valueLabels.includes(x));
      const uniqueOptionsObj = uniqueOptions.map((unique) => ({
        label: unique,
        isValid: false,
        isLoading: false,
      }));
      const allValues = values.concat(uniqueOptionsObj);
      onChange(allValues);
      setInputValue('');
    } else {
      setInputValue(newInputValue);
    }
  };
  // handling edititing of chips on click
  const handleEditChip = (label: string) => {
    const newValues = values.filter((val) => val.label !== label);
    if (inputValue === '') {
      onChange(newValues);
    }
    if (inputValue !== '') {
      onChange([
        ...newValues,
        {
          label: inputValue,
          isValid: false,
          isLoading: false,
        },
      ]);
    }
    setInputValue(label);
  };
  // submitting the input value on blur, so that chip is createad
  const handleOnBlur = () => {
    if (inputValue !== '') {
      onChange([
        ...values,
        {
          label: inputValue,
          isValid: false,
          isLoading: false,
        },
      ]);
      setInputValue('');
    }
  };

  return {
    inputValue,
    setInputValue,
    handleInputChange,
    handleEditChip,
    handleOnBlur,
    valueLabels,
    t,
  };
}
