import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as Y from 'yjs';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import {
  editorDataToYjs,
  handleAddItem,
  handleDisableForwardVideo,
  handleDuplicateItem,
  handleEditorChange,
  handleHrefChange,
  handleImageUpload,
  handleInfoTypeChange,
  handleMediaTypeChange,
  handleRemoveItem,
  handleRequiredVideo,
  handleResizeItem,
  handleSelectedTrainingsChange,
  handleSetImage,
  handleSetVideo,
  handleTrainingsIconChange,
  handleTrainingsSelectAllChange,
  handleTrainingsTitleChange,
  handleTrainingsTypeChange,
  handleUpdateEmbeddedItem,
  handleUpdateVideoPoster,
  handleUpdateVideoTitle,
  moveItemToEndAndExpand,
  moveItemToNewRow,
  yjsToEditorData,
  handleUndoCrop,
} from './helpers';
import {
  handleDragAndDropToSideOfRow,
  handleDropIntoFullRow,
  insertItemBefore,
  moveItemToNewPosition,
} from './helpers/dnd/Dnd.helpers';

interface EditorContextI {
  ydoc: Y.Doc;
  undoManager: Y.UndoManager;
  docName: string;
  yjsToEditorData: ReturnType<typeof yjsToEditorData>;
  editorDataToYjs: ReturnType<typeof editorDataToYjs>;
  handleRemoveItem: ReturnType<typeof handleRemoveItem>;
  handleEditorChange: ReturnType<typeof handleEditorChange>;
  handleAddItem: ReturnType<typeof handleAddItem>;
  handleResizeItem: ReturnType<typeof handleResizeItem>;
  handleInfoTypeChange: ReturnType<typeof handleInfoTypeChange>;
  handleMediaTypeChange: ReturnType<typeof handleMediaTypeChange>;
  handleSetImage: ReturnType<typeof handleSetImage>;
  handleSetVideo: ReturnType<typeof handleSetVideo>;
  handleImageUpload: ReturnType<typeof handleImageUpload>;
  moveItemToEndAndExpand: ReturnType<typeof moveItemToEndAndExpand>;
  moveItemToNewRow: ReturnType<typeof moveItemToNewRow>;
  handleDragAndDropToSideOfRow: ReturnType<typeof handleDragAndDropToSideOfRow>;
  handleDuplicateItem: ReturnType<typeof handleDuplicateItem>;
  moveItemToNewPosition: ReturnType<typeof moveItemToNewPosition>;
  handleDropIntoFullRow: ReturnType<typeof handleDropIntoFullRow>;
  handleTrainingsTitleChange: ReturnType<typeof handleTrainingsTitleChange>;
  handleTrainingsIconChange: ReturnType<typeof handleTrainingsIconChange>;
  handleSelectedTrainingsChange: ReturnType<typeof handleSelectedTrainingsChange>;
  handleTrainingsSelectAllChange: ReturnType<typeof handleTrainingsSelectAllChange>;
  handleUpdateVideoPoster: ReturnType<typeof handleUpdateVideoPoster>;
  handleDisableForwardVideo: ReturnType<typeof handleDisableForwardVideo>;
  handleRequiredVideo: ReturnType<typeof handleRequiredVideo>;
  handleUpdateVideoTitle: ReturnType<typeof handleUpdateVideoTitle>;
  handleUpdateEmbeddedItem: ReturnType<typeof handleUpdateEmbeddedItem>;
  insertItemBefore: ReturnType<typeof insertItemBefore>;
  handleTrainingsTypeChange: ReturnType<typeof handleTrainingsTypeChange>;
  handleHrefChange: ReturnType<typeof handleHrefChange>;
  handleUndoCrop: ReturnType<typeof handleUndoCrop>;
}

const EditorContext = createContext<EditorContextI | undefined>(undefined);

export function EditorProvider({
  children,
  documentName,
}: {
  children: React.ReactNode;
  documentName: string;
}) {
  const { data: userData } = useCurrentUserQuery();
  const [connectionData, setConnectionData] = useState<{
    doc: Y.Doc;
    docName: string;
    undoManager: Y.UndoManager;
  }>();

  const doc = connectionData?.doc;
  const undoManager = connectionData?.undoManager;
  const docName = connectionData?.docName;

  useEffect(() => {
    const newDoc = new Y.Doc();
    const manager = new Y.UndoManager(newDoc.getMap('editorData'), {
      captureTimeout: 0,
      trackedOrigins: new Set([]),
    });
    setConnectionData({ doc: newDoc, undoManager: manager, docName: documentName });
    return () => {
      newDoc.destroy();
      manager.destroy();
    };
  }, [documentName]);

  const userId = userData?.id;

  useEffect(() => {
    if (undoManager && userId) {
      undoManager.trackedOrigins.add(userId);
    }
  }, [userId, undoManager]);

  const contextValue: EditorContextI | undefined = useMemo(() => {
    if (!doc || !undoManager || !docName) return undefined;
    const yEditorData = doc.getMap('editorData');
    return {
      ydoc: doc,
      undoManager,
      docName,
      yjsToEditorData: yjsToEditorData(yEditorData),
      editorDataToYjs: editorDataToYjs(yEditorData),
      handleRemoveItem: handleRemoveItem(doc, yEditorData, userData?.id),
      handleEditorChange: handleEditorChange(doc, yEditorData, userData?.id),
      handleAddItem: handleAddItem(doc, yEditorData, userData?.id),
      handleResizeItem: handleResizeItem(doc, yEditorData, userData?.id),
      handleInfoTypeChange: handleInfoTypeChange(doc, yEditorData, userData?.id),
      handleMediaTypeChange: handleMediaTypeChange(doc, yEditorData, userData?.id),
      handleSetImage: handleSetImage(doc, yEditorData, userData?.id),
      handleSetVideo: handleSetVideo(doc, yEditorData, userData?.id),
      handleImageUpload: handleImageUpload(doc, yEditorData, userData?.id),
      moveItemToEndAndExpand: moveItemToEndAndExpand(doc, yEditorData, userData?.id),
      moveItemToNewRow: moveItemToNewRow(doc, yEditorData, userData?.id),
      handleDragAndDropToSideOfRow: handleDragAndDropToSideOfRow(doc, yEditorData, userData?.id),
      handleDuplicateItem: handleDuplicateItem(doc, yEditorData, userData?.id),
      moveItemToNewPosition: moveItemToNewPosition(doc, yEditorData, userData?.id),
      handleDropIntoFullRow: handleDropIntoFullRow(doc, yEditorData, userData?.id),
      handleTrainingsTitleChange: handleTrainingsTitleChange(doc, yEditorData, userData?.id),
      handleTrainingsIconChange: handleTrainingsIconChange(doc, yEditorData, userData?.id),
      handleSelectedTrainingsChange: handleSelectedTrainingsChange(doc, yEditorData, userData?.id),
      handleTrainingsSelectAllChange: handleTrainingsSelectAllChange(
        doc,
        yEditorData,
        userData?.id
      ),
      handleUpdateVideoPoster: handleUpdateVideoPoster(doc, yEditorData, userData?.id),
      handleUpdateVideoTitle: handleUpdateVideoTitle(doc, yEditorData, userData?.id),
      handleDisableForwardVideo: handleDisableForwardVideo(doc, yEditorData, userData?.id),
      handleRequiredVideo: handleRequiredVideo(doc, yEditorData, userData?.id),
      handleUpdateEmbeddedItem: handleUpdateEmbeddedItem(doc, yEditorData, userData?.id),
      insertItemBefore: insertItemBefore(doc, yEditorData, userData?.id),
      handleTrainingsTypeChange: handleTrainingsTypeChange(doc, yEditorData, userData?.id),
      handleHrefChange: handleHrefChange(doc, yEditorData, userData?.id),
      handleUndoCrop: handleUndoCrop(doc, yEditorData, userData?.id),
    };
  }, [userData, doc, undoManager, docName]);

  return (
    <EditorContext.Provider value={contextValue}>{contextValue && children}</EditorContext.Provider>
  );
}

export const useEditor = () => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useEditor must be used within an EditorProvider');
  }
  return context;
};
