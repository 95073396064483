import { TFunction } from 'i18next';
import { ShareLevel } from 'src/lib/types/share-level';
import { Document, File } from 'src/store/api/driveApi';

export default function getManagerTitle(
  rowData: Document | File,
  t: TFunction<'translation', undefined>
) {
  const { generalAccess, shareLevel, managersCount } = rowData;

  if (
    ['everyone', 'public'].includes(generalAccess.type) &&
    generalAccess.required === ShareLevel.manage
  ) {
    return t('documentsPage.table.managerColumn.everyone');
  }

  if (shareLevel === ShareLevel.manage) {
    if (managersCount === 2) {
      return t('documentsPage.table.managerColumn.youAndOther', { count: 1 });
    }

    return managersCount > 1
      ? t('documentsPage.table.managerColumn.youAndOthers', { count: managersCount - 1 })
      : t('documentsPage.table.managerColumn.you');
  }

  if (managersCount === 0) {
    return t('documentsPage.table.managerColumn.none');
  }
  if (managersCount === 1) {
    return t('documentsPage.table.managerColumn.other', { count: managersCount });
  }
  return t('documentsPage.table.managerColumn.others', { count: managersCount });
}
