import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  WorkbaseCheckmarkBigFilledIcon,
  WorkbaseEyeNothingIcon,
} from 'src/assets/icons/workbaseIcons';

export default function DraftModeMenu({
  status,
  onStatusChange,
  withButton = true,
  dataCy,
}: {
  status: 'published' | 'draft';
  onStatusChange: (status: 'published' | 'draft') => void;
  withButton?: boolean;
  dataCy?: string;
}) {
  const [isOpen, setOpen] = useState<null | HTMLElement>(null);
  const handleOpen = (e: React.MouseEvent<any>) => {
    setOpen(e.currentTarget);
  };
  const handleClose = () => setOpen(null);
  const { t } = useTranslation();

  const menuItems = [
    {
      value: 'published',
      label: t('editor.trainingCoursePage.trainingModulesWidget.visibleText'),
      icon: WorkbaseCheckmarkBigFilledIcon,
      color: 'success' as const,
      onClick: () => {
        onStatusChange('published');
      },
    },
    {
      value: 'draft',
      label: t('editor.trainingCoursePage.trainingModulesWidget.draftText'),
      icon: WorkbaseEyeNothingIcon,
      color: 'secondary' as const,
      onClick: () => {
        onStatusChange('draft');
      },
    },
  ];
  const selectedMenuItem = menuItems.find(({ value }) => value === status);
  const defaultMenuItem = menuItems[0];
  const color = selectedMenuItem?.color || defaultMenuItem.color;
  const icon = selectedMenuItem?.icon || defaultMenuItem.icon;
  return (
    <>
      {withButton && (
        <Button
          onClick={handleOpen}
          color="inherit"
          size="small"
          disableTouchRipple
          startIcon={<SvgIcon fontSize="inherit" color={color} component={icon} />}
          sx={{
            pointerEvents: 'all',
          }}
          data-cy={dataCy && `${dataCy}-button`}
        >
          {selectedMenuItem?.label || defaultMenuItem.label}
        </Button>
      )}
      {!withButton && (
        <IconButton
          color={color}
          size="small"
          onClick={handleOpen}
          sx={{ pointerEvents: 'all' }}
          data-cy={dataCy && `${dataCy}-icon-button`}
        >
          <SvgIcon fontSize="inherit" component={icon} color={color} />
        </IconButton>
      )}
      <Menu
        anchorEl={isOpen}
        onClose={handleClose}
        open={Boolean(isOpen)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -2,
          horizontal: 'right',
        }}
      >
        {menuItems.map(({ value, label, icon: iconComponent, color: iconColor, onClick }) => (
          <MenuItem
            data-cy={dataCy && `${dataCy}-item-${value}`}
            key={value}
            dense
            selected={value === status}
            onClick={() => {
              onClick();
              handleClose();
            }}
          >
            <ListItemIcon>
              <SvgIcon color={iconColor} component={iconComponent} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
