import { useCallback, useEffect } from 'react';
import { IconButton, Stack, SvgIcon, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseBackIcon, WorkbaseForwardIcon } from 'src/assets/icons/workbaseIcons';
import isUserTyping from 'src/lib/utils/isUserTyping';
import { useEditor } from '../../controller/EditorContext';

export default function HistoryItems() {
  const { undoManager } = useEditor();
  const { t } = useTranslation();

  const handleUndo = useCallback(() => {
    undoManager.undo();
  }, [undoManager]);

  const handleRedo = useCallback(() => {
    undoManager.redo();
  }, [undoManager]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isUserTyping()) return;
      const isUndoRedoCommand =
        (event.metaKey || event.ctrlKey) && (event.key === 'z' || event.key === 'y');

      if (!isUndoRedoCommand) return;

      event.preventDefault();

      if (event.key === 'z' && !event.shiftKey) {
        handleUndo();
      } else {
        handleRedo();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUndo, handleRedo]);

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Tooltip title={t('undo')}>
        <IconButton variant="soft" onClick={handleUndo} data-cy="editor-undo-button">
          <SvgIcon component={WorkbaseBackIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('redo')}>
        <IconButton variant="soft" onClick={handleRedo} data-cy="editor-redo-button">
          <SvgIcon component={WorkbaseForwardIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
