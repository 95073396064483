import axiosInstance from 'src/axios';
import { API_URL } from 'src/global-config';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

interface VideoDuplicateResponseSchema {
  id: string;
}

const videoDuplicate = async (
  videoId: string,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<VideoDuplicateResponseSchema>> => {
  const headers = {
    'Content-Type': 'application/json',
    ...config.headers,
  };

  const finalConfig = {
    ...config,
    headers,
  };

  return axiosInstance.post(`${API_URL}/videos/${videoId}/duplicate`, {}, finalConfig);
};

export { videoDuplicate };
