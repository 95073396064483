import { createApi } from '@reduxjs/toolkit/query/react';
import { DefaultImage } from 'src/ui-components/branded-default-image/BrandedDefaultImage';
import { baseQuery } from '../api';
import { trainingsApi } from './trainingsApi';
import { trainingModulesApi } from './trainingModulesApi';
import { companyUsersApi } from '../company-users/companyUsersApi';
import { trainingSectionsApi } from './trainingSectionsApi';

export interface TrainingLesson {
  id: string;
  name: string;
  status: TrainingLessonStatus;
  trainingSectionId: string;
  imageUrl: string | null;
  done: boolean;
  position: string;
  nextPublishedTrainingLessonId: string | null;
  estimatedTimeToComplete: number;
  progress: number;
  defaultImage: DefaultImage;
}

export type TrainingLessonStatus = 'draft' | 'published';

export const trainingLessonsApi = createApi({
  reducerPath: 'trainingLessonsApi',
  baseQuery,
  tagTypes: [
    'TrainingLessonsBySectionId',
    'TrainingLesson',
    'TrainingLessonCanMarkAsDone',
    'TrainingLessonsBySectionAndUserId',
  ],
  endpoints: (builder) => ({
    // queries
    trainingLesson: builder.query<TrainingLesson, string>({
      query: (lessonId) => ({
        url: `/company-users/me/training-lessons/${lessonId}`,
      }),
      providesTags: (_, __, lessonId) => [{ type: 'TrainingLesson', id: lessonId }],
    }),
    trainingLessonsBySectionId: builder.query<TrainingLesson[], string>({
      query: (sectionId) => ({
        url: `company-users/me/training-sections/${sectionId}/lessons`,
      }),
      providesTags: (_, __, sectionId) => [{ type: 'TrainingLessonsBySectionId', id: sectionId }],
    }),
    trainingLessonsBySectionAndUserId: builder.query<
      TrainingLesson[],
      { sectionId: string; userId: string }
    >({
      query: ({ sectionId, userId }) => ({
        url: `/company-users/${userId}/training-sections/${sectionId}/lessons`,
      }),
      providesTags: (_, __, { sectionId }) => [
        { type: 'TrainingLessonsBySectionAndUserId', id: sectionId },
      ],
    }),
    // mutations
    patchTrainingLesson: builder.mutation<
      void,
      Partial<TrainingLesson> & { imageId?: string | null; trainingId?: string; moduleId?: string }
    >({
      query: (trainingLesson) => ({
        url: `/training-lessons/${trainingLesson.id}`,
        method: 'PATCH',
        body: {
          ...trainingLesson,
          id: undefined,
          sectionId: undefined,
          imageUrl: undefined,
        },
      }),
      invalidatesTags: (_, __, lesson) => [
        { type: 'TrainingLessonsBySectionAndUserId', id: lesson.trainingSectionId },
        { type: 'TrainingLesson', id: lesson.id },
      ],
      onQueryStarted: (trainingLesson, { dispatch, queryFulfilled }) => {
        const dispUpdateLessonsBySectionId = dispatch(
          trainingLessonsApi.util.updateQueryData(
            'trainingLessonsBySectionId',
            trainingLesson.trainingSectionId as string,
            (draft) =>
              draft.map((lesson) =>
                lesson.id === trainingLesson.id ? { ...lesson, ...trainingLesson } : lesson
              )
          )
        );
        const dispUpdateLesson = dispatch(
          trainingLessonsApi.util.updateQueryData(
            'trainingLesson',
            trainingLesson.id as string,
            (draft) => ({
              ...draft,
              ...trainingLesson,
            })
          )
        );

        queryFulfilled
          .then(() => {
            dispatch(
              trainingsApi.util.invalidateTags([
                'TrainingLessonsCount',
                'TrainingEstimatedTime',
                'TrainingProgress',
                'TrainingsProgressByUserId',
                'TrainingsByUserId',
                { type: 'TrainingLessonsCountById', id: trainingLesson.trainingId },
                { type: 'TrainingEstimatedTimeById', id: trainingLesson.trainingId },
                { type: 'TrainingProgressById', id: trainingLesson.trainingId },
              ])
            );
            dispatch(
              trainingModulesApi.util.invalidateTags([
                'TrainingModuleLessonsCount',
                'TrainingModuleEstimatedTime',
                { type: 'TrainingModulesProgressByTrainingId', id: trainingLesson.trainingId },
                {
                  type: 'TrainingModulesProgressByTrainingAndUserId',
                  id: trainingLesson.trainingId,
                },
                { type: 'TrainingModulesByTrainingId', id: trainingLesson.trainingId },
                { type: 'TrainingModulesProgress', id: trainingLesson.moduleId },
              ])
            );
            dispatch(
              trainingSectionsApi.util.invalidateTags([
                { type: 'TrainingSectionsByModuleAndUserId', id: trainingLesson.moduleId },
              ])
            );
          })
          .catch(() => {
            dispUpdateLessonsBySectionId.undo();
            dispUpdateLesson.undo();
          });
      },
    }),
    duplicateTrainingLesson: builder.mutation<
      TrainingLesson,
      { lessonId: string; trainingId: string; moduleId: string; trainingSectionId: string }
    >({
      query: ({ lessonId }) => ({
        url: `/training-lessons/${lessonId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, lesson) => [
        { type: 'TrainingLessonsBySectionAndUserId', id: lesson.trainingSectionId },
      ],
      onQueryStarted: ({ lessonId, trainingId, moduleId }, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(
            trainingLessonsApi.util.updateQueryData(
              'trainingLessonsBySectionId',
              response.data.trainingSectionId,
              (draft) => {
                const lessonIdx = draft.findIndex((lesson) => lesson.id === lessonId);
                if (lessonIdx >= 0) {
                  draft.splice(lessonIdx + 1, 0, response.data);
                }
                return draft;
              }
            )
          );
          dispatch(
            trainingsApi.util.invalidateTags([
              'TrainingLessonsCount',
              'TrainingEstimatedTime',
              'TrainingProgress',
              'TrainingsProgressByUserId',
              'TrainingsByUserId',
              { type: 'TrainingLessonsCountById', id: trainingId },
              { type: 'TrainingEstimatedTimeById', id: trainingId },
              { type: 'TrainingProgressById', id: trainingId },
            ])
          );
          dispatch(
            trainingModulesApi.util.invalidateTags([
              'TrainingModuleLessonsCount',
              'TrainingModuleEstimatedTime',
              { type: 'TrainingModulesProgressByTrainingId', id: trainingId },
              { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
              { type: 'TrainingModulesByTrainingId', id: trainingId },
              { type: 'TrainingModulesProgress', id: moduleId },
            ])
          );
          dispatch(companyUsersApi.util.invalidateTags(['CompanyUsersTrainingProgress']));
          dispatch(
            trainingSectionsApi.util.invalidateTags([
              { type: 'TrainingSectionsByModuleAndUserId', id: moduleId },
            ])
          );
        });
      },
    }),
    deleteTrainingLesson: builder.mutation<
      void,
      { trainingSectionId: string; id: string; trainingId?: string; moduleId?: string }
    >({
      query: ({ id }) => ({
        url: `/training-lessons/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, lesson) => [
        { type: 'TrainingLessonsBySectionAndUserId', id: lesson.trainingSectionId },
      ],
      onQueryStarted: (
        { id, trainingSectionId, trainingId, moduleId },
        { dispatch, queryFulfilled }
      ) => {
        const disp = dispatch(
          trainingLessonsApi.util.updateQueryData(
            'trainingLessonsBySectionId',
            trainingSectionId,
            (draft) => draft.filter((lesson) => lesson.id !== id)
          )
        );
        queryFulfilled
          .then(() => {
            dispatch(
              trainingsApi.util.invalidateTags([
                'TrainingLessonsCount',
                'TrainingEstimatedTime',
                'TrainingProgress',
                'TrainingsProgressByUserId',
                'TrainingsByUserId',
                { type: 'TrainingLessonsCountById', id: trainingId },
                { type: 'TrainingEstimatedTimeById', id: trainingId },
                { type: 'TrainingProgressById', id: trainingId },
              ])
            );
            dispatch(
              trainingModulesApi.util.invalidateTags([
                'TrainingModuleLessonsCount',
                'TrainingModuleEstimatedTime',
                { type: 'TrainingModulesProgressByTrainingId', id: trainingId },
                { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
                { type: 'TrainingModulesByTrainingId', id: trainingId },
                { type: 'TrainingModulesProgress', id: moduleId },
              ])
            );
            dispatch(companyUsersApi.util.invalidateTags(['CompanyUsersTrainingProgress']));
            dispatch(
              trainingSectionsApi.util.invalidateTags([
                { type: 'TrainingSectionsByModuleAndUserId', id: moduleId },
              ])
            );
          })
          .catch(disp.undo);
      },
    }),
    postTrainingLesson: builder.mutation<
      TrainingLesson,
      {
        name?: string;
        trainingSectionId: string;
        imageId?: string;
        status?: TrainingLessonStatus;
        imageUrl?: string;
      }
    >({
      query: (lesson) => ({
        url: `/training-lessons`,
        method: 'POST',
        body: { ...lesson, imageUrl: undefined },
      }),
      invalidatesTags: (_, __, { trainingSectionId }) => [
        { type: 'TrainingLessonsBySectionAndUserId', id: trainingSectionId },
      ],
      onQueryStarted: (lesson, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(
            trainingLessonsApi.util.updateQueryData(
              'trainingLessonsBySectionId',
              lesson.trainingSectionId,
              (draft) => [...draft, { ...response.data, imageUrl: lesson.imageUrl || null }]
            )
          );
        });
      },
    }),
    markTrainingLessonAsDone: builder.mutation<
      TrainingLesson,
      { lessonId: string; sectionId: string; trainingId?: string; moduleId?: string }
    >({
      query: ({ lessonId }) => ({
        url: `/training-lessons/${lessonId}/done`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, lesson) => [
        { type: 'TrainingLessonsBySectionAndUserId', id: lesson.sectionId },
      ],
      onQueryStarted: (
        { lessonId, sectionId, trainingId, moduleId },
        { dispatch, queryFulfilled }
      ) => {
        const disp1 = dispatch(
          trainingLessonsApi.util.updateQueryData(
            'trainingLessonsBySectionId',
            sectionId,
            (draft) =>
              draft.map((lesson) => (lesson.id === lessonId ? { ...lesson, done: true } : lesson))
          )
        );
        const disp2 = dispatch(
          trainingLessonsApi.util.updateQueryData('trainingLesson', lessonId, (draft) => ({
            ...draft,
            done: true,
          }))
        );
        queryFulfilled.then(() => {
          dispatch(
            trainingsApi.util.invalidateTags([
              'TrainingProgress',
              'TrainingsProgressByUserId',
              { type: 'TrainingProgressById', id: trainingId },
            ])
          );
          dispatch(
            trainingModulesApi.util.invalidateTags([
              { type: 'TrainingModulesProgressByTrainingId', id: trainingId },
              { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
              { type: 'TrainingModulesProgress', id: moduleId },
            ])
          );
          dispatch(companyUsersApi.util.invalidateTags(['CompanyUsersTrainingProgress']));
        });
        queryFulfilled.catch(disp1.undo);
        queryFulfilled.catch(disp2.undo);
      },
    }),
    markTrainingLessonAsUndone: builder.mutation<
      TrainingLesson,
      { lessonId: string; sectionId: string; trainingId?: string; moduleId?: string }
    >({
      query: ({ lessonId }) => ({
        url: `/training-lessons/${lessonId}/undone`,
        method: 'POST',
      }),
      invalidatesTags: (_, __, lesson) => [
        { type: 'TrainingLessonsBySectionAndUserId', id: lesson.sectionId },
      ],
      onQueryStarted: (
        { lessonId, sectionId, trainingId, moduleId },
        { dispatch, queryFulfilled }
      ) => {
        const disp1 = dispatch(
          trainingLessonsApi.util.updateQueryData(
            'trainingLessonsBySectionId',
            sectionId,
            (draft) =>
              draft.map((lesson) => (lesson.id === lessonId ? { ...lesson, done: false } : lesson))
          )
        );
        const disp2 = dispatch(
          trainingLessonsApi.util.updateQueryData('trainingLesson', lessonId, (draft) => ({
            ...draft,
            done: false,
          }))
        );
        queryFulfilled.then(() => {
          dispatch(
            trainingsApi.util.invalidateTags([
              'TrainingProgress',
              'TrainingsProgressByUserId',
              { type: 'TrainingProgressById', id: trainingId },
            ])
          );
          dispatch(
            trainingModulesApi.util.invalidateTags([
              { type: 'TrainingModulesProgressByTrainingId', id: trainingId },
              { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
              { type: 'TrainingModulesProgress', id: moduleId },
            ])
          );
          dispatch(companyUsersApi.util.invalidateTags(['CompanyUsersTrainingProgress']));
        });
        queryFulfilled.catch(disp1.undo);
        queryFulfilled.catch(disp2.undo);
      },
    }),
    moveTrainingLessonToNewPosition: builder.mutation<
      void,
      { sectionId: string; idOfMovingLesson: string; order: number; position: string }
    >({
      query: ({ position, idOfMovingLesson, sectionId }) => ({
        url: `/training-lessons/${idOfMovingLesson}`,
        method: 'PATCH',
        body: {
          position,
          trainingSectionId: sectionId,
        },
      }),
      invalidatesTags: (_, __, lesson) => [
        { type: 'TrainingLessonsBySectionAndUserId', id: lesson.sectionId },
      ],
      onQueryStarted: async (
        { sectionId, idOfMovingLesson, order, position },
        { dispatch, queryFulfilled, getState }
      ) => {
        const state = getState();
        const lessonsQueires = state.trainingLessonsApi.queries as any;
        const allSectionLessonQueryKeys = Object.keys(state.trainingLessonsApi.queries);

        const sourceSectionId = allSectionLessonQueryKeys.reduce(
          (prev: string | undefined, curr) => {
            if (prev) return prev;
            const lesson = lessonsQueires[curr].data.find(({ id }: any) => id === idOfMovingLesson);
            if (lesson) {
              return curr.substring(curr.indexOf('"') + 1, curr.lastIndexOf('"'));
            }

            return prev;
          },
          undefined
        ) as string;

        let lessonToMoveCached: TrainingLesson | null = null;
        const disp1 = dispatch(
          trainingLessonsApi.util.updateQueryData(
            'trainingLessonsBySectionId',
            sourceSectionId,
            (draft) => {
              if (sourceSectionId !== sectionId) {
                return draft.filter((lesson) => {
                  const isMovingLesson = lesson.id === idOfMovingLesson;
                  if (isMovingLesson) {
                    lessonToMoveCached = { ...lesson, position };
                  }
                  return !isMovingLesson;
                });
              }
              const lessonIdx = draft.findIndex((lessons) => lessons.id === idOfMovingLesson);
              if (lessonIdx !== -1) {
                const [lessonToMove] = draft.splice(lessonIdx, 1);
                lessonToMove.position = position;
                lessonToMoveCached = lessonToMove;
                draft.splice(order - 1, 0, lessonToMove);
              }
              return draft;
            }
          )
        );

        const disp2 = dispatch(
          trainingLessonsApi.util.updateQueryData(
            'trainingLessonsBySectionId',
            sectionId,
            (draft) => {
              if (sourceSectionId === sectionId) {
                return draft;
              }
              if (lessonToMoveCached) {
                draft.splice(order - 1, 0, lessonToMoveCached);
              }
              return draft;
            }
          )
        );
        dispatch(companyUsersApi.util.invalidateTags(['CompanyUsersTrainingProgress']));

        try {
          await queryFulfilled;
        } catch {
          queryFulfilled.catch(disp1.undo);
          queryFulfilled.catch(disp2.undo);
        }
      },
    }),
    trainingLessonCanMarkAsDone: builder.query<
      { result: boolean },
      { lessonId: string; moduleId: string }
    >({
      query: ({ lessonId }) => ({
        url: `/company-users/me/training-lessons/${lessonId}/can-mark-as-done`,
      }),
      providesTags: (_, __, { lessonId }) => [
        { type: 'TrainingLessonCanMarkAsDone', id: lessonId },
      ],
    }),
  }),
});

export const {
  useTrainingLessonsBySectionIdQuery,
  useTrainingLessonsBySectionAndUserIdQuery,
  useDeleteTrainingLessonMutation,
  useDuplicateTrainingLessonMutation,
  usePatchTrainingLessonMutation,
  useMoveTrainingLessonToNewPositionMutation,
  usePostTrainingLessonMutation,
  useMarkTrainingLessonAsDoneMutation,
  useMarkTrainingLessonAsUndoneMutation,
  useTrainingLessonQuery,
  useTrainingLessonCanMarkAsDoneQuery,
} = trainingLessonsApi;
