import tldList from '../constants/tldList';

function validateUrl(url: string) {
  const pattern = new RegExp(
    '^' +
      '(https?:\\/\\/)?' + // protocol
      '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?' + // port
      '(\\/[-a-zA-Z\\d%_.~+:]*)*' + // path
      '(\\?[;&a-zA-Z\\d%_:.~+\\-=@{}]*)?' + // query string
      '(\\#[-a-zA-Z\\d_]*)?$', // fragment locator
    'i'
  );

  // Remove the trailing slash for TLD validation
  const urlForTldValidation = url.endsWith('/') ? url.slice(0, -1) : url;
  const urlParts = urlForTldValidation.split('.');
  const lastPart = urlParts[urlParts.length - 1];
  const tldElement = lastPart.split('/')[0];

  return (
    pattern.test(url) &&
    (url.includes('.') || url.startsWith('www.')) &&
    tldList.includes(tldElement.toLowerCase())
  );
}

export default validateUrl;
