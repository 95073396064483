import useIsMobile from 'src/hooks/useIsMobile';
import {
  WorkbaseContentIcon,
  WorkbaseEmployeeIcon,
  WorkbasePageIcon,
  WorkbaseShareIcon,
} from 'src/assets/icons/workbaseIcons';
import { ShareLevel } from 'src/lib/types/share-level';
import { SvgIcon } from '@mui/material';
import { useDocumentsQuery, useGetFilesQuery } from 'src/store/api/driveApi';
import { useTranslation } from 'react-i18next';
import { DocumentsTabData } from './types';

export default function useTabsData() {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { data: filesData, isLoading: isFilesDataLoading } = useGetFilesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: documentsData, isLoading: isDocumentsDataLoading } = useDocumentsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const docs = documentsData?.data || [];
  const files = filesData || [];
  const tabsData: DocumentsTabData[] = [
    {
      key: 'documents',
      label: isMobile ? (
        <SvgIcon fontSize="small">
          <WorkbasePageIcon />
        </SvgIcon>
      ) : (
        t('documentsPage.allDocumentsTab')
      ),
      rows: docs,
    },
    {
      key: 'createdByMe',
      label: isMobile ? (
        <SvgIcon fontSize="small">
          <WorkbaseEmployeeIcon />
        </SvgIcon>
      ) : (
        t('documentsPage.createdByMeTab')
      ),
      rows: docs.filter((row) => row.shareLevel === ShareLevel.manage),
    },
    {
      key: 'shared',
      label: isMobile ? (
        <SvgIcon fontSize="small">
          <WorkbaseShareIcon />
        </SvgIcon>
      ) : (
        t('documentsPage.sharedTab')
      ),
      rows: docs.filter((row) => row.shareLevel !== ShareLevel.manage),
    },
    {
      key: 'files',
      label: isMobile ? (
        <SvgIcon fontSize="small">
          <WorkbaseContentIcon />
        </SvgIcon>
      ) : (
        t('documentsPage.filesTab')
      ),
      rows: files,
    },
  ];

  return { tabsData, isFilesDataLoading, isDocumentsDataLoading };
}
