import throttle from 'lodash.throttle';
import { enqueueSnackbar, SnackbarAction } from 'notistack';

type Types = 'success' | 'error' | 'info' | 'default' | 'warning';

export const showNotification = (
  message: string,
  type: Types = 'success',
  action?: SnackbarAction,
  persist?: boolean
  // eslint-disable-next-line max-params
) => enqueueSnackbar({ message, variant: type, action, persist });

export const throttledShowNotification = throttle(
  // eslint-disable-next-line max-params
  (message: string, type: Types = 'success', action?: SnackbarAction, persist: boolean = false) => {
    showNotification(message, type, action, persist);
  },
  1000
);
