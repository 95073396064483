import { EditorState } from 'remirror';
import { useCommands, useRemirrorContext } from '@remirror/react';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import LinkInput from './LinkInput';

interface Props {
  onRemove: () => void;
  setLinkInputValue: (val: string) => void;
  value: string;
}

function getLinkHrefFromSelection(state: Readonly<EditorState>): string | undefined {
  // Variables to hold the start and end positions of the selection
  const { from, to } = state.selection;

  let hrefValue = '';

  // Iterate over nodes in the current selection
  state.doc.nodesBetween(from, to, (node) => {
    // Get the marks for the current node
    const { marks } = node;

    // Find the link mark within the node's marks
    const linkMark = marks.find((mark) => mark.type.name === 'link');

    // If a link mark is found, extract its href attribute
    if (linkMark) {
      hrefValue = linkMark.attrs.href;
      return false; // Breaks out of nodesBetween once we find the href
    }
    return undefined;
  });

  if (hrefValue?.startsWith('//')) {
    hrefValue = hrefValue.slice(2);
  }

  return hrefValue;
}

export default function TextSelectionLinkInput({ onRemove, setLinkInputValue, value }: Props) {
  const { updateLink, removeLink } = useCommands();
  const { getState } = useRemirrorContext();
  const { selection } = getState();
  const { from, to } = selection;
  return (
    <LinkInput
      value={value}
      setLinkInputValue={setLinkInputValue}
      onRemove={onRemove}
      onApply={(v) => {
        const href = ensureHttpProtocol(v);
        updateLink({ href }, { from, to });
        onRemove();
      }}
      href={getLinkHrefFromSelection(getState()) || ''}
      onClear={() => {
        removeLink({ from, to });
        onRemove();
      }}
    />
  );
}
