import React, { createContext, useReducer, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { sidebarApi } from 'src/store/api/sidebarApi';
import { WebSocketLike } from 'react-use-websocket/dist/lib/types';
import { ChatState, ChatAction } from './types';
import { chatReducer } from './chatReducer';
import { useSocket } from './use-socket';
import useFetchChats from './use-fetchChats';

const initialState: ChatState = {
  isOpen: false,
  isFullScreen: false,
  locationBeforeFullscreen: null,
  currentInput: '',
  messages: [],
  threads: [],
  threadId: null,
  currentStream: '',
  waitingResponse: false,
  error: null,
  needsUpdate: null,
  isLoading: false,
  imageUrl: null,
  lastUserMessage: null,
};

export const ChatContext = createContext<{
  state: ChatState;
  dispatch: React.Dispatch<ChatAction>;
  getWebSocket: () => WebSocketLike | null;
  sendJsonMessage: (message: any) => void;
}>({
  state: initialState,
  dispatch: () => null,
  getWebSocket: () => null,
  sendJsonMessage: () => null,
});

export function ChatProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const { getWebSocket, sendJsonMessage } = useSocket(state.threadId, dispatch);
  const rtkDispatch = useDispatch<any>();
  useFetchChats(dispatch, state.threadId);

  useEffect(() => {
    dispatch({ type: 'SET_IMAGE_URL', payload: null });
    dispatch({ type: 'WEBSOCKET_ERROR', payload: null });
  }, [state.threadId]);

  useEffect(() => {
    if (state.needsUpdate) {
      rtkDispatch(sidebarApi.util.invalidateTags(['Sidebar']));
      dispatch({ type: 'SET_NEEDS_UPDATE', payload: null });
    }
  }, [state.needsUpdate, rtkDispatch]);

  const contextValue = useMemo(
    () => ({ state, dispatch, getWebSocket, sendJsonMessage }),
    [state, dispatch, getWebSocket, sendJsonMessage]
  );

  return <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>;
}
