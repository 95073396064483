import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { WorkbaseTrainingsIcon } from 'src/assets/icons/workbaseIcons';
import { Training } from 'src/store/api/trainings/trainingsApi';
import { useTranslation } from 'react-i18next';
import Empty from 'src/ui-components/custom/empty/Empty';
import TrainingCard from '../../../../../../core/TrainingCard';

interface Props {
  readOnly: boolean;
  trainings: Training[];
  selectedItems?: string[];
  selectedAllItems?: boolean;
  placeholder: ReactNode;
  columnsCount: number;
}

export default function TrainingsContent({
  trainings,
  selectedAllItems,
  selectedItems = [],
  placeholder,
  columnsCount,
}: Props) {
  const { t } = useTranslation();
  const trainingsToDisplay = selectedAllItems
    ? trainings
    : trainings.filter(({ id }) => selectedItems.includes(id));
  const isEmptyNoPermissionContent =
    trainingsToDisplay?.length === 0 && selectedItems?.length !== 0;

  const columnDict = {
    1: 1,
    2: 2,
    3: 3,
    4: 3,
  };
  const colResizeFactor = columnDict[columnsCount as keyof typeof columnDict];

  return (
    <Box>
      {trainingsToDisplay.length === 0 && (
        <Empty
          icon={WorkbaseTrainingsIcon}
          description={
            isEmptyNoPermissionContent
              ? t('editor.trainingsSection.noTrainingsAcessMessage')
              : placeholder
          }
        />
      )}
      <Box
        gap={2}
        sx={{
          margin: '-1px',
          display: 'grid',
          gridTemplateColumns: `repeat(${colResizeFactor}, 1fr)`,
        }}
      >
        {trainingsToDisplay.map((training) => (
          <TrainingCard key={training.id} {...training} hasPermission />
        ))}
      </Box>
    </Box>
  );
}
