import { Avatar, FormControlLabel, ListItemAvatar, Stack, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  GeneralAccessType,
  ResourceType,
  useGeneralAccessQuery,
} from 'src/store/api/shareResourceApi';
import { WorkbaseBusinessPlusIcon, WorkbaseLockIcon } from 'src/assets/icons/workbaseIcons';
import { ElementType } from 'react';
import { ShareLevel } from 'src/lib/types/share-level';
import AccessControlItem from './access-list/access-items/AccessControlItem';
import PermissionSelect from './access-list/PermissionSelect';
import RequiredSwitch from './access-list/RequiredSwitch';
import GeneralAccessSwitch from './access-list/GeneralAccessSwitch';

interface Props {
  resourceId: string;
  resourceType: ResourceType;
}

export default function GeneralAccess({ resourceId, resourceType }: Props) {
  const { t } = useTranslation();
  const { data } = useGeneralAccessQuery({ resourceId, resourceType });
  const isTrainingResourceType = resourceType === 'training';
  const iconPropsDict: Record<GeneralAccessType, ElementType> = {
    restricted: WorkbaseLockIcon,
    everyone: WorkbaseBusinessPlusIcon,
  };
  const accessType = data?.generalAccess.type || 'everyone';
  const isEveryone = accessType === 'everyone';
  const everyoneDescription =
    data?.generalAccess.shareLevel === ShareLevel.edit
      ? t('permissionsShare.everyoneCanEditDescription')
      : t('permissionsShare.everyoneCanViewDescription');
  return (
    <Stack pr={2}>
      <AccessControlItem
        prefix={
          <ListItemAvatar>
            <Avatar size="large">
              <SvgIcon component={iconPropsDict[data?.generalAccess.type || 'everyone']} />
            </Avatar>
          </ListItemAvatar>
        }
        primaryText={
          <FormControlLabel
            sx={{ ml: 0 }}
            control={
              <GeneralAccessSwitch
                resourceId={resourceId}
                resourceType={resourceType}
                generalAccessType={accessType}
              />
            }
            label={t(`permissionsShare.everyone`)}
            labelPlacement="start"
            data-cy="share-modal-permission-to-everyone"
          />
        }
        secondaryText={
          isEveryone ? everyoneDescription : t('permissionsShare.restrictedDescription')
        }
        suffix={
          isEveryone && (
            <Stack direction="row" gap={1}>
              {data && (
                <>
                  <PermissionSelect
                    resourceId={resourceId}
                    resourceType={resourceType}
                    shareLevel={data.generalAccess.shareLevel}
                    isGeneralAccess
                    isEveryone={isEveryone}
                  />
                  {isTrainingResourceType && (
                    <RequiredSwitch
                      resourceId={resourceId}
                      resourceType={resourceType}
                      isGeneralAccess
                      isRequired={data.generalAccess.required}
                    />
                  )}
                </>
              )}
            </Stack>
          )
        }
      />
    </Stack>
  );
}
