import { Divider, Stack } from '@mui/material';
import { RefObject } from 'react';
import LinkInput from './LinkInput';
import TextSelectionLinkInput from './TextSelectionLinkInput';
import BaseActions, { BaseActionsProps } from './BaseActions';
import TrainingsActions from './tranings-actions';
import TextMenu from './text-menu';
import ImageActions, { ImageActionsProps } from './ImageActions';
import VideoActions, { VideoActionsProps } from './VideoActions';
import UploadActions, { UploadActionsProps } from './UploadActions';
import InfoActions, { InfoActionsProps } from './InfoActions';
import EmbeddedActions, { EmbeddedActionsProps } from './EmbeddedActions';
import { TrainingsActionsProps } from './tranings-actions/TrainingsActions';
import UndoCropAction, { UndoCropActionProps } from './UndoCropAction';

const divider = <Divider orientation="vertical" flexItem />;

export interface EditBarActionsProps {
  baseActionsProps: BaseActionsProps;
  imageActionsProps?: Omit<ImageActionsProps, 'onLinkIconClick'>;
  videoActionsProps?: VideoActionsProps;
  textActionsProps?: {
    textAllignmentHidden?: boolean;
  };
  uploadActionsProps?: UploadActionsProps;
  infoActionsProps?: InfoActionsProps;
  embeddedActionsProps?: EmbeddedActionsProps;
  trainingsActionsProps?: TrainingsActionsProps;
  hrefProps?: HrefProps;
  widgetContainerRef?: RefObject<HTMLDivElement>;
  undoCropActionProps?: UndoCropActionProps;
  linkInputVisible: boolean;
  setLinkInputVisible: (val: boolean) => void;
  linkInputVal: string;
  setLinkInputVal: (val: string) => void;
}

export interface HrefProps {
  onChange: (href: string) => void;
  href?: string;
}

export default function EditBarActions({
  widgetContainerRef,
  trainingsActionsProps,
  textActionsProps,
  hrefProps,
  imageActionsProps,
  videoActionsProps,
  uploadActionsProps,
  infoActionsProps,
  embeddedActionsProps,
  undoCropActionProps,
  baseActionsProps,
  linkInputVisible,
  setLinkInputVisible,
  linkInputVal,
  setLinkInputVal,
}: EditBarActionsProps) {
  const handleShowLinkInput = () => setLinkInputVisible(true);
  const handleHideLinkInput = () => {
    setLinkInputVisible(false);
    if (widgetContainerRef?.current) {
      (widgetContainerRef.current as HTMLDivElement).focus({ preventScroll: true });
    }
  };

  const actions = (
    <>
      {trainingsActionsProps && (
        <>
          <TrainingsActions {...trainingsActionsProps} />
          {divider}
        </>
      )}

      {textActionsProps && (
        <>
          <TextMenu
            {...textActionsProps}
            onLinkIconClick={handleShowLinkInput}
            linkIconAlwaysClickable={!!hrefProps}
          />
          {divider}
        </>
      )}
      {imageActionsProps && (
        <>
          <ImageActions {...imageActionsProps} onLinkIconClick={handleShowLinkInput} />
          {divider}
        </>
      )}
      {videoActionsProps && (
        <>
          <VideoActions {...videoActionsProps} />
          {divider}
        </>
      )}
      {uploadActionsProps && (
        <>
          <UploadActions {...uploadActionsProps} />
          {divider}
        </>
      )}
      {infoActionsProps && (
        <>
          <InfoActions {...infoActionsProps} />
          {divider}
        </>
      )}
      {embeddedActionsProps && (
        <>
          <EmbeddedActions {...embeddedActionsProps} />
          {divider}
        </>
      )}
      {undoCropActionProps && (
        <>
          <UndoCropAction {...undoCropActionProps} />
          {divider}
        </>
      )}
      <BaseActions {...baseActionsProps} />
    </>
  );

  let linkInput = null;
  if (hrefProps || textActionsProps) {
    linkInput = hrefProps ? (
      <LinkInput
        onRemove={handleHideLinkInput}
        onApply={hrefProps.onChange}
        onClear={() => hrefProps.onChange('')}
        value={linkInputVal}
        href={hrefProps.href || ''}
        setLinkInputValue={setLinkInputVal}
      />
    ) : (
      <TextSelectionLinkInput
        onRemove={handleHideLinkInput}
        setLinkInputValue={setLinkInputVal}
        value={linkInputVal}
      />
    );
  }

  return (
    <Stack direction="row" gap={1} alignItems="center">
      {!linkInputVisible && actions}
      {linkInputVisible && linkInput}
    </Stack>
  );
}
