import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseImageDragAndUploadIcon } from 'src/assets/icons/workbaseIcons';
import Empty from 'src/ui-components/custom/empty/Empty';

export default function AiDropZone({
  getInputProps,
  isDragActive,
}: {
  getInputProps: () => any;
  isDragActive: boolean;
}) {
  const { t } = useTranslation();
  return (
    <>
      <input {...getInputProps()} />
      {isDragActive && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          width="100%"
          height="100%"
          sx={{ backdropFilter: 'blur(10px)' }}
          zIndex={1000}
        >
          <Empty description={t('assistant.dropImage')} icon={WorkbaseImageDragAndUploadIcon} />
        </Box>
      )}
    </>
  );
}
