import { GridRow } from 'src/features/editor/model';
import Cell from './Cell';

interface Props {
  row: GridRow;
  readOnly: boolean;
}

export default function OneColRow({ row, readOnly }: Props) {
  return row.items.map((item) => <Cell key={item.id} item={item} readOnly={readOnly} />);
}
