import EditorWithContextProvider from 'src/features/editor/view/EditorWithContextProvider';
import ShareModal from 'src/core/share-modal/ShareModal';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import { useTranslation } from 'react-i18next';
import {
  useDeleteWikiCardMutation,
  useDuplicateWikiCardMutation,
  WikiCardType,
} from 'src/store/api/wikiApi';
import { useNavigate } from 'react-router-dom';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { ShareLevel } from 'src/lib/types/share-level';
import WikiCardHeader from './header/WikiCardHeader';

interface Props {
  id: string;
  shareLevel?: ShareLevel;
  addPermission?: boolean;
  wikiCards: WikiCardType[];
  readOnly?: boolean;
  focusedIndex: number;
}

export default function WikiContent({
  id,
  shareLevel,
  addPermission,
  wikiCards,
  readOnly = true,
  focusedIndex,
}: Props) {
  const { t } = useTranslation();
  const [deleteWikiCard] = useDeleteWikiCardMutation();
  const [duplicateWikiCard] = useDuplicateWikiCardMutation();
  const navigate = useNavigate();

  return (
    <EditorWithContextProvider
      readOnly={readOnly}
      documentName={`wikiCard.${id}`}
      renderHeader={() => (
        <WikiCardHeader
          id={id}
          readOnly={readOnly}
          name={wikiCards[focusedIndex]?.name as string}
          icon={wikiCards[focusedIndex]?.icon || 'WorkbaseWikiIcon'}
          imageUrl={wikiCards[focusedIndex]?.imageUrl as string}
        />
      )}
      topBarRightContent={
        <>
          {shareLevel === ShareLevel.manage && (
            <ShareModal
              dataCy="wiki-page"
              name={wikiCards[focusedIndex]?.name || t('wikiPage.unnamedWikiCard')}
              resourceId={id}
              resourceType="wikiCard"
              shareLevel={shareLevel}
            />
          )}
          <DeleteDuplicateMenu
            dataCy="wiki-page"
            onDuplicate={
              addPermission
                ? () => {
                    duplicateWikiCard(id).then((resp: any) => {
                      if (resp.error) {
                        showNotification(t('notifications.duplicateError'), 'error');
                        return;
                      }
                      showNotification(t('notifications.duplicateSuccess'));
                      navigate(`/wiki/${resp.data.id}/edit`);
                    });
                  }
                : undefined
            }
            deleteDialogDescription={t('wikiPage.deleteWikiModalText')}
            onDelete={
              shareLevel === ShareLevel.manage
                ? () => {
                    deleteWikiCard(id).then((resp: any) => {
                      if (resp.error) {
                        showNotification(t('notifications.deleteError'), 'error');
                        return;
                      }
                      showNotification(t('notifications.deleteSuccess'));
                      const newFocusedIndex =
                        focusedIndex >= wikiCards.length - 1 ? focusedIndex - 1 : focusedIndex + 1;
                      navigate(`/wiki/${wikiCards[newFocusedIndex]?.id}/edit`);
                    });
                  }
                : undefined
            }
          />
        </>
      }
    />
  );
}
