export default function formatBytes(bytes: number) {
  const { userAgent } = navigator;
  let divisor = 1024;

  // Check the OS and adjust divisor accordingly
  if (userAgent.includes('Linux') || userAgent.includes('Mac OS')) {
    divisor = 1000;
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Bytes';
  const i = Math.floor(Math.log(bytes) / Math.log(divisor)); // Determine which size unit to use
  const formattedSize = (bytes / divisor ** i).toFixed(2); // Calculate size in the appropriate unit
  return `${formattedSize} ${sizes[i]}`;
}
