import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';
import { PermissionCode } from './userPermissionsApi';

export interface Permission {
  id: string;
  code: PermissionCode;
}

export enum PermissionGroupCodes {
  SETTINGS = 'SETTINGS',
  ADMIN_PERMISSIONS = 'ADMIN_PERMISSIONS',
  TEAM = 'TEAM',
  GENERAL = 'GENERAL',
  CUSTOMER_PORTAL = 'CUSTOMER_PORTAL',
}

export type PermissionGroupCode = keyof typeof PermissionGroupCodes;

export interface PermissionGroup {
  id: string;
  code: PermissionGroupCode;
  permissions: Permission[];
}

export interface PermissionsType {
  permissionGroups: PermissionGroup[];
}

export const permissionGroupsApi = createApi({
  reducerPath: 'permissions',
  baseQuery,
  endpoints: (builder) => ({
    permissionGroups: builder.query<PermissionsType, void>({
      query: () => '/authz/permission-groups',
    }),
  }),
});

export const { usePermissionGroupsQuery } = permissionGroupsApi;
