import { Components, Theme } from '@mui/material';

const MuiDialogContent: Components<Theme>['MuiDialogContent'] = {
  styleOverrides: {
    root: {
      padding: 20,
    },
  },
};

export default MuiDialogContent;
