import { Box, IconButton } from '@mui/material';
import LottieChevron from 'src/ui-components/custom/lottie/LottieChevron';
interface ToggleFullWidthArrowProps {
  isFullWidth: boolean;
  onFullWidthChange: (isFullWidth: boolean) => void;
}

export const toggleFullWidthArrowClassName = 'toggle-full-width-arrow';

export default function ToggleFullWidthArrow({
  isFullWidth,
  onFullWidthChange,
}: ToggleFullWidthArrowProps) {
  return (
    <Box
      position="absolute"
      top="50%"
      right={0}
      zIndex={20}
      className={toggleFullWidthArrowClassName}
      sx={{
        transform: 'translate(50%, -50%) rotate(-90deg) scale(0.75)',
        opacity: 0,
        transition: (theme) =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeInOut,
          }),
      }}
    >
      <IconButton
        value="toggle-full-width"
        data-cy="sidebarToggleFullWidthArrow"
        onClick={() => onFullWidthChange(!isFullWidth)}
        size="small"
        variant="soft"
        color="primary"
      >
        <LottieChevron open={isFullWidth} lottieProps={{ color: 'primary' }} />
      </IconButton>
    </Box>
  );
}
