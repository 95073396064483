import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import BrandedHeader from 'src/ui-components/branded-header';
import { useDashboardQuery } from 'src/store/api/dashboardApi';
import { useLinksQuery } from 'src/store/api/linksApi';
import { useAppSelector } from 'src/store';
import Image from 'src/ui-components/image';
import useThemeLogos from 'src/hooks/useThemeLogos';
import RoleSelectorWithLinks from '../RoleSelectorWithLinks';
import NewsfeedSlider from './neewsfeed/Newsfeed';

interface Props {
  readOnly: boolean;
}

export default function DashboardHeader({ readOnly }: Props) {
  const { t } = useTranslation();
  const { data: currentUserData } = useCurrentUserQuery();
  const { data: dashboardData } = useDashboardQuery();
  const selectedRole = useAppSelector((state) => state.dashboard.selectedRole);
  const { data } = useLinksQuery(
    { resourceType: 'role', resourceId: selectedRole?.value || '' },
    { skip: !selectedRole }
  );
  const { favicon } = useThemeLogos();
  const roles = dashboardData?.roles || [];
  function getGreetingText() {
    const greetingMorning = t('dashboardPage.header.greetingMorning');
    const greetingAfternoon = t('dashboardPage.header.greetingAfternoon');
    const greetingEvening = t('dashboardPage.header.greetingEvening');

    const hour = new Date().getHours();
    if (hour < 12 && hour >= 3) {
      return greetingMorning;
    }
    if (hour < 18 && hour >= 12) {
      return greetingAfternoon;
    }
    return greetingEvening;
  }
  const shouldRenderSubHeader = !(readOnly && roles.length === 1 && data?.length === 0);

  return (
    <BrandedHeader
      subHeader={
        shouldRenderSubHeader && (
          <RoleSelectorWithLinks links={data} roles={roles} isEditing={!readOnly} />
        )
      }
      stickyBottomContent={readOnly}
    >
      <Stack gap={2} width="100%">
        <Stack direction="row" spacing={1} alignItems="center">
          <Box borderRadius={1} bgcolor="background.default" p={1}>
            <Image {...favicon} width={40} height={40} />
          </Box>
          <Typography variant="h4" fontWeight="bold" color="primary.contrastText">
            {getGreetingText()}, {currentUserData?.firstName}
          </Typography>
        </Stack>
        <Card>
          <CardContent>
            <NewsfeedSlider />
          </CardContent>
        </Card>
      </Stack>
    </BrandedHeader>
  );
}
