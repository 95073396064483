import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';

export default function useDebouncedIsOver(isOver: boolean) {
  const [debouncedIsOver, setDebouncedIsOver] = useState(isOver);
  const debouncedIsOverFunction = useMemo(
    () => debounce((value) => setDebouncedIsOver(value), 25),
    []
  );
  useEffect(() => {
    debouncedIsOverFunction(isOver);
    return () => debouncedIsOverFunction.cancel();
  }, [isOver, debouncedIsOverFunction]);

  return debouncedIsOver;
}
