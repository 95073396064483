import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import { WorkbaseAddContentIcon, WorkbaseModuleIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { TrainingModule } from 'src/store/api/trainings/trainingModulesApi';
import Empty from 'src/ui-components/custom/empty/Empty';
import SingleModule from './SingleModule';

interface Props {
  readOnly: boolean;
  modules: TrainingModule[];
  selectedItems?: string[];
  selectedAllItems?: boolean;
}

export default function ModulesContent({ modules, selectedItems = [], readOnly }: Props) {
  const { t } = useTranslation();

  const modulesToDisplay = modules.filter(({ id }) => selectedItems.includes(id));

  return (
    <Box>
      {modulesToDisplay.length === 0 && (
        <Empty
          icon={WorkbaseModuleIcon}
          description={
            <Typography color="text.secondary" fontWeight="medium" textAlign="center">
              {t('editor.trainingsSection.moduleSelectionMessage.useLabel')}{' '}
              <Box component="span" display="inline-block" sx={{ verticalAlign: 'sub' }}>
                <SvgIcon component={WorkbaseAddContentIcon} />
              </Box>{' '}
              {t('editor.trainingsSection.moduleSelectionMessage.selectModuleLabel')}
            </Typography>
          }
        />
      )}
      <Stack gap={2.5}>
        {modulesToDisplay.map((module) => (
          <SingleModule {...module} key={module.id} readOnly={readOnly} />
        ))}
      </Stack>
    </Box>
  );
}
