import { Components, Theme } from '@mui/material';

const MuiAccordion: Components<Theme>['MuiAccordion'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      '&::before': {
        display: 'none',
      },
    }),
  },
};

export default MuiAccordion;
