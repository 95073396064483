import { Emoji } from '@emoji-mart/data';
import { useTranslation } from 'react-i18next';
import { IconName } from 'src/ui-components/icon';

export const useFilteredEmojisAndIcons = (
  searchVal: string,
  allEmojis: Record<string, Emoji>,
  allIcons: IconName[]
) => {
  const { t } = useTranslation();

  const filterEmojisAndIcons = () => {
    const lowercasedSearchVal = searchVal.trim().toLowerCase();
    const emojis = Object.values(allEmojis).filter(
      (emoji) =>
        emoji.name.toLowerCase().includes(lowercasedSearchVal) ||
        emoji.skins[0].native.toLowerCase().includes(lowercasedSearchVal)
    );

    const icons = allIcons.filter((icon) => t(icon).toLowerCase().includes(lowercasedSearchVal));

    return { emojis, icons };
  };

  return filterEmojisAndIcons();
};
