import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './api';

export interface RoleDepartment {
  id: string;
  name: string;
}

export interface Role {
  id: string;
  name: string;
  department: RoleDepartment;
}

export interface DashboardResponse {
  roles: Role[];
}

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  tagTypes: ['Dashboard'],
  baseQuery,
  endpoints: (builder) => ({
    dashboard: builder.query<DashboardResponse, void>({
      query: () => ({
        url: '/dashboard',
      }),
      providesTags: ['Dashboard'],
    }),
  }),
});

export const { useDashboardQuery } = dashboardApi;
