import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Blocker, useBlocker } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useAppSelector } from 'src/store';
import useOnEnterTrigger from 'src/hooks/useOnEnterTrigger';
import { FileType } from '../grid/widgets/edit-bar/actions/UploadActions';
import { UploadedMedia } from '../../controller/Editor.slice';

function ModalContent({
  blocker,
  mediaType,
  uploadingMedia,
}: {
  blocker: Blocker;
  mediaType: FileType;
  uploadingMedia: UploadedMedia[];
}) {
  const { t } = useTranslation();
  const mediaDict: Record<FileType, string> = {
    image: t('editor.uploadMediaModal.image'),
    video: t('editor.uploadMediaModal.video'),
    file: t('editor.uploadMediaModal.file'),
  };
  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (uploadingMedia?.length !== 0) {
        // some browsers require a returnValue to be a string
        // eslint-disable-next-line no-param-reassign
        e.returnValue = 'val';
        return 'val';
      }
      return undefined;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploadingMedia]);

  useOnEnterTrigger(() => blocker.proceed?.());

  return (
    <>
      <DialogTitle>{t('editor.uploadMediaModal.title')}</DialogTitle>
      <DialogContent>
        {t('editor.uploadMediaModal.content', { media: mediaDict[mediaType] })}
      </DialogContent>
      <DialogActions data-cy="blocker-component-dialog-actions">
        <Button onClick={() => blocker.reset?.()} data-cy="blocker-component-dialog-cancel-action">
          {t('cancel')}
        </Button>
        <Button
          color="error"
          onClick={() => blocker.proceed?.()}
          data-cy="blocker-component-dialog-proceed-action"
        >
          {t('proceed')}
        </Button>
      </DialogActions>
    </>
  );
}

export default function BlockerComponent() {
  const uploadingMedia = useAppSelector((state) => state.editor.uploadingMedia);

  const mediaType = uploadingMedia.map((el) => el.mediaType)[uploadingMedia.length - 1];

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && uploadingMedia.length !== 0
  );

  return (
    <Dialog open={blocker.state === 'blocked' && !!mediaType} data-cy="blocker-component-dialog">
      <ModalContent blocker={blocker} mediaType={mediaType} uploadingMedia={uploadingMedia} />
    </Dialog>
  );
}
