import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';

const MuiCssBaseline: Components<Theme>['MuiCssBaseline'] = {
  styleOverrides: `
    svg:not(.MuiChip-deleteIcon) {
      pointer-events: none;
    }
    html, body, #root {
      height: 100%;
    }
  `,
};

export default MuiCssBaseline;
