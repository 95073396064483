import { Resizable } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  HorizontalResizeDot,
  VerticalBottomLeftResizeDot,
  VerticalBottomRightResizeDot,
  VerticalResizeDot,
} from 'src/ui-components/resize-dot/ResizeDot';
import { COLUMN_COUNT } from '../../../constants';

const defaultIndicatorEnabled = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

export type ColumnType = 1 | 2 | 3 | 4;

interface ColumnResize {
  column: ColumnType;
  direction: Direction;
  height?: number;
  aspectRatio?: number;
}

export type ResizeStopCallbackType = (resize: ColumnResize) => void;

interface ResizableWrapperProps {
  children: React.ReactNode;
  onResizeStop?: ResizeStopCallbackType;
  onResizeStart?: () => void;
  setColumnsCount: (val: number) => void;
  height?: number;
  minHeight?: number;
  verticalResize?: boolean;
  horizontalResize?: boolean;
  style?: CSSProperties;
}

// eslint-disable-next-line max-statements
const defineColumns = (
  resizingWrapperElement: HTMLElement,
  direction: Direction,
  rowElement: HTMLElement | undefined
) => {
  let column: ColumnType = 1;

  const rowClient = rowElement?.getBoundingClientRect();
  const rowStart = rowClient?.left || 0;
  const rowEnd = rowClient?.right || 0;
  const rowSize = rowEnd - rowStart;
  const singleColumnSize = rowSize / COLUMN_COUNT;

  const firstColumnStart = rowStart;
  const firstColumnEnd = rowStart + singleColumnSize;
  const firstColumnMiddle = (firstColumnEnd + firstColumnStart) / 2;

  const secondColumnStart = firstColumnEnd;
  const secondColumnEnd = firstColumnEnd + singleColumnSize;
  const secondColumnMiddle = (secondColumnEnd + secondColumnStart) / 2;

  const thirdColumnStart = secondColumnEnd;
  const thirdColumnEnd = secondColumnEnd + singleColumnSize;
  const thirdColumnMiddle = (thirdColumnEnd + thirdColumnStart) / 2;

  const fourthColumnStart = thirdColumnEnd;
  const fourthColumnEnd = thirdColumnEnd + singleColumnSize;
  const fourthColumnMiddle = (fourthColumnEnd + fourthColumnStart) / 2;
  const resizingFromLeft = direction === 'left' || direction === 'bottomLeft';
  const resizingFromRight = direction === 'right' || direction === 'bottomRight';

  const { left, right } = resizingWrapperElement.getBoundingClientRect();
  if (
    (resizingFromLeft && left <= firstColumnMiddle) ||
    (resizingFromRight && right >= firstColumnMiddle && right <= secondColumnMiddle)
  ) {
    column = 1;
  }
  if (
    (resizingFromLeft && left <= secondColumnMiddle && left > firstColumnMiddle) ||
    (resizingFromRight && right >= secondColumnMiddle && right <= thirdColumnMiddle)
  ) {
    column = 2;
  }
  if (
    (resizingFromLeft && left <= thirdColumnMiddle && left > secondColumnMiddle) ||
    (resizingFromRight && right >= thirdColumnMiddle && right <= fourthColumnMiddle)
  ) {
    column = 3;
  }
  if (
    (resizingFromLeft && left <= fourthColumnEnd && left > thirdColumnMiddle) ||
    (resizingFromRight && right >= fourthColumnMiddle)
  ) {
    column = 4;
  }
  return column;
};

function ResizableWrapper({
  children,
  onResizeStop,
  onResizeStart,
  height,
  setColumnsCount,
  minHeight = 0,
  horizontalResize,
  verticalResize,
  style,
}: ResizableWrapperProps) {
  const ref = useRef<Resizable>(null);
  const rowElement = ref.current?.parentNode?.parentNode?.parentElement || undefined;
  const [lockAspectRatio, setLockAspectRatio] = useState(false);

  useEffect(() => {
    if (height === undefined && verticalResize) {
      ref.current?.updateSize({ height: undefined });
    }
  }, [height, verticalResize]);

  return (
    <Resizable
      ref={ref}
      size={{ height }}
      minWidth={0}
      style={style}
      minHeight={minHeight}
      defaultSize={{ width: '100%', height: 'auto' }}
      boundsByDirection
      lockAspectRatio={lockAspectRatio}
      onResizeStart={() => {
        onResizeStart?.();
      }}
      onResize={(...attrs) => {
        const [e, direction, resizingWrapperElement, delta] = attrs;
        const isDiagonal = ['topRight', 'bottomRight', 'bottomLeft', 'topLeft'].includes(direction);
        if (isDiagonal && e.shiftKey) {
          setLockAspectRatio(true);
        } else {
          setLockAspectRatio(false);
        }

        const column = defineColumns(resizingWrapperElement, direction, rowElement);
        setColumnsCount(column);
        if (direction === 'bottomLeft' || direction === 'left') {
          resizingWrapperElement.style.setProperty('transform', `translateX(${-delta.width}px)`);
        }
        if (verticalResize) {
          resizingWrapperElement.style.setProperty(
            'height',
            `${resizingWrapperElement.clientHeight}px`
          );
        }
      }}
      onResizeStop={(...attrs) => {
        setLockAspectRatio(false);
        const [, direction, resizingWrapperElement] = attrs;
        const column = defineColumns(resizingWrapperElement, direction, rowElement);
        resizingWrapperElement.style.setProperty('transform', 'none');
        const resizingWrapperElementHeight = resizingWrapperElement?.clientHeight as number;
        const innerResizableElementHeight = resizingWrapperElementHeight;
        const resize: ColumnResize = {
          column,
          direction,
          height: innerResizableElementHeight,
        };
        onResizeStop?.(resize);
      }}
      enable={{
        ...defaultIndicatorEnabled,
        ...(horizontalResize && onResizeStart && { left: true, right: true }),
        ...(verticalResize &&
          onResizeStart && { bottomLeft: true, bottomRight: true, bottom: true }),
      }}
      handleComponent={{
        left: <HorizontalResizeDot />,
        right: <HorizontalResizeDot />,
        bottomLeft: <VerticalBottomLeftResizeDot />,
        bottomRight: <VerticalBottomRightResizeDot />,
        bottom: <VerticalResizeDot />,
      }}
      handleStyles={{
        left: {
          display: horizontalResize ? 'block' : 'none',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          height: '10px',
          left: 0,
          zIndex: 1,
        },
        right: {
          display: horizontalResize ? 'block' : 'none',
          position: 'absolute',
          top: '50%',
          transform: 'translate(30%, -60%)',
          height: '10px',
          right: 0,
        },
        bottomLeft: {
          display: verticalResize ? 'block' : 'none',
          position: 'absolute',
          bottom: 0,
          transform: 'translate(-30%, 0%)',
          width: '10px',
          height: '10px',
          left: 0,
        },
        bottomRight: {
          display: verticalResize ? 'block' : 'none',
          position: 'absolute',
          bottom: 0,
          transform: 'translate(30%, 0%)',
          width: '10px',
          height: '10px',
          right: 0,
        },
        bottom: {
          display: verticalResize ? 'block' : 'none',
          position: 'absolute',
          bottom: 0,
          width: '10px',
          left: '50%',
          transform: 'translate(-60%, 30%)',
        },
      }}
    >
      {children}
    </Resizable>
  );
}

export default ResizableWrapper;
