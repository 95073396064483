import { Box, ButtonBase } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'src/store';
import isUserTyping from 'src/lib/utils/isUserTyping';
import DnDDragButton from 'src/ui-components/dnd-drag-button/DnDDragButton';
import EditBar, { EditBarProps } from '../edit-bar/EditBar';
import ResizableWrapper, { ResizeStopCallbackType } from './resize/ResizableWrapper';
import HighlightBorder from './HighlightBorder';

interface ChildrenProps {
  EditBarComponent: React.ReactNode;
  focused: boolean;
  columnsCount: number;
}

interface HighlightWrapperProps {
  children: (props: ChildrenProps) => React.ReactNode;
  verticalResize?: {};
  horizontalResize?: {};
  dragging?: { dragRef?: any; previewRef?: any };
  editBarProps?: Omit<EditBarProps, 'widgetContainerRef'>;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  onResizeStart?: () => void;
  hideEditbar?: boolean;
  columnsCountNum?: number;
  hideHighlightLines?: boolean;
  height?: number;
  minHeight?: number;
  id: string;
  dataCy?: string;
}

const DEFAULT_MIN_HEIGHT = 30;

const setHighZIndeexForCurrentGridCell = (id: string) => {
  const el = document.getElementById(id);
  if (el) {
    el.style.zIndex = '5';
  }
};

const removeZIndexFromCurrentGridCell = (id: string) => {
  const el = document.getElementById(id);
  if (el) {
    el.style.removeProperty('z-index');
  }
};

export default function HighlightWrapper({
  children,
  verticalResize,
  horizontalResize,
  dragging,
  editBarProps,
  onResizeStop,
  onResizeStart,
  hideEditbar = false,
  columnsCountNum,
  id,
  height,
  minHeight,
  readOnly,
  dataCy,
}: HighlightWrapperProps) {
  const [columnsCount, setColumnsCount] = useState(columnsCountNum || 4);
  const [focused, setFocused] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const isEditorDragMode = useAppSelector((state) => state.editor.isDragging);
  useEffect(() => {
    if (focused && !hideEditbar) {
      const handleKeyPress = (event: KeyboardEvent) => {
        if ((event.key === 'Delete' || event.key === 'Backspace') && !isUserTyping()) {
          editBarProps?.baseActionsProps.onDelete();
        }

        if ((event.ctrlKey || event.metaKey) && event.key === 'd') {
          event.preventDefault();
          editBarProps?.baseActionsProps.onDuplicate();
        }
      };

      window.addEventListener('keydown', handleKeyPress);

      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }
    return undefined;
  }, [editBarProps?.baseActionsProps, focused, hideEditbar]);

  const editBarRenderProp =
    focused && !isResizing && !hideEditbar && editBarProps ? (
      <EditBar
        {...editBarProps}
        widgetContainerRef={buttonRef}
        baseActionsProps={{
          ...editBarProps.baseActionsProps,
          onDuplicate: () => {
            editBarProps.baseActionsProps.onDuplicate();
          },
        }}
      />
    ) : null;
  const wrapperSx =
    focused || isResizing
      ? {
          '.opacity-editor-item': {
            opacity: 1,
          },
        }
      : {
          '&:hover .opacity-editor-item': readOnly ? {} : { opacity: 1 },
          '.opacity-editor-item': {
            visibility: readOnly ? 'hidden' : 'visible',
          },
        };

  return (
    <Box
      sx={{
        ...wrapperSx,
        pointerEvents: isEditorDragMode ? 'none' : 'auto',
      }}
    >
      <ResizableWrapper
        data-cy={dataCy && `${dataCy}-highlight-wrapper`}
        height={height}
        style={{
          opacity: isResizing ? 0.6 : 1,
        }}
        minHeight={verticalResize ? minHeight || DEFAULT_MIN_HEIGHT : undefined}
        setColumnsCount={setColumnsCount}
        horizontalResize={!readOnly && !!horizontalResize}
        verticalResize={!readOnly && !!verticalResize}
        onResizeStop={(e) => {
          removeZIndexFromCurrentGridCell(id);
          setIsResizing(false);
          onResizeStop?.(e);
        }}
        onResizeStart={() => {
          setHighZIndeexForCurrentGridCell(id);
          setIsResizing(true);
          onResizeStart?.();
        }}
      >
        <Box
          ref={dragging?.previewRef}
          onMouseDown={(e) => e.stopPropagation()}
          style={{ transform: 'translate(0,0)' }}
          height="100%"
        >
          <ButtonBase
            data-cy="editor-grid-item-container"
            component={Box}
            ref={buttonRef}
            borderRadius={1}
            position="relative"
            display="block"
            width="100%"
            height="100%"
            className="editor-item-button"
            disableRipple
            onMouseDown={(e) => e.stopPropagation()}
            onFocus={
              readOnly
                ? undefined
                : () => {
                    setFocused(true);
                  }
            }
            onBlur={() => {
              setFocused(false);
            }}
            sx={{
              display: 'block',
              cursor: 'auto',
              transform: 'translate(0,0)',
            }}
          >
            <HighlightBorder />
            {children({
              EditBarComponent: editBarRenderProp,
              focused,
              columnsCount,
            })}
          </ButtonBase>
        </Box>
        {dragging && !readOnly && <DnDDragButton {...dragging} buttonRef={buttonRef} />}
      </ResizableWrapper>
    </Box>
  );
}
