import { Stack, Typography, IconButton, SvgIcon, Box } from '@mui/material';
import { WorkbaseCloseIcon } from 'src/assets/icons/workbaseIcons';
import Image from 'src/ui-components/image';

interface AttachmentImageProps {
  imageUrl: string;
  fileName: string;
  readonly: boolean;
  onClose?: () => void;
}

export default function AttachmentImage({
  imageUrl,
  fileName,
  readonly,
  onClose,
}: AttachmentImageProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <Stack direction="row" gap={1} alignItems="center" maxWidth="100%" overflow="hidden">
        <Box border={({ palette }) => `1px solid ${palette.divider}`} borderRadius={1}>
          <Image src={imageUrl} alt={fileName} width={40} height={40} fit="cover" />
        </Box>
        <Typography noWrap variant="caption" flexShrink={0.75}>
          {fileName}
        </Typography>
      </Stack>
      {!readonly && (
        <IconButton
          size="small"
          onClick={onClose}
          data-cy="ai-assistant-attachment-image-close-button"
        >
          <SvgIcon component={WorkbaseCloseIcon} fontSize="inherit" />
        </IconButton>
      )}
    </Stack>
  );
}
