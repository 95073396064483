import { Components, SvgIcon, Theme } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { WorkbaseEmployeeIcon } from 'src/assets/icons/workbaseIcons';

declare module '@mui/material/Avatar' {
  interface AvatarOwnProps {
    size?: OverridableStringUnion<'small' | 'medium' | 'large' | 'xlarge', {}>;
  }
}

const MuiAvatar: Components<Theme>['MuiAvatar'] = {
  defaultProps: {
    size: 'medium',
    children: (
      <SvgIcon
        component={WorkbaseEmployeeIcon}
        color="inherit"
        fontSize="small"
        sx={{ pb: '2px' }}
      />
    ),
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      textTransform: 'uppercase',
      backgroundColor: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.contrastText,
      ...(ownerState.size === 'small' && {
        width: 24,
        height: 24,
      }),
      ...(ownerState.size === 'medium' && {
        width: 34,
        height: 34,
      }),
      ...(ownerState.size === 'large' && {
        width: 44,
        height: 44,
      }),
      ...(ownerState.size === 'xlarge' && {
        width: 80,
        height: 80,
      }),
    }),
  },
};

export default MuiAvatar;
