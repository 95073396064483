import { WorkbaseChevronArrowLottie } from 'src/assets/lottie';
import LottieContainer, { LottieContainerProps } from './LottieContainer';
import { useEffect, useRef } from 'react';
import { LottieRefCurrentProps } from 'lottie-react';

export default function LottieChevron({
  open,
  lottieProps,
}: {
  open: boolean;
  lottieProps?: Omit<LottieContainerProps, 'animationData'>;
}) {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    if (open) {
      lottieRef.current?.playSegments([0, 70], true);
    } else {
      lottieRef.current?.playSegments([70, 140], true);
    }
  }, [open]);

  return (
    <LottieContainer
      animationData={WorkbaseChevronArrowLottie}
      lottieRef={lottieRef}
      autoplay={false}
      loop={false}
      {...lottieProps}
    />
  );
}
