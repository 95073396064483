// eslint-disable-next-line no-useless-escape
export const noSpecialCharsRegex = /^[\u00C0-\u017Fa-zA-Z\-\' ]+$/;

export const stringValidation = (
  value: string,
  t: (key: string) => string
): { hasError: boolean; errorMessage: string } => {
  let errorMessage = '';

  if (value.trim() === '') {
    errorMessage = t('validationErrorMessages.requiredErrorMsg');
  } else if (!noSpecialCharsRegex.test(value)) {
    errorMessage = t('validationErrorMessages.specialCharsErrorMsg');
  } else if (value.length > 35) {
    errorMessage = t('validationErrorMessages.fieldLengthErrorMsg');
  }

  return {
    hasError: errorMessage !== '',
    errorMessage,
  };
};
