import { Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseModuleIcon } from 'src/assets/icons/workbaseIcons';

export default function EmptyPlaceholder() {
  const { t } = useTranslation();
  return (
    <Stack
      alignItems="center"
      gap={0.5}
      borderRadius={1}
      bgcolor="background.default"
      py={6}
      px={2}
    >
      <SvgIcon component={WorkbaseModuleIcon} fontSize="large" />
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        gap={0.5}
        sx={{ textAlign: 'center' }}
        alignItems="center"
      >
        <Typography variant="body1" color="text.secondary" fontWeight="medium">
          {t('editor.trainingCoursePage.trainingModulesWidget.emptyPlaceholderLabel')}
        </Typography>
      </Stack>
    </Stack>
  );
}
