const darkModePalette = {
  background: {
    default: '#202020',
    paper: '#191919',
  },
  grey: {
    850: '#313131',
  },
};

export default darkModePalette;
