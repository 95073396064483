import { Box, Container, Stack, useTheme } from '@mui/material';
import useScrollOnDrag from 'src/lib/hooks/use-scroll-on-drag';
import { useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { TrainingModuleWithStatus } from 'src/store/api/trainings/trainingModulesApi';
import Sections from './sections';
import ModuleHeader from './ModuleHeader';
import ModuleOverviewBreadcrumbs from './ModuleOverviewBreadcrumbs';

interface Props {
  data: TrainingModuleWithStatus;
  editMode: boolean;
  onAddLessonCallback?: (id: string) => void;
  hideBreadcrumbs: boolean;
}

export default function ModuleOverviewContent({
  data,
  editMode,
  onAddLessonCallback,
  hideBreadcrumbs,
}: Props) {
  const contentRef = useScrollOnDrag();
  const theme = useTheme();
  const [isMobileView, setIsMobileView] = useState<boolean>();

  useResizeObserver(contentRef, (entry) => {
    setIsMobileView(entry.contentRect.width < theme.breakpoints.values.sm);
  });

  const containerPaddingStyles = isMobileView ? { px: '16px !important' } : undefined;

  return (
    <Stack alignItems="center" ref={contentRef} height="100%" overflow="auto">
      {isMobileView !== undefined && (
        <>
          {!hideBreadcrumbs && (
            <Container
              component={Box}
              maxWidth="lg"
              py={isMobileView ? 2 : 2.5}
              pb={isMobileView ? 0 : 2}
              sx={containerPaddingStyles}
            >
              <ModuleOverviewBreadcrumbs
                dataCy="module-overview-breadcrumbs"
                moduleName={data.name}
              />
            </Container>
          )}
          <ModuleHeader
            trainingModule={data}
            readOnly={!editMode}
            mobileView={isMobileView}
            dataCy="module-overview-header"
          />
          <Container maxWidth="lg" sx={containerPaddingStyles}>
            <Sections
              dataCy="module-overview-sections"
              moduleId={data.id}
              mobileView={isMobileView}
              readOnly={!editMode}
              onAddLessonCallback={onAddLessonCallback}
            />
          </Container>
        </>
      )}
    </Stack>
  );
}
