import { Typography, Stack, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function BetaBadge() {
  const { t } = useTranslation();
  return (
    <Stack
      gap={1.5}
      component={Paper}
      direction="row"
      alignItems="center"
      borderRadius={1}
      sx={{
        color: 'primary.color',
        px: 2,
      }}
    >
      <Typography variant="body2" fontWeight="bold">
        {t('beta.publicBeta')}
      </Typography>
      <Typography variant="caption" fontWeight="medium">
        {t('beta.featureFlags')}
      </Typography>
    </Stack>
  );
}
