import { Box, Stack } from '@mui/material';
import { useDrop } from 'react-dnd';
import { useEditor } from '../../../controller';
import { GridItemType, GridRow } from '../../../model';
import useDebouncedIsOver from './useDebouncedIsOver';

interface Props {
  rowIndex: number;
}

const getCanDropItem = (dragItemIndex: number, rowIndex: number, row?: GridRow) => {
  const isItemTheOnlyItemInTheRow = row?.items.length === 1;
  const canDropItem =
    (dragItemIndex === rowIndex && !isItemTheOnlyItemInTheRow) ||
    (dragItemIndex + 1 === rowIndex && !isItemTheOnlyItemInTheRow) ||
    (dragItemIndex !== rowIndex && dragItemIndex + 1 !== rowIndex);
  return canDropItem;
};

export default function DroppableRowInBetween({ rowIndex }: Props) {
  const { moveItemToNewRow } = useEditor();
  const [{ isOver, dragItemIndex, row }, drop] = useDrop({
    accept: 'GRID_ITEM',
    drop: (item: { rowIndex: number; item: GridItemType; row: GridRow }, monitor) => {
      if (monitor && getCanDropItem(dragItemIndex, rowIndex, row)) {
        moveItemToNewRow(item?.item.id, rowIndex);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      dragItemIndex: monitor.getItem()?.rowIndex,
      row: monitor.getItem()?.row,
    }),
  });
  const debouncedIsOver = useDebouncedIsOver(isOver);
  const canDropItem = getCanDropItem(dragItemIndex, rowIndex, row);
  if (!canDropItem) return null;
  return (
    <Stack
      ref={drop}
      height={40}
      position="absolute"
      width="100%"
      zIndex="3"
      left={0}
      top={0}
      sx={{
        transform: 'translateY(-50%)',
      }}
    >
      <Box
        height={8}
        m="auto"
        bgcolor={debouncedIsOver && canDropItem ? 'primary.main' : 'transparent'}
        width="100%"
        borderRadius={1}
      />
    </Stack>
  );
}
