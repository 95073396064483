import { useCallback, useEffect, useRef, useState } from 'react';
import fixWebmDuration from 'fix-webm-duration';

// eslint-disable-next-line max-statements
export default function useScreenRecordingManager(
  onRecordingFinishCallback: (blob: Blob) => void,
  onRecordingStartedCallback: () => void
) {
  const [startTime, setStartTime] = useState<number | null>(null);
  const [countdownVisible, setCountdownVisible] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const recordedChunksRef = useRef<Blob[]>([]);
  const [screenStream, setScreenStream] = useState<MediaStream | null>(null);
  const [selectedMicrophone, setSelectedMicrophone] = useState<string>('');
  const [selectedCamera, setSelectedCamera] = useState<string>('');
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordingStreamRef = useRef<MediaStream | null>(null);
  const recordingAudioStreamRef = useRef<MediaStream | null>(null);

  const handleStartRecording = () => {
    setCountdownVisible(true);
  };

  const onRecordedChunksCapture = useCallback((data: Blob) => {
    recordedChunksRef.current.push(data);
  }, []);

  const stopScreenStreamTracks = useCallback(() => {
    if (screenStream) {
      screenStream.getTracks().forEach((track) => track.stop());
    }
  }, [screenStream]);

  const stopRecordingStreamTracks = useCallback(() => {
    if (recordingStreamRef.current) {
      recordingStreamRef.current.getTracks().forEach((track) => track.stop());
    }
  }, [recordingStreamRef]);

  const stopRecordingAudioStreamTracks = useCallback(() => {
    if (recordingAudioStreamRef.current) {
      recordingAudioStreamRef.current.getTracks().forEach((track) => track.stop());
    }
  }, [recordingAudioStreamRef]);

  useEffect(() => () => stopScreenStreamTracks(), [stopScreenStreamTracks]);

  useEffect(
    () => () => {
      stopRecordingStreamTracks();
      stopRecordingAudioStreamTracks();
    },
    [stopRecordingStreamTracks, stopRecordingAudioStreamTracks]
  );

  // eslint-disable-next-line max-statements
  const handleCountdownFinish = useCallback(async () => {
    onRecordingStartedCallback();
    setCountdownVisible(false);
    setIsRecording(true);
    setStartTime(Date.now());
    const isMicrophoneSelected = !(selectedMicrophone === '' || selectedMicrophone === 'noDevice');
    const stream =
      screenStream ||
      (await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: selectedCamera || undefined,
          aspectRatio: { exact: 16 / 9, ideal: 16 / 9 },
        },
        audio: false,
      }));

    if (isMicrophoneSelected) {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: selectedMicrophone || undefined,
          echoCancellation: true,
          noiseSuppression: true,
        },
      });
      recordingAudioStreamRef.current = audioStream;
      const audioTracks = audioStream.getAudioTracks();
      audioTracks.forEach((track) => stream.addTrack(track));
    }

    recordingStreamRef.current = stream;

    let mimeType = 'video/webm;codecs=vp8';
    if (!MediaRecorder.isTypeSupported(mimeType)) {
      mimeType = 'video/mp4';
    }
    if (!MediaRecorder.isTypeSupported(mimeType)) {
      mimeType = '';
    }

    mediaRecorderRef.current = new MediaRecorder(stream, {
      videoBitsPerSecond: 8000000,
      mimeType,
    });

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        onRecordedChunksCapture(event.data);
      }
    };

    mediaRecorderRef.current.start();
  }, [
    mediaRecorderRef,
    selectedMicrophone,
    screenStream,
    selectedCamera,
    onRecordedChunksCapture,
    onRecordingStartedCallback,
  ]);

  const handlePauseRecording = () => {
    if (countdownVisible) {
      setCountdownVisible(false);
    } else {
      setIsRecordingPaused(true);
      mediaRecorderRef.current?.pause();
    }
  };

  const handleResumeRecording = () => {
    setIsRecordingPaused(false);
    mediaRecorderRef.current?.resume();
  };

  const handleCompleteRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.onstop = () => {
        const duration = Date.now() - (startTime || 0);
        if (recordedChunksRef.current.length > 0) {
          const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
          fixWebmDuration(blob, duration, onRecordingFinishCallback);
        }

        stopScreenStreamTracks();
        setScreenStream(null);

        stopRecordingStreamTracks();
        recordingStreamRef.current = null;

        stopRecordingAudioStreamTracks();
        recordingAudioStreamRef.current = null;
      };
      mediaRecorderRef.current?.stop();
    }
    setIsRecordingPaused(false);
    setIsRecording(false);
  };

  return {
    countdownVisible,
    isRecording,
    isRecordingPaused,
    handleStartRecording,
    handleCountdownFinish,
    handlePauseRecording,
    handleResumeRecording,
    handleCompleteRecording,
    onRecordedChunksCapture,
    recordedChunksRef,
    screenStream,
    setScreenStream,
    selectedMicrophone,
    setSelectedMicrophone,
    selectedCamera,
    setSelectedCamera,
  };
}
