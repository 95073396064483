import { Components, Theme } from '@mui/material';

const MuiTableRow: Components<Theme>['MuiTableRow'] = {
  styleOverrides: {
    root: {
      '&:last-child > .MuiTableCell-root': { borderBottom: 0 },
    },
  },
};

export default MuiTableRow;
