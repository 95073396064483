import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  readOnly?: boolean;
  dashed?: boolean;
}

export default function BorderWrapper({
  children,
  readOnly,
  dashed = false,

  sx,
  ...restOfProps
}: Props) {
  return (
    <Box
      borderRadius={1}
      borderColor={readOnly ? 'transparent' : 'divider'}
      sx={{ borderStyle: dashed ? 'dashed' : 'solid', borderWidth: 2, ...sx }}
      height="100%"
      {...restOfProps}
    >
      {children}
    </Box>
  );
}
