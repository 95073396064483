import { useParams } from 'react-router-dom';
import EditViewProtectedWrapper from 'src/core/EditViewProtectedWrapper';
import ModeSwitch from 'src/core/ModeSwitch';
import ModuleOverview from 'src/features/module-overview/view';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import { ShareLevel } from 'src/lib/types/share-level';

export default function ModuleOverviewView() {
  const param = useParams();
  const { data: shareLevelData, isSuccess } = useShareLevelQuery({
    resourceType: 'training',
    resourceId: param.trainingId as string,
  });
  const canEdit =
    shareLevelData?.shareLevel === ShareLevel.edit ||
    shareLevelData?.shareLevel === ShareLevel.manage;
  if (!isSuccess) return null;
  const editableContent = (
    <ModeSwitch>{(isEditing) => <ModuleOverview editMode={isEditing} />}</ModeSwitch>
  );
  const notEditableContent = <ModuleOverview editMode={false} />;
  return (
    <EditViewProtectedWrapper
      canEdit={canEdit}
      canView={shareLevelData.shareLevel !== ShareLevel.none}
    >
      {canEdit ? editableContent : notEditableContent}
    </EditViewProtectedWrapper>
  );
}
