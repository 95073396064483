import { Typography } from '@mui/material';
import DOMPurify from 'dompurify';

interface Props {
  content: string;
}

function HtmlContent({ content }: Props) {
  const createMarkup = (html: string) => ({ __html: DOMPurify.sanitize(html) });

  return <Typography noWrap dangerouslySetInnerHTML={createMarkup(content)} />;
}

export default HtmlContent;
