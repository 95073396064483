import { MediaPlayerInstance, MediaProvider, Poster, Track } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { useLazyVideoMetadataQuery } from 'src/store/api/videos';
import isTouchDevice from 'src/lib/utils/isTouchDevice';
import { VideoProgress } from 'src/store/api/trainings/videoProgressApi';
import useVideoProgress from './hooks/useVideoProgress';
import useDefaultLayoutTranslations from './hooks/useDefaultLayoutTranslations';
import CustomBufferingIndicator from './CustomBufferingIndicator';
import StyledMediaPlayer from './StyledMediaPlayer';
import useEncodingProgress from './hooks/useEncodingProgress';
import useInitializePlayer from './hooks/useInitializePlayer';
import MediaStorage from './MediaStorage';

interface Props {
  src: string | null;
  title?: string;
  poster: string;
  watchedDuration?: number;
  onVideoProgress?: (data: Partial<VideoProgress>) => void;
  disabledFastForward?: boolean;
  canPlay?: boolean;
  aspectRatio: number;
  videoId: string;
  captions: boolean;
  chapters: boolean;
}

export default function VidstackPlayer({
  src,
  title,
  poster,
  onVideoProgress,
  disabledFastForward = false,
  watchedDuration,
  canPlay = true,
  aspectRatio,
  videoId,
  captions,
  chapters,
}: Props) {
  const { t } = useTranslation();
  const playerRef = useRef<MediaPlayerInstance>(null);
  const translations = useDefaultLayoutTranslations();
  const { palette } = useTheme();

  const { handleTimeUpdate, handleEnded } = useVideoProgress({
    playerRef,
    watchedDuration,
    disabledFastForward,
    onVideoProgress,
  });

  const { handlePlayRequest, handleMouseDown } = useInitializePlayer(canPlay);

  const encodingProgress = useEncodingProgress(videoId, src);

  const [fetchVideoMetadataQuery, { data }] = useLazyVideoMetadataQuery();

  useEffect(() => {
    if (src || captions || chapters) {
      fetchVideoMetadataQuery(videoId);
    }
  }, [captions, chapters, src, fetchVideoMetadataQuery, videoId]);

  const progress =
    disabledFastForward && playerRef.current?.duration && watchedDuration
      ? watchedDuration / (playerRef.current?.duration || 1)
      : undefined;

  const transcription = data?.transcriptions[0];
  return (
    <StyledMediaPlayer
      key={poster}
      ref={playerRef}
      style={{ height: '100%', pointerEvents: src ? 'auto' : 'none' }}
      src={src || undefined}
      viewType="video"
      streamType="on-demand"
      logLevel="warn"
      crossOrigin
      playsInline
      title={title}
      controlsDelay={1500}
      aspectRatio={aspectRatio?.toString()}
      poster={poster}
      onMouseDown={handleMouseDown}
      $progress={progress}
      onTimeUpdate={handleTimeUpdate}
      onEnded={handleEnded}
      storage={MediaStorage}
      onMediaPlayRequest={handlePlayRequest}
    >
      <MediaProvider>
        <Poster className="vds-poster" />
        <Track src={data?.chapters || undefined} kind="chapters" type="vtt" default />
        {transcription && transcription.languageCode !== 'und' && (
          <Track
            src={transcription?.url || undefined}
            kind="subtitles"
            label={t(`languages.${transcription.languageCode}`)}
            type="vtt"
            default
          />
        )}
      </MediaProvider>
      <DefaultVideoLayout
        icons={defaultLayoutIcons}
        translations={translations}
        colorScheme={palette.mode}
        noModal={!isTouchDevice()}
        thumbnails={data?.previewThumbnailVttUrl}
        slots={{
          bufferingIndicator: (
            <CustomBufferingIndicator src={src} encodingProgress={encodingProgress} t={t} />
          ),
          googleCastButton: null,
        }}
      />
    </StyledMediaPlayer>
  );
}
