import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  styled,
  Stack,
  Button,
} from '@mui/material';
import DocViewer from '@cyntler/react-doc-viewer';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useTranslation } from 'react-i18next';
import { WorkbaseCloseCircleIcon } from 'src/assets/icons/workbaseIcons';
import Empty from 'src/ui-components/custom/empty/Empty';
import getFileIcon from '../helpers/fileIconMap';
import { useDownloadFileMutation } from '../../../store/api/driveApi'; // Import RTK Query hook

interface FilePreviewDialogProps {
  open: boolean;
  onClose: () => void;
  fileId: string;
  fileType: string;
  fileName?: string;
}
// not mui
// eslint-disable-next-line no-restricted-syntax
const StyledDocViewer = styled(DocViewer)(({ theme }) => ({
  // Example: Make the background color adapt to the theme's background
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  overflowX: 'hidden',
  overflowY: 'auto',
  maxHeight: '100%',
  background: `${theme.palette.background.paper} !important`,
  '& #header-bar': {
    background: theme.palette.background.default,
    '& #file-name': {
      color: theme.palette.text.primary,
    },
  },
  '& #proxy-renderer': {
    display: 'flex',
    flexGrow: 1,
  },
  '& #msdoc-renderer': {
    display: 'flex',
    flexGrow: 1,
    minHeight: '500px',
  },
  // Add more styles as needed to match your design preferences
}));

function FilePreviewDialog({ open, onClose, fileId, fileType, fileName }: FilePreviewDialogProps) {
  const [downloadFile] = useDownloadFileMutation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [document, setDocument] = useState<any[]>([]);

  const handleDownloadFile = async () => {
    setIsLoading(true);
    const result = await downloadFile(fileId);

    setIsLoading(false);
    if (result && 'data' in result) {
      // Use the signed URL directly
      const { downloadUrl } = result.data;

      setDocument([{ uri: downloadUrl, fileName }]);
      setDownloadLink(downloadUrl);
    }
  };

  const handleFileDownload = async () => {
    try {
      const response = await fetch(downloadLink as unknown as URL);
      const blob = await response.blob();

      const link = window.document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = '';
      window.document.body.appendChild(link);
      link.click();
      window.document.body.removeChild(link);
    } catch (_err) {
      showNotification(t('documentsPage.downloadFailed'));
    }
  };

  useEffect(() => {
    if (getFileIcon(fileType) === '📁') {
      setError('display');
    } else {
      setError(null);
    }
    if (fileId) {
      handleDownloadFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId, fileType]);

  return (
    <Dialog open={open} onClose={onClose} scroll="body" fullWidth maxWidth="lg">
      <DialogTitle>{t('documentsPage.viewFile')}</DialogTitle>

      <Stack maxHeight="100%" flexGrow={1} overflow="hidden">
        <DialogContent>
          <Stack direction="column" gap={2}>
            {isLoading && <CircularProgress />}
            {!isLoading && error && (
              <Empty
                icon={WorkbaseCloseCircleIcon}
                description={t('documentsPage.unsupportedFile')}
              />
            )}
            {!isLoading && !error && document.length > 0 && (
              <StyledDocViewer documents={document} className="doc-viewer" />
            )}
            {!isLoading && downloadLink && (
              <Button
                variant="contained"
                onClick={handleFileDownload}
                color="primary"
                data-cy="documents-page-download-button"
              >
                {t('documentsPage.download')}
              </Button>
            )}
          </Stack>
        </DialogContent>
      </Stack>
    </Dialog>
  );
}

export default FilePreviewDialog;
