import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import { ShareLevel } from 'src/lib/types/share-level';

export default function useTrainingPermissions() {
  const param = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    data: shareLevelData,
    isSuccess,
    isFetching,
  } = useShareLevelQuery({
    resourceType: 'training',
    resourceId: param.trainingId as string,
  });

  useEffect(() => {
    if (shareLevelData?.shareLevel === ShareLevel.none) {
      navigate('/');
    }
  }, [shareLevelData?.shareLevel, navigate]);

  const hasEditingPermissions =
    shareLevelData?.shareLevel !== ShareLevel.view &&
    shareLevelData?.shareLevel !== ShareLevel.none;

  useEffect(() => {
    const isEditPath = pathname.endsWith('/edit');
    if (isSuccess && !hasEditingPermissions && isEditPath && !isFetching) {
      navigate(pathname.slice(0, -5));
    }
  }, [isSuccess, hasEditingPermissions, navigate, param.trainingId, pathname, isFetching]);

  return { hasEditingPermissions };
}
