import { MenuItem, Select, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import { ShareLevel } from 'src/lib/types/share-level';
import { useTranslation } from 'react-i18next';
import {
  GeneralAccessType,
  ResourceType,
  useDeleteSharedResourceMutation,
  useLazyPermissionAfterChangeQuery,
  usePatchGeneralAccessMutation,
  usePatchSharedResourceMutation,
} from 'src/store/api/shareResourceApi';
import {
  WorkbasePermissions,
  WorkbaseDeleteSquareIcon,
  WorkbaseEyeViewIcon,
  WorkbaseEditIcon,
} from 'src/assets/icons/workbaseIcons';
import { useConfirm } from 'material-ui-confirm';

interface PermissionSelectProps {
  resourceId: string;
  resourceType: ResourceType;
  id?: string;
  shareLevel?: ShareLevel;
  isGeneralAccess?: boolean;
  isEveryone?: boolean;
  dataCy?: string;
}

interface ShareLevelMenuItem {
  value: ShareLevel | 'delete';
  label: string;
  startIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  onClick: () => void;
  description?: string;
  divider?: boolean;
}

function PermissionSelect({
  resourceId,
  resourceType,
  id,
  shareLevel,
  isGeneralAccess = false,
  isEveryone = true,
  dataCy,
}: PermissionSelectProps): JSX.Element {
  const { t } = useTranslation();
  const [patchGeneralAccess] = usePatchGeneralAccessMutation();
  const [patchResource] = usePatchSharedResourceMutation();
  const [deleteResource] = useDeleteSharedResourceMutation();
  const confirm = useConfirm();
  const [trigger] = useLazyPermissionAfterChangeQuery();

  const contentTypeTextDict: Record<ResourceType, string> = {
    training: t('permissionsShare.permissionChangeWarningModal.training'),
    embeddedApp: t('permissionsShare.permissionChangeWarningModal.embeddedApp'),
    page: t('permissionsShare.permissionChangeWarningModal.page'),
    whiteboard: t('permissionsShare.permissionChangeWarningModal.whiteboard'),
    wikiCard: t('permissionsShare.permissionChangeWarningModal.wikiCard'),
    file: '',
  };
  const contentTypeText = resourceType && contentTypeTextDict[resourceType];

  const handleDeleteResource = () =>
    deleteResource({
      resourceType: resourceType as ResourceType,
      resourceId: resourceId as string,
      sharedWithId: id as string,
    });

  const checkShareLevelAfterChange = (newShareLevel: ShareLevel) =>
    trigger({
      resourceType: resourceType as ResourceType,
      resourceId: resourceId as string,
      shareLevel: newShareLevel,
      resourceShareId: id as string,
    });

  const handleConfirmDangerousPatch = (newShareLevel: ShareLevel) => {
    let permissionChangeWarningModalContent = '';
    if (newShareLevel === ShareLevel.none) {
      permissionChangeWarningModalContent = t(
        'permissionsShare.permissionChangeWarningModal.looseAccess',
        { contentType: contentTypeText }
      );
    } else if ([ShareLevel.view, ShareLevel.edit].includes(newShareLevel)) {
      permissionChangeWarningModalContent = t(
        'permissionsShare.permissionChangeWarningModal.lowerPermission',
        { contentType: contentTypeText }
      );
    }
    confirm({
      title: t('areYouSure'),
      description: permissionChangeWarningModalContent,
    }).then(() => {
      patchResource({
        resourceType: resourceType as ResourceType,
        resourceId: resourceId as string,
        id: id as string,
        shareLevel: newShareLevel,
      });
    });
  };

  const patchMutation = (props: {
    shareLevel?: ShareLevel;
    required?: boolean;
    type?: GeneralAccessType;
  }) => {
    if (isGeneralAccess) {
      patchGeneralAccess({
        resourceType: resourceType as ResourceType,
        resourceId: resourceId as string,
        ...props,
      });
    } else if (
      shareLevel === ShareLevel.manage &&
      props.shareLevel !== ShareLevel.manage &&
      props.shareLevel !== undefined
    ) {
      checkShareLevelAfterChange(props.shareLevel).then((resp) => {
        if (resp.data?.shareLevel === ShareLevel.manage) {
          patchResource({
            resourceType: resourceType as ResourceType,
            resourceId: resourceId as string,
            id: id as string,
            ...props,
          });
        } else {
          handleConfirmDangerousPatch(resp.data?.shareLevel as ShareLevel);
        }
      });
    } else {
      patchResource({
        resourceType: resourceType as ResourceType,
        resourceId: resourceId as string,
        id: id as string,
        ...props,
      });
    }
  };

  const manageDeleteOptions: ShareLevelMenuItem[] = [
    {
      value: ShareLevel.manage,
      label: t('permissionsShare.manager'),
      startIcon: WorkbasePermissions,
      onClick: () =>
        shareLevel !== ShareLevel.manage && patchMutation({ shareLevel: ShareLevel.manage }),
      description: t('permissionsShare.managerDescription'),
    },
    {
      value: 'delete',
      label: t('permissionsShare.removeAccess'),
      startIcon: WorkbaseDeleteSquareIcon,
      onClick: () => {
        if (shareLevel === ShareLevel.manage) {
          checkShareLevelAfterChange(ShareLevel.none).then((resp) => {
            if (resp.data?.shareLevel === ShareLevel.manage) {
              handleDeleteResource();
            } else {
              patchMutation({ shareLevel: ShareLevel.none });
            }
          });
        } else {
          handleDeleteResource();
        }
      },
      divider: true,
    },
  ];

  const shareLevelMenuItems: ShareLevelMenuItem[] = [
    {
      value: ShareLevel.view,
      label: t('permissionsShare.view'),
      startIcon: WorkbaseEyeViewIcon,
      onClick: () =>
        shareLevel !== ShareLevel.view && patchMutation({ shareLevel: ShareLevel.view }),
    },
    {
      value: ShareLevel.edit,
      label: t('permissionsShare.edit'),
      startIcon: WorkbaseEditIcon,
      onClick: () =>
        shareLevel !== ShareLevel.edit && patchMutation({ shareLevel: ShareLevel.edit }),
    },
    ...(isGeneralAccess ? [] : manageDeleteOptions),
  ];

  return (
    <Select size="small" value={shareLevel} disabled={!isEveryone}>
      {shareLevelMenuItems.map((item) => (
        <MenuItem
          key={item.value}
          value={item.value}
          onClick={item.onClick}
          data-cy={dataCy && `${dataCy}-permission-menu-${item.label}-item`}
        >
          <Tooltip disableInteractive title={item.description}>
            <Stack direction="row" alignItems="center" gap={1} width="100%">
              <SvgIcon component={item.startIcon} fontSize="small" />
              <Stack>
                <Typography>{item.label}</Typography>
              </Stack>
            </Stack>
          </Tooltip>
        </MenuItem>
      ))}
    </Select>
  );
}

export default PermissionSelect;
