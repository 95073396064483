import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';
import React, { createContext, useState, useContext, ReactNode, useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

interface UnsavedChangesContextType {
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  handleSafeSetSearchParams: (params: Record<string, string>) => void;
  confirmUnsavedChanges: () => Promise<void>;
}

const UnsavedChangesContext = createContext<UnsavedChangesContextType | undefined>(undefined);

interface UnsavedChangesProviderProps {
  children: ReactNode;
}

function UnsavedChangesProvider({ children }: UnsavedChangesProviderProps) {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const { t } = useTranslation();
  const confirm = useConfirm();
  const [, setSearchParams] = useSearchParams();

  const confirmUnsavedChanges = useCallback(() => {
    return confirm({
      title: t('unsavedChanges.title'),
      description: t('unsavedChanges.description'),
    });
  }, [confirm, t]);

  const handleSafeSetSearchParams = useCallback(
    (params: Record<string, string>) => {
      if (hasUnsavedChanges) {
        confirmUnsavedChanges()
          .then(() => {})
          .then(() => {
            setSearchParams(params);
            setHasUnsavedChanges(false);
          });
      } else {
        setSearchParams(params);
      }
    },
    [hasUnsavedChanges, confirmUnsavedChanges, setSearchParams]
  );

  const contextValue = useMemo(
    () => ({
      hasUnsavedChanges,
      setHasUnsavedChanges,
      handleSafeSetSearchParams,
      confirmUnsavedChanges,
    }),
    [hasUnsavedChanges, setHasUnsavedChanges, handleSafeSetSearchParams, confirmUnsavedChanges]
  );

  return (
    <UnsavedChangesContext.Provider value={contextValue}>{children}</UnsavedChangesContext.Provider>
  );
}

function useUnsavedChanges(): UnsavedChangesContextType {
  const context = useContext(UnsavedChangesContext);
  if (context === undefined) {
    throw new Error('useUnsavedChanges must be used within a UnsavedChangesProvider');
  }
  return context;
}

export { UnsavedChangesProvider, useUnsavedChanges };
