import { Components, Theme } from '@mui/material';
import { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef } from 'react';
import { WorkbaseChevronArrowLottie } from 'src/assets/lottie';
import LottieContainer from 'src/ui-components/custom/lottie/LottieContainer';

const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    IconComponent: (props) => {
      const ref = useRef<LottieRefCurrentProps | null>(null);
      const isOpen = props.className.includes('MuiSelect-iconOpen');
      let className = props.className;
      className = className.replace(' MuiSelect-iconOpen', '');
      useEffect(() => {
        if (isOpen) {
          ref.current?.playSegments([0, 70], true);
        } else {
          ref.current?.playSegments([70, 140], true);
        }
      }, [isOpen]);
      return (
        <LottieContainer
          animationData={WorkbaseChevronArrowLottie}
          lottieRef={ref}
          autoplay={false}
          loop={false}
          className={className}
        />
      );
    },
  },
  styleOverrides: {
    root: {
      '& .MuiSelect-select': {
        paddingRight: '48px !important',
      },
    },
    icon: {
      right: 14,
      transform: 'none !important',
    },
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        '& .MuiSelect-select': {
          padding: '8px 14px',
        },
      },
    },
  ],
};

export default MuiSelect;
