import { Stack, SvgIcon, SvgIconProps, Typography, TypographyProps } from '@mui/material';
import React, { ElementType, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbaseHelpIcon } from 'src/assets/icons/workbaseIcons';

export interface EmptyProps {
  description?: React.ReactNode;
  icon?: ElementType;
  svgIconProps?: SvgIconProps;
  typographyProps?: TypographyProps;
  children?: ReactNode;
}

export default function Empty({
  children,
  description,
  icon,
  svgIconProps,
  typographyProps,
}: EmptyProps) {
  const { t } = useTranslation();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      maxWidth="80%"
      gap={1}
      margin="auto"
      py={8}
      data-cy="empty-placeholder"
    >
      <SvgIcon
        fontSize="large"
        {...svgIconProps}
        component={icon ?? WorkbaseHelpIcon}
        data-cy="empty-placeholder-icon"
      />
      <Typography
        variant="h5"
        fontWeight="medium"
        {...typographyProps}
        data-cy="empty-placeholder-description"
      >
        {description ?? t('placeholder')}
      </Typography>
      <Typography textAlign="center">{children}</Typography>
    </Stack>
  );
}
