import { Components, Theme } from '@mui/material';

const MuiSlider: Components<Theme>['MuiSlider'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: `calc(100% - ${theme.spacing(4)})`,
    }),
  },
};

export default MuiSlider;
