import { DefaultLayoutTranslations } from '@vidstack/react/types/vidstack';
import { useTranslation } from 'react-i18next';

export default function useDefaultLayoutTranslations(): DefaultLayoutTranslations {
  const { t, i18n } = useTranslation();
  const lang = i18n.language as 'en' | 'de';

  const langDict: { en: DefaultLayoutTranslations; de: DefaultLayoutTranslations } = {
    en: {
      'Caption Styles': t('Caption Styles'),
      'Captions look like this': t('Captions look like this'),
      'Closed-Captions Off': t('Closed-Captions Off'),
      'Closed-Captions On': t('Closed-Captions On'),
      'Display Background': t('Display Background'),
      'Enter Fullscreen': t('Enter Fullscreen'),
      'Enter PiP': t('Enter PiP'),
      'Exit Fullscreen': t('Exit Fullscreen'),
      'Exit PiP': t('Exit PiP'),
      'Google Cast': t('Google Cast'),
      'Keyboard Animations': t('Keyboard Animations'),
      'Seek Backward': t('Seek Backward'),
      'Seek Forward': t('Seek Forward'),
      'Skip To Live': t('Skip To Live'),
      'Text Background': t('Text Background'),
      Accessibility: t('Accessibility'),
      AirPlay: t('AirPlay'),
      Announcements: t('Announcements'),
      Audio: t('Audio'),
      Auto: t('Auto'),
      Boost: t('Boost'),
      Captions: t('Captions'),
      Chapters: t('Chapters'),
      Color: t('Color'),
      Connected: t('Connected'),
      Connecting: t('Connecting'),
      Continue: t('Continue'),
      Default: t('Default'),
      Disabled: t('Disabled'),
      Disconnected: t('Disconnected'),
      Download: t('Download'),
      Family: t('Family'),
      Font: t('Font'),
      Fullscreen: t('Fullscreen'),
      LIVE: t('LIVE'),
      Loop: t('Loop'),
      Mute: t('Mute'),
      Normal: t('Normal'),
      Off: t('Off'),
      Opacity: t('Opacity'),
      Pause: t('Pause'),
      PiP: t('PiP'),
      Play: t('Play'),
      Playback: t('Playback'),
      Quality: t('Quality'),
      Replay: t('Replay'),
      Reset: t('Reset'),
      Seek: t('Seek'),
      Settings: t('Settings'),
      Shadow: t('Shadow'),
      Size: t('Size'),
      Speed: t('Speed'),
      Text: t('Text'),
      Track: t('Track'),
      Unmute: t('Unmute'),
      Volume: t('Volume'),
    },
    de: {
      'Caption Styles': t('Untertitelstile'),
      'Captions look like this': t('Untertitel sehen so aus'),
      'Closed-Captions Off': t('Untertitel aus'),
      'Closed-Captions On': t('Untertitel an'),
      'Display Background': t('Hintergrund anzeigen'),
      'Enter Fullscreen': t('Vollbildmodus aktivieren'),
      'Enter PiP': t('Bild-in-Bild aktivieren'),
      'Exit Fullscreen': t('Vollbildmodus verlassen'),
      'Exit PiP': t('Bild-in-Bild beenden'),
      'Google Cast': t('Google Cast'),
      'Keyboard Animations': t('Tastaturanimationen'),
      'Seek Backward': t('Zurückspulen'),
      'Seek Forward': t('Vorspulen'),
      'Skip To Live': t('Zu Live springen'),
      'Text Background': t('Text Hintergrund'),
      Accessibility: t('Barrierefreiheit'),
      AirPlay: t('AirPlay'),
      Announcements: t('Ankündigungen'),
      Audio: t('Audio'),
      Auto: t('Automatisch'),
      Boost: t('Verstärken'),
      Captions: t('Untertitel'),
      Chapters: t('Kapitel'),
      Color: t('Farbe'),
      Connected: t('Verbunden'),
      Connecting: t('Verbinden'),
      Continue: t('Weiter'),
      Default: t('Standard'),
      Disabled: t('Deaktiviert'),
      Disconnected: t('Getrennt'),
      Download: t('Herunterladen'),
      Family: t('Familie'),
      Font: t('Schriftart'),
      Fullscreen: t('Vollbild'),
      LIVE: t('LIVE'),
      Loop: t('Schleife'),
      Mute: t('Stumm'),
      Normal: t('Normal'),
      Off: t('Aus'),
      Opacity: t('Deckkraft'),
      Pause: t('Pause'),
      PiP: t('Bild-in-Bild'),
      Play: t('Abspielen'),
      Playback: t('Wiedergabe'),
      Quality: t('Qualität'),
      Replay: t('Erneut abspielen'),
      Reset: t('Zurücksetzen'),
      Seek: t('Suchen'),
      Settings: t('Einstellungen'),
      Shadow: t('Schatten'),
      Size: t('Größe'),
      Speed: t('Geschwindigkeit'),
      Text: t('Text'),
      Track: t('Spur'),
      Unmute: t('Stummschaltung aufheben'),
      Volume: t('Lautstärke'),
    },
  };

  return langDict[lang];
}
