import { Components, Theme } from '@mui/material';

const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  styleOverrides: {
    root: {
      padding: 20,
    },
  },
};

export default MuiDialogTitle;
