import { TopBar } from 'src/ui-components/layout/top-bar';
import { ShareLevel } from 'src/lib/types/share-level';
import EmbeddedAppInputs from './EmbeddedAppInputs';
import EmbeddedAppActions from './EmbeddedAppActions';

interface Props {
  id: string;
  name?: string;
  shareLevel?: ShareLevel;
  hasAddPermission?: boolean;
  sidebar?: boolean;
}

function EmbeddedAppTopBar({
  id,
  name,
  shareLevel = ShareLevel.none,
  hasAddPermission = false,
  sidebar,
}: Props) {
  return (
    <TopBar
      leftContent={<EmbeddedAppInputs id={id} />}
      rightContent={
        <EmbeddedAppActions
          sidebar={sidebar}
          id={id}
          name={name}
          shareLevel={shareLevel}
          hasAddPermission={hasAddPermission}
        />
      }
    />
  );
}

export default EmbeddedAppTopBar;
