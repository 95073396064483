import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseEditIcon } from '../../../../../../../assets/icons/workbaseIcons';

export interface EmbeddedActionsProps {
  onClick: () => void;
}

export default function EmbeddedActions({ onClick }: EmbeddedActionsProps) {
  const { t } = useTranslation();
  return (
    <Tooltip title={t(`editor.topBar.tooltip.edit`)} placement="top">
      <span>
        <IconButton
          data-cy="editor-topbar-embedded-edit-button"
          onMouseDown={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          <SvgIcon component={WorkbaseEditIcon} fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  );
}
