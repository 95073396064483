import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

export type ResourceType =
  | 'page'
  | 'training_lesson'
  | 'training'
  | 'training_module'
  | 'embedded_app'
  | 'company_user';

export interface ResultData {
  id: string;
  name: string;
  trainingId: string | null;
  trainingModuleId: string | null;
  trainingSectionId: string | null;
  imageUrl: string | null;
  source: ResourceType;
  context: string | null;
  email: string;
  icon: string | null;
  defaultImage: string | null;
}

export const searchApi = createApi({
  reducerPath: 'search',
  baseQuery,
  endpoints: (builder) => ({
    search: builder.query<ResultData[], string>({
      query: (searchQuery) => `/search/?query=${searchQuery}`,
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useSearchQuery } = searchApi;
