import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

export interface Link {
  id: string;
  name: string;
  url: string;
  imageUrl: string;
}

export type ResourceType = 'role' | 'page' | 'wikiCard';

interface Resource {
  resourceType: ResourceType;
  resourceId: string;
}

export const linksApi = createApi({
  reducerPath: 'linksApi',
  tagTypes: ['ResourceLinks'],
  baseQuery,
  endpoints: (builder) => ({
    links: builder.query<Link[], Resource>({
      query: ({ resourceId, resourceType }) => ({
        url: `/links`,
        params: { resourceId, resourceType },
      }),
      providesTags: (_, __, { resourceId }) => [{ type: 'ResourceLinks', id: resourceId }],
    }),
    postLinkToResource: builder.mutation<Link, Resource & Omit<Link, 'id' | 'imageUrl'>>({
      query: (payload) => ({
        url: '/links',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (_, __, { resourceId }) => [{ type: 'ResourceLinks', id: resourceId }],
      async onQueryStarted(newLink, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          linksApi.util.updateQueryData(
            'links',
            { resourceId: newLink.resourceId, resourceType: newLink.resourceType },
            (draft) => {
              draft.push({ ...newLink, id: 'temp-id', imageUrl: '' });
            }
          )
        );
        try {
          const { data: createdLink } = await queryFulfilled;
          dispatch(
            linksApi.util.updateQueryData(
              'links',
              { resourceId: newLink.resourceId, resourceType: newLink.resourceType },
              (draft) => {
                const index = draft.findIndex((link) => link.id === 'temp-id');
                if (index !== -1) {
                  draft[index] = createdLink;
                }
              }
            )
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
    patchLink: builder.mutation<
      Link,
      Resource & Partial<Omit<Link, 'imageUrl' | 'id'>> & { imageId?: string | null; id: string }
    >({
      query: ({ id, ...rest }) => ({
        url: `/links/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: (_, __, { resourceId }) => [{ type: 'ResourceLinks', id: resourceId }],
    }),
    removeLink: builder.mutation<void, Resource & { id: string }>({
      query: ({ id, ...rest }) => ({
        url: `/links/${id}`,
        method: 'DELETE',
        body: rest,
      }),
      invalidatesTags: (_, __, { resourceId }) => [{ type: 'ResourceLinks', id: resourceId }],
    }),
  }),
});

export const {
  useLinksQuery,
  usePostLinkToResourceMutation,
  usePatchLinkMutation,
  useRemoveLinkMutation,
} = linksApi;
