import { useCallback, useState } from 'react';
import {
  getDescendants,
  NodeRender,
  PlaceholderRender,
  Tree,
  TreeProps,
} from '@minoru/react-dnd-treeview';
import DndIndicator from 'src/ui-components/custom/dnd-indicator/DndIndicator';
import useTreeOpenHandler from './useTreeOpenHandler';
import Node from './Node';
import StyledWrapper from './StyledWrapper';
import { TreeNodeData } from './types';
import useOnDrop from './useOnDrop';

type OptionalTreePropsKeys = 'render' | 'rootId';

interface OptionalTreeProps extends Partial<Pick<TreeProps<TreeNodeData>, OptionalTreePropsKeys>> {}

interface MandatoryTreeProps extends Omit<TreeProps<TreeNodeData>, OptionalTreePropsKeys> {}

export interface HierarchicalTreeProps extends OptionalTreeProps, MandatoryTreeProps {
  disableListPaddings?: boolean;
}

export default function HierarchicalTree({
  tree,
  rootId,
  onDrop,
  classes,
  disableListPaddings = false,
  ...restOfProps
}: HierarchicalTreeProps) {
  const { ref, toggle, openIds, setOpenIds } = useTreeOpenHandler(tree);
  const [isDragging, setIsDragging] = useState(false);

  const renderNode = useCallback<NodeRender<TreeNodeData>>(
    (node, renderParams) => (
      <Node
        node={node}
        onToggleOpen={() => {
          if (node.droppable) {
            toggle(node?.id);
          }
        }}
        renderParams={renderParams}
        treeData={tree}
        isDragging={isDragging}
      />
    ),
    [tree, toggle, isDragging]
  );

  const renderPlaceholder = useCallback<PlaceholderRender<TreeNodeData>>(
    (_, { depth }) => (
      <DndIndicator
        sx={{ left: depth * 12, right: 0, width: 'auto !important', transform: 'translateY(-50%)' }}
      />
    ),
    []
  );

  const handleDrop = useOnDrop({ tree, toggle, openedNodes: openIds, onDrop });

  const noListPaddingsStyles = {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    '.treeRoot ul': {
      paddingBottom: '0px',
    },
    '& > ul > .listItem:first-child': {
      marginTop: '0px !important',
    },
  };
  return (
    <StyledWrapper sx={disableListPaddings ? noListPaddingsStyles : undefined}>
      <Tree
        ref={ref}
        classes={{
          root: 'treeRoot',
          placeholder: 'placeholder',
          listItem: 'listItem',
          ...classes,
        }}
        tree={tree}
        sort={false}
        rootId={rootId || 0}
        initialOpen={openIds}
        onChangeOpen={(nodes) => {
          setOpenIds(nodes);
        }}
        insertDroppableFirst={false}
        enableAnimateExpand
        canDrop={(data, options) => {
          const descendants =
            (options.dragSourceId && getDescendants(data, options.dragSourceId)) || [];
          const isDroppingIntoDescendant = !!descendants.find((d) => d.id === options.dropTargetId);
          const isDroppingIntoItself = options.dragSourceId === options.dropTargetId;
          return !isDroppingIntoDescendant && !isDroppingIntoItself;
        }}
        onDragStart={() => {
          setIsDragging(true);
        }}
        onDragEnd={() => {
          setIsDragging(false);
        }}
        canDrag={() => true}
        dropTargetOffset={10}
        placeholderRender={renderPlaceholder}
        render={renderNode}
        onDrop={handleDrop}
        {...restOfProps}
      />
    </StyledWrapper>
  );
}
