import { RefObject, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function useScrollIntoView(lessonId: string, elementRef: RefObject<HTMLDivElement>) {
  const params = useParams<{ trainingId: string; moduleId: string; lessonId?: string }>();
  useEffect(() => {
    if (params.lessonId === lessonId) {
      const rect = elementRef.current?.getBoundingClientRect();
      if (rect && rect.y > window.innerHeight) {
        setTimeout(() => {
          elementRef.current?.scrollIntoView({ block: 'center' });
        });
      }
    }
  }, [lessonId, params.lessonId, elementRef]);
}
