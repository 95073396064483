import { Box } from '@mui/material';
import { useDrop } from 'react-dnd';
import { useEditor } from '../../../controller';
import { GridItemType } from '../../../model';

interface Props {
  lastRowIndex: number;
  oneColMode: boolean;
  lastGridItemId?: string;
}

export default function DroppableBottomRow({ lastRowIndex, oneColMode, lastGridItemId }: Props) {
  const { moveItemToEndAndExpand } = useEditor();
  const [{ isOver, dragItemIndex, item }, drop] = useDrop({
    accept: 'GRID_ITEM',
    drop: (i: { rowIndex: number; item: GridItemType }, monitor) => {
      if (
        monitor &&
        !(i.item.columnStart === 1 && i.item.columnEnd === 5 && lastRowIndex === dragItemIndex)
      ) {
        moveItemToEndAndExpand(i?.item.id);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      dragItemIndex: monitor.getItem()?.rowIndex,
      item: monitor.getItem()?.item,
    }),
  });
  const canDropItem =
    !(item?.columnStart === 1 && item?.columnEnd === 5 && lastRowIndex === dragItemIndex) &&
    oneColMode
      ? lastGridItemId !== item?.id
      : true;
  return (
    <Box ref={drop} height={100} mx="auto">
      <Box
        height={8}
        borderRadius={1}
        bgcolor={isOver && canDropItem ? 'primary.main' : 'transparent'}
        flexGrow={1}
      />
    </Box>
  );
}
