import { CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import CircularProgressWithContent from './CircularProgressWithContent';

interface Props {
  value: number;
  circularProgressProps?: Omit<CircularProgressProps, 'value'>;
  backdropCircularProgressProps?: Omit<CircularProgressProps, 'value'>;
}

export default function CircularProgressWithLabel({
  value,
  circularProgressProps,
  backdropCircularProgressProps,
}: Props) {
  return (
    <CircularProgressWithContent
      circularProgressProps={{
        variant: 'determinate',
        color: 'success',
        ...circularProgressProps,
        value,
      }}
    >
      <CircularProgress
        {...backdropCircularProgressProps}
        variant="determinate"
        value={100}
        sx={{
          position: 'absolute',
          color: 'divider',
          ...backdropCircularProgressProps?.sx,
        }}
      />
      <Typography variant="caption" fontWeight="medium">{`${Math.round(value)}%`}</Typography>
    </CircularProgressWithContent>
  );
}
