import ExpandedTree from './expanded';
import ShrankTree from './shrank/ShrankTree';
import useTreeData from './useTreeData';

interface Props {
  expanded: boolean;
}

export default function SidebarTree({ expanded }: Props) {
  const data = useTreeData();
  return expanded ? <ExpandedTree data={data} /> : <ShrankTree data={data} />;
}
