import { Box } from '@mui/material';
import UploadProgress from './UploadProgress';
import { FileType } from '../edit-bar/actions/UploadActions';

interface Props {
  loading: boolean;
  uploadProgress: number;
  onStopUploading: () => void;
  fileSize?: number;
  children: React.ReactNode;
  mediaType: FileType;
}

export default function WithUploadProgressWrapper({
  loading,
  uploadProgress,
  onStopUploading,
  fileSize,
  children,
  mediaType,
}: Props) {
  return (
    <>
      <Box height="100%" visibility={loading ? 'hidden' : 'visible'}>
        {children}
      </Box>
      {loading && (
        <Box
          position="absolute"
          left={0}
          top={0}
          bottom={0}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={1000000}
        >
          <UploadProgress
            mediaType={mediaType}
            size={fileSize || 0}
            progress={uploadProgress}
            onClose={onStopUploading}
          />
        </Box>
      )}
    </>
  );
}
