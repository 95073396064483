import { Stack, IconButton, SvgIcon, TextField, InputBase, Box, Container } from '@mui/material';
import { memo, useContext, useRef, useEffect } from 'react';
import {
  WorkbaseArrowUpCircleIcon,
  WorkbaseAttachmentIcon,
  WorkbaseCloseCircleIcon,
} from 'src/assets/icons/workbaseIcons';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useTranslation } from 'react-i18next';
import { MessageContent, useCreateThreadMutation } from 'src/store/api/aiChatApi';
import { useNavigate } from 'react-router-dom';
import useIsMobile from 'src/hooks/useIsMobile';
import { ChatContext } from '../../helpers/chatContext';
import AttachmentImage from './AttachmentImage';
import { loadAllUserData } from '../../helpers/use-socket';

function DialogInputs() {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const isMobile = useIsMobile();
  const textFieldRef = useRef<HTMLInputElement>(null);
  const { state, dispatch, sendJsonMessage } = useContext(ChatContext);
  const { t } = useTranslation();
  const [createThread] = useCreateThreadMutation();
  const navigate = useNavigate();
  useEffect(() => {
    textFieldRef.current?.focus();
  }, [state.threadId, state.imageUrl]);

  const createMessageContents = (): MessageContent[] => {
    const contents: MessageContent[] = [];
    if (state.currentInput.trim()) {
      contents.push({ type: 'text', text: state.currentInput });
    }
    if (state.imageUrl) {
      contents.push(state.imageUrl);
    }
    return contents;
  };

  const createNewThread = async (): Promise<string> => {
    const { id } = await createThread().unwrap();
    dispatch({ type: 'SET_SELECTED_CHAT', payload: id });
    if (state.isFullScreen || isMobile) {
      navigate(`/ai/${id}`);
    }
    return id;
  };
  const updateState = (currentThreadId: string): void => {
    dispatch({ type: 'SET_CURRENT_INPUT', payload: '' });
    dispatch({ type: 'SET_WAITING_RESPONSE', payload: true });
    dispatch({ type: 'WEBSOCKET_ERROR', payload: null });
    dispatch({ type: 'SET_SELECTED_CHAT', payload: currentThreadId });
    dispatch({ type: 'SET_IMAGE_URL', payload: null });
  };

  const handleSendMessage = async (): Promise<void> => {
    const messageContents = createMessageContents();
    if (messageContents.length === 0) return;
    let currentThreadId = state.threadId;
    if (!currentThreadId) {
      currentThreadId = await createNewThread();
    }

    dispatch({
      type: 'SEND_CHAT_MESSAGE',
      payload: {
        thread_id: currentThreadId,
        role: 'user',
        content: messageContents,
        createdAt: new Date().getTime(),
      },
    });
    sendJsonMessage({
      type: 'content',
      content: messageContents,
      metadata: { ...loadAllUserData(), retry: false },
      threadId: currentThreadId,
    });
    updateState(currentThreadId);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_CURRENT_INPUT', payload: event.target.value });
  };

  const handleImageSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const response = await imageUpload(file);
        const { variants } = response.data.result;
        dispatch({
          type: 'SET_IMAGE_URL',
          payload: {
            type: 'image_url',
            file_name: file.name,
            image_url: {
              url: variants[0],
            },
          },
        });
      } catch (_err) {
        showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
      }
    }
  };
  // Set the cursor to the end of the input when component is mounted
  useEffect(() => {
    if (textFieldRef.current && state.currentInput) {
      const input = textFieldRef.current;
      const { length } = state.currentInput;
      input.setSelectionRange(length, length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  const handleCancelMessage = () => {
    sendJsonMessage({
      type: 'system',
      content: 'cancelRun',
    });
  };

  return (
    <Container maxWidth="lg" disableGutters>
      {state.imageUrl && (
        <Box px={2} py={0.5} position="relative" display="flex">
          <AttachmentImage
            imageUrl={state.imageUrl.image_url.url}
            fileName={state.imageUrl.file_name}
            readonly={false}
            onClose={() => dispatch({ type: 'SET_IMAGE_URL', payload: null })}
          />
        </Box>
      )}
      <Stack py={1} px={1} direction="row" gap={1} alignItems="center">
        <InputBase
          type="file"
          inputProps={{
            accept: 'image/jpeg,image/png',
          }}
          sx={{ display: 'none' }}
          inputRef={fileInputRef}
          onChange={handleImageSelect}
        />
        <IconButton
          size="medium"
          onClick={handleAttachmentClick}
          data-cy="ai-assistant-chat-attach-file-button"
        >
          <SvgIcon component={WorkbaseAttachmentIcon} />
        </IconButton>

        <TextField
          data-cy="ai-assistant-chat-ask-textField"
          variant="outlined"
          placeholder={t('assistant.ask')}
          value={state.currentInput}
          autoFocus
          inputRef={textFieldRef}
          size="small"
          fullWidth
          onKeyDown={(e) => {
            if (
              e.key === 'Enter' &&
              !e.shiftKey &&
              !state.currentStream?.length &&
              !state.waitingResponse
            ) {
              if (!state.currentInput.trim()) return;
              e.preventDefault();
              handleSendMessage();
            }
          }}
          onChange={handleInputChange}
          multiline
          maxRows={4}
        />
        {/* Send Button */}
        {state.currentStream?.length > 0 ? (
          <IconButton
            size="medium"
            onClick={handleCancelMessage}
            data-cy="ai-assistant-chat-cancel-message-button"
          >
            <SvgIcon component={WorkbaseCloseCircleIcon} />
          </IconButton>
        ) : (
          <IconButton
            data-cy="ai-assistant-chat-send-message-button"
            size="medium"
            onClick={handleSendMessage}
            disabled={
              state.currentStream?.length > 0 || state.waitingResponse || !state.currentInput.trim()
            }
          >
            <SvgIcon component={WorkbaseArrowUpCircleIcon} />
          </IconButton>
        )}
      </Stack>
    </Container>
  );
}

export default memo(DialogInputs);
