import { Components, Theme } from '@mui/material';

const MuiPopover: Components<Theme>['MuiPopover'] = {
  styleOverrides: {
    paper: ({ ownerState }) => ({
      '&&': {
        marginTop: ownerState.anchorOrigin?.vertical === 'top' ? '-2px' : '2px',
      },
    }),
  },
};

export default MuiPopover;
