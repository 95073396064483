import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: (builder) => ({
    refreshToken: builder.mutation({
      query: (refreshToken: string) => ({
        url: 'authn/token/company/refresh',
        method: 'POST',
        body: {
          refreshToken,
        },
      }),
    }),
    exchangeToken: builder.mutation({
      query: (exchangeToken: string) => ({
        url: 'authn/login/company/exchange',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${exchangeToken}`,
        },
      }),
    }),
  }),
});

export const { useRefreshTokenMutation, useExchangeTokenMutation } = authApi;
