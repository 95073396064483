import { Middleware, AnyAction, MiddlewareAPI } from '@reduxjs/toolkit';
import { setAuthState } from '../client/authSlice';

const UnauthorizedMiddlewareGuard: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action: AnyAction) => {
    if (action.type.endsWith('/rejected')) {
      const { error, payload } = action;
      if (
        (error && payload?.data?.code === 'USER_NOT_FOUND') ||
        payload?.data?.code === 'COMPANY_NOT_FOUND' ||
        (action.type.startsWith('currentUser') && action.payload.data.statusCode === 401)
      ) {
        dispatch(setAuthState({ token: undefined }));
      }
    }

    return next(action);
  };

export default UnauthorizedMiddlewareGuard;
