import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
  borderColor: string;
}

export default function ContentWrapper({ children, borderColor }: Props) {
  return (
    <Box
      borderRadius={1}
      position="relative"
      width="100%"
      height={662}
      minHeight={0}
      borderColor={borderColor}
      sx={{ borderStyle: 'solid', borderWidth: 4, overflow: 'hidden' }}
    >
      {children}
    </Box>
  );
}
