import { GlobalStyles } from '@mui/material';

function FontGlobalStyles() {
  return (
    <GlobalStyles
      styles={`
      @font-face {
        font-family: 'Base';
        font-style: normal;
        font-weight: 400;
        src: url('/fonts/Base_Regular.ttf');
        font-display: swap;
      }
      @font-face {
        font-family: 'Base';
        font-style: italic;
        font-weight: 400;
        src: url('/fonts/Base_Regular_Italic.ttf');
        font-display: swap;
      }
      @font-face {
        font-family: 'Base';
        font-style: normal;
        font-weight: 500;
        src: url('/fonts/Base_Medium.ttf');
        font-display: swap;
      }
      @font-face {
        font-family: 'Base';
        font-style: italic;
        font-weight: 500;
        src: url('/fonts/Base_Medium_Italic.ttf');
        font-display: swap;
      }
      @font-face {
        font-family: 'Base';
        font-style: normal;
        font-weight: 700;
        src: url('/fonts/Base_Bold.ttf');
        font-display: swap;
      }
      @font-face {
        font-family: 'Base';
        font-style: italic;
        font-weight: 700;
        src: url('/fonts/Base_Bold_Italic.ttf');
        font-display: swap;
      }
    `}
    />
  );
}

export default FontGlobalStyles;
