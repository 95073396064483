import { useEffect, useState, RefObject } from 'react';

export function useFixedPosition(
  widgetContainerRef: RefObject<HTMLDivElement>,
  editBarContainerRef: RefObject<HTMLDivElement>,
  editBarContentRef: RefObject<HTMLDivElement>
) {
  const [isFixed, setIsFixed] = useState(false);
  const [position, setPosition] = useState<{ left?: number; right?: number }>({ left: 0 });
  const [isActiveGridInView, setActiveGridInView] = useState(false);

  useEffect(() => {
    const handlePositionSet = () => {
      const container = widgetContainerRef?.current;
      if (!container || !editBarContentRef.current) return undefined;
      const containerWidth = container.offsetWidth;
      const containerRect = container.getBoundingClientRect();
      const containerDistanceToRight = window.innerWidth - containerRect.right;
      const editBarWidth = editBarContentRef.current.offsetWidth;
      if (editBarWidth < containerDistanceToRight + containerWidth) {
        setPosition({ left: containerRect.left });
      } else {
        setPosition({ right: containerDistanceToRight });
      }
      return undefined;
    };

    handlePositionSet();
    window.addEventListener('resize', handlePositionSet);

    return () => {
      window.removeEventListener('resize', handlePositionSet);
    };
  }, [editBarContentRef, widgetContainerRef]);

  useEffect(() => {
    const topBar = document.getElementById('editor-top-bar');
    const container = document.getElementById('top-bar-layout-content');
    const gridItemContainer = widgetContainerRef.current;

    if (!topBar || !editBarContainerRef.current || !gridItemContainer) return undefined;

    const checkToolbarIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        setIsFixed(!entry.isIntersecting);
      });
    };

    const toolbarObserver = new IntersectionObserver(checkToolbarIntersection, {
      root: container,
      rootMargin: `-${
        topBar.offsetHeight + editBarContainerRef.current.offsetHeight
      }px 0px 0px 0px`,
      threshold: 0,
    });

    toolbarObserver.observe(editBarContainerRef.current);

    const checkContainerElementIntersection = (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      setActiveGridInView(entry.isIntersecting);
    };

    const containerElementObserver = new IntersectionObserver(checkContainerElementIntersection, {
      root: container,
      rootMargin: `-${topBar.offsetHeight}px 0px 0px 0px`,
      threshold: 0,
    });

    containerElementObserver.observe(gridItemContainer);

    return () => {
      toolbarObserver.disconnect();
      containerElementObserver.disconnect();
    };
  }, [widgetContainerRef, editBarContainerRef]);

  return { isFixed, isActiveGridInView, position };
}
