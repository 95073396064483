import { Autocomplete, AutocompleteProps, Chip, TextField, Tooltip } from '@mui/material';
import { EmailChipsValueProps, useEmailChips } from './controller/use-email-chips';

export interface EmailChipsProps {
  values: EmailChipsValueProps[];
  onChange: (val: EmailChipsValueProps[]) => void;
  error?: boolean;
  placeholder: string;
  autoFocus?: boolean;
  autoCompleteProps?: Omit<AutocompleteProps<EmailChipsValueProps, true, true, true>, 'onChange'>;
}

export default function EmailChips({
  values,
  onChange,
  placeholder,
  autoFocus,
  error,
  autoCompleteProps,
}: EmailChipsProps) {
  const { inputValue, handleInputChange, handleEditChip, handleOnBlur, valueLabels, t } =
    useEmailChips({ values, onChange });

  return (
    <Autocomplete
      clearIcon={false}
      options={[]}
      open={false}
      freeSolo
      onBlur={handleOnBlur}
      multiple
      size="small"
      value={valueLabels}
      inputValue={inputValue}
      onChange={(_, newValue) =>
        onChange(
          newValue.map((val) => {
            const foundVal = values.find((value) => value.label === val);
            if (foundVal) return foundVal;
            return {
              label: val,
              isValid: false,
              isLoading: false,
            };
          }) as EmailChipsValueProps[]
        )
      }
      onInputChange={(_, newInputValue) => handleInputChange(newInputValue)}
      renderInput={(params) => (
        <TextField
          value={inputValue}
          autoFocus={autoFocus}
          placeholder={values.length > 0 ? '' : placeholder}
          error={error}
          helperText={error ? t('addEmployees.usersError') : ''}
          {...params}
        />
      )}
      renderTags={(_, getTagProps) =>
        values.map((val, index) => {
          const tagProps = getTagProps({ index });
          const chip = (
            <Chip
              label={val.label}
              color={val.isValid ? 'primary' : 'error'}
              variant="filled"
              {...tagProps}
              onClick={() => handleEditChip(val.label)}
            />
          );
          if (val.isValid) return chip;
          return (
            <Tooltip
              title={
                val.isExists
                  ? t('addEmployees.userAlreadyExists')
                  : t('companySettings.employeeRecommendations.email.error')
              }
              placement="bottom"
              arrow
              key={val.label}
            >
              {chip}
            </Tooltip>
          );
        })
      }
      {...autoCompleteProps}
    />
  );
}
