import Dialog from '@mui/material/Dialog';
import { lazy, Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import { UnsavedChangesProvider, useUnsavedChanges } from 'src/lib/utils/UnsavedChangesContext';

const CompanySettings = lazy(() => import('src/views/company-settings'));

function CompanySettingsDialogWrapper() {
  const [searchParams] = useSearchParams();
  const { handleSafeSetSearchParams } = useUnsavedChanges();
  const handleClose = () => {
    handleSafeSetSearchParams({});
  };

  return (
    <Dialog
      open={!!searchParams.get('settings')}
      onClose={handleClose}
      PaperProps={{
        sx: {
          height: 'calc(100vh - 100px)',
          maxHeight: ({ breakpoints }) => breakpoints.values.md,
        },
      }}
      maxWidth="xl"
      fullWidth
    >
      <Suspense fallback={<LottieLogoSpinner margin="auto" />}>
        <CompanySettings />
      </Suspense>
    </Dialog>
  );
}

export default function CompanySettingsPopup() {
  return (
    <UnsavedChangesProvider key="company-settings">
      <CompanySettingsDialogWrapper />
    </UnsavedChangesProvider>
  );
}
