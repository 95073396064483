import { memo, useRef } from 'react';
import { Stack } from '@mui/material';
import RecordingToolbar from './toolbar/RecordingToolbar';
import Webcam from './Webcam';
import RecordingPlaceholder from './RecordingPlaceholder';
import ScreenRecorder from './ScreenRecorder';
import Countdown from './Countdown';
import StartRecordingButton from './StartRecordingButton';
import PauseFinishButtons from './PauseFinishButtons';
import ContentWrapper from './ContentWrapper';
import CenteredPauseCircle from './CenteredPauseCircle';
import Timer from './Timer';
import useScreenRecordingManager from './useScreenRecordingManager';
import usePictureInPictureCamera from './usePictureInPictureCamera';

function isNoDevice(val: string) {
  return val === '' || val === 'noDevice';
}

function getContentBorderColor(isRecording: boolean, isPaused: boolean): string {
  if (isPaused) return 'warning.light';
  if (isRecording) return 'error.main';
  return 'divider';
}

interface Props {
  onRecordingStarted: () => void;
  onRecordingFinish: (blob: Blob) => void;
}

function ScreenCameraRecorder({ onRecordingFinish, onRecordingStarted }: Props) {
  const screenVideoRef = useRef<HTMLVideoElement>(null);

  const {
    isRecording,
    countdownVisible,
    isRecordingPaused,
    handlePauseRecording,
    handleResumeRecording,
    handleCompleteRecording,
    handleCountdownFinish,
    handleStartRecording,
    screenStream,
    setScreenStream,
    selectedMicrophone,
    setSelectedMicrophone,
    selectedCamera,
    setSelectedCamera,
  } = useScreenRecordingManager(onRecordingFinish, onRecordingStarted);

  const { handleCameraSelect, handleScreenStreamChange, webcamRef } = usePictureInPictureCamera({
    screenStream,
    setSelectedCamera,
    setScreenStream,
  });

  const isCameraSelected = !isNoDevice(selectedCamera);
  const isNoStreamingDevicesSelected = !isCameraSelected && !screenStream;
  const showFinishButtonsVisible = isRecording || countdownVisible;
  return (
    <Stack gap={2.5} width="100%" maxHeight="100%">
      <RecordingToolbar
        onSelectCamera={handleCameraSelect}
        selectedCamera={selectedCamera}
        selectedMicrophone={selectedMicrophone}
        onSelectMicrophone={setSelectedMicrophone}
        screenStream={screenStream}
        onScreenStreamSelect={handleScreenStreamChange}
        isRecording={isRecording}
        isCountdownActive={countdownVisible}
      />
      <ContentWrapper borderColor={getContentBorderColor(isRecording, isRecordingPaused)}>
        {isNoStreamingDevicesSelected && <RecordingPlaceholder />}
        {isCameraSelected && (
          <Webcam ref={webcamRef} deviceId={selectedCamera} opacity={screenStream ? 0 : 1} />
        )}
        {screenStream && <ScreenRecorder ref={screenVideoRef} stream={screenStream} />}
        {countdownVisible && <Countdown startCount={3} onCountdownFinish={handleCountdownFinish} />}
        {isRecordingPaused && !isNoStreamingDevicesSelected && <CenteredPauseCircle />}
        {isRecording && <Timer isStopped={isRecordingPaused} />}
      </ContentWrapper>
      {showFinishButtonsVisible && (
        <PauseFinishButtons
          completeDisabled={countdownVisible}
          onPause={handlePauseRecording}
          isPaused={isRecordingPaused}
          onResume={handleResumeRecording}
          onComplete={handleCompleteRecording}
          isNoDevices={isNoStreamingDevicesSelected}
        />
      )}
      {!showFinishButtonsVisible && (
        <StartRecordingButton
          disabled={isNoStreamingDevicesSelected}
          onClick={handleStartRecording}
        />
      )}
    </Stack>
  );
}

export default memo(ScreenCameraRecorder);
