import { useEffect, useState } from 'react';
import { HocuspocusProvider } from '@hocuspocus/provider';
import * as Y from 'yjs';
import {
  AwarenessItem,
  setAwarenessState,
} from 'src/features/awareness-avatars/controller/awareness.slice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/store';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';

export interface HocuspocusProviderProps {
  documentName: string;
  socketUrl: string;
  readOnly: boolean;
  ydoc: Y.Doc;
}

const useHocuspocusProvider = ({
  documentName,
  socketUrl,
  readOnly,
  ydoc,
}: HocuspocusProviderProps) => {
  const [provider, setProvider] = useState<HocuspocusProvider>();
  const token = useAppSelector((state) => state.auth.token);
  const { data } = useCurrentUserQuery();
  const dispatch = useDispatch();

  useEffect(() => {
    if (provider && data) {
      provider.setAwarenessField('user', {
        id: data.id,
        firstName: data.firstName,
        lastName: data.lastName,
        avatarUrl: data.avatarUrl,
      });

      return () => {
        provider.setAwarenessField('user', undefined);
      };
    }
    return undefined;
  }, [provider, data]);

  useEffect(() => {
    if (provider) {
      provider.setAwarenessField('isEditing', !readOnly);

      return () => {
        provider.setAwarenessField('isEditing', undefined);
      };
    }
    return undefined;
  }, [provider, readOnly]);

  useEffect(() => {
    if (!token) return undefined;
    const hocuspocusProvider = new HocuspocusProvider({
      url: socketUrl,
      name: documentName,
      document: ydoc,
      token,
      onAwarenessUpdate: ({ states }) => {
        const filteredData = states.filter((state) => state.user) as any[];

        function sortEditingFirst(a: AwarenessItem, b: AwarenessItem): number {
          if (b.isEditing === a.isEditing) {
            return 0;
          }
          return b.isEditing ? 1 : -1;
        }
        filteredData.sort(sortEditingFirst);

        const userMap = filteredData.reduce((map, item) => {
          const userId = item.user.id;
          const existingItem = map.get(userId);

          if (!existingItem || (!existingItem.isEditing && item.isEditing)) {
            map.set(userId, item);
          }

          return map;
        }, new Map<string, AwarenessItem>());

        const awarenessArray = Array.from(userMap.values()) as any[];
        dispatch(setAwarenessState(awarenessArray));
      },
    });
    hocuspocusProvider.connect();
    setProvider(hocuspocusProvider);

    return () => {
      hocuspocusProvider.configuration.websocketProvider.disconnect();
      hocuspocusProvider.configuration.websocketProvider.destroy();
      hocuspocusProvider.disconnect();
      hocuspocusProvider.destroy();
    };
  }, [token, documentName, socketUrl, ydoc, dispatch]);

  return provider;
};

export default useHocuspocusProvider;
