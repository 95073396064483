import { Box, Drawer, IconButton, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppLogo from './AppLogo';
import NavGroup from './nav-group';
import ExpandedTree from './tree/expanded';
import useTreeData from './tree/useTreeData';
import MaskedScrollBox from './MaskedScrollBox';
import { ChatContext } from '../ai-chat/helpers/chatContext';
import HamburgerLottie from './HamburgerLottie';

export default function AppSidedrawer() {
  const treeData = useTreeData();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { dispatch } = useContext(ChatContext);
  const { pathname } = useLocation();
  useEffect(() => {
    setDrawerOpen(false);
    dispatch({ type: 'CLOSE_DIALOG' });
  }, [pathname, dispatch]);
  return (
    <>
      <AppLogo fullWidth={false} />
      <Box
        order={3}
        width={48}
        height={48}
        position="relative"
        overflow="hidden"
        flexShrink={0}
        zIndex={drawerOpen ? 9999999 : 0}
      >
        <IconButton
          onClick={() => setDrawerOpen(!drawerOpen)}
          // eslint-disable-next-line no-restricted-syntax
          sx={{
            width: '100%',
            height: '100%',
            '& > *': {
              position: 'absolute',
            },
          }}
        >
          <HamburgerLottie isOpen={drawerOpen} />
        </IconButton>
      </Box>
      <Drawer
        anchor="bottom"
        PaperProps={{
          sx: {
            width: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            height: 'calc(100% - 65px)',
            top: 65,
            border: 'none',
          },
        }}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        keepMounted
      >
        <Stack width="100%" marginX="auto" height="100%" overflow="auto" px={2}>
          <MaskedScrollBox>
            <NavGroup fullWidth />
            <ExpandedTree data={treeData} />
          </MaskedScrollBox>
        </Stack>
      </Drawer>
    </>
  );
}
