import { TabContext, TabPanel } from '@mui/lab';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import * as WorkbaseIcons from 'src/assets/icons/workbaseIcons';
import { useState, useMemo, useEffect } from 'react';
import { IconName } from 'src/ui-components/icon';
import { EmojiMartData } from '@emoji-mart/data';
import EmojiCategoryPanels from './grid/EmojiCategoryPanels';
import IconSelectorTabs from './IconSelectorTabs';
import SearchIcons from './SearchIcons';
import { TabVals } from './types';
import IconOrEmojiGrid from './grid/IconOrEmojiGrid';

interface IconSelectorTabContextProps {
  emojiData: EmojiMartData;
  icon: string;
  onIconClick: (icon: string) => void;
  clearSearchVal: () => void;
  searchVal: string;
  dataCy?: string;
}

export default function IconSelectorTabContext({
  emojiData,
  icon,
  onIconClick,
  clearSearchVal,
  searchVal,
  dataCy,
}: IconSelectorTabContextProps) {
  const [tabVal, setTabVal] = useState<TabVals>('workbaseIcons');

  useEffect(() => {
    setTabVal(searchVal === '' ? 'workbaseIcons' : 'searchIcons');
  }, [searchVal]);

  const icons = useMemo(() => Object.keys(WorkbaseIcons) as IconName[], []);
  return (
    <TabContext value={tabVal}>
      <IconSelectorTabs
        dataCy={dataCy}
        setTabVal={setTabVal}
        clearSearchVal={clearSearchVal}
        emojiCategories={emojiData.categories}
      />
      <Box overflow="scroll" maxHeight={250} pb={2}>
        <TabPanel value="workbaseIcons">
          <Grid container columns={emojiData.categories.length + 1}>
            <IconOrEmojiGrid icons={icons} selectedIcon={icon} onClick={onIconClick} />
          </Grid>
        </TabPanel>
        <EmojiCategoryPanels emojiData={emojiData} onClick={onIconClick} selectedIcon={icon} />
        <TabPanel value="searchIcons">
          {searchVal !== '' && (
            <SearchIcons
              searchVal={searchVal}
              handleIconClick={onIconClick}
              emojiData={emojiData}
              icons={icons}
              selectedIcon={icon}
            />
          )}
        </TabPanel>
      </Box>
    </TabContext>
  );
}
