import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import {
  useTrainingModulesByTrainingIdQuery,
  TrainingModuleWithStatus,
  useTrainingModulesProgressByTrainingIdQuery,
} from 'src/store/api/trainings/trainingModulesApi';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import ReadOnlyModules from './ReadOnlyModules';
import Title from './Title';
import EmptyPlaceholder from './EmptyPlaceholder';
import EditingModules from './editing-modules';

interface Props {
  readOnly: boolean;
}

export default function ModulesList({ readOnly }: Props) {
  const param = useParams() as { trainingId: string };

  const { data, isLoading, isSuccess } = useTrainingModulesByTrainingIdQuery(param.trainingId, {
    skip: !param.trainingId,
  });

  const { data: progressData } = useTrainingModulesProgressByTrainingIdQuery(
    param.trainingId as string,
    { skip: !param.trainingId, refetchOnMountOrArgChange: true }
  );

  const publishedModules = data?.filter(({ status }) => status === 'published');

  const displayData = !isSuccess ? [] : data;

  if (
    readOnly &&
    (!displayData || displayData.length === 0 || !publishedModules || publishedModules.length === 0)
  ) {
    return null;
  }

  return (
    <Stack width="100%">
      <Title />
      {isLoading && (
        <Box sx={{ margin: 'auto' }}>
          <LottieLogoSpinner />
        </Box>
      )}
      {displayData && !isLoading && (
        <>
          {displayData.length === 0 && <EmptyPlaceholder />}
          <Stack>
            {readOnly ? (
              <Stack gap={2.5}>
                <ReadOnlyModules
                  modules={publishedModules as TrainingModuleWithStatus[]}
                  progressData={progressData}
                />
              </Stack>
            ) : (
              <EditingModules modules={displayData} progressData={progressData} />
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
}
