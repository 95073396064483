import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { SvgIcon } from '@mui/material';
import { WorkbaseCloseCircleIcon, WorkbaseSearchIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';

export default function SearchTextField({ value, onChange, ...restOfProps }: TextFieldProps) {
  const { t } = useTranslation();
  const onClear = () => {
    const event = { ...new Event('change'), target: { value: '' } };
    onChange?.(event as unknown as React.ChangeEvent<HTMLInputElement>);
  };
  return (
    <TextField
      value={value}
      onChange={onChange}
      placeholder={t('searchPlaceholder')}
      {...restOfProps}
      slotProps={{
        ...restOfProps.slotProps,
        input: {
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon component={WorkbaseSearchIcon} />
            </InputAdornment>
          ),
          endAdornment: value ? (
            <InputAdornment position="end">
              <IconButton onClick={onClear}>
                <SvgIcon component={WorkbaseCloseCircleIcon} />
              </IconButton>
            </InputAdornment>
          ) : null,
          ...restOfProps.slotProps?.input,
        },
      }}
    />
  );
}
