import { useState } from 'react';
import { Box, Skeleton } from '@mui/material';

export interface ImageProps {
  src: string;
  alt: string;
  fit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  width?: number | string;
  height?: number | string;
  loading?: boolean;
  borderRadius?: number;
  objectPosition?: string;
}

function Image({
  src,
  alt,
  height = '100%',
  width = '100%',
  fit = 'cover',
  loading,
  borderRadius = 1,
  objectPosition = 'center',
}: ImageProps) {
  const [isImageLoaded, setIsImageLoaded] = useState(true);

  const handleImageLoad = () => {
    setIsImageLoaded(false);
  };

  return (
    <>
      {(isImageLoaded || loading) && (
        <Skeleton variant="rectangular" width={width} height={height} />
      )}
      <Box
        component="img"
        borderRadius={borderRadius}
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        width={width}
        height={height}
        sx={{
          display: isImageLoaded || loading ? 'none' : 'block',
          objectFit: fit,
          objectPosition,
        }}
      />
    </>
  );
}

export default Image;
