import { format } from 'date-fns';

type InputValue = Date | string | number | null | undefined;

export function formatDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function formatDateFromTimeStamp(date: InputValue) {
  const fm = 'dd.MM.yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function formatDateTimeFromTimeStamp(date: InputValue) {
  const fm = 'dd.MM.yyyy HH:MM:SS';

  return date ? format(new Date(date), fm) : '';
}
