import { Tldraw, useFileSystem } from '@tldraw/tldraw';
import { useTheme } from '@mui/material';
import { useMultiplayerState } from './useMultiplayerState';
import './styles.css';

interface Props {
  socketUrl: string;
  documentName: string;
  readonly: boolean;
}

export default function MultiplayerTldraw({ socketUrl, documentName, readonly }: Props) {
  const fileSystemEvents = useFileSystem();
  const { palette } = useTheme();
  const { onMount, onUndo, onRedo, onChangePresence, onChangePage } = useMultiplayerState(
    socketUrl,
    documentName,
    readonly
  );

  return (
    <Tldraw
      autofocus
      onChangePage={onChangePage}
      disableAssets
      readOnly={readonly}
      onMount={onMount}
      darkMode={palette.mode === 'dark'}
      showMultiplayerMenu={false}
      {...fileSystemEvents}
      onUndo={onUndo}
      onRedo={onRedo}
      showPages={false}
      onChangePresence={onChangePresence}
    />
  );
}
