import { Box, Divider } from '@mui/material';
import HighlightWrapper from '../highlight-wrapper';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: any;
  onResizeStart?: () => void;
  id: string;
}

export default function EditorDivider({
  onDelete,
  onDuplicate,
  readOnly,
  onResizeStop,
  dragging,
  onResizeStart,
  id,
}: Props) {
  return (
    <HighlightWrapper
      dataCy="editor-widgets-divider"
      id={id}
      editBarProps={{ baseActionsProps: { onDelete, onDuplicate } }}
      horizontalResize={{}}
      dragging={dragging}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      readOnly={readOnly}
    >
      {({ EditBarComponent }) => (
        <>
          {EditBarComponent}
          <Box py={1}>
            <Divider
              component={Box}
              borderBottom={(theme) => `2px solid ${theme.palette.divider} !important`}
            />
          </Box>
        </>
      )}
    </HighlightWrapper>
  );
}
