import { Components, Theme } from '@mui/material';
import { WorkbaseDateIcon } from 'src/assets/icons/workbaseIcons';

const MuiDatePicker: Components<Theme>['MuiDatePicker'] = {
  defaultProps: {
    format: 'DD.MM.YYYY',
    slots: {
      openPickerIcon: WorkbaseDateIcon,
    },
    slotProps: {
      popper: {
        placement: 'bottom-end',
      },
    },
  },
};

export default MuiDatePicker;
