import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbaseArrowDownIcon } from '../../../../assets/icons/workbaseIcons';
import { IconButtonData } from './iconButtonGroup.types';

interface Props {
  data: IconButtonData[];
}

export default function MoreIconsDropdownButton({ data }: Props) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Tooltip title={t('more')}>
        <IconButton size="small" onClick={handleOpen} data-cy="top-bar-more-icons-button">
          <SvgIcon fontSize="small" component={WorkbaseArrowDownIcon} />
        </IconButton>
      </Tooltip>
      <Menu
        id="more-tools-dropdown-menu"
        anchorEl={isOpen}
        onClose={handleClose}
        open={Boolean(isOpen)}
        data-cy="top-bar-more-tools-dropdown-menu"
      >
        {data.map(({ key, onClick, icon, tooltip }) => (
          <MenuItem
            data-cy={`top-bar-more-tools-menu-${icon}-item`}
            key={key}
            onClick={() => {
              onClick();
              handleClose();
            }}
          >
            <ListItemIcon>
              <SvgIcon component={icon} />
            </ListItemIcon>
            <ListItemText primary={tooltip} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
