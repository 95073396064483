import { forwardRef, memo, RefObject, useEffect, useRef, useState } from 'react';
import { Container, Stack } from '@mui/material';
import { ChatStateMessage } from '../../helpers/types';
import AiAlertError from './AiAlertError';
import CurrentMessage from './CurrentMessage';
import { WrappedMessage } from './WrappedMessage';

const MessagesList = memo(
  forwardRef<
    HTMLDivElement,
    {
      // errors that are not true
      // eslint-disable-next-line react/no-unused-prop-types
      messages: ChatStateMessage[];
      // eslint-disable-next-line react/no-unused-prop-types
      onSendMessage: (message: string) => void;
      // eslint-disable-next-line react/no-unused-prop-types
      messagesEndRef: RefObject<HTMLDivElement>;
      // eslint-disable-next-line react/no-unused-prop-types
      isScrolled: boolean;
    }
  >(({ messages, onSendMessage, messagesEndRef, isScrolled }, ref) => {
    const [isMobile, setIsMobile] = useState(false);
    const stackContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      setIsMobile(stackContainerRef.current ? stackContainerRef.current.clientWidth <= 600 : true);
    }, []);
    return (
      <Stack pt={10} overflow="auto" flexGrow={1} ref={ref}>
        <Container maxWidth="lg" component={Stack} flexGrow={1} width="100%">
          <Stack flexGrow={1}>
            {messages.map(
              (message) =>
                message.content[0]?.text && (
                  <WrappedMessage key={message.id} message={message} isMobile={isMobile} />
                )
            )}
            <CurrentMessage
              isMobile={isMobile}
              messagesEndRef={messagesEndRef}
              isScrolled={isScrolled}
            />
          </Stack>
          <AiAlertError onSendMessage={onSendMessage} />
          <div ref={messagesEndRef} />
        </Container>
      </Stack>
    );
  })
);

export default MessagesList;
