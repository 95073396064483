import { Stack } from '@mui/material';
import { useState } from 'react';
import Empty from 'src/ui-components/custom/empty/Empty';
import useIsMobile from 'src/hooks/useIsMobile';
import Header from './Header';
import { filterItemsBySearchVal } from '../../../lib/utils/search-util';
import ItemList from './items';
import { SidebarHeaderIcon, SidebarItem } from './types';

export interface SidebarProps {
  items: SidebarItem[];
  activeKey: string;
  headerIcon?: SidebarHeaderIcon;
  disableSearchBar?: boolean;
  disableVirtualization?: boolean;
  withDragAndDrop?: boolean;
  reorderFunction?: (obj: { id: string; order: number; source: number }) => void;
  dataCy?: string;
}

const SIDEBAR_ITEM_MIN_WIDTH = 80;

export default function Sidebar({
  items,
  activeKey,
  headerIcon,
  disableVirtualization = false,
  disableSearchBar = false,
  withDragAndDrop = false,
  reorderFunction,
  dataCy,
}: SidebarProps) {
  const [searchVal, setSearchVal] = useState<string>('');
  const handleSearchValChange = (val: string) => setSearchVal(val);
  const filteredGroups = filterItemsBySearchVal(items, searchVal);
  const enableDnD = withDragAndDrop && !!reorderFunction && !searchVal;
  const isMobile = useIsMobile();
  return (
    <Stack height="100%" minWidth={SIDEBAR_ITEM_MIN_WIDTH} pt={isMobile ? 1 : 0} pb={2}>
      <Stack gap={1.5} height="100%">
        {!isMobile && (
          <Header
            iconData={headerIcon}
            searchVal={searchVal}
            handleSearchValChange={handleSearchValChange}
            disableSearchBar={disableSearchBar}
            dataCy={dataCy}
          />
        )}
        {filteredGroups.length === 0 ? (
          !isMobile && <Empty />
        ) : (
          <ItemList
            data={filteredGroups}
            activeKey={activeKey}
            dataCy={dataCy}
            enableDnd={enableDnD}
            disableVirtualization={disableVirtualization}
            onMove={
              enableDnD
                ? ({ id, hoverIndex }: { id: string; hoverIndex: number }) => {
                    const idxOfMoveItem = filteredGroups.findIndex((item) => item.key === id);
                    reorderFunction({
                      id,
                      order: hoverIndex,
                      source: idxOfMoveItem,
                    });
                  }
                : undefined
            }
          />
        )}
      </Stack>
    </Stack>
  );
}
