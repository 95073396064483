import tldList from '../constants/tldList';

function validateEmail(email: string) {
  const pattern = /\S+@\S+\.\S+/;

  // Remove the trailing slash for TLD validation
  const emailParts = email.split('.');
  const lastPart = emailParts[emailParts.length - 1];

  return pattern.test(email) && tldList.includes(lastPart.toLowerCase());
}

export default validateEmail;
