import { Box, Tooltip, MenuItem } from '@mui/material';
import { Emoji as EmojiMartEmoji } from '@emoji-mart/data';
import Grid from '@mui/material/Grid2';
import { useTranslation } from 'react-i18next';
import SvgIconByName, { IconName } from 'src/ui-components/icon/SvgIconByName';
import Emoji from '../../emoji/Emoji';

interface EmojiElementProps {
  emoji: EmojiMartEmoji;
  selectedIcon: string;
  onClick: (icon: string) => void;
}

interface IconElementProps {
  value: IconName;
  selectedIcon: string;
  onClick: (icon: string) => void;
}

interface IconGridProps {
  emojis?: EmojiMartEmoji[];
  icons?: IconName[];
  selectedIcon: string;
  onClick: (icon: string) => void;
}

const commonTooltipProps = {
  disableInteractive: true,
  enterNextDelay: 200,
};

function EmojiElement({ emoji, selectedIcon, onClick }: EmojiElementProps) {
  const { t } = useTranslation();
  return (
    <Grid size={1} key={emoji.id}>
      <Tooltip title={t(emoji.name)} {...commonTooltipProps}>
        <MenuItem
          key={emoji.name}
          selected={selectedIcon === emoji.skins[0].native}
          sx={{ height: 44, borderRadius: 0.5 }}
          onClick={() => onClick(emoji.skins[0].native)}
        >
          <Box display="flex" width="100%" justifyContent="center">
            <Emoji emoji={emoji.skins[0].native} fontSize="medium" />
          </Box>
        </MenuItem>
      </Tooltip>
    </Grid>
  );
}

function IconElement({ value, selectedIcon, onClick }: IconElementProps) {
  const { t } = useTranslation();
  return (
    <Grid size={1} key={value}>
      <Tooltip title={t(value)} {...commonTooltipProps}>
        <MenuItem
          key={value}
          selected={selectedIcon === value}
          sx={{ height: 44, borderRadius: 0.5 }}
          onClick={() => onClick(value)}
        >
          <Box display="flex" width="100%" justifyContent="center">
            <SvgIconByName iconName={value} fontSize="small" />
          </Box>
        </MenuItem>
      </Tooltip>
    </Grid>
  );
}

export default function IconOrEmojiGrid({ emojis, icons, selectedIcon, onClick }: IconGridProps) {
  return (
    <>
      {Array.isArray(icons) &&
        icons.map((value) => (
          <IconElement
            key={value}
            value={value}
            selectedIcon={selectedIcon}
            onClick={onClick}
            data-cy={`icon-or-emoji-${value}-icon`}
          />
        ))}
      {Array.isArray(emojis) &&
        emojis.map((emoji) => (
          <EmojiElement
            data-cy={`icon-or-emoji-${selectedIcon}-emoji`}
            key={emoji.id}
            emoji={emoji as EmojiMartEmoji}
            selectedIcon={selectedIcon}
            onClick={onClick}
          />
        ))}
    </>
  );
}
