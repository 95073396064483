import { Dialog } from '@mui/material';
import EmbeddedItemModalContent, {
  EmbeddedItemModalContentProps,
} from './EmbeddedItemModalContent';

interface Props extends EmbeddedItemModalContentProps {
  open: boolean;
}

export default function EmbeddedItemModal({ open, onClose, ...restOfProps }: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-cy="editor-embedded-item-modal"
    >
      <EmbeddedItemModalContent onClose={onClose} {...restOfProps} />
    </Dialog>
  );
}
