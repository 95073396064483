import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';
import { useSidebarQuery } from 'src/store/api/sidebarApi';
import { TreeNodeModel } from 'src/ui-components/custom/hierarchical-tree/types';

export default function useTreeData(): TreeNodeModel[] {
  const { data } = useSidebarQuery();
  const { t } = useTranslation();
  const endPath = useMaintainEditPath();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const resourceMap = {
    page: {
      path: '/page',
      emptyText: t('customSites.unnamedPage'),
    },
    embeddedApp: {
      path: '/app',
      emptyText: t('embeddedApps.unnamedApp'),
    },
    whiteboard: {
      path: '/whiteboard',
      emptyText: t('whiteboards.unnamedWhiteboard'),
    },
  };

  const treeData = data?.sidebarItems.map((item) => ({
    id: item.id,
    parent: item.parentId === null ? 0 : item.parentId,
    droppable: true,
    text: item.name || resourceMap[item.resourceType].emptyText,
    data: {
      icon: item.icon,
      active: !!matchPath(
        { path: `${resourceMap[item.resourceType].path}/${item.id}${endPath}`, end: false },
        pathname
      ),
      onClick: () => navigate(`${resourceMap[item.resourceType].path}/${item.id}${endPath}`),
      position: item.position,
    },
  }));

  return treeData || [];
}
