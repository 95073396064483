import { useActive, useChainedCommands, useRemirrorContext } from '@remirror/react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

export default function useTextStylesItems() {
  const { t } = useTranslation();
  const { heading, bulletList, orderedList } = useActive();

  const { view } = useRemirrorContext();
  const { $head } = view.state.selection;
  const attrs =
    Object.keys($head.node().attrs).length === 0
      ? $head.node().content.firstChild?.attrs
      : $head.node().attrs;
  const currentAlignment = attrs?.nodeTextAlignment;

  const chain = useChainedCommands();
  const titleActive = heading({ level: 1 });
  const headingActive = heading({ level: 2 });
  const subtitleActive = heading({ level: 3 });
  const bulletListActive = bulletList();
  const orderedListActive = orderedList();
  const handleRemovingLists = () => {
    if (bulletListActive) chain.toggleBulletList();
    if (orderedListActive) chain.toggleOrderedList();
  };

  const handleRemovingHeadings = () => {
    if (titleActive) chain.toggleHeading({ level: 1 });
    if (headingActive) chain.toggleHeading({ level: 2 });
    if (subtitleActive) chain.toggleHeading({ level: 3 });
  };

  const items = [
    {
      key: 'title',
      text: <Typography variant="h6">{t('editor.textEditorItemStyles.labelTitle')}</Typography>,
      placeholder: t('editor.textEditorItemStyles.labelTitle'),
      onClick: () => {
        handleRemovingLists();
        chain.toggleHeading({ level: 1, nodeTextAlignment: currentAlignment });
        chain.run();
      },
      active: titleActive,
    },
    {
      key: 'heading',
      placeholder: t('editor.textEditorItemStyles.labelHeading'),
      text: <Typography variant="h5">{t('editor.textEditorItemStyles.labelHeading')}</Typography>,
      onClick: () => {
        handleRemovingLists();
        chain.toggleHeading({ level: 2, nodeTextAlignment: currentAlignment });
        chain.run();
      },
      active: headingActive,
    },
    {
      key: 'subtitle',
      text: <Typography variant="h6">{t('editor.textEditorItemStyles.labelSubtitle')}</Typography>,
      placeholder: t('editor.textEditorItemStyles.labelSubtitle'),
      onClick: () => {
        handleRemovingLists();
        chain.toggleHeading({ level: 3, nodeTextAlignment: currentAlignment });
        chain.run();
      },
      active: subtitleActive,
    },
    {
      key: 'text',
      text: <Typography variant="body1">{t('editor.textEditorItemStyles.labelText')}</Typography>,
      placeholder: t('editor.textEditorItemStyles.labelText'),
      active:
        !titleActive &&
        !headingActive &&
        !subtitleActive &&
        !bulletListActive &&
        !orderedListActive,
      onClick: () => {
        handleRemovingLists();
        handleRemovingHeadings();
        chain.run();
      },
    },
    {
      key: 'bullet-list',
      text: (
        <Typography variant="body1">{t('editor.textEditorItemStyles.labelBulletList')}</Typography>
      ),
      placeholder: t('editor.textEditorItemStyles.labelBulletList'),
      active: bulletListActive,
      onClick: () => {
        handleRemovingHeadings();
        chain.toggleBulletList();
        chain.run();
      },
    },
    {
      key: 'ordered-list',
      text: (
        <Typography variant="body1">{t('editor.textEditorItemStyles.labelOrderedList')}</Typography>
      ),
      placeholder: t('editor.textEditorItemStyles.labelOrderedList'),
      active: orderedListActive,
      onClick: () => {
        handleRemovingHeadings();
        chain.toggleOrderedList();
        chain.run();
      },
    },
  ];
  return items;
}
