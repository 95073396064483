// correct initialization of i18next from their docs
/* eslint-disable import-x/no-named-as-default */
/* eslint-disable import-x/no-named-as-default-member */
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEn from './langs/en.json';
import translationDe from './langs/de.json';
import workbaseEmojisDe from './langs/emojis/workbaseEmojis-de.json';
import workbaseEmojisEn from './langs/emojis/workbaseEmojis-en.json';
import { defaultLang } from './config-lang';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: { ...translationEn, ...workbaseEmojisEn } },
      de: { translations: { ...translationDe, ...workbaseEmojisDe } },
    },
    lng: defaultLang.value,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
