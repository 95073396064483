import { Dispatch } from '@reduxjs/toolkit';
import { trainingLessonsApi } from '../trainings/trainingLessonsApi';
import { trainingsApi } from '../trainings/trainingsApi';
import { trainingModulesApi } from '../trainings/trainingModulesApi';

function invalidateTrainingEstimatedTime({
  dispatch,
  sectionId,
  trainingId,
  lessonId,
}: {
  dispatch: Dispatch;
  sectionId: string;
  trainingId: string;
  lessonId: string;
}) {
  dispatch(
    trainingLessonsApi.util.invalidateTags([
      { type: 'TrainingLessonsBySectionId', id: sectionId },
      { type: 'TrainingLesson', id: lessonId },
    ])
  );
  dispatch(
    trainingsApi.util.invalidateTags([
      'TrainingEstimatedTime',
      { type: 'TrainingEstimatedTimeById', id: trainingId },
    ])
  );
  dispatch(trainingModulesApi.util.invalidateTags(['TrainingModuleEstimatedTime']));
}

export default invalidateTrainingEstimatedTime;
