import { ConfirmProvider as MuiConfirmProvider } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';

export default function ConfirmProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  return (
    <MuiConfirmProvider
      defaultOptions={{
        confirmationButtonProps: { autoFocus: true, color: 'error' },
        confirmationText: t('proceed'),
        cancellationText: t('cancel'),
        cancellationButtonProps: {
          children: t('cancel'),
          color: 'inherit',
        },
      }}
    >
      {children}
    </MuiConfirmProvider>
  );
}
