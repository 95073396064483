import { Fragment } from 'react';
import { Divider, IconButton, Stack, SvgIcon, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  WorkbaseContentNewIcon,
  WorkbaseDownloadIcon,
  WorkbaseLinksIcon,
} from '../../../../../../../assets/icons/workbaseIcons';

export interface ImageActionsProps {
  onUpload: () => void;
  onDownload: () => void;
  onLinkIconClick: () => void;
}

export default function ImageActions({ onUpload, onDownload, onLinkIconClick }: ImageActionsProps) {
  const { t } = useTranslation();
  const items = [
    {
      key: 'upload',
      onClick: () => onUpload(),
      icon: WorkbaseContentNewIcon,
    },
    {
      key: 'download',
      onClick: () => onDownload(),
      icon: WorkbaseDownloadIcon,
      divider: true,
    },
    {
      key: 'link',
      onClick: () => {
        onLinkIconClick();
      },
      icon: WorkbaseLinksIcon,
    },
  ];

  return (
    <Stack direction="row" gap={1}>
      {items.map(({ key, onClick, icon, divider }) => (
        <Fragment key={`item-${key}`}>
          <Tooltip title={t(`editor.topBar.tooltip.${key}`)} placement="top">
            <IconButton
              data-cy={`editor-topbar-image-action-${key}-button`}
              onMouseDown={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <SvgIcon component={icon} fontSize="small" />
            </IconButton>
          </Tooltip>
          {divider && <Divider orientation="vertical" flexItem />}
        </Fragment>
      ))}
    </Stack>
  );
}
