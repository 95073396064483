import { InputAdornment, Stack, TextField, Typography } from '@mui/material';
import debounce from 'lodash.debounce';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Training } from 'src/store/api/trainings/trainingsApi';
import { useTranslation } from 'react-i18next';
import { TrainingType } from 'src/features/editor/model';
import { TrainingModule } from 'src/store/api/trainings/trainingModulesApi';
import IconOrEmojiSelector from 'src/ui-components/custom/icon-selector';
import SvgIconOrEmoji from 'src/ui-components/icon/SvgIconOrEmoji';
import TrainingsContent from './TrainingsContent';
import ModulesContent from './modules/ModulesContent';
import BorderWrapper from '../border-wrapper';

function Heading({
  value,
  onChange,
  readOnly,
  icon,
  onIconChange,
  trainingsType,
}: Omit<Props, 'trainings' | 'modules' | 'selectedItems' | 'selectedAllItems'>) {
  const { t } = useTranslation();
  const [val, setVal] = useState(value);
  const defaultIcon =
    trainingsType === 'trainings' ? 'WorkbaseTrainingsIcon' : 'WorkbaseModuleIcon';
  const [titleIcon, setTitileIcon] = useState<string>(icon);

  useEffect(() => {
    if (icon === '' || !icon) {
      setTitileIcon(defaultIcon);
    } else if (icon) {
      setTitileIcon(icon);
    }
  }, [defaultIcon, icon]);

  useEffect(() => {
    setVal(value);
  }, [value]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((updatedVal) => {
      if (!readOnly) {
        onChange(updatedVal);
      }
    }, 300),
    [readOnly]
  );

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {!readOnly && (
        <>
          <IconOrEmojiSelector
            dataCy="editor-training-item-title"
            value={titleIcon}
            buttonSize="small"
            onChange={(newIcon) => {
              onIconChange(newIcon);
            }}
          />
          <TextField
            data-cy="editor-training-item-title-text-field"
            fullWidth
            multiline
            className="training-item-title-text-field"
            value={val}
            variant="standard"
            placeholder={readOnly ? undefined : t('editor.trainingsSection.titlePlaceholder')}
            onChange={(e: any) => {
              if (e.nativeEvent.inputType === 'insertLineBreak') return;
              const newVal = e.target.value;
              setVal(newVal);
              debouncedOnChange(newVal);
            }}
            slotProps={{
              input: {
                startAdornment: <InputAdornment position="start" />,
              },
            }}
          />
        </>
      )}
      {readOnly && (
        <>
          <SvgIconOrEmoji content={titleIcon} color="inherit" />
          <Typography variant="h6">{val}</Typography>
        </>
      )}
    </Stack>
  );
}

interface Props {
  value: string;
  onChange: (val: string) => void;
  readOnly: boolean;
  trainings: Training[];
  modules: TrainingModule[];
  selectedItems?: string[];
  selectedAllItems?: boolean;
  trainingsType?: TrainingType;
  icon: string;
  onIconChange: (val: string) => void;
  placeholder?: ReactNode;
  columnsCount: number;
}

export default function Content({
  value,
  onChange,
  readOnly,
  trainings,
  modules,
  selectedAllItems,
  selectedItems = [],
  icon,
  onIconChange,
  trainingsType,
  placeholder,
  columnsCount,
}: Props) {
  const contentsDict: Record<TrainingType, JSX.Element> = {
    trainings: (
      <TrainingsContent
        readOnly={readOnly}
        trainings={trainings}
        selectedAllItems={selectedAllItems}
        selectedItems={selectedItems}
        placeholder={placeholder}
        columnsCount={columnsCount}
      />
    ),
    modules: (
      <ModulesContent
        readOnly={readOnly}
        modules={modules}
        selectedItems={selectedItems}
        selectedAllItems={selectedAllItems}
      />
    ),
  };

  return (
    <BorderWrapper readOnly={readOnly} minHeight={190}>
      <Stack gap={2}>
        <Heading
          onChange={onChange}
          readOnly={readOnly}
          value={value}
          trainingsType={trainingsType}
          icon={icon}
          onIconChange={onIconChange}
          columnsCount={columnsCount}
        />
        {trainingsType && contentsDict[trainingsType]}
      </Stack>
    </BorderWrapper>
  );
}
