import { useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'material-ui-confirm';
import { videoUpload } from 'src/lib/utils/videoUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useAppDispatch } from 'src/store';
import { setRecordingModalPayload } from 'src/features/editor/controller/Editor.slice';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { VideoProgress } from 'src/store/api/trainings/videoProgressApi';
import { Box } from '@mui/material';
import { VideoUploadType } from 'src/features/editor/model';
import getVideoAspectRatio from 'src/lib/utils/getVideoAspectRatio';
import {
  getFirstFrameFromVideoFile,
  getCurrentFrameFromVideoElement,
} from 'src/lib/utils/videoFrame';
import HighlightWrapper from '../highlight-wrapper';
import { allowedFileTypes, MAX_FILESIZE } from '../upload-item/config';
import useUploadingMedia from '../hooks/useUploadingMedia';
import WithUploadProgressWrapper from '../upload-progress/WithUploadProgressWrapper';
import VidstackPlayer from './vidstack-player';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';
import BorderWrapper from '../border-wrapper';

export interface VideoItemProps {
  id: string;
  videoId: string;
  title: string;
  onDelete: () => void;
  onDuplicate: () => void;
  onUpdateVideoPoster: (poster?: string) => void;
  onUpdateVideoTitle: (title: string) => void;
  onVideoDownload: () => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  url: string | null;
  videoPoster?: string;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
  height?: number;
  disabledFastForward?: boolean;
  onDisableFastForward?: (isFastForwardDisabled: boolean) => void;
  isVideoRequired?: boolean;
  onRequired?: (isVideoRequired: boolean) => void;
  watchedDuration?: number;
  onVideoProgress?: (data: Partial<VideoProgress>) => void;
  documentName?: string;
  onVideoUpload: (props: VideoUploadType) => void;
  onUndoCrop: () => void;
  originalAspectRatio?: number;
  captions: boolean;
  chapters: boolean;
}

export default function VideoItem({
  id,
  onDelete,
  onDuplicate,
  onResizeStop,
  onVideoDownload,
  dragging,
  onResizeStart,
  onUpdateVideoPoster,
  onDisableFastForward,
  disabledFastForward = false,
  onRequired,
  isVideoRequired = false,
  documentName,
  url,
  videoPoster,
  readOnly,
  height,
  onUndoCrop,
  watchedDuration,
  onVideoProgress,
  onVideoUpload,
  videoId,
  originalAspectRatio = 1.777777777,
  captions,
  chapters,
}: VideoItemProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const deleteModalConfirm = useConfirm();
  const dispatch = useAppDispatch();
  const [percentage, setPercentage] = useState(0);

  const { handleClearUploading, handleAddingUploading } = useUploadingMedia(id, 'video');

  const resetStates = () => {
    setLoading(false);
    setFile(null);
    setPercentage(0);
    handleClearUploading();
  };

  const handleDisableForward = () => onDisableFastForward?.(!disabledFastForward);
  const handleRequired = () => onRequired?.(!isVideoRequired);

  const handleThumbnailUpload = useCallback(
    async (thumbnail: Blob) => {
      (
        document.getElementById(id)?.querySelector('.editor-item-button') as HTMLButtonElement
      )?.focus();
      const response = await imageUpload(new File([thumbnail], 'poster.jpg'));

      if (response.status !== 201) {
        showNotification(t('validationErrorMessages.generalUploadErrorMessage'), 'error');
      }

      const { result } = response.data;
      const { variants } = result;
      const [imageUrl] = variants;
      onUpdateVideoPoster(imageUrl);
    },
    [onUpdateVideoPoster, t, id]
  );

  // eslint-disable-next-line max-statements
  const handleVideoUpload = async (acceptedFiles: File[]) => {
    setLoading(true);
    const newFile = acceptedFiles[0];
    setFile(Object.assign(newFile));
    if (newFile.size > MAX_FILESIZE) {
      showNotification(t('validationErrorMessages.UploadFileSizeErrorMessage'), 'error');
      resetStates();
      return;
    }

    let aspectRatio: number;
    let currentFrame: Blob | null = null;

    try {
      aspectRatio = await getVideoAspectRatio(newFile);
      currentFrame = await getFirstFrameFromVideoFile(newFile);
    } catch (_) {
      showNotification(t('validationErrorMessages.generalUploadErrorMessage'), 'error');
      resetStates();
      return;
    }

    let poster: string | undefined;

    if (currentFrame) {
      const response = await imageUpload(new File([currentFrame], 'poster.jpg'));
      if (response.status !== 201) {
        showNotification(t('validationErrorMessages.generalUploadErrorMessage'), 'error');
        resetStates();
        return;
      }

      const { result } = response.data;
      const { variants } = result;
      const [imageUrl] = variants;
      poster = imageUrl;
    }

    const onVideoUploadSuccess = async (vId: string) => {
      onVideoUpload({
        url: null,
        videoId: vId,
        fileName: newFile.name,
        originalAspectRatio: aspectRatio,
        poster,
        captions: false,
        chapters: false,
      });
      resetStates();
    };
    try {
      handleAddingUploading();
      await videoUpload({
        file: newFile,
        errorMessage: t('validationErrorMessages.UploadFailed'),
        onVideoUploadSuccess,
        setPercentage,
        documentName,
      });
    } catch {
      showNotification(t('validationErrorMessages.UploadFailed'), 'error');
      resetStates();
    }
  };

  const handlePosterUploadFromCurrentFrame = async () => {
    const video = document.getElementById(id)?.querySelector('video');
    if (video) {
      const currentFrame = await getCurrentFrameFromVideoElement(video, originalAspectRatio);
      if (currentFrame !== null) handleThumbnailUpload(currentFrame);
    }
  };

  const { open: openForPoster, getInputProps: getInputPropsForPoster } = useDropzone({
    onDrop: (acceptedFiles: File[]) => handleThumbnailUpload(acceptedFiles[0]),
    noDrag: true,
    accept: {
      ...allowedFileTypes.image,
    },
  });

  const { getInputProps, open: openForVideo } = useDropzone({
    onDrop: handleVideoUpload,
    noDrag: true,
    accept: {
      ...allowedFileTypes.video,
    },
  });

  return (
    <HighlightWrapper
      dataCy="editor-widgets-video-item"
      id={id}
      editBarProps={{
        videoActionsProps: {
          onUploadPoster: openForPoster,
          onUploadVideo: () => openForVideo(),
          onUploadPosterFromCurrentFrame: handlePosterUploadFromCurrentFrame,
          onVideoDownload,
          onDisableFastForward: handleDisableForward,
          onRequired: handleRequired,
          isVideoRequired,
          disableFastForward: disabledFastForward,
          showRequired: documentName?.split('.')[0] === 'trainingLesson',
          onOpenRecordingModal: () => {
            dispatch(
              setRecordingModalPayload({
                id,
                onUploadProgress: setPercentage,
              })
            );
          },
          downloadDisabled: loading || !url,
        },
        baseActionsProps: {
          onDelete: () => {
            deleteModalConfirm({
              title: t('editor.topBar.customQuestionsOnSettingIcon.areYouSure'),
              description: t('editor.uploadMediaModal.removeVideo'),
            }).then(() => {
              resetStates();
              onDelete();
            });
          },
          onDuplicate,
          hideDuplicate: documentName?.split('.')[0] === 'trainingLesson',
        },
        undoCropActionProps: { onClick: onUndoCrop },
      }}
      dragging={dragging}
      horizontalResize={{}}
      verticalResize={{}}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      readOnly={readOnly}
      height={height}
      minHeight={160}
    >
      {({ EditBarComponent, focused }) => (
        <>
          {EditBarComponent}
          {/* eslint-disable-next-line no-restricted-syntax */}
          <BorderWrapper readOnly={false} sx={{ overflow: 'hidden' }}>
            <WithUploadProgressWrapper
              loading={loading}
              uploadProgress={percentage}
              fileSize={file?.size}
              onStopUploading={resetStates}
              mediaType="video"
            >
              <Box height="100%" mb="-10px">
                <VidstackPlayer
                  src={url}
                  aspectRatio={originalAspectRatio}
                  poster={videoPoster || ''}
                  watchedDuration={watchedDuration}
                  disabledFastForward={disabledFastForward}
                  onVideoProgress={onVideoProgress}
                  canPlay={readOnly || focused}
                  videoId={videoId}
                  chapters={chapters}
                  captions={captions}
                />
              </Box>
            </WithUploadProgressWrapper>
            <input {...getInputProps()} />
            <input {...getInputPropsForPoster()} />
          </BorderWrapper>
        </>
      )}
    </HighlightWrapper>
  );
}
