import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';

const DEBOUNCE_TIME = 500;

const useDebouncedValue = (
  val: string | undefined,
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
) => {
  const [value, setValue] = useState(val || '');

  useEffect(() => {
    setValue(val || '');
  }, [val]);

  const debouncedOnChange = useMemo(() => debounce(onChange, DEBOUNCE_TIME), [onChange]);

  useEffect(
    () => () => {
      debouncedOnChange.cancel();
    },
    [debouncedOnChange]
  );

  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    event
  ) => {
    setValue(event.target.value);
    debouncedOnChange(event);
  };

  return { handleChange, value };
};

export default useDebouncedValue;
