import axiosInstance from 'src/axios';
import { API_URL } from 'src/global-config';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

interface ImageDuplicateResponseSchema {
  result: {
    id: string;
    variants: string[];
  };
}

const imageDuplicate = async (
  imageId: string,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<ImageDuplicateResponseSchema>> =>
  axiosInstance.post(`${API_URL}/images/${imageId}/duplicate`, {}, config);

export { imageDuplicate };
