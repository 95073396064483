import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { useFetchMessagesQuery } from 'src/store/api/aiChatApi';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';

export default function useFetchChats(dispatch: React.Dispatch<any>, threadId: string | null) {
  const { t } = useTranslation();
  const hasAnyPermission = useHasAnyPermission();
  const canAccessAiAssistant = hasAnyPermission([PermissionCodes.ACCESS_AI_ASSISTANT]);
  const { data, error, isFetching } = useFetchMessagesQuery(threadId as string, {
    skip: !threadId || !canAccessAiAssistant,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isFetching) {
      dispatch({ type: 'SET_CHAT_LOADING', payload: true });
    } else {
      dispatch({ type: 'SET_CHAT_LOADING', payload: false });
    }
  }, [isFetching, dispatch]);

  useEffect(() => {
    if (!threadId) return;

    dispatch({ type: 'SET_CHAT_LOADING', payload: true });

    if (data && data.summary && !isFetching) {
      const formattedMessages = data.messages.map((msg: any) => ({
        role: msg.role,
        createdAt: msg.createdAt,
        content: msg.content,
        id: msg.id,
      }));
      dispatch({ type: 'SET_INITIAL_MESSAGES', payload: formattedMessages });
    }

    if (!isFetching || error) {
      dispatch({ type: 'SET_CHAT_LOADING', payload: false });
    }
  }, [threadId, data, isFetching, error, dispatch, t]);
}
