import { Box, Button, useTheme } from '@mui/material';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import BaseEditor from '../base-editor/BaseEditor';
import HighlightWrapper from '../highlight-wrapper';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';

export interface EditorButtonProps {
  value?: string;
  onDelete: () => void;
  onDuplicate: () => void;
  onChange?: (val: string) => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
  href?: string;
  onHrefChange: (href: string) => void;
  id: string;
}

export default function EditorButton({
  onDelete,
  onDuplicate,
  value,
  readOnly = false,
  onChange,
  onResizeStop,
  dragging,
  onResizeStart,
  onHrefChange,
  href,
  id,
}: EditorButtonProps) {
  const theme = useTheme();
  const textColor = theme.palette.primary.contrastText;

  return (
    <HighlightWrapper
      dataCy="editor-widgets-button"
      id={id}
      editBarProps={{
        baseActionsProps: { onDelete, onDuplicate },
        textActionsProps: {},
        hrefProps: {
          href,
          onChange: onHrefChange,
        },
      }}
      horizontalResize={{}}
      onResizeStop={onResizeStop}
      dragging={dragging}
      onResizeStart={onResizeStart}
      readOnly={readOnly}
    >
      {({ EditBarComponent }) => {
        const btnProps = readOnly ? { href, target: '_blank' } : {};
        return (
          <Box p={1}>
            <Button
              fullWidth
              {...btnProps}
              sx={{
                cursor: readOnly && href ? 'pointer' : 'auto',
                '&:active': {
                  transform: 'none',
                },
              }}
            >
              <BaseEditor
                key={id}
                dataCy="editor-widgets-button"
                value={value}
                textColor={textColor}
                autoLink={false}
                readOnly={readOnly}
                onChange={onChange}
              >
                {EditBarComponent}
              </BaseEditor>
            </Button>
          </Box>
        );
      }}
    </HighlightWrapper>
  );
}
