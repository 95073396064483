import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactNode;
}

export default function DatePickerLocalizationProvider({ children }: Props) {
  const { i18n } = useTranslation();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      {children}
    </LocalizationProvider>
  );
}
