import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

export enum PermissionCodes {
  MANAGE_ALL_COMPANY_SETTINGS = 'MANAGE_ALL_COMPANY_SETTINGS',
  MANAGE_DEPARTMENTS = 'MANAGE_DEPARTMENTS',
  MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
  MANAGE_BILLING = 'MANAGE_BILLING',

  ADMIN_ACCESS_DASHBOARD_AND_PAGES = 'ADMIN_ACCESS_DASHBOARD_AND_PAGES',
  ADMIN_ACCESS_EMBEDDED_APPS = 'ADMIN_ACCESS_EMBEDDED_APPS',
  ADMIN_ACCESS_WIKIS = 'ADMIN_ACCESS_WIKIS',
  // ADMIN_ACCESS_NEWS = 'ADMIN_ACCESS_NEWS',
  // ADMIN_ACCESS_SURVEYS = 'ADMIN_ACCESS_SURVEYS',
  ADMIN_ACCESS_TRAININGS = 'ADMIN_ACCESS_TRAININGS',
  ADMIN_ACCESS_WHITEBOARDS = 'ADMIN_ACCESS_WHITEBOARDS',
  ADMIN_ACCESS_FILES = 'ADMIN_ACCESS_FILES',

  MANAGE_EMPLOYEES = 'MANAGE_EMPLOYEES',
  VIEW_EMPLOYEES = 'VIEW_EMPLOYEES',
  VIEW_EMPLOYEES_DETAILED_TRAINING_PROGRESS = 'VIEW_EMPLOYEES_DETAILED_TRAINING_PROGRESS',
  VIEW_EMPLOYEES_SENSITIVE_DATA = 'VIEW_EMPLOYEES_SENSITIVE_DATA',

  ACCESS_AI_ASSISTANT = 'ACCESS_AI_ASSISTANT',
  ADD_PAGES = 'ADD_PAGES',
  ADD_EMBEDDED_APPS = 'ADD_EMBEDDED_APPS',
  ADD_WIKIS = 'ADD_WIKIS',
  // ADD_NEWS = 'ADD_NEWS',
  // ADD_SURVEYS = 'ADD_SURVEYS',
  ADD_TRAININGS = 'ADD_TRAININGS',
  ADD_WHITEBOARDS = 'ADD_WHITEBOARDS',

  MANAGE_CUSTOMER_PORTAL = 'MANAGE_CUSTOMER_PORTAL',
  MANAGE_CUSTOMERS = 'MANAGE_CUSTOMERS',
  MANAGE_CUSTOMER_GROUPS = 'MANAGE_CUSTOMER_GROUPS',
}

export type PermissionCode = keyof typeof PermissionCodes;

export type GeneralPermissionsCodes = Extract<
  PermissionCodes,
  | PermissionCodes.ADD_PAGES
  | PermissionCodes.ADD_EMBEDDED_APPS
  | PermissionCodes.ADD_TRAININGS
  | PermissionCodes.ADD_WHITEBOARDS
  | PermissionCodes.ADD_WIKIS
>;

export interface UserPermission {
  id: string;
  code: PermissionCode;
}

export const userPermissionsApi = createApi({
  reducerPath: 'userPermissionsApi',
  tagTypes: ['UserPermissions'],
  baseQuery,
  endpoints: (builder) => ({
    userPermissions: builder.query<UserPermission[], void>({
      query: () => '/company-users/me/permissions',
      providesTags: ['UserPermissions'],
    }),
  }),
});

export const { useUserPermissionsQuery } = userPermissionsApi;
