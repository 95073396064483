import { Box, Typography, alpha, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import ColoredCircle from './ColoredCircle';

interface Props {
  startCount: number;
  onCountdownFinish: () => void;
}

export default function Countdown({ startCount, onCountdownFinish }: Props) {
  const [countdown, setCountdown] = useState(startCount);
  const theme = useTheme();

  useEffect(() => {
    const handleCountdownFinish = () => {
      onCountdownFinish();
    };

    const decrementCountdown = () => {
      setCountdown((prevCount) => prevCount - 1);
    };

    if (countdown <= 0) {
      handleCountdownFinish();
      return undefined;
    }

    const intervalId = setInterval(decrementCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [countdown, onCountdownFinish]);

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        backgroundColor: 'action.disabledBackground',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ColoredCircle backgroundColor={alpha(theme.palette.primary.main, 0.9)}>
        <Typography variant="h5" fontWeight="medium" color="primary.contrastText">
          {countdown}
        </Typography>
      </ColoredCircle>
    </Box>
  );
}
