import { ButtonBase, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface Props {
  dragRef?: any;
  buttonRef: any;
  sx?: SxProps<Theme>;
}

export default function DnDDragButton({ dragRef, buttonRef, sx }: Props) {
  return (
    <ButtonBase
      data-cy="editor-grid-dnd-indicator"
      ref={dragRef}
      className="opacity-editor-item"
      draggable
      onDragStart={() => {
        if (buttonRef.current) {
          //  eslint-disable-next-line no-param-reassign
          buttonRef.current.style.transition = 'none';
          // eslint-disable-next-line no-param-reassign
          buttonRef.current.style.transform = 'scale(0.5) translateY(0)';
          buttonRef.current.blur();
        }
      }}
      sx={{
        width: 30,
        height: 10,
        opacity: 0,
        transition: (theme) =>
          theme.transitions.create(['transform', 'opacity'], {
            duration: theme.transitions.duration.short,
            easing: theme.transitions.easing.easeInOut,
          }),
        borderRadius: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'primary.contrastText',
        bgcolor: 'primary.main',
        position: 'absolute',
        zIndex: 3,
        top: 2,
        left: '50%',
        cursor: 'move',
        transform: `translate(-50%, -60%)`,
        '&:hover': {
          transform: `translate(-50%, -60%) scale(1.4)`,
        },
        ...sx,
      }}
    />
  );
}
