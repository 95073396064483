import { TFunction } from 'i18next';
import { NavigateFunction } from 'react-router-dom';
import { WikiCardType } from 'src/store/api/wikiApi';
import SvgIconOrEmoji from 'src/ui-components/icon/SvgIconOrEmoji';
import { ListItemIcon } from '@mui/material';

// eslint-disable-next-line max-params
export function convertData(
  data: WikiCardType[],
  t: TFunction,
  navigate: NavigateFunction,
  path: string
) {
  return data.map((item) => ({
    key: item.id,
    text: item.name || t('wikiPage.unnamedWikiCard'),
    position: item.position,
    subtext: '',
    prefix: (
      <ListItemIcon>
        <SvgIconOrEmoji content={item.icon} fontSize="medium" />
      </ListItemIcon>
    ),
    onClick: () => {
      navigate(`/wiki/${item.id}${path}`, { replace: true });
    },
  }));
}
