import { Box, Stack } from '@mui/material';
import { useDrop } from 'react-dnd';
import { useAppSelector } from 'src/store';
import { useMemo } from 'react';
import { useEditor } from '../../../controller';
import { BaseGridItem, GridItemType, GridRow } from '../../../model';
import useDebouncedIsOver from '../droppable-items/useDebouncedIsOver';

interface Props {
  itemId: string;
}

export default function DroppableRowInBetween({ itemId }: Props) {
  const gridData = useAppSelector((state) => state.editor.editorData?.grid);
  const { insertItemBefore } = useEditor();
  const [{ isOver, item }, drop] = useDrop({
    accept: 'GRID_ITEM',
    drop: (i: { rowIndex: number; item: GridItemType; row: GridRow }, monitor) => {
      if (monitor) {
        insertItemBefore(i.item.id, itemId);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem()?.item,
    }),
  });

  const debouncedIsOver = useDebouncedIsOver(isOver);
  const nextItemId = useMemo(() => {
    if (!gridData) return null;
    const flatList = gridData.reduce<
      { id: string; rowIndex: number; itemIndex: number; item: BaseGridItem }[]
    >(
      (acc, row, rowIndex) => [
        ...acc,

        ...row.items.map((i, iIdex) => ({ id: i.id, rowIndex, itemIndex: iIdex, item: i })),
      ],
      []
    );

    const currentIndex = flatList?.findIndex((i) => i.id === item?.id);
    if (currentIndex === -1) return null; // Item not found

    const nextItem = flatList[currentIndex + 1] || null;
    return nextItem ? nextItem.item.id : null;
  }, [gridData, item?.id]);

  const isDisabled = item?.id === itemId || nextItemId === itemId;

  return (
    <Stack
      ref={drop}
      sx={{
        height: '20px',
        position: 'absolute',
        width: '100%',
        zIndex: 3,
        maxWidth: 620,
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
        top: '0',
        pointerEvents: isDisabled ? 'none' : 'auto',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: debouncedIsOver && !isDisabled ? 'info.main' : 'transparent',
          height: '10px',
          width: '100%',
          borderRadius: (theme) => theme.shape.borderRadius,
        }}
      />
    </Stack>
  );
}
