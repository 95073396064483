import { createApi } from '@reduxjs/toolkit/query/react';
import { DefaultImage } from 'src/ui-components/branded-default-image/BrandedDefaultImage';
import { baseQuery } from '../api';
import { companyUsersApi } from '../company-users/companyUsersApi';

export interface Training {
  id: string;
  name: string;
  description: string;
  imageUrl: string | null;
  hasPermission: boolean;
  category: TrainingCategory;
  defaultImage: DefaultImage;
  modulesCount: number;
  lessonsCount: number;
  sectionsCount: number;
  estimatedTimeToComplete: number;
}

export interface TrainingsProgress {
  trainingId: string;
  progress: number;
}

export interface TrainingModulesProgress {
  trainingModuleId: string;
  progress: number;
}

type TrainingCategory = 'trainingsForMe' | 'trainingsForMePublic' | 'optionalTrainings';

export const trainingsApi = createApi({
  reducerPath: 'trainingsApi',
  baseQuery,
  tagTypes: [
    'Trainings',
    'Training',
    'TrainingsByUserId',
    'TrainingLessonsCount',
    'TrainingEstimatedTime',
    'TrainingLessonsCountById',
    'TrainingEstimatedTimeById',
    'TrainingProgress',
    'TrainingsProgressByUserId',
    'TrainingProgressById',
  ],
  endpoints: (builder) => ({
    getTrainings: builder.query<Training[], void>({
      query: () => ({
        url: `/company-users/me/trainings`,
      }),
      providesTags: ['Trainings'],
    }),
    getSingleTraining: builder.query<Training, string>({
      query: (id) => ({
        url: `/trainings/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'Training', id }],
    }),
    postTraining: builder.mutation<void, void>({
      query: (data) => ({
        url: `/trainings`,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(
            trainingsApi.util.invalidateTags([
              'TrainingProgress',
              'Trainings',
              'TrainingsProgressByUserId',
              'TrainingsByUserId',
            ])
          );
          dispatch(companyUsersApi.util.invalidateTags(['CompanyUsersTrainingProgress']));
        });
      },
    }),
    patchTraining: builder.mutation<
      void,
      Partial<Omit<Training, 'id' | 'imageUrl'> & { id: string; imageId?: string | null }>
    >({
      query: ({ id, ...rest }) => ({
        url: `/trainings/${id}`,
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: (_, __, { id }) => [
        'Trainings',
        'TrainingsByUserId',
        { type: 'Training', id },
      ],
    }),
    deleteTrainings: builder.mutation<void, string>({
      query: (id) => ({
        url: `/trainings/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          trainingsApi.util.invalidateTags([
            'TrainingsByUserId',
            'Trainings',
            'TrainingsProgressByUserId',
          ]);
          dispatch(trainingsApi.endpoints.getTrainings.initiate(undefined, { forceRefetch: true }));
          dispatch(companyUsersApi.util.invalidateTags(['CompanyUsersTrainingProgress']));
        });
      },
    }),
    duplicateTrainings: builder.mutation<void, string>({
      query: (id) => ({
        url: `/trainings/${id}/duplicate`,
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(
            trainingsApi.util.invalidateTags([
              'TrainingsByUserId',
              'Trainings',
              'TrainingsProgressByUserId',
            ])
          );
          dispatch(trainingsApi.endpoints.getTrainings.initiate(undefined, { forceRefetch: true }));
          dispatch(companyUsersApi.util.invalidateTags(['CompanyUsersTrainingProgress']));
        });
      },
    }),
    trainingProgress: builder.query<TrainingsProgress[], void>({
      query: () => ({
        url: `/company-users/me/trainings/user-progress`,
      }),
      providesTags: ['TrainingProgress'],
    }),
    trainingsProgressByUserId: builder.query<TrainingsProgress[], string>({
      query: (userId) => ({
        url: `/company-users/${userId}/trainings/user-progress`,
      }),
      providesTags: ['TrainingsProgressByUserId'],
    }),
    trainingsByUserId: builder.query<Training[], string>({
      query: (userId) => ({
        url: `/company-users/${userId}/trainings`,
      }),
      providesTags: ['TrainingsByUserId'],
    }),
    trainingProgressById: builder.query<TrainingsProgress, string>({
      query: (trainingId) => ({
        url: `/company-users/me/trainings/${trainingId}/user-progress`,
      }),
      providesTags: (_, __, trainingId) => [{ type: 'TrainingProgressById', id: trainingId }],
    }),
  }),
});

export const {
  useGetTrainingsQuery,
  usePatchTrainingMutation,
  useGetSingleTrainingQuery,
  usePostTrainingMutation,
  useDeleteTrainingsMutation,
  useDuplicateTrainingsMutation,
  useTrainingProgressQuery,
  useTrainingsProgressByUserIdQuery,
  useTrainingProgressByIdQuery,
  useTrainingsByUserIdQuery,
} = trainingsApi;
