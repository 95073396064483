import { BottomNavigation, BottomNavigationAction, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseEmployeeportalIcon, WorkbaseWaveIcon } from 'src/assets/icons/workbaseIcons';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { useAppDispatch, useAppSelector } from 'src/store';
import { switchPortal } from 'src/store/client/userPortalSlice';

export default function CustomerPortalSwitch() {
  const currentPortal = useAppSelector((state) => state.userPortal.currentPortal);
  const { t } = useTranslation();
  const hasAnyPermission = useHasAnyPermission();
  const dispatch = useAppDispatch();
  if (!hasAnyPermission(['MANAGE_CUSTOMERS', 'MANAGE_CUSTOMER_GROUPS', 'MANAGE_CUSTOMER_PORTAL']))
    return null;
  return (
    <BottomNavigation
      value={currentPortal}
      onChange={(_, newValue) => {
        dispatch(switchPortal(newValue));
      }}
    >
      <BottomNavigationAction
        value="customerPortal"
        label={
          <Typography variant="body2" fontWeight="medium" noWrap>
            {t('customerPortal')}
          </Typography>
        }
        icon={<WorkbaseWaveIcon />}
      />
      <BottomNavigationAction
        value="employeePortal"
        label={
          <Typography variant="body2" fontWeight="medium" noWrap>
            {t('employeePortal')}
          </Typography>
        }
        icon={<WorkbaseEmployeeportalIcon />}
      />
    </BottomNavigation>
  );
}
