import { useState } from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseDeleteSquareIcon, WorkbaseImageNewIcon } from 'src/assets/icons/workbaseIcons';
import ImageEditOverlay from './ImageEditOverlay';

interface Props {
  onImageRemove: () => Promise<void>;
  dataCy?: string;
  rootProps?: any;
  inputProps?: any;
  open?: () => void;
}

export default function ImageEditorWithPreview({
  onImageRemove,
  dataCy,
  rootProps,
  inputProps,
  open,
}: Props) {
  const [isMenuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) =>
    setMenuOpen(event.currentTarget);
  const handleCloseMenu = () => setMenuOpen(null);

  const handleFileMenuItemClick = () => {
    open?.();
  };

  return (
    <>
      <ImageEditOverlay
        rootProps={rootProps}
        dataCy={dataCy}
        onClick={(e) => {
          handleOpenMenu(e);
        }}
      />
      <Menu
        data-cy={dataCy && `${dataCy}-menu`}
        open={Boolean(isMenuOpen)}
        onClose={handleCloseMenu}
        anchorEl={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={handleFileMenuItemClick}
          data-cy={dataCy && `${dataCy}-upload-menu-item`}
        >
          <ListItemIcon>
            <SvgIcon component={WorkbaseImageNewIcon} fontSize="small" color="action" />
          </ListItemIcon>
          <ListItemText
            primary={t('upload')}
            primaryTypographyProps={{ fontWeight: 'bold', variant: 'body1' }}
          />
        </MenuItem>
        <MenuItem
          data-cy={dataCy && `${dataCy}-remove-menu-item`}
          onClick={() => {
            onImageRemove();
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={t('remove')}
            primaryTypographyProps={{ fontWeight: 'bold', variant: 'body1' }}
          />
        </MenuItem>
      </Menu>
      <input {...inputProps} />
    </>
  );
}
