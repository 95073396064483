import { Box, Container } from '@mui/material';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import BaseEditor from '../base-editor/BaseEditor';
import HighlightWrapper from '../highlight-wrapper';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  value?: string;
  readOnly?: boolean;
  onChange?: (val: string) => void;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  id: string;
}

export default function HeadlineItem({
  onDelete,
  onDuplicate,
  value,
  readOnly = false,
  onChange,
  onResizeStop,
  dragging,
  id,
}: Props) {
  return (
    <Box borderRadius={1} py={1} bgcolor="background.default">
      <Container data-cy="editor-grid-head-line-item">
        <HighlightWrapper
          dataCy="editor-widgets-headline-item"
          id={id}
          editBarProps={{
            baseActionsProps: { onDelete, onDuplicate },
            textActionsProps: { textAllignmentHidden: false },
          }}
          dragging={dragging}
          onResizeStop={onResizeStop}
          readOnly={readOnly}
        >
          {({ EditBarComponent }) => (
            <BaseEditor
              value={value}
              onChange={onChange}
              readOnly={readOnly}
              singleLine
              dataCy="editor-widgets-headline-item"
            >
              {EditBarComponent}
            </BaseEditor>
          )}
        </HighlightWrapper>
      </Container>
    </Box>
  );
}
