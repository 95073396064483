import type { PayloadAction } from '@reduxjs/toolkit';
import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { sidebarApi } from '../api/sidebarApi';
import { userPermissionsApi } from '../api/userPermissionsApi';
import { settingsApi } from '../api/settingsApi';
import { newsfeedApi } from '../api/newsfeedApi';
import { trainingsApi } from '../api/trainings/trainingsApi';

interface UserPortalModeState {
  currentPortal: PortalType;
}

const initialState: UserPortalModeState = {
  currentPortal: 'employeePortal',
};

export type PortalType = 'customerPortal' | 'employeePortal';

const persistConfig = {
  key: 'userPortalMode',
  storage,
};

export const userPortalSlice = createSlice({
  name: 'userPortal',
  initialState,
  reducers: {
    switchPortal: (state, action: PayloadAction<PortalType>) => {
      state.currentPortal = action.payload;
    },
  },
});

export const userPortalMiddleware = createListenerMiddleware();

userPortalMiddleware.startListening({
  actionCreator: userPortalSlice.actions.switchPortal,
  effect: async (_, { dispatch }) => {
    dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
    dispatch(userPermissionsApi.util.invalidateTags(['UserPermissions']));
    dispatch(settingsApi.util.invalidateTags(['Settings']));
    dispatch(newsfeedApi.util.invalidateTags(['Newsfeed']));
    dispatch(trainingsApi.util.invalidateTags(['Trainings']));
  },
});

export const { switchPortal } = userPortalSlice.actions;

export const userPortalReducer = persistReducer(persistConfig, userPortalSlice.reducer);
