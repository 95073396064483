import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';
import { departmentsApi } from 'src/features/company-settings/departments/controller/departmentsApi';
import { driveApi } from '../driveApi';
import { sidebarApi } from '../sidebarApi';
import { CompanyUser, Employee, EmployeePatchRequest } from './types';

interface ChangeEmailResponse {
  cooldown: number;
}

export interface CompanyUserProgress {
  id: string;
  progress: number;
}

export const companyUsersApi = createApi({
  reducerPath: 'companyUsersApi',
  tagTypes: ['CompanyUsers', 'CompanyUsersTrainingProgress', 'SearchCompanyUsers'],
  baseQuery,
  endpoints: (builder) => ({
    // queries
    employees: builder.query<Employee[], void>({
      query: () => '/company-users',
      providesTags: ['CompanyUsers'],
    }),
    companyUsersTrainingProgress: builder.query<CompanyUserProgress[], void>({
      query: () => '/company-users/trainings-progress',
      providesTags: ['CompanyUsersTrainingProgress'],
    }),
    searchCompanyUsers: builder.query<CompanyUser[], string>({
      query: (query) => ({
        url: `/company-users/search?query=${query}`,
      }),
      providesTags: ['SearchCompanyUsers'],
    }),
    patchEmployee: builder.mutation<Employee, { id: string; data: Partial<EmployeePatchRequest> }>({
      query: ({ id, data }) => ({
        url: `/company-users/${id}`,
        body: data,
        method: 'PATCH',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(companyUsersApi.util.invalidateTags(['CompanyUsers']));
          dispatch(departmentsApi.util.invalidateTags(['Departments']));
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
      },
    }),
    createCompanyUser: builder.mutation<void, any>({
      query: (data) => ({
        url: `/company-users/batch`,
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['CompanyUsers'],
    }),
    changeEmail: builder.mutation<ChangeEmailResponse, { id: string; email: string }>({
      query: (data) => ({
        url: `/company-users/${data.id}/change-email`,
        body: { email: data.email },
        method: 'POST',
      }),
      onQueryStarted: ({ id, email }, { dispatch, queryFulfilled }) => {
        const disp = dispatch(
          companyUsersApi.util.updateQueryData('employees', undefined, (draft) =>
            draft.map((employee) =>
              employee.id === id ? { ...employee, pendingEmailChange: email } : employee
            )
          )
        );
        queryFulfilled
          .then(({ data }) => {
            dispatch(companyUsersApi.util.updateQueryData('emailChangeCooldown', id, () => data));
          })
          .catch(disp.undo);
      },
    }),
    emailChangeCooldown: builder.query<ChangeEmailResponse, string>({
      query: (id) => `/company-users/${id}/change-email`,
    }),
    cancelEmailChange: builder.mutation<void, string>({
      query: (id) => ({
        url: `/company-users/${id}/change-email/cancel`,
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(companyUsersApi.endpoints.employees.initiate(undefined, { forceRefetch: true }));
        });
      },
    }),
  }),
});

export const {
  useEmployeesQuery,
  useCompanyUsersTrainingProgressQuery,
  useSearchCompanyUsersQuery,
  usePatchEmployeeMutation,
  useCreateCompanyUserMutation,
  useChangeEmailMutation,
  useEmailChangeCooldownQuery,
  useCancelEmailChangeMutation,
} = companyUsersApi;
