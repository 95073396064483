import { Grid2, Stack, Typography, useTheme } from '@mui/material';
import useResizeObserver from '@react-hook/resize-observer';
import { useRef, useState } from 'react';
import TrainingCard from 'src/core/TrainingCard';
import { Training } from 'src/store/api/trainings/trainingsApi';
import Empty from 'src/ui-components/custom/empty/Empty';

interface Props {
  title: string;
  trainings: Training[];
  emptyText: string;
}

export default function TrainingsCategory({ title, trainings, emptyText }: Props) {
  const containerRef = useRef(null);
  const theme = useTheme();
  const [columns, setColumns] = useState(3);

  useResizeObserver(containerRef, (entry) => {
    const { width } = entry.contentRect;

    if (width < theme.breakpoints.values.sm) {
      setColumns(1);
    } else if (width < theme.breakpoints.values.md) {
      setColumns(2);
    } else {
      setColumns(3);
    }
  });

  return (
    <Stack gap={2}>
      <Typography variant="h5" fontWeight="medium">
        {title}
      </Typography>
      {trainings.length === 0 && <Empty description={emptyText} />}
      <Grid2 ref={containerRef} container spacing={2}>
        {trainings.map((training) => (
          <Grid2 key={training.id} size={12 / columns}>
            <TrainingCard {...training} hasPermission />
          </Grid2>
        ))}
      </Grid2>
    </Stack>
  );
}
