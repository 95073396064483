import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './api';

interface CompanyUser {
  id: string;
  details: {
    firstName: string;
    lastName: string;
    email: string;
    avatarUrl: null | string;
  };
}

interface Department {
  id: string;
  details: {
    name: string;
  };
}

interface Role {
  id: string;
  details: {
    name: string;
    department: {
      id: string;
      name: string;
    };
  };
}

interface ResourcesResults {
  companyUsers: CompanyUser[];
  departments: Department[];
  roles: Role[];
}

export type ResourceType = 'training' | 'embeddedApp' | 'page' | 'whiteboard' | 'wikiCard' | 'file';

export const searchResourceApi = createApi({
  reducerPath: 'searchResource',
  baseQuery,
  endpoints: (builder) => ({
    // queries
    searchResultsByResource: builder.query<
      ResourcesResults,
      { resourceId: string; resourceType: ResourceType; query: string }
    >({
      query: ({ resourceId, resourceType, query }) => ({
        url: `/authz/resources/share/search?resourceId=${resourceId}&resourceType=${resourceType}&query=${query}`,
      }),
    }),
  }),
});

export const { useSearchResultsByResourceQuery } = searchResourceApi;
