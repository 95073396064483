import { Box, Stack, Typography, SvgIcon, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbaseContentIcon, WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import { imageUpload } from 'src/lib/utils/imageUpload';
import {
  useTrainingProgressByIdQuery,
  useGetSingleTrainingQuery,
  usePatchTrainingMutation,
} from 'src/store/api/trainings/trainingsApi';
import BrandedHeader from 'src/ui-components/branded-header';
import BrandedDefaultImage from 'src/ui-components/branded-default-image';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';
import ImageWithDefaultFallback from 'src/ui-components/image/ImageWithDefaultFallback';
import useDebouncedValue from 'src/hooks/useDebouncedValue';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import useIsMobile from 'src/hooks/useIsMobile';
import CircularProgressWithLabel from 'src/ui-components/custom/progress/CircularProgressWithLabel';

interface Props {
  readOnly: boolean;
  trainingId: string;
}

const typographyColor = 'primary.contrastText';

export default function TrainingCourseHeader({ readOnly, trainingId }: Props) {
  const { data, isLoading } = useGetSingleTrainingQuery(trainingId);
  const [patchTraining] = usePatchTrainingMutation();
  const { data: progressData } = useTrainingProgressByIdQuery(trainingId as string, {
    refetchOnMountOrArgChange: true,
  });

  const { t } = useTranslation();

  const lessonsCountLabel = t('lesson', { count: data?.lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: data?.estimatedTimeToComplete || 0 });

  const imgChange = async (file: File): Promise<unknown> => {
    try {
      const response = await imageUpload(file);
      const { id } = response.data.result;
      return await patchTraining({
        id: trainingId,
        imageId: id,
      });
    } catch (_err) {
      showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
    }
    return undefined;
  };

  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      patchTraining({ name: e.target.value, id: trainingId });
    },
    [patchTraining, trainingId]
  );
  const debouncedNameData = useDebouncedValue(data?.name, handleNameChange);

  const handleDescriptionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      patchTraining({ description: e.target.value, id: trainingId });
    },
    [patchTraining, trainingId]
  );

  const debouncedDescriptionData = useDebouncedValue(data?.description, handleDescriptionChange);

  const trainingNameToDisplay = data?.name || t('trainingDetailPage.unnamedTraining');

  const isMobile = useIsMobile();

  const imageSize = {
    width: isMobile ? '100%' : 290,
    height: 160,
  };

  return (
    <BrandedHeader backgroundImage={{ url: data?.imageUrl, blur: true }}>
      <Box margin="auto" width="100%">
        <Stack
          direction={isMobile ? 'column-reverse' : 'row'}
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Stack
            gap={1}
            color={typographyColor}
            minWidth={0}
            flexShrink={1}
            justifyContent="center"
            flexGrow={1}
            width="100%"
          >
            <Stack flexDirection="row" gap={2} alignItems="center">
              <CircularProgressWithLabel
                value={progressData?.progress || 0}
                circularProgressProps={{ color: 'inherit' }}
              />
              {readOnly ? (
                <Stack gap={1} minWidth={0}>
                  <Box overflow="hidden">
                    <Typography
                      fontWeight="medium"
                      variant="h4"
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {trainingNameToDisplay}
                    </Typography>
                  </Box>
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <SvgIcon component={WorkbaseContentIcon} fontSize="small" />
                    <Typography variant="caption">
                      {lessonsCountLabel} – {estimatedTimeLabel}
                    </Typography>
                  </Stack>
                </Stack>
              ) : (
                <TextField
                  data-cy="training-header-title-textfield"
                  autoFocus
                  fullWidth
                  value={debouncedNameData.value}
                  placeholder={`${t('trainingDetailPage.unnamedTraining')}`}
                  onChange={debouncedNameData.handleChange}
                  color="contrastText"
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box color="inherit" display="flex">
                            <SvgIcon component={WorkbaseEditIcon} color="inherit" />
                          </Box>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              )}
            </Stack>
            {readOnly && (
              <Box overflow="hidden">
                <Typography
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                  }}
                  fontWeight="medium"
                  variant="body1"
                >
                  {data?.description}
                </Typography>
              </Box>
            )}
            {!readOnly && (
              <TextField
                data-cy="training-header-placeholder-textfield"
                placeholder={t('editor.trainingsSection.moduleTitlePlaceholder')}
                multiline
                rows={4}
                maxRows={4}
                fullWidth
                value={debouncedDescriptionData.value}
                onChange={debouncedDescriptionData.handleChange}
                color="contrastText"
              />
            )}
          </Stack>
          <Box width={isMobile ? '100%' : 'auto'}>
            {!readOnly && (
              <Box {...imageSize}>
                <EditableImageUpload
                  dataCy="training-course-header"
                  src={data?.imageUrl}
                  onChange={imgChange}
                  onClear={() => patchTraining({ id: trainingId, imageId: null })}
                  placeholder={
                    <BrandedDefaultImage
                      defaultImage={data?.defaultImage as any}
                      dataCy="training-course-header"
                    />
                  }
                />
              </Box>
            )}
            {readOnly && (
              <ImageWithDefaultFallback
                src={data?.imageUrl}
                loading={isLoading}
                {...imageSize}
                alt={t('trainingDetailPage.trainingImageAlt')}
                defaultImage={data?.defaultImage as any}
              />
            )}
          </Box>
        </Stack>
      </Box>
    </BrandedHeader>
  );
}
