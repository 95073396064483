import { Components, Theme } from '@mui/material';

const MuiDialogActions: Components<Theme>['MuiDialogActions'] = {
  styleOverrides: {
    root: {
      padding: 20,
      paddingTop: 8,
      gap: 8,
    },
  },
};

export default MuiDialogActions;
