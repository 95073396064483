import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, FormControlLabel, Stack, SvgIcon, Typography } from '@mui/material';
import { WorkbaseEmployeeportalIcon } from 'src/assets/icons/workbaseIcons';
import { useConfirm } from 'material-ui-confirm';
import useScreenStream from './useScreenStream';

interface Props {
  onScreenStreamSelect: (val: MediaStream | null) => void;
  stream: MediaStream | null;
  disabled: boolean;
  isCameraSelected: boolean;
}

export default function ScreenRecorderButton({
  onScreenStreamSelect,
  stream,
  disabled,
  isCameraSelected,
}: Props) {
  const { getScreenStream, stopScreenStream } = useScreenStream(stream, onScreenStreamSelect);
  const { t } = useTranslation();
  const confirm = useConfirm();
  const dontShowAgainStorageKey = 'screenSharingWarning';

  const showScreenSharingWarning = () => {
    let dontShowAgainChecked = false;

    confirm({
      title: t('recording.screenSharingWarningTitle'),
      description: (
        <Stack gap={2}>
          <Typography>{t('recording.screenSharingWarningDescription')}</Typography>
          <FormControlLabel
            onChange={(_, checked) => {
              dontShowAgainChecked = checked;
            }}
            label={t('dontShowAgain')}
            control={<Checkbox />}
          />
        </Stack>
      ),
      confirmationText: t('recording.startSharing'),
    }).then(() => {
      if (dontShowAgainChecked) {
        localStorage.setItem(dontShowAgainStorageKey, 'true');
      } else {
        localStorage.removeItem(dontShowAgainStorageKey);
      }
      getScreenStream();
    });
  };

  const handleStartScreenSharing = () => {
    const dontShowAgain = localStorage.getItem(dontShowAgainStorageKey) === 'true';

    if (isCameraSelected && !dontShowAgain) {
      showScreenSharingWarning();
    } else {
      getScreenStream();
    }
  };

  return (
    <Box flexShrink={0}>
      <Button
        data-cy={`screen-camera-recorder-${stream ? 'stopSharing' : 'startSharing'}-button`}
        startIcon={
          <SvgIcon>
            <WorkbaseEmployeeportalIcon />
          </SvgIcon>
        }
        disabled={disabled}
        color={stream ? 'error' : 'primary'}
        onClick={() => {
          if (stream) {
            stopScreenStream();
          } else {
            handleStartScreenSharing();
          }
        }}
      >
        {t(stream ? 'recording.stopSharing' : 'recording.startSharing')}
      </Button>
    </Box>
  );
}
