import { Components, Theme } from '@mui/material';

declare module '@mui/material/Typography' {
  interface TypographyOwnProps {
    wordBreak?: 'break-word' | 'keep-all' | 'break-all' | 'normal';
  }
}

const MuiTypography: Components<Theme>['MuiTypography'] = {
  defaultProps: {
    wordBreak: 'normal',
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      wordBreak: ownerState.wordBreak,
    }),
  },
};

export default MuiTypography;
