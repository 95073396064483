import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  SvgIcon,
  Typography,
  Stack,
  Skeleton,
  TableCellProps,
  Tooltip,
  Box,
  IconButton,
} from '@mui/material';
import { File, useDeleteFileMutation } from 'src/store/api/driveApi';
import { WorkbaseDeleteSquareIcon, WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import Empty from 'src/ui-components/custom/empty/Empty';
import { useTranslation } from 'react-i18next';
import { formatDateTimeFromTimeStamp } from 'src/lib/utils/format-time';
import ShareModal from 'src/core/share-modal/ShareModal';
import { ShareLevel } from 'src/lib/types/share-level';
import { useConfirm } from 'material-ui-confirm';
import useIsMobile from 'src/hooks/useIsMobile';
import formatBytes from '../helpers/formatBytes';
import getFileIcon from '../helpers/fileIconMap';
import FileRenameDialog from '../file-viewer/FileRenameDialog';
import FilePreviewDialog from '../file-viewer/FilePreviewDialog';
import getManagerTitle from './getManagerTitle';
import DocumentNameCell from './DocumentNameCell';

interface FilesTableProps {
  files: File[];
  uploadCount?: number;
}

function FilesTable(props: FilesTableProps) {
  const { t } = useTranslation();
  const [openedFile, setOpenedFile] = useState<File | null>(null);
  const [fileEditData, setFileEditData] = useState<File | null>(null);
  const [deleteFile] = useDeleteFileMutation();
  const confirm = useConfirm();
  const { files, uploadCount } = props;

  const handleOpenFile = (file: File) => {
    setOpenedFile(file);
  };

  const colsData: { name: string; cellProps?: Partial<TableCellProps> }[] = [
    { name: t('documentsPage.table.columnNames.fileName'), cellProps: { align: 'left' } },
    { name: t('documentsPage.table.columnNames.fileOwner'), cellProps: { align: 'left' } },
    { name: t('documentsPage.table.columnNames.updatedAt'), cellProps: { align: 'left' } },
    { name: t('documentsPage.table.columnNames.fileSize'), cellProps: { align: 'left' } },
    { name: t('documentsPage.table.columnNames.actions'), cellProps: { align: 'center' } },
  ];

  const isMobile = useIsMobile();
  return (
    <>
      <FileRenameDialog fileData={fileEditData} onClose={() => setFileEditData(null)} />
      <FilePreviewDialog
        open={!!openedFile}
        onClose={() => setOpenedFile(null)}
        fileId={openedFile?.id as string}
        fileType={openedFile?.fileType as string}
        fileName={openedFile?.filename}
      />
      <TableContainer component={Paper} variant="outlined" sx={{ flexGrow: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {colsData.map(({ name, cellProps }) => (
                <TableCell key={name} {...cellProps}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {files.length > 0 || uploadCount ? (
              files.map((file) => (
                <TableRow key={file.id}>
                  <TableCell>
                    <Box sx={{ width: '100%', maxWidth: isMobile ? 200 : 400 }}>
                      <DocumentNameCell
                        id={file.id}
                        onClick={() => handleOpenFile(file)}
                        icon={getFileIcon(file.fileType)}
                        title={file.filename}
                        sharedWithOthers={file.sharedWithOthers}
                        resourceType={file.resourceType}
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" noWrap>
                      {getManagerTitle(file, t)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" noWrap>
                      {formatDateTimeFromTimeStamp(file.updatedAt)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" noWrap>
                      {formatBytes(file.fileSize)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" justifyContent="flex-end" gap={1}>
                      <ShareModal
                        name={file.filename}
                        resourceId={file.id}
                        resourceType="file"
                        shareLevel={file.shareLevel}
                        dataCy="files-table"
                      />
                      {file.shareLevel !== ShareLevel.view && (
                        <Tooltip title={t('edit')}>
                          <IconButton variant="soft" onClick={() => setFileEditData(file)}>
                            <SvgIcon component={WorkbaseEditIcon} fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {file.shareLevel === ShareLevel.manage && (
                        <Tooltip title={t('delete')}>
                          <IconButton
                            data-cy="documents-page-delete-file"
                            variant="soft"
                            onClick={() => {
                              confirm({
                                title: t('areYouSure'),
                                description: t('documentsPage.deleteFileConfirmDescription', {
                                  name: file.filename,
                                }),
                                confirmationText: t('delete'),
                              }).then(() => {
                                deleteFile(file.id);
                              });
                            }}
                          >
                            <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={colsData.length} align="center">
                  <Empty />
                </TableCell>
              </TableRow>
            )}
            {/* eslint-disable-next-line prefer-spread */}
            {Array.apply(null, Array(uploadCount || 0)).map(() => (
              <TableRow key={`skeleton-${crypto.randomUUID()}`}>
                <TableCell colSpan={3}>
                  <Skeleton height="100%" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default FilesTable;
