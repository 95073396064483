import { useDropzone } from 'react-dropzone';
import { alpha, Box, Button, IconButton, Stack, SvgIcon } from '@mui/material';
import Image from 'src/ui-components/image';
import {
  WorkbaseDeleteSquareIcon,
  WorkbaseEditIcon,
  WorkbaseLinksIcon,
} from 'src/assets/icons/workbaseIcons';
import FaviconMenu from './FaviconMenu';

interface EditableLinkButtonProps {
  faviconUrl?: string;
  children?: React.ReactNode;
  href?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
  editable?: boolean;
  onRemove?: () => void;
  onEdit?: () => void;
  onFaviconChange?: (e: any) => void;
  onRemoveFavicon?: () => void;
}

function EditableLinkButton({
  children,
  href,
  faviconUrl,
  editable,
  onRemove,
  onEdit,
  onFaviconChange,
  onRemoveFavicon,
}: EditableLinkButtonProps) {
  const handleDrop = (acceptedFiles: File[]) => onFaviconChange?.(acceptedFiles[0]);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp'],
      'image/gif': ['.gif'],
      'image/png': ['.png', '.apng'],
      'image/avif': ['.avif'],
      'image/svg+xml': ['.svg'],
      'image/webp': ['.webp'],
    },
  });

  const editOverlay = (
    <Stack
      {...getRootProps()}
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={1}
      borderRadius={1}
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        opacity: isDragActive ? 0.7 : 0,
        backgroundColor: (theme) => alpha(theme.palette.common.black, 0.8),
        transition: (theme) =>
          theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
          }),
        '&:hover': {
          opacity: 1,
        },
        '&& > *': {
          borderRadius: 0.5,
        },
        '&& > *:hover': {
          backgroundColor: 'common.white',
          color: 'primary.main',
        },
      }}
    >
      <IconButton
        onClick={onRemove}
        size="small"
        color="inherit"
        data-cy="editable-link-delete-button"
      >
        <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
      </IconButton>
      <IconButton
        onClick={onEdit}
        color="inherit"
        size="small"
        disableFocusRipple
        data-cy="editable-link-edit-button"
      >
        <SvgIcon component={WorkbaseEditIcon} fontSize="small" />
      </IconButton>
      <FaviconMenu
        faviconUrl={faviconUrl}
        onRemoveFavicon={onRemoveFavicon}
        getInputProps={getInputProps}
        onFaviconChange={onFaviconChange}
      />
    </Stack>
  );

  return (
    <Box minWidth={200}>
      <Button
        data-cy="ediatble-link-button"
        href={editable ? '' : href || ''}
        target="_blank"
        fullWidth
        startIcon={
          faviconUrl ? (
            // eslint-disable-next-line i18n-checker/no-text-as-attribute
            <Image borderRadius={0.5} width={16} height={16} src={faviconUrl} alt="Favicon" />
          ) : (
            <SvgIcon component={WorkbaseLinksIcon} fontSize="small" />
          )
        }
      >
        {children}
        {editable && editOverlay}
      </Button>
    </Box>
  );
}

export default EditableLinkButton;
