import { SidebarItem } from 'src/ui-components/layout/sidebar/types';
import normalizeString from './normalizeString';

export function filterItemsBySearchVal<T extends SidebarItem>(data: T[], searchVal: string): T[] {
  // Normalize the searchVal
  const searchTerm = normalizeString(searchVal ?? '');
  // Filter the groupData array and keep sections with matching items
  const filteredItems = data.filter((item) => {
    const searchText = normalizeString(item.text);
    return searchText.includes(searchTerm);
  });

  return filteredItems;
}
