import { useEffect, useState } from 'react';

const ITEM_WIDTH = 48;

function useElementWidth(el?: HTMLElement | null): number {
  const [elementWidth, setElementWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    if (!el) return;

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setElementWidth(entry.contentRect.width);
      });
    });

    resizeObserver.observe(el);

    setElementWidth(el.clientWidth);
    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.unobserve(el);
      resizeObserver.disconnect();
    };
  }, [el]);

  return elementWidth;
}

export default function useResponsiveness(
  topBarRef: React.RefObject<HTMLDivElement>,
  childrenContainerRef: React.RefObject<HTMLDivElement>,
  toolItemsCount: number
): number {
  const topBarWidth = useElementWidth(topBarRef.current);
  const childrenContainerWidth = useElementWidth(childrenContainerRef.current);
  const [elementsInDropdown, setElementsInDropdown] = useState(0);

  useEffect(() => {
    const availableSpace = topBarWidth - (childrenContainerWidth || 0) - ITEM_WIDTH;
    const widthDiff = ITEM_WIDTH * toolItemsCount - availableSpace;

    const calculatedElementsInDropdown = widthDiff <= 0 ? 0 : Math.ceil(widthDiff / ITEM_WIDTH);

    if (calculatedElementsInDropdown > toolItemsCount) {
      setElementsInDropdown(toolItemsCount);
    } else {
      setElementsInDropdown(calculatedElementsInDropdown);
    }
  }, [topBarWidth, childrenContainerWidth, toolItemsCount]);

  return elementsInDropdown;
}
