import {
  Box,
  Container,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import {
  usePatchTrainingLessonMutation,
  useTrainingLessonQuery,
} from 'src/store/api/trainings/trainingLessonsApi';
import useDebouncedValue from 'src/hooks/useDebouncedValue';
import { ChangeEvent, useCallback } from 'react';
import BrandedHeader from 'src/ui-components/branded-header';
import LessonBreadcrumbs from './LessonBreadcrumbs';

interface Props {
  lessonId: string;
  readOnly: boolean;
}

export default function LessonHeader({ readOnly, lessonId }: Props) {
  const { t } = useTranslation();
  const { data } = useTrainingLessonQuery(lessonId);
  const [patchLesson] = usePatchTrainingLessonMutation();

  const handleLessonNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      patchLesson({ id: lessonId, name: e.target.value });
    },
    [lessonId, patchLesson]
  );
  const { handleChange, value } = useDebouncedValue(data?.name, handleLessonNameChange);

  const handleImageChange = useCallback(
    (imageId: string | null) => {
      patchLesson({ id: lessonId, imageId });
    },
    [lessonId, patchLesson]
  );

  return (
    <Stack alignItems="flex-start" gap={2}>
      <Container>
        <LessonBreadcrumbs />
      </Container>
      <BrandedHeader
        minHeight={160}
        backgroundImage={{
          url: data?.imageUrl,
          onChange: handleImageChange,
        }}
      >
        <Stack gap={1} alignItems="center" justifyContent="center" width="100%" alignSelf="center">
          {!readOnly && (
            <TextField
              data-cy="lesson-header-input"
              value={value}
              placeholder={t('writeTitlePlaceholder')}
              color="contrastText"
              onChange={handleChange}
              fullWidth
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box color="primary.contrastText">
                        <SvgIcon component={WorkbaseEditIcon} />
                      </Box>
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
          {readOnly && (
            <Typography variant="h4" fontWeight="bold">
              {data?.name ? data?.name : t('modulePage.unnamedLesson')}
            </Typography>
          )}
        </Stack>
      </BrandedHeader>
    </Stack>
  );
}
