import { Components, Theme } from '@mui/material';

const MuiSkeleton: Components<Theme>['MuiSkeleton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
    }),
  },
};

export default MuiSkeleton;
