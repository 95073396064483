import * as Y from 'yjs';
import { UUID } from 'uuidjs';
import { findItemIndexInRow, moveItemToNewRow, removeItemFromRow } from '../../helpers';

export const handleDragAndDropToSideOfRow =
  (yDoc: any, yEditorData: any, origin?: any) =>
  (droppedItemId: string, targetRowId: string, position: 'left' | 'right') =>
    // eslint-disable-next-line max-statements
    yDoc.transact(() => {
      const gridArray = yEditorData.get('grid');
      const targetRow = gridArray.toArray().find((yRow: any) => yRow.get('id') === targetRowId);
      const isTargetRowCurrentRow = gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');
        const itemIndex = findItemIndexInRow(yItems, droppedItemId);
        if (itemIndex !== -1 && yRow.get('id') === targetRowId) {
          return true;
        }
        return false;
      });
      const targetRowItems = targetRow.get('items');
      const totalColumns = 4;
      // check sides
      const isSideColumnFilled = targetRowItems.toArray().some((yItem: any) => {
        if (position === 'left') {
          return yItem.get('columnStart') === 1;
        }
        return yItem.get('columnEnd') === 5;
      });
      if (!isSideColumnFilled) return;

      let itemToMove: any = null;
      let targetRowIndex = 0;
      let deletedRowIndex = null;
      // Remove the item from its original row
      gridArray.forEach((yRow: any, index: number) => {
        const yItems = yRow.get('items');
        const itemIndex = findItemIndexInRow(yItems, droppedItemId);
        if (yRow.get('id') === targetRowId) {
          targetRowIndex = index;
        }
        if (itemIndex !== -1) {
          itemToMove = yItems.get(itemIndex).toJSON();
          if (isTargetRowCurrentRow) {
            // eslint-disable-next-line max-depth
            if (
              (position === 'left' && itemToMove.columnStart === 1) ||
              (position === 'right' && itemToMove.columnEnd === 5)
            ) {
              itemToMove = null;
              return;
            }
          }
          removeItemFromRow(yItems, itemIndex);
          if (yItems.length === 0) {
            // Remove the row if it's now empty
            deletedRowIndex = index;
            gridArray.delete(gridArray.toArray().indexOf(yRow), 1);
          }
        }
      });

      if (!itemToMove) return; // Exit if item not found

      if (deletedRowIndex !== null && deletedRowIndex < targetRowIndex) {
        targetRowIndex -= 1;
      }

      if (targetRowItems.length === 4) {
        const insertNewGridItem = () => {
          const yNewItem = new Y.Map(Object.entries(itemToMove));
          const indexToInsert = position === 'left' ? 0 : 3;
          targetRowItems.insert(indexToInsert, [yNewItem]);
        };

        const itemToMoveToNextRow = targetRowItems
          .toArray()
          .find((yItem: any) =>
            position === 'left' ? yItem.get('columnEnd') === 5 : yItem.get('columnStart') === 1
          )
          .toJSON();
        itemToMoveToNextRow.columnStart = 1;
        itemToMoveToNextRow.columnEnd = 2;
        const itemToMoveToNextRowIndex = targetRowItems
          .toArray()
          .findIndex((yItem: any) => yItem.get('id') === itemToMoveToNextRow.id);
        targetRowItems.delete(itemToMoveToNextRowIndex);
        const newRow = new Y.Map();
        newRow.set('id', UUID.generate());
        const newYItems = new Y.Array();
        const yRightmostItem = new Y.Map(
          Object.entries(itemToMoveToNextRow).map(([key, value]) => [key, value])
        );
        newYItems.push([yRightmostItem]);
        newRow.set('items', newYItems);
        gridArray.insert(targetRowIndex + 1, [newRow]);
        // Shift items in the target row to the right of the insertion point
        targetRowItems.toArray().forEach((yItem: any) => {
          if (position === 'left') {
            yItem.set('columnStart', yItem.get('columnStart') + 1);
            yItem.set('columnEnd', yItem.get('columnEnd') + 1);
          } else {
            yItem.set('columnStart', yItem.get('columnStart') - 1);
            yItem.set('columnEnd', yItem.get('columnEnd') - 1);
          }
        });
        //  Insert the new item
        itemToMove.columnStart = position === 'left' ? 1 : 4;
        itemToMove.columnEnd = position === 'left' ? 2 : 5;
        insertNewGridItem();
        return;
      }

      const sortedItems = targetRow
        .get('items')
        .toArray()
        .sort((a: any, b: any) => a.get('columnStart') - b.get('columnStart'));
      const availableSpace =
        totalColumns -
        sortedItems.reduce(
          (acc: any, item: any) => acc + (item.get('columnEnd') - item.get('columnStart')),
          0
        );

      let itemToMoveWidth = itemToMove.columnEnd - itemToMove.columnStart;
      if (itemToMoveWidth > availableSpace) {
        itemToMoveWidth = availableSpace || 1;
      }

      // adjust move item positon
      if (position === 'left') {
        itemToMove.columnStart = 1;
        itemToMove.columnEnd = itemToMove.columnStart + itemToMoveWidth;
      } else {
        itemToMove.columnEnd = totalColumns + 1;
        itemToMove.columnStart = itemToMove.columnEnd - itemToMoveWidth;
      }

      if (availableSpace === 0) {
        if (position === 'left') {
          let spaceToFree = 1; // We need to free at least 1 column for itemToMove
          // eslint-disable-next-line max-depth
          for (let i = 0; i < sortedItems.length && spaceToFree > 0; i += 1) {
            const item = sortedItems[i];
            const currentSize = item.get('columnEnd') - item.get('columnStart');
            // eslint-disable-next-line max-depth
            if (currentSize > 1) {
              // If the item can be resized
              const newSize = Math.max(1, currentSize - spaceToFree);
              item.set('columnStart', item.get('columnEnd') - newSize); // Resize the item
              spaceToFree -= currentSize - newSize;
            } else if (i === 0) {
              item.set('columnStart', 2);
              item.set('columnEnd', 3);
            } else if (i === 1) {
              item.set('columnStart', 3);
              item.set('columnEnd', 4);
            } else if (i === 2) {
              item.set('columnStart', 4);
              item.set('columnEnd', 5);
            }
          }
        } else {
          // Start resizing and shifting items to the left
          let spaceToFree = 1; // Need to free at least 1 column for itemToMove
          for (let i = sortedItems.length - 1; i >= 0 && spaceToFree > 0; i -= 1) {
            const item = sortedItems[i];
            const currentSize = item.get('columnEnd') - item.get('columnStart');
            if (currentSize > 1) {
              // If the item can be resized
              const newSize = Math.max(1, currentSize - spaceToFree);
              item.set('columnEnd', item.get('columnStart') + newSize); // Resize the item
              spaceToFree -= currentSize - newSize;
            } else if (i === sortedItems.length - 1) {
              item.set('columnStart', 3);
              item.set('columnEnd', 4);
            } else if (i === sortedItems.length - 2) {
              item.set('columnStart', 2);
              item.set('columnEnd', 3);
            } else if (i === sortedItems.length - 3) {
              item.set('columnStart', 1);
              item.set('columnEnd', 2);
            }
          }
        }
      } else if (position === 'left') {
        for (let i = 0; i < sortedItems.length; i += 1) {
          const currentItem = sortedItems[i];
          const previousItem = i > 0 ? sortedItems[i - 1] : null;

          if (!previousItem) {
            const start = currentItem.get('columnStart') + itemToMoveWidth;
            const end = currentItem.get('columnEnd') + itemToMoveWidth;
            currentItem.set('columnStart', start);
            currentItem.set('columnEnd', end);
          } else {
            const overlapping = previousItem.get('columnEnd') - currentItem.get('columnStart');
            if (overlapping > 0) {
              currentItem.set('columnStart', currentItem.get('columnStart') + overlapping);
              currentItem.set('columnEnd', currentItem.get('columnEnd') + overlapping);
            }
          }
        }
      } else {
        for (let i = sortedItems.length - 1; i >= 0; i -= 1) {
          const currentItem = sortedItems[i];
          const nextItem = i < sortedItems.length - 1 ? sortedItems[i + 1] : null;

          if (!nextItem) {
            // Shift the last item to the left
            const start = currentItem.get('columnStart') - itemToMoveWidth;
            const end = currentItem.get('columnEnd') - itemToMoveWidth;
            currentItem.set('columnStart', Math.max(1, start)); // Ensure the start doesn't go below 1
            currentItem.set('columnEnd', Math.max(start + 1, end)); // Adjust the end if necessary
          } else {
            // Calculate overlap with the next item, if any
            const overlapping = currentItem.get('columnEnd') - nextItem.get('columnStart');
            if (overlapping > 0) {
              currentItem.set('columnStart', currentItem.get('columnStart') - overlapping);
              currentItem.set('columnEnd', currentItem.get('columnEnd') - overlapping);
            }
          }
        }
      }
      const yItemToMove = new Y.Map(Object.entries(itemToMove));
      if (position === 'left') targetRow.get('items').unshift([yItemToMove]);
      else targetRow.get('items').push([yItemToMove]);
    }, origin);

export const moveItemToNewPosition =
  (ydoc: any, yEditorData: any, origin?: any) =>
  ({
    itemId,
    newColumnStart,
    newColumnEnd,
    targetRowId,
  }: {
    itemId: string;
    newColumnStart: number;
    newColumnEnd: number;
    targetRowId: string;
  }) =>
    ydoc.transact(() => {
      const gridArray = yEditorData.get('grid');
      let itemToMove: any = null;

      // Find and remove the item
      gridArray.toArray().some((yRow: any, rowIndex: number) => {
        const yItems = yRow.get('items');
        const itemIndex = findItemIndexInRow(yItems, itemId);

        if (itemIndex !== -1) {
          itemToMove = yItems.get(itemIndex).toJSON();
          if (
            itemToMove.columnStart === newColumnStart &&
            itemToMove.columnEnd === newColumnEnd &&
            targetRowId === yRow.get('id')
          ) {
            itemToMove = null;
            return true;
          }
          removeItemFromRow(yItems, itemIndex);
          if (yItems.length === 0 && targetRowId !== yRow.get('id')) {
            gridArray.delete(rowIndex, 1);
          }
          return true; // Stop iteration
        }
        return false;
      });

      if (!itemToMove) {
        return; // Item not found
      }

      // Update item's column positions
      itemToMove.columnStart = newColumnStart;
      itemToMove.columnEnd = newColumnEnd;

      // Insert item into new row
      const yNewItem = new Y.Map(Object.entries(itemToMove));
      gridArray.toArray().forEach((yRow: any) => {
        if (yRow.get('id') === targetRowId) {
          let indexToInsert = null;
          yRow
            .get('items')
            .toArray()

            .some((yItem: any, index: number) => {
              if (yItem.get('columnStart') >= newColumnEnd) {
                indexToInsert = index;
                return true;
              }
              return false;
            });
          if (indexToInsert === null) {
            indexToInsert = yRow.get('items').toArray().length;
          }
          yRow.get('items').insert(indexToInsert, [yNewItem]);
        }
      });
    }, origin);

export const handleDropIntoFullRow =
  (ydoc: any, yEditorData: any, origin?: any) =>
  ({
    itemId,
    targetRowId,
    columnEndPosition,
  }: {
    itemId: string;
    targetRowId: string;
    columnEndPosition: number;
  }) => {
    // eslint-disable-next-line max-statements
    ydoc.transact(() => {
      const gridArray = yEditorData.get('grid');
      let itemToMove: any = null;
      let targetRow: any = null;
      let targetRowIndex = 0;
      let deletedRowIndex = null;

      // Find the item and the target row
      gridArray.forEach((yRow: any, index: number) => {
        if (yRow.get('id') === targetRowId) {
          targetRow = yRow;
          targetRowIndex = index;
        }
        const yItems = yRow.get('items');
        const itemIndex = findItemIndexInRow(yItems, itemId);
        if (itemIndex !== -1) {
          itemToMove = yItems.get(itemIndex).toJSON();
          const isRowEmpty = removeItemFromRow(yItems, itemIndex);
          if (isRowEmpty) {
            gridArray.delete(index, 1);
            deletedRowIndex = index;
          }
        }
      });

      if (deletedRowIndex !== null && deletedRowIndex < targetRowIndex) {
        targetRowIndex -= 1;
      }

      if (!itemToMove || !targetRow) return; // Exit if item or row not found

      const targetRowItems = targetRow.get('items');

      const insertNewGridItem = () => {
        const yNewItem = new Y.Map(Object.entries(itemToMove));
        let indexToInsert = null;
        targetRowItems.toArray().some((yItem: any, index: number) => {
          if (yItem.get('columnStart') >= itemToMove.columnEnd) {
            indexToInsert = index;
            return true;
          }
          return false;
        });
        if (indexToInsert === null) {
          indexToInsert = targetRowItems.toArray().length;
        }
        targetRowItems.insert(indexToInsert, [yNewItem]);
      };

      // inserting in full row
      if (targetRowItems.length === 4) {
        // Move the rightmost item to a new row
        const rightmostItem = targetRowItems
          .toArray()
          .find((yItem: any) => yItem.get('columnEnd') === 5)
          .toJSON();
        rightmostItem.columnStart = 1;
        rightmostItem.columnEnd = 2;
        const rightMostItemIndex = targetRowItems
          .toArray()
          .findIndex((yItem: any) => yItem.get('id') === rightmostItem.id);
        targetRowItems.delete(rightMostItemIndex);
        const newRow = new Y.Map();
        newRow.set('id', UUID.generate());
        const newYItems = new Y.Array();
        const yRightmostItem = new Y.Map(
          Object.entries(rightmostItem).map(([key, value]) => [key, value])
        );
        newYItems.push([yRightmostItem]);
        newRow.set('items', newYItems);
        gridArray.insert(targetRowIndex + 1, [newRow]);
        // Shift items in the target row to the right of the insertion point
        targetRowItems.forEach((yItem: any) => {
          if (yItem.get('columnStart') >= columnEndPosition) {
            yItem.set('columnStart', yItem.get('columnStart') + 1);
            yItem.set('columnEnd', yItem.get('columnEnd') + 1);
          }
        });
        //  Insert the new item
        itemToMove.columnStart = columnEndPosition;
        itemToMove.columnEnd = columnEndPosition + 1;
        insertNewGridItem();
        return;
      }

      const availableSpace =
        4 -
        targetRowItems
          .toArray()
          .reduce(
            (acc: any, item: any) => acc + (item.get('columnEnd') - item.get('columnStart')),
            0
          );
      let itemToMoveWidth = itemToMove.columnEnd - itemToMove.columnStart;
      if (availableSpace < itemToMoveWidth) {
        itemToMoveWidth = availableSpace || 1;
      }
      // shifting items
      if (availableSpace > 0) {
        if (columnEndPosition === 2) {
          // swifting items right
          targetRowItems.forEach((yItem: any) => {
            if (yItem.get('columnStart') >= columnEndPosition && yItem.get('columnEnd') !== 5) {
              yItem.set('columnStart', yItem.get('columnStart') + itemToMoveWidth);
              yItem.set('columnEnd', yItem.get('columnEnd') + itemToMoveWidth);
            }
          });
          itemToMove.columnStart = columnEndPosition;
          itemToMove.columnEnd = columnEndPosition + itemToMoveWidth;
          insertNewGridItem();
          return;
        }

        if (columnEndPosition === 4) {
          // swifting items left
          targetRowItems.forEach((yItem: any) => {
            if (yItem.get('columnStart') < columnEndPosition && yItem.get('columnStart') !== 1) {
              yItem.set('columnStart', yItem.get('columnStart') - itemToMoveWidth);
              yItem.set('columnEnd', yItem.get('columnEnd') - itemToMoveWidth);
            }
          });
          itemToMove.columnStart = columnEndPosition - itemToMoveWidth;
          itemToMove.columnEnd = columnEndPosition;
          insertNewGridItem();
          return;
        }

        if (columnEndPosition === 3) {
          const hasLastItem = !!targetRowItems
            .toArray()
            .find((item: any) => item.get('columnEnd') === 5);
          // eslint-disable-next-line max-depth
          if (hasLastItem) {
            // swifting items left
            targetRowItems.forEach((yItem: any) => {
              if (yItem.get('columnStart') < columnEndPosition && yItem.get('columnStart') !== 1) {
                yItem.set('columnStart', yItem.get('columnStart') - itemToMoveWidth);
                yItem.set('columnEnd', yItem.get('columnEnd') - itemToMoveWidth);
              }
            });
            itemToMove.columnStart = columnEndPosition - itemToMoveWidth;
            itemToMove.columnEnd = columnEndPosition;
            insertNewGridItem();
            return;
          }

          const hasFirstItem = !!targetRowItems
            .toArray()
            .find((item: any) => item.get('columnStart') === 1);
          // eslint-disable-next-line max-depth
          if (hasFirstItem) {
            targetRowItems.forEach((yItem: any) => {
              if (yItem.get('columnStart') >= columnEndPosition && yItem.get('columnEnd') !== 5) {
                yItem.set('columnStart', yItem.get('columnStart') + itemToMoveWidth);
                yItem.set('columnEnd', yItem.get('columnEnd') + itemToMoveWidth);
              }
            });
            itemToMove.columnStart = columnEndPosition;
            itemToMove.columnEnd = columnEndPosition + itemToMoveWidth;
            insertNewGridItem();
            return;
          }
          let swiftValue = itemToMoveWidth;
          targetRowItems.forEach((yItem: any) => {
            if (yItem.get('columnStart') >= columnEndPosition && yItem.get('columnEnd') !== 5) {
              yItem.set('columnStart', yItem.get('columnStart') + 1);
              yItem.set('columnEnd', yItem.get('columnEnd') + 1);
              swiftValue -= 1;
            }
          });
          // eslint-disable-next-line max-depth
          if (swiftValue) {
            targetRowItems.forEach((yItem: any) => {
              if (yItem.get('columnStart') < columnEndPosition && yItem.get('columnStart') !== 1) {
                yItem.set('columnStart', yItem.get('columnStart') - 1);
                yItem.set('columnEnd', yItem.get('columnEnd') - 1);
              }
            });
            itemToMove.columnStart = columnEndPosition - 1;
            itemToMove.columnEnd = itemToMove.columnStart + itemToMoveWidth;
            insertNewGridItem();
            return;
          }
          itemToMove.columnStart = columnEndPosition;
          itemToMove.columnEnd = itemToMove.columnStart + itemToMoveWidth;
          insertNewGridItem();
          return;
        }
      }
      // resizing items
      let itemResized = false;
      targetRowItems.forEach((yItem: any) => {
        const canItemBeResized = yItem.get('columnEnd') - yItem.get('columnStart') > 1;
        if (yItem.get('columnStart') >= columnEndPosition && canItemBeResized) {
          yItem.set('columnStart', yItem.get('columnStart') + 1);
          itemToMove.columnStart = columnEndPosition;
          itemToMove.columnEnd = columnEndPosition + itemToMoveWidth;
          insertNewGridItem();
          itemResized = true;
        }
      });
      if (itemResized) return;
      targetRowItems.forEach((yItem: any) => {
        const canItemBeResized = yItem.get('columnEnd') - yItem.get('columnStart') > 1;
        if (yItem.get('columnStart') < columnEndPosition && canItemBeResized) {
          yItem.set('columnEnd', yItem.get('columnEnd') - itemToMoveWidth);
          itemToMove.columnStart = columnEndPosition - itemToMoveWidth;
          itemToMove.columnEnd = columnEndPosition;
          insertNewGridItem();
        }
      });
    }, origin);
  };

export const insertItemBefore =
  (ydoc: any, yEditorData: any, origin?: any) => (movingItemId: string, targetItemId: string) => {
    ydoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');

      // Find the target item's position
      let targetRowId = '-1';
      let targetRowIdx = -1;
      let targetItemColStart = -1;
      let targetItemElementType = null;
      let yTargetRowItems: any = null;
      gridArray.toArray().some((yRow: any, rowIdx: number) => {
        yTargetRowItems = yRow.get('items');
        const itemIndex = findItemIndexInRow(yTargetRowItems, targetItemId);
        if (itemIndex !== -1) {
          targetRowId = yRow.get('id');
          targetItemColStart = yTargetRowItems.get(itemIndex).get('columnStart');
          targetItemElementType = yTargetRowItems.get(itemIndex).get('elementType');
          targetRowIdx = rowIdx;
          return true; // Stop iteration
        }
        return false;
      });

      if (targetRowId === '-1' || targetItemColStart === -1 || yTargetRowItems === null) {
        // not found
        return;
      }

      if (targetItemElementType === 'heading' || targetItemColStart === 1) {
        moveItemToNewRow(ydoc, yEditorData, origin)(movingItemId, targetRowIdx);
        return;
      }

      const isGapBefore = !yTargetRowItems
        .toArray()
        .find((yItem: any) => yItem.get('columnEnd') === targetItemColStart);

      if (isGapBefore) {
        moveItemToNewPosition(
          ydoc,
          yEditorData
        )({
          itemId: movingItemId,
          newColumnStart: targetItemColStart - 1,
          newColumnEnd: targetItemColStart,
          targetRowId,
        });
        return;
      }

      handleDropIntoFullRow(
        ydoc,
        yEditorData
      )({ itemId: movingItemId, targetRowId, columnEndPosition: targetItemColStart });
    }, origin);
  };
