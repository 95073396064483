import { BaseCurrentUser } from 'src/store/api/currentUserApi';

export default function replaceParamsInLink(link?: string, user?: BaseCurrentUser): string {
  if (!user || !link) return '';
  const paramMap: { [key: string]: string } = {
    '{{WorkbaseUserID}}': user.id,
    '{{WorkbaseUserEmail}}': user.email,
    '{{WorkbaseUserFirstname}}': user.firstName,
    '{{WorkbaseUserLastname}}': user.lastName,
    '{{WorkbaseUserPhone}}': user.phoneNumber,
  };
  return Object.keys(paramMap).reduce(
    (updatedLink, param) => updatedLink.replace(new RegExp(param, 'g'), paramMap[param]),
    link
  );
}
