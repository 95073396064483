import { alpha, Box, Grid2 } from '@mui/material';
import { useDrop } from 'react-dnd';
import { BaseGridItem, GridItemType, GridRow } from 'src/features/editor/model';
import { useEditor } from 'src/features/editor/controller';
import { COLUMN_COUNT } from '../constants';
import useDebouncedIsOver from './useDebouncedIsOver';

function countConsecutiveEmptyColumns(items: BaseGridItem[], columnStart: number): number {
  const sortedItems = items.slice().sort((a, b) => a.columnStart - b.columnStart);
  const nextItem = sortedItems.find((item) => item.columnStart >= columnStart);

  if (!nextItem) {
    return COLUMN_COUNT - columnStart + 1;
  }

  if (nextItem.columnStart === columnStart) {
    return 0;
  }

  return nextItem.columnStart - columnStart;
}

function countConsecutiveEmptyColumnsBefore(items: BaseGridItem[], columnEnd: number): number {
  const sortedItems = items.slice().sort((a, b) => a.columnEnd - b.columnEnd);
  const prevItem = sortedItems.reverse().find((item) => item.columnEnd <= columnEnd);

  if (!prevItem) {
    return columnEnd - 1;
  }

  if (prevItem.columnEnd === columnEnd) {
    return 0;
  }

  return columnEnd - prevItem.columnEnd;
}

function calculateDimensions(filteredItems: BaseGridItem[], colStart: number, item: BaseGridItem) {
  const itemWidth = item ? item.columnEnd - item.columnStart : 1;
  const emptyColsAfter = countConsecutiveEmptyColumns(filteredItems, colStart);
  const emptyColsBefore = countConsecutiveEmptyColumnsBefore(filteredItems, colStart);

  const itemWidthAfterDrop =
    itemWidth > emptyColsAfter + emptyColsBefore ? emptyColsAfter + emptyColsBefore : itemWidth;
  const emptySpaceBeforeNeeded =
    itemWidthAfterDrop - emptyColsAfter < 0 ? 0 : itemWidthAfterDrop - emptyColsAfter;

  return { itemWidthAfterDrop, emptySpaceBeforeNeeded };
}

export default function DroppableEmptyCell({
  row,
  colStart,
  size = 3,
}: {
  row: GridRow;
  colStart: number;
  size?: number;
}) {
  const { moveItemToNewPosition } = useEditor();
  const [{ isOver, item }, drop] = useDrop({
    accept: 'GRID_ITEM',
    drop: (_: { rowIndex: number; item: GridItemType }, monitor) => {
      if (monitor && item.elementType !== 'heading') {
        const filteredItems = row?.items.filter(({ id }) => id !== item?.id);
        const { itemWidthAfterDrop, emptySpaceBeforeNeeded } = calculateDimensions(
          filteredItems,
          colStart,
          item
        );
        moveItemToNewPosition({
          itemId: item.id,
          targetRowId: row?.id,
          newColumnStart: colStart - emptySpaceBeforeNeeded,
          newColumnEnd: colStart - emptySpaceBeforeNeeded + itemWidthAfterDrop,
        });
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem()?.item,
    }),
  });

  const debouncedIsOver = useDebouncedIsOver(isOver);

  const filteredItems = row.items.filter(({ id }) => id !== item?.id);

  const { itemWidthAfterDrop, emptySpaceBeforeNeeded } = calculateDimensions(
    filteredItems,
    colStart,
    item
  );

  const emptySpaceBeforeTransformFactorDict = {
    1: 16,
    2: 7.5,
    3: 5,
    4: 3.5,
  };

  const translateVal = (1 / itemWidthAfterDrop) * emptySpaceBeforeNeeded * 100;
  const isDropActive = debouncedIsOver && item?.elementType !== 'heading';
  const activeBoxStyles = isDropActive
    ? {
        transform: `translateX(calc(-${translateVal}% - ${
          emptySpaceBeforeNeeded *
          emptySpaceBeforeTransformFactorDict[itemWidthAfterDrop as 1 | 2 | 3 | 4]
        }px))`,
        width: `calc(${100 * itemWidthAfterDrop}% + ${(itemWidthAfterDrop - 1) * 16}px)`,
        opacity: 1,
      }
    : {};

  return (
    <Grid2 ref={drop} position="relative" size={size} zIndex={10}>
      <Box
        ref={drop}
        borderRadius={1}
        bgcolor={({ palette }) => alpha(palette.primary.light, 0.2)}
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          borderStyle: 'solid',
          borderWidth: 1,
          width: '100%',
          borderColor: 'primary.main',
          transformOrigin: '0 0',
          opacity: 0,
          ...activeBoxStyles,
        }}
      />
    </Grid2>
  );
}
