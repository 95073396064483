import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { useTrainingSectionsByModuleIdQuery } from 'src/store/api/trainings/trainingSectionsApi';
import { SearchTextField } from 'src/ui-components/custom/textfield/search';
import SectionList from './SectionList';

interface Props {
  moduleId: string;
  readOnly: boolean;
  mobileView: boolean;
  onAddLessonCallback?: (id: string) => void;
  dataCy?: string;
}

export default function Sections({
  moduleId,
  readOnly,
  mobileView,
  onAddLessonCallback,
  dataCy,
}: Props) {
  const [searchVal, setSearchVal] = useState('');
  const { data } = useTrainingSectionsByModuleIdQuery(moduleId);

  if (!data) return null;
  return (
    <Stack gap={2}>
      <Box>
        <SearchTextField
          data-cy={dataCy && `${dataCy}-search`}
          fullWidth
          size="small"
          value={searchVal}
          onChange={(e) => setSearchVal(e.target.value)}
        />
      </Box>
      <SectionList
        data={data}
        dataCy={dataCy}
        mobileView={mobileView}
        searchTerm={searchVal}
        moduleId={moduleId}
        readOnly={readOnly}
        onAddLessonCallback={onAddLessonCallback}
      />
    </Stack>
  );
}
