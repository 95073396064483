export function getEmbeddedUrl(url: string): string {
  const regexes = [
    {
      regex: /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.*\?v=))([^#&?]*).*/,
      embedUrl: (id: string) => `https://www.youtube.com/embed/${id}`,
    },
    {
      regex: /vimeo\.com\/(?:.*\/)?(\d+)(.*)?/,
      embedUrl: (id: string) => `https://player.vimeo.com/video/${id}`,
    },
    {
      regex: /drive\.google\.com\/file\/d\/([^/]*)/,
      embedUrl: (id: string) => `https://drive.google.com/file/d/${id}/preview`,
    },
    {
      regex: /loom\.com\/(?:share|embed)\/([^/?]*)/,
      embedUrl: (id: string) => `https://www.loom.com/embed/${id}`,
    },
    {
      regex: /(?:wistia\.com|wistia\.net)\/(?:medias|embed)\/([^/?]*)/,
      embedUrl: (id: string) =>
        `https://fast.wistia.net/embed/iframe/${id}?seo=false&videoFoam=true`,
    },
  ];

  let embeddedUrl = url;

  regexes.forEach(({ regex, embedUrl }) => {
    const match = url.match(regex);
    if (match) {
      const id = match[1];
      embeddedUrl = embedUrl(id);
    }
  });

  return embeddedUrl;
}
