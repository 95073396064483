import { TextSelection } from '@remirror/pm/state';
import {
  ApplySchemaAttributes,
  KeyBindings,
  MarkExtension,
  MarkExtensionSpec,
  MarkSpecOverride,
  findParentNodeOfType,
} from 'remirror';

export class CustomSelectAllExtension extends MarkExtension {
  /* eslint-disable class-methods-use-this */
  get name() {
    return 'customSelectAll' as const;
  }

  /* eslint-disable class-methods-use-this */
  createMarkSpec(extra: ApplySchemaAttributes, override: MarkSpecOverride): MarkExtensionSpec {
    return {
      ...override,
      attrs: extra.defaults(),
      parseDOM: [
        {
          tag: 'samp',
          getAttrs: extra.parse,
        },
        ...(override.parseDOM ?? []),
      ],
      toDOM: (node) => ['samp', extra.dom(node), 0],
    };
  }

  /* eslint-disable class-methods-use-this */
  createKeymap(): KeyBindings {
    return {
      'Mod-a': ({ dispatch, state }): any => {
        const { doc } = state;
        const from = 1;
        const to = doc.content.size - 1;
        // Create a new transaction with the updated selection
        const tr = state.tr.setSelection(TextSelection.create(doc, from, to));
        dispatch?.(tr);

        // Prevent the default "Select All" behavior
        return true;
      },
      'Mod-b': ({ state }): boolean => {
        // Check if the current selection or cursor is within a heading
        const heading = findParentNodeOfType({
          types: state.schema.nodes.heading,

          selection: state.selection,
        });
        // If inside a heading, prevent the default action and do nothing
        if (heading) {
          return true;
        }

        // Otherwise, perform the default bold toggle action
        return false;
      },
    };
  }
}
