import { Components, Theme } from '@mui/material';

const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      fontWeight: 500,
      paddingLeft: 12,
      paddingRight: 12,
      marginBottom: '2px',
      transition: 'transform 0.2s ease-out',
      '&:active': {
        transform: 'scale(0.975)',
      },
      '&:last-child': {
        marginBottom: 0,
      },
    }),
  },
};

export default MuiMenuItem;
