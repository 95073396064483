import ImageEditOverlay from './ImageEditOverlay';

interface Props {
  dataCy?: string;
  inputProps?: any;
  rootProps?: any;
}

export default function ImageEditorWithoutPreview({ dataCy, inputProps }: Props) {
  return (
    <ImageEditOverlay dataCy={dataCy}>
      <input {...inputProps} />
    </ImageEditOverlay>
  );
}
