import { Alert, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTrainingModuleQuery } from 'src/store/api/trainings/trainingModulesApi';
import ModuleOverviewContent from './ModuleOverviewContent';

interface Props {
  editMode?: boolean;
  onAddLessonCallback?: (id: string) => void;
  hideBreadcrumbs?: boolean;
}

export default function ModuleOverview({
  editMode = false,
  onAddLessonCallback,
  hideBreadcrumbs = false,
}: Props) {
  const { t } = useTranslation();
  const param = useParams();
  if (param.moduleId === undefined) throw new Error('no module Id');
  const { data, error, refetch } = useTrainingModuleQuery(param.moduleId);

  if (error) {
    return (
      <Alert
        severity="error"
        data-cy="module-overview-error"
        action={
          <Button onClick={refetch} size="small">
            {t('validationErrorMessages.labelErrorTryAgainButton')}
          </Button>
        }
      >
        {t('modulePage.moduleErrorDescription')}
      </Alert>
    );
  }

  if (!data) return null;
  return (
    <ModuleOverviewContent
      data={data}
      editMode={editMode}
      onAddLessonCallback={onAddLessonCallback}
      hideBreadcrumbs={hideBreadcrumbs}
    />
  );
}
