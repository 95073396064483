import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  WorkbaseDashboardIcon,
  WorkbaseFolderIcon,
  WorkbaseTrainingsIcon,
  WorkbaseWikiIcon,
} from 'src/assets/icons/workbaseIcons';

function checkEditModePersistExcludedPath(pathname: string) {
  const editPersistExcludedPaths = ['team'];
  return editPersistExcludedPaths.reduce((prev, curr) => prev || pathname.includes(curr), false);
}

export default function useNavData() {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isEditMode = pathname.includes('/edit');
  const isEditModePersistExcludedPath = checkEditModePersistExcludedPath(pathname);

  const endPath = isEditMode && !isEditModePersistExcludedPath ? '/edit' : '';

  const navData = [
    {
      id: 'dashboard',
      title: t('sidebar.tabTitleDashboard'),
      to: `${endPath}` || '/',
      pattern: {
        path: isEditMode ? '/edit' : '',
        end: true,
      },
      icon: WorkbaseDashboardIcon,
    },
    {
      id: 'trainings',
      title: t('sidebar.tabTitleTrainings'),
      to: `/trainings${endPath}`,
      pattern: {
        path: '/trainings',
        end: false,
      },
      icon: WorkbaseTrainingsIcon,
    },
    {
      id: 'wiki',
      title: t('sidebar.tabTitleWiki'),
      to: `/wiki${endPath}`,
      pattern: {
        path: '/wiki',
        end: false,
      },
      icon: WorkbaseWikiIcon,
    },
    {
      id: 'documents',
      title: t('sidebar.tabDrive'),
      to: `/documents`,
      pattern: {
        path: '/documents',
        end: false,
      },
      icon: WorkbaseFolderIcon,
    },
  ];
  return navData;
}
