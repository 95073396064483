import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ResultData, useSearchQuery } from 'src/store/api/searchApi';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Divider,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useAppSelector } from 'src/store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { WorkbaseRecentIcon, WorkbaseSearchIcon } from 'src/assets/icons/workbaseIcons';
import Empty from 'src/ui-components/custom/empty/Empty';
import { SearchTextField } from 'src/ui-components/custom/textfield/search';
import isUserTyping from 'src/lib/utils/isUserTyping';
import { useTranslation } from 'react-i18next';
import { getNavigationRoute, removeHtmlTags } from '../controller/utils';
import SingleSearchResult from './SingleSearchResult';
import { SearchHistoryItem } from '../controller/search.slice';

const GLOBAL_SEARCH_WIDTH = 620;

export default function GlobalSearch() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>('');
  const [, setSearchParams] = useSearchParams();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const searchInputRef = useRef<HTMLDivElement | null>(null);
  const searchHistory = useAppSelector((state) => state.searchHistory.searchHistory);
  const { data } = useSearchQuery(debouncedSearchValue, {
    skip: debouncedSearchValue.trim() === '',
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === '/' && !isFocused && searchInputRef.current) {
        if (!isUserTyping()) {
          event.preventDefault();
          searchInputRef.current.focus();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFocused]);

  const debouncedSetDebouncedSearchValue = useRef(debounce(setDebouncedSearchValue, 250)).current;
  const debouncedOnChange = useCallback(
    (val: string) => {
      debouncedSetDebouncedSearchValue(val);
    },
    [debouncedSetDebouncedSearchValue]
  );

  const handleInputChange = (val: string) => {
    setSearchValue(val);
    debouncedOnChange(val);
  };

  const sortedOptions: ResultData[] = useMemo(() => {
    if (!data) return [];
    const companyUsers = data?.filter((item) => item.source === 'company_user');
    const otherItems = data?.filter((item) => item.source !== 'company_user');
    if (companyUsers.length && otherItems.length)
      return [...companyUsers, { id: 'divider', name: 'divider' } as ResultData, ...otherItems];
    if (companyUsers.length && !otherItems.length) return [...companyUsers];
    return [...otherItems];
  }, [data]);

  const renderOption = useCallback(
    (props: Record<string, any>, option: ResultData) => {
      const trimmedSearchValue = searchValue?.trim();

      if (option.id === 'divider') {
        // Prevent it from being clickable as default autocomplete item
        return <Divider flexItem {...props} className="" onClick={() => {}} />;
      }

      if (!!data && trimmedSearchValue) {
        return <SingleSearchResult item={option} {...props} dataCy="search-result" />;
      }

      if (!trimmedSearchValue) {
        return (
          <MenuItem {...props} data-cy={`search-result-${option.name}`}>
            <Stack direction="row" color="text.secondary">
              <ListItemIcon>
                <SvgIcon component={WorkbaseRecentIcon} fontSize="medium" htmlColor="inherit" />
              </ListItemIcon>
              <Typography variant="body1" color="text.secondary" noWrap>
                {removeHtmlTags(option.name as string)}
              </Typography>
            </Stack>
          </MenuItem>
        );
      }

      return null;
    },
    [data, searchValue]
  );
  return (
    <Box width="100%" maxWidth={GLOBAL_SEARCH_WIDTH} position="relative">
      <Autocomplete
        data-cy="global-search"
        selectOnFocus={false}
        clearOnBlur={false}
        blurOnSelect
        openOnFocus
        forcePopupIcon={false}
        disableClearable
        filterOptions={(x) => x}
        getOptionLabel={() => ''} // bugs when using navigate
        options={searchValue.trim() ? sortedOptions : (searchHistory as any)}
        slotProps={{
          paper: {
            sx: {
              // limit the height of the dropdown to 5 items
              '& .MuiAutocomplete-listbox': {
                maxHeight: 60 * 5,
              },
            },
          },
        }}
        noOptionsText={
          <Empty description={searchHistory.length || searchValue ? t('notFound') : undefined} />
        }
        inputValue={searchValue}
        onChange={(_, value: string | SearchHistoryItem | ResultData) => {
          if (typeof value !== 'string' && value) {
            const route =
              'navigateRoute' in value ? value.navigateRoute : getNavigationRoute(value);
            if (!route.startsWith('/')) {
              setSearchParams({ employee: route });
            } else {
              setTimeout(() => navigate(route, { relative: 'route' }), 0); // navigate after closing the dropdown
            }
            handleInputChange('');
          }
        }}
        renderInput={({ InputProps, ...restOfProps }: AutocompleteRenderInputParams) => (
          <SearchTextField
            {...restOfProps}
            data-cy="global-search-textfield"
            value={searchValue}
            {...(isFocused ? {} : { placeholder: undefined })}
            onChange={(e) => handleInputChange(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            focused={isFocused}
            slotProps={{
              input: {
                ...InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon component={WorkbaseSearchIcon} />
                    {!isFocused && !searchValue && (
                      <Stack
                        gap={0.5}
                        direction="row"
                        position="absolute"
                        left={50}
                        color="text.disabled"
                        alignItems="center"
                      >
                        {t('searchbar.placeholderStart')}
                        <Stack
                          borderColor="divider"
                          alignItems="center"
                          justifyContent="center"
                          width={16}
                          height={16}
                          borderRadius="2px"
                          sx={{ borderStyle: 'solid' }}
                        >
                          /
                        </Stack>
                        {t('searchbar.placeholderEnd')}
                      </Stack>
                    )}
                  </InputAdornment>
                ),
              },
            }}
            inputRef={searchInputRef}
          />
        )}
        renderOption={renderOption}
      />
    </Box>
  );
}
