import { Box } from '@mui/material';
import { useDrop } from 'react-dnd';
import { useEditor } from 'src/features/editor/controller';
import { useAppSelector } from 'src/store';
import { GridItemType, GridRow } from '../../../model';
import useDebouncedIsOver from './useDebouncedIsOver';

const getIsDroppingActive = ({
  item,
  rowId,
  row,
  itemColumnEnd,
}: {
  item: GridItemType;
  rowId: string;
  row?: GridRow;
  itemColumnEnd: number;
}) => {
  const isDraggingWithinTheSameRow = rowId === row?.id;
  const isDraggingItemToSamePosition = itemColumnEnd === item?.columnStart;
  return (
    !(isDraggingWithinTheSameRow && isDraggingItemToSamePosition) && item?.elementType !== 'heading'
  );
};

export default function DroppableInBetweenRowItem({
  itemColumnEnd,
  rowId,
}: {
  itemColumnEnd: number;
  rowId: string;
}) {
  const { handleDropIntoFullRow } = useEditor();
  const isDragging = useAppSelector((state) => state.editor.isDragging);
  const [{ isOver, item, row }, drop] = useDrop({
    accept: 'GRID_ITEM',
    drop: (i: { rowIndex: number; item: GridItemType; row: GridRow }, monitor) => {
      if (monitor && getIsDroppingActive({ item: i.item, rowId, row: i.row, itemColumnEnd })) {
        handleDropIntoFullRow({
          itemId: i.item.id,
          targetRowId: rowId,
          columnEndPosition: itemColumnEnd,
        });
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      row: monitor.getItem()?.row,
      item: monitor.getItem()?.item,
    }),
  });
  const debouncedIsOver = useDebouncedIsOver(isOver);

  if (!isDragging) return null;

  const isActive = debouncedIsOver && getIsDroppingActive({ item, rowId, row, itemColumnEnd });

  return (
    <Box
      ref={drop}
      px={8}
      position="absolute"
      right={0}
      height="100%"
      zIndex={4}
      top={0}
      sx={{
        transform: 'translateX(calc(50% + 8px))',
      }}
    >
      <Box
        bgcolor={isActive ? 'primary.main' : 'transparent'}
        borderRadius={1}
        height="100%"
        width={8}
      />
    </Box>
  );
}
