import { Button, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WorkbaseTwoEmployeesIcon } from 'src/assets/icons/workbaseIcons';
import { DocumentResourceType } from 'src/store/api/driveApi';
import SvgIconOrEmoji from 'src/ui-components/icon/SvgIconOrEmoji';

interface DocumentNameCellProps {
  id: string;
  title: string;
  icon: string;
  sharedWithOthers?: boolean;
  resourceType: DocumentResourceType | 'file';
  onClick?: () => void;
}

export default function DocumentNameCell({
  title,
  icon,
  sharedWithOthers,
  resourceType,
  id,
  onClick,
}: DocumentNameCellProps) {
  const { t } = useTranslation();
  const emptyResourceName = {
    page: t('customSites.unnamedPage'),
    embeddedApp: t('embeddedApps.unnamedApp'),
    whiteboard: t('whiteboards.unnamedWhiteboard'),
    file: t('documentsPage.unnamedFile'),
  }[resourceType];

  const resourceLink = {
    page: 'page',
    embeddedApp: 'app',
    whiteboard: 'whiteboard',
    file: 'file',
  }[resourceType];

  const buttonProps = onClick
    ? {
        onClick,
      }
    : {
        component: RouterLink,
        to: `/${resourceLink}/${id}`,
      };
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <SvgIconOrEmoji content={icon} fontSize="small" />
      <Button
        {...buttonProps}
        variant="text"
        color="inherit"
        data-cy={`document-table-navigate-button-to${id}`}
      >
        <Typography noWrap color="inherit" fontWeight="inherit" fontSize="inherit">
          {title || emptyResourceName}
        </Typography>
      </Button>
      <Tooltip title={t('documentsPage.shared')}>
        <span>
          {sharedWithOthers && <SvgIcon component={WorkbaseTwoEmployeesIcon} fontSize="small" />}
        </span>
      </Tooltip>
    </Stack>
  );
}
