import { CSSProperties, forwardRef } from 'react';
import Webcam from 'react-webcam';

interface Props {
  deviceId: string;
  opacity?: CSSProperties['opacity'];
}

export default forwardRef<Webcam, Props>(({ deviceId, opacity }, ref) => {
  const videoConstraints = {
    width: 1920,
    height: 1080,
    facingMode: 'user',
    muted: true,
    deviceId: { exact: deviceId },
    aspectRatio: { exact: 16 / 9, ideal: 16 / 9 },
  };
  return (
    <Webcam
      ref={ref}
      audio={false}
      mirrored
      videoConstraints={videoConstraints}
      screenshotFormat="image/jpeg"
      style={{
        objectFit: 'contain',
        width: 'calc(100% + 16px)',
        height: 'calc(100% + 16px)',
        position: 'absolute',
        left: -8,
        top: -8,
        opacity,
      }}
    />
  );
});
