import { alpha, Stack, SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import {
  WorkbaseCheckmarkBigFilledIcon,
  WorkbaseErrorFilledIcon,
  WorkbaseInfoFilledIcon,
  WorkbaseNotesIcon,
  WorkbaseQuestionBubbleFilledIcon,
  WorkbaseWarningFilledIcon,
} from 'src/assets/icons/workbaseIcons';
import React, { ElementType } from 'react';
import BaseEditor from '../base-editor/BaseEditor';
import { InfoType } from '../edit-bar/actions/InfoActions';

interface Props {
  infoType: InfoType;
  value?: string;
  readOnly: boolean;
  children?: React.ReactNode;
  onChange?: (val: string) => void;
}

export default function InfoPanelUI({ infoType, value, readOnly, children, onChange }: Props) {
  const theme = useTheme();
  const infoTypesDict: Record<
    InfoType,
    { bgcolor: string; icon: { component: ElementType; color: SvgIconProps['color'] } }
  > = {
    info: {
      bgcolor: alpha(theme.palette.info.light, 0.25),
      icon: { component: WorkbaseInfoFilledIcon, color: 'info' },
    },
    question: {
      bgcolor: 'background.default',
      icon: { component: WorkbaseQuestionBubbleFilledIcon, color: 'inherit' },
    },
    notes: {
      bgcolor: alpha(theme.palette.secondary.main, 0.25),
      icon: { component: WorkbaseNotesIcon, color: 'secondary' },
    },
    success: {
      bgcolor: alpha(theme.palette.success.light, 0.25),
      icon: { component: WorkbaseCheckmarkBigFilledIcon, color: 'success' },
    },
    warning: {
      bgcolor: alpha(theme.palette.warning.light, 0.25),
      icon: { component: WorkbaseWarningFilledIcon, color: 'warning' },
    },
    error: {
      bgcolor: alpha(theme.palette.error.light, 0.25),
      icon: { component: WorkbaseErrorFilledIcon, color: 'error' },
    },
  };

  const { bgcolor, icon } = infoTypesDict[infoType];

  return (
    <Stack direction="row" bgcolor={bgcolor} borderRadius={1} pl={1}>
      <Stack mt={0.75}>
        <SvgIcon component={icon.component} color={icon.color} fontSize="small" />
      </Stack>
      <BaseEditor
        value={value}
        readOnly={readOnly}
        onChange={onChange}
        dataCy="editor-widgets-info-panel-ui"
      >
        {children}
      </BaseEditor>
    </Stack>
  );
}
