import { KeyboardEvent, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';

export interface ValidationResult {
  isError: boolean;
  message?: string;
}

export type ValidationTextFieldProps = Omit<
  TextFieldProps,
  'value' | 'onChange' | 'label' | 'onSubmit' | 'error'
> & {
  value?: string;
  onSubmit: (value: string) => Promise<ValidationResult> | void;
  placeholder?: string;
  validateFn?: (value: string) => Promise<ValidationResult> | ValidationResult;
};

function EmbeddedLinkValidationTextField({
  value,
  onSubmit,
  validateFn,
  placeholder,
}: ValidationTextFieldProps) {
  const { t } = useTranslation();
  const [currentVal, setCurrentVal] = useState(value || '');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isValChanged = currentVal !== value;
  const [valChangedWhenFocus, setValChangedWhenFocus] = useState(false);

  useEffect(() => {
    setCurrentVal(value || '');
  }, [value]);

  const saveButtonDisabled =
    isLoading || (currentVal === '' && value === undefined) || !valChangedWhenFocus;

  const handleSave = async () => {
    setValChangedWhenFocus(false);
    if (saveButtonDisabled) return;

    setIsLoading(true);
    const validateFunctionResp = await validateFn?.(currentVal);

    if (!validateFunctionResp?.isError) {
      const validationResult = await onSubmit(currentVal);

      if (validationResult?.isError) {
        showNotification(
          validationResult.message ? validationResult.message : t('invalidInput'),
          'error'
        );
        setError(true);
        setIsLoading(false);
        return;
      }
    } else {
      showNotification(
        validateFunctionResp?.message ? validateFunctionResp.message : t('invalidInput'),
        'error'
      );
    }
    setError(validateFunctionResp?.isError ? validateFunctionResp?.isError : false);
    setIsLoading(false);
  };

  const handleEnterDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const target = e.target as HTMLInputElement;
      target.blur();
    }
  };
  return (
    <TextField
      data-cy="embedded-link-validation-textField"
      error={error}
      value={currentVal}
      placeholder={placeholder}
      size="small"
      fullWidth
      label={isValChanged ? value : undefined}
      onKeyDown={handleEnterDown}
      onChange={(e) => {
        setCurrentVal(e.target.value);
        setValChangedWhenFocus(true);
      }}
      onBlur={handleSave}
    />
  );
}

export default memo(EmbeddedLinkValidationTextField);
