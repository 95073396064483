import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AwarenessItem {
  clientId: string;
  user: AwarenessUserData;
  isEditing: boolean;
  tdUser: TdAwarenessUserData;
}

export interface AwarenessUserData {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
}

export interface TdAwarenessUserData {
  id: string;
  color: string;
}

interface AwarenessState {
  awarenessState: AwarenessItem[];
}

const initialState: AwarenessState = {
  awarenessState: [],
};

const AwarenessSlice = createSlice({
  name: 'awareness',
  initialState,
  reducers: {
    setAwarenessState: (state, action: PayloadAction<AwarenessItem[]>) => {
      state.awarenessState = action.payload;
    },
  },
});

export const { setAwarenessState } = AwarenessSlice.actions;
export default AwarenessSlice;
