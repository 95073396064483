import { Divider, Stack, SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import { TrainingType } from 'src/features/editor/model';
import { useTranslation } from 'react-i18next';
import { ElementType } from 'react';
import {
  WorkbaseTrainingsIcon,
  WorkbaseModuleIcon,
} from '../../../../../../../../assets/icons/workbaseIcons';
import TrainingsSelection, { TrainingsSelectionProps } from './TrainingsSelection';

export interface TrainingsActionsProps extends TrainingsSelectionProps {
  handleTrainingsTypeChange: (TrainingsType: TrainingType) => void;
  selectedTrainingsType: TrainingType;
}

export default function TrainingsActions({
  options,
  selected,
  onChange,
  handleTrainingsTypeChange,
  selectedTrainingsType,
}: TrainingsActionsProps) {
  const { t } = useTranslation();
  const items: { key: TrainingType; icon: ElementType }[] = [
    {
      key: 'trainings',
      icon: WorkbaseTrainingsIcon,
    },
    {
      key: 'modules',
      icon: WorkbaseModuleIcon,
    },
  ];
  return (
    <>
      <TrainingsSelection options={options} selected={selected} onChange={onChange} />
      <Divider orientation="vertical" flexItem />
      <Stack direction="row" gap={1}>
        {items.map(({ key, icon }) => (
          <Tooltip key={key} title={t(`editor.topBar.tooltip.${key}`)} placement="top">
            <ToggleButton
              value={key}
              size="small"
              selected={key === selectedTrainingsType}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              onClick={() => handleTrainingsTypeChange(key)}
            >
              <SvgIcon component={icon} fontSize="small" />
            </ToggleButton>
          </Tooltip>
        ))}
      </Stack>
    </>
  );
}
