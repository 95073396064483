import { Box } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
  backgroundColor: string;
}

export default function ColoredCircle({ children, backgroundColor }: Props) {
  return (
    <Box
      sx={{
        backgroundColor,
        width: 100,
        height: 100,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
}
