import {
  indexCharacterSet,
  generateJitteredKeyBetween as generateKeyBetween,
} from 'fractional-indexing-jittered';

const baseSet = indexCharacterSet({
  chars: '0123456789abcdefghijklmnopqrstuvwxyz',
});

export default function generateJitteredKeyBetween(lower: string | null, upper: string | null) {
  return generateKeyBetween(lower, upper, baseSet);
}
