import { TDBinding, TDShape, TDUser, TldrawApp } from '@tldraw/tldraw';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import useHocuspocusProvider, {
  HocuspocusProviderProps,
} from 'src/lib/hooks/use-hocuspocus-provider';
import * as Y from 'yjs';

export function useMultiplayerState(socketUrl: string, documentName: string, readOnly?: boolean) {
  const tldrawRef = useRef<TldrawApp>();
  const ydocRef = useRef(new Y.Doc());
  const yShapes: any = ydocRef.current.getMap('shapes');
  const yBindings: any = ydocRef.current.getMap('bindings');
  const undoManager = useMemo(() => new Y.UndoManager([yShapes, yBindings]), [yShapes, yBindings]);
  const provider = useHocuspocusProvider({
    documentName,
    socketUrl,
    readOnly,
    ydoc: ydocRef.current,
  } as HocuspocusProviderProps);
  const onMount = useCallback(
    (app: TldrawApp) => {
      app.loadRoom(documentName);
      app.pause();
      tldrawRef.current = app;

      app.replacePageContent(
        Object.fromEntries(yShapes.entries()),
        Object.fromEntries(yBindings.entries()),
        {}
      );
    },
    [documentName, yBindings, yShapes]
  );

  const onChangePage = useCallback(
    (
      _: any,
      shapes: Record<string, TDShape | undefined>,
      bindings: Record<string, TDBinding | undefined>
    ) => {
      undoManager.stopCapturing();
      ydocRef.current.transact(() => {
        Object.entries(shapes).forEach(([id, shape]) => {
          if (!shape) {
            yShapes.delete(id);
          } else {
            yShapes.set(shape.id, shape);
          }
        });

        Object.entries(bindings).forEach(([id, binding]) => {
          if (!binding) {
            yBindings.delete(id);
          } else {
            yBindings.set(binding.id, binding);
          }
        });
      });
    },
    [undoManager, yBindings, yShapes]
  );

  const onUndo = useCallback(() => {
    undoManager.undo();
  }, [undoManager]);

  const onRedo = useCallback(() => {
    undoManager.redo();
  }, [undoManager]);

  const onChangePresence = useCallback(
    (_: any, user: TDUser) => {
      provider?.awareness?.setLocalStateField('tdUser', user);
    },
    [provider]
  );

  useEffect(() => {
    const onChangeAwareness = () => {
      const tldraw = tldrawRef.current;

      if (!tldraw || !tldraw.room) return;
      const entries = Array.from(provider?.awareness?.getStates()?.entries() as any);
      const users: any[] = [];
      const others: any[] = [];
      const clientID = provider?.awareness?.clientID;

      entries.forEach(([key, state]: any) => {
        if (state.tdUser !== undefined) {
          users.push(state);
          if (key !== clientID) {
            others.push(state);
          }
        }
      });

      const ids = others.map((other) => other.tdUser.id as string);

      Object.values(tldraw.room.users).forEach((user) => {
        if (user && !ids.includes(user.id) && user.id !== tldraw.room?.userId) {
          tldraw.removeUser(user.id);
        }
      });
      tldraw.updateUsers(others.map((other) => other.tdUser).filter(Boolean));
    };

    provider?.awareness?.on('change', onChangeAwareness);

    return () => provider?.awareness?.off('change', onChangeAwareness);
  }, [provider]);

  useEffect(() => {
    function handleChanges() {
      const tldraw = tldrawRef.current;

      if (!tldraw) return;

      tldraw.replacePageContent(
        Object.fromEntries(yShapes.entries()),
        Object.fromEntries(yBindings.entries()),
        {}
      );
    }

    yShapes.observeDeep(handleChanges);

    return () => yShapes.unobserveDeep(handleChanges);
  }, [yBindings, yShapes]);

  return {
    onMount,
    onUndo,
    onRedo,
    onChangePresence,
    onChangePage,
  };
}
