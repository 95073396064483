import { Components, Theme } from '@mui/material';

const MuiAutocomplete: Components<Theme>['MuiAutocomplete'] = {
  defaultProps: {
    slotProps: {
      popper: {
        modifiers: [
          {
            name: 'preventOverflow',
            enabled: false,
          },
          {
            name: 'flip',
            enabled: false,
          },
        ],
      },
    },
  },
  styleOverrides: {
    paper: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  variants: [
    {
      props: { size: 'medium' },
      style: {
        '.MuiAutocomplete-inputRoot': {
          padding: '5px 14px',
        },
      },
    },
  ],
};

export default MuiAutocomplete;
