import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DraftModeMenu from 'src/core/DraftModeMenu';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import {
  TrainingSectionStatus,
  useDeleteTrainingSectionMutation,
  useDuplicateTrainingSectionMutation,
  usePatchTrainingSectionMutation,
} from 'src/store/api/trainings/trainingSectionsApi';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import SvgIconOrEmoji from 'src/ui-components/icon/SvgIconOrEmoji';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import IconOrEmojiSelector from 'src/ui-components/custom/icon-selector';

export default function SectionTitle({
  dataCy,
  sectionName = '',
  lessonsCount,
  sectionStatus,
  readOnly,
  sectionId,
  trainingModuleId,
  mobileView,
  icon = 'WorkbaseSectionIcon',
  isCurrentSectionLessonActive,
  totalEstimatedLessonsTime,
}: Readonly<{
  dataCy?: string;
  sectionName: string;
  lessonsCount: number;
  icon: string;
  readOnly?: boolean;
  sectionId: string;
  sectionStatus: TrainingSectionStatus;
  trainingModuleId?: string;
  mobileView: boolean;
  isCurrentSectionLessonActive: boolean;
  totalEstimatedLessonsTime: number;
}>) {
  const { t } = useTranslation();
  const [localSectionName, setLocalSectionName] = useState(sectionName);
  const [patchTrainingSection] = usePatchTrainingSectionMutation();
  const [duplicateTrainingSection] = useDuplicateTrainingSectionMutation();
  const [deleteTrainingSection] = useDeleteTrainingSectionMutation();
  const navigate = useNavigate();
  const params = useParams();
  const lessonsCountLabel = t('lesson', { count: lessonsCount || 0 });
  const estimatedTimeCountLabel = t('minute', {
    count: totalEstimatedLessonsTime || 0,
  });

  useEffect(() => {
    setLocalSectionName(sectionName);
  }, [sectionName, readOnly]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSectionTitleChange = useCallback(
    debounce((val: string) => {
      patchTrainingSection({
        trainingSection: { name: val, id: sectionId, trainingModuleId },
        trainingId: params.trainingId as string,
        trainingModuleId: trainingModuleId as string,
      });
    }, 1000),
    [trainingModuleId, patchTrainingSection, sectionId]
  );

  const lessonDurationCountText = (
    <Typography fontWeight="medium" variant="body2" color="text.secondary">
      {lessonsCountLabel} – {estimatedTimeCountLabel}
    </Typography>
  );

  const readOnlyContent = (
    <>
      <IconButton size="small">
        <SvgIconOrEmoji content={icon} />
      </IconButton>
      <Box flexShrink={1} minWidth={0} textOverflow="hidden">
        <Typography fontWeight="bold" variant="body1" noWrap>
          {sectionName || t('modulePage.unnamedSection')}
        </Typography>
        {lessonDurationCountText}
      </Box>
    </>
  );

  const draftModeMenu = (
    <Box alignSelf="stretch" display="flex">
      <DraftModeMenu
        withButton={!mobileView}
        status={sectionStatus}
        onStatusChange={(status) => {
          patchTrainingSection({
            trainingSection: { status, id: sectionId, trainingModuleId },
            trainingId: params.trainingId as string,
            trainingModuleId: trainingModuleId as string,
          });
        }}
      />
    </Box>
  );
  const editTextFieldProps = {
    value: localSectionName,
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setLocalSectionName(val);
      debouncedSectionTitleChange(val);
    },
    fullWidth: true,
    placeholder: t('writeTitlePlaceholder'),
    textFieldProps: {
      'data-cy': dataCy && `${dataCy}-text-field`,
      fullWidth: true,
      autoFocus: false,
      onClick: (e: any) => {
        e.stopPropagation();
      },
    },
  };

  const changeableIconProps = {
    icon,
    onIconChange: (iconName: string) => {
      patchTrainingSection({
        trainingSection: { icon: iconName, id: sectionId, trainingModuleId },
        trainingId: params.trainingId as string,
        trainingModuleId: trainingModuleId as string,
      });
    },
  };

  const editContent = (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap={mobileView ? 1.5 : 2}
      width="100%"
      height="100%"
      alignItems="center"
      id={sectionId}
    >
      <Stack
        gap={mobileView ? 1 : 2}
        onClick={(e) => e.stopPropagation()}
        direction="row"
        width="100%"
        alignItems="flex-start"
      >
        <Box flexShrink={0}>
          <IconOrEmojiSelector
            value={changeableIconProps.icon}
            onChange={changeableIconProps.onIconChange}
            buttonSize="small"
            dataCy="section-title"
          />
        </Box>
        <Stack width="100%">
          <TextField
            {...editTextFieldProps}
            size="small"
            fullWidth
            data-cy="section-title-textfield"
          />
          {mobileView && (
            <Stack direction="row" gap={0.5} alignItems="center">
              {lessonDurationCountText}
              {draftModeMenu}
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack
        onClick={(e) => e.stopPropagation()}
        direction="row"
        gap={mobileView ? 1.5 : 2}
        alignItems="center"
        ml="auto"
        mr={mobileView ? 0 : 1}
      >
        {!mobileView && draftModeMenu}
        <DeleteDuplicateMenu
          dataCy="section-title"
          onDelete={() => {
            deleteTrainingSection({
              trainingModuleId: trainingModuleId as string,
              id: sectionId,
              trainingId: params.trainingId as string,
              moduleId: params.moduleId as string,
            }).then((resp: any) => {
              if (resp.error) {
                showNotification(t('notifications.deleteError'), 'error');
              } else {
                if (isCurrentSectionLessonActive) {
                  navigate(`/trainings/${params.trainingId}/modules/${params.moduleId}/edit`);
                }
                showNotification(t('notifications.deleteSuccess'));
              }
            });
          }}
          onDuplicate={() => {
            duplicateTrainingSection({
              moduleId: trainingModuleId as string,
              sectionId,
            }).then((resp: any) => {
              if (resp.error) {
                showNotification(t('notifications.duplicateError'), 'error');
              } else {
                showNotification(t('notifications.duplicateSuccess'));
              }
            });
          }}
          deleteDialogDescription={t('modulePage.deleteSectionModal.content')}
        />
      </Stack>
    </Stack>
  );

  return (
    <Stack gap={1.25} flexDirection="row" alignItems="center" width="100%" pr={1}>
      {readOnly ? readOnlyContent : editContent}
    </Stack>
  );
}
