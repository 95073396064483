import { useEffect, useRef, useState } from 'react';

export default function useSingleModuleResponsivness() {
  const [isSmallVersion, setIsSmallVersion] = useState(false);
  const [isOneColVersion, setOneColVersion] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const card = cardRef.current;

    if (card) {
      // Function to be called whenever the size of the card changes
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setIsSmallVersion(entry.contentRect.width <= 600);
          setOneColVersion(entry.contentRect.width <= 360);
        }
      });

      resizeObserver.observe(card);

      return () => resizeObserver.unobserve(card);
    }

    return undefined;
  }, []);

  return { isSmallVersion, isOneColVersion, cardRef };
}
