import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';
import { setCustomerGroups } from '../../features/company-settings/customer-groups/store/customerGroupsSlice';
import { CustomerGroup } from '../../features/company-settings/customer-groups/store/types';

export const customerGroupsApi = createApi({
  reducerPath: 'customerGroupsApi',
  tagTypes: ['CustomerGroups'],
  baseQuery,
  endpoints: (builder) => ({
    customerGroups: builder.query<CustomerGroup[], void>({
      query: () => '/customer-groups',
      providesTags: ['CustomerGroups'],
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((resp) => {
          dispatch(setCustomerGroups(resp.data));
        });
      },
    }),
    putCustomerGroups: builder.mutation<void, CustomerGroup[]>({
      query: (data) => ({
        url: `/customer-groups`,
        body: {
          customerGroups: data,
        },
        method: 'PUT',
      }),
      invalidatesTags: ['CustomerGroups'],
    }),
  }),
});

export const { useCustomerGroupsQuery, usePutCustomerGroupsMutation } = customerGroupsApi;
