import { Box } from '@mui/material';
import { GridItemType } from 'src/features/editor/model';
import { useDrag } from 'react-dnd';
import useElementConfigs from './useElementConfigs';
import DroppableRowInBetween from './DroppableRowInBetween';
import { isItemEmpty } from '../utils';

interface Props {
  item: GridItemType;
  readOnly: boolean;
}

export default function Cell({ item, readOnly }: Props) {
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'GRID_ITEM',
    item: {
      item,
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      if (item) {
        const getBtnElement = (): HTMLButtonElement | undefined | null =>
          document.getElementById(item?.id)?.querySelector('.MuiButtonBase-root');
        const btn = getBtnElement();
        if (btn) {
          btn.style.transitionDuration = '0.15s';
          btn.style.transform = 'translate(0,0)';
          setTimeout(() => {
            getBtnElement()?.focus();
          });
        }
      }
    },
  });

  const elementConfigs = useElementConfigs();

  if (readOnly && isItemEmpty(item)) return null;
  const element = elementConfigs[item.elementType];
  return (
    <Box
      sx={{
        paddingTop: '10px',
        paddingBottom: '10px',
        position: 'relative',
        zIndex: 1,
        borderWidth: '1px',
        borderStyle: 'dashed',
        borderTop: 'none',
        borderColor: readOnly ? 'transparent' : 'grey.300',
        '& > *:nth-child(1)': {
          opacity: isDragging ? 0 : 1,
        },
      }}
      className="grid-cell"
      id={item.id}
      key={item.id}
      ref={readOnly ? undefined : dragRef}
    >
      {element.render({
        item,
        readOnly,
        dragging: {
          dragRef,
          previewRef,
        },
      })}
      <DroppableRowInBetween itemId={item.id} />
    </Box>
  );
}
