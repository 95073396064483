import { forwardRef } from 'react';
import { alpha, Box, Stack } from '@mui/material';

export interface TopBarProps {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}
export default forwardRef(({ leftContent, rightContent }: TopBarProps, ref) => (
  <Box
    id="editor-top-bar"
    width="100%"
    zIndex={4}
    px={2}
    height={70}
    position="relative"
    display="flex"
    sx={{
      backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.6),
      backdropFilter: 'blur(8px)',
      pointerEvents: 'auto',
    }}
    ref={ref}
  >
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      gap={2}
      alignItems="center"
      height="inherit"
    >
      <Stack gap={1} direction="row" alignItems="center" flexShrink={1} flexGrow={1} minWidth={0}>
        {leftContent}
      </Stack>
      {rightContent && (
        <Stack gap={2} direction="row" alignItems="center">
          {rightContent}
        </Stack>
      )}
    </Stack>
  </Box>
));
