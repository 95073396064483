import { Components, Theme } from '@mui/material';

const MuiPopper: Components<Theme>['MuiPopper'] = {
  styleOverrides: {
    root: {
      '&[data-popper-placement="bottom"]': {
        marginTop: '2px !important',
      },
      '&[data-popper-placement="top"]': {
        marginBottom: '2px !important',
      },
      '&[data-popper-placement="left"]': {
        marginRight: '2px !important',
      },
      '&[data-popper-placement="right"]': {
        marginLeft: '2px !important',
      },
    },
  },
};

export default MuiPopper;
