import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';
import { driveApi } from './driveApi';
import { sidebarApi } from './sidebarApi';

interface Whiteboard {
  id: string;
  name: string;
  icon: string;
  sidebar: boolean;
}

interface WhiteboardPatchPayload extends Partial<Whiteboard> {
  id: string;
}

export const whiteboardsApi = createApi({
  reducerPath: 'whiteboardsApi',
  tagTypes: ['Whiteboard'],
  baseQuery,
  endpoints: (builder) => ({
    whiteboard: builder.query<Whiteboard, string>({
      query: (id) => `/whiteboards/${id}`,
      providesTags: (_, __, dashboardId) => [{ type: 'Whiteboard', id: dashboardId }],
    }),
    postWhiteboard: builder.mutation<Whiteboard, void>({
      query: () => ({
        url: '/whiteboards',
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((resp) => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
          dispatch(
            whiteboardsApi.util.updateQueryData('whiteboard', resp.data.id, () => resp.data)
          );
        });
      },
    }),
    patchWhiteboard: builder.mutation<Whiteboard, WhiteboardPatchPayload>({
      query: ({ id, ...rest }) => ({
        url: `/whiteboards/${id}`,
        method: 'PATCH',
        body: rest,
      }),
      onQueryStarted: ({ id, ...rest }, { dispatch, queryFulfilled }) => {
        dispatch(
          whiteboardsApi.util.updateQueryData('whiteboard', id, (draft) => ({ ...draft, ...rest }))
        );
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
      },
    }),
    deleteWhiteboard: builder.mutation<void, string>({
      query: (id) => ({
        url: `/whiteboards/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (id, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
        const disp = dispatch(
          whiteboardsApi.util.updateQueryData('whiteboard', id, () => undefined)
        );
        queryFulfilled.catch(disp.undo);
      },
    }),
    duplicateWhiteboard: builder.mutation<void, string>({
      query: (id) => ({
        url: `/whiteboards/${id}/duplicate`,
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
      },
    }),
  }),
});

export const {
  useWhiteboardQuery,
  usePatchWhiteboardMutation,
  usePostWhiteboardMutation,
  useDeleteWhiteboardMutation,
  useDuplicateWhiteboardMutation,
} = whiteboardsApi;
