import { Components, Theme } from '@mui/material';

declare module '@mui/material/ListItemIcon' {
  interface ListItemIconProps {
    color?:
      | 'inherit'
      | 'default'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning';
  }
}

const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
  defaultProps: {
    color: 'default',
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      minWidth: '0 !important',
      marginRight: '12px',
      '&:last-child': {
        marginRight: 0,
      },
      // Apply color styles based on the 'color' prop
      ...(ownerState.color &&
        ownerState.color !== 'default' && {
          color: ownerState.color === 'inherit' ? 'inherit' : theme.palette[ownerState.color].main,
        }),
    }),
  },
};

export default MuiListItemIcon;
