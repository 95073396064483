import { TopBar } from 'src/ui-components/layout/top-bar';
import WhiteboardIconNameSelector from './WhiteboardIconNameSelector';
import WhiteboardActionButtons from './WhiteboardActionButtons';

interface Props {
  id: string;
}

function WhiteboardTopBar({ id }: Props) {
  return (
    <TopBar
      leftContent={<WhiteboardIconNameSelector id={id} />}
      rightContent={<WhiteboardActionButtons id={id} />}
    />
  );
}

export default WhiteboardTopBar;
