import { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  stream: MediaStream | null;
}

export default forwardRef<HTMLVideoElement, Props>(({ stream }, ref) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (ref) {
      if (typeof ref !== 'function' && ref.current) {
        // eslint-disable-next-line no-param-reassign
        ref.current.srcObject = stream;
        // eslint-disable-next-line no-param-reassign
        ref.current.muted = true;
        ref.current.play();
      }
    }
  }, [stream, ref]);
  return (
    <video
      ref={ref}
      style={{
        objectFit: 'contain',
        position: 'absolute',
        left: -4,
        top: -4,
        width: 'calc(100% + 8px)',
        height: 'calc(100% + 8px)',
      }}
    >
      <track kind="captions" src="" srcLang="en" label={t('recording.englishCaption')} />
      {t('recording.videoTagNotSupported')}
    </video>
  );
});
