import { TopBar } from 'src/ui-components/layout/top-bar';
import {
  WorkbaseCloseIcon,
  WorkbaseDeleteSquareIcon,
  WorkbaseExitFullscreenIcon,
  WorkbaseFullscreenIcon,
  WorkbaseNewChatIcon,
} from 'src/assets/icons/workbaseIcons';
import { ChangeEvent, useCallback, useState } from 'react';
import { useFetchThreadsQuery, useDeleteThreadMutation, Thread } from 'src/store/api/aiChatApi';
import { throttle } from 'lodash';
import { NavigateFunction } from 'react-router-dom';
import {
  IconButton,
  MenuItem,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'src/hooks/useIsMobile';

interface ChatTopBarProps {
  dispatch: React.Dispatch<any>;
  navigate: NavigateFunction;
  isFullScreen: boolean;
  threadId: string;
  locationBeforeFullscreen: string | null;
}

export default function ChatTopBar({
  dispatch,
  navigate,
  isFullScreen,
  threadId,
  locationBeforeFullscreen,
}: ChatTopBarProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { data: threads } = useFetchThreadsQuery();
  const [deleteThread] = useDeleteThreadMutation();
  const isMobile = useIsMobile();
  const deleteButtonDisabled =
    !threadId || (threads && !threads.length) || isLoading || !threads?.length;

  const handleAddThread = () => {
    dispatch({ type: 'CLEAR_CURRENT_THREAD' });
    if (isMobile) {
      navigate('/ai');
    }
  };

  const handleToggleFullScreen = () => {
    if (isFullScreen) {
      dispatch({ type: 'CLOSE_FULLSCREEN' });
      if (locationBeforeFullscreen) {
        navigate(locationBeforeFullscreen);
        dispatch({ type: 'SET_LOCATION_BEFORE_FULLSCREEN', payload: null });
      } else {
        navigate('/', { replace: true });
      }
    } else {
      dispatch({ type: 'OPEN_FULLSCREEN' });
      dispatch({ type: 'SET_LOCATION_BEFORE_FULLSCREEN', payload: window.location.pathname });
      if (threadId) {
        navigate(`/ai/${threadId}`, { replace: true });
      } else {
        navigate('/ai');
      }
    }
  };

  const handleCloseDialog = () => {
    if (isFullScreen) {
      handleToggleFullScreen();
    }
    dispatch({ type: 'CLOSE_DIALOG' });
  };

  const handleDeleteThread = async (id: string) => {
    if (!threads?.length) return;
    setIsLoading(true);
    const previousThreads = threads || [];
    const index = previousThreads.findIndex((thread: Thread) => thread.id === id);
    await deleteThread(id);
    if (previousThreads.length === 1) {
      dispatch({ type: 'CLEAR_CURRENT_THREAD' });
      if (isFullScreen || isMobile) {
        navigate('/ai');
      }
    } else if (index > 0) {
      dispatch({ type: 'SET_SELECTED_CHAT', payload: previousThreads[index - 1].id });
      if (isFullScreen || isMobile) {
        navigate(`/ai/${previousThreads[index - 1].id}`);
      }
    } else if (index === 0 && previousThreads.length > 1) {
      dispatch({ type: 'SET_SELECTED_CHAT', payload: previousThreads[index + 1].id });
      if (isFullScreen || isMobile) {
        navigate(`/ai/${previousThreads[index + 1].id}`);
      }
    }
    setIsLoading(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledHandleDeleteThread = useCallback(throttle(handleDeleteThread, 1000), [
    handleDeleteThread,
  ]);

  const handleSelectThread = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      if (isMobile) {
        navigate(`/ai/${e.target.value}`);
      } else {
        dispatch({ type: 'SET_SELECTED_CHAT', payload: e.target.value });
      }
    }
  };

  const items = [
    {
      key: 'addThread',
      elementType: 'addThread',
      icon: WorkbaseNewChatIcon,
      onClick: handleAddThread,
      disabled: !threadId,
      hide: true,
      text: t('assistant.newChat'),
    },
    {
      key: 'deleteThread',
      elementType: 'deleteThread',
      icon: WorkbaseDeleteSquareIcon,
      onClick: () => throttledHandleDeleteThread(threadId),
      disabled: deleteButtonDisabled,
      hide: !isFullScreen,
      text: t('assistant.deleteChat'),
    },
    {
      key: 'fullScreen',
      elementType: 'fullScreen',
      icon: isFullScreen ? WorkbaseExitFullscreenIcon : WorkbaseFullscreenIcon,
      onClick: handleToggleFullScreen,
      text: isFullScreen ? t('assistant.exitFullScreen') : t('assistant.fullScreen'),
    },
    {
      key: 'closeChat',
      elementType: 'closeChat',
      icon: WorkbaseCloseIcon,
      onClick: handleCloseDialog,
      text: t('assistant.closeChat'),
    },
  ];

  return (
    <Stack position="absolute" top={0} right={0} zIndex={2} width="100%">
      <TopBar
        data-cy="ai-assistant-top-bar"
        rightContent={
          !isMobile && (
            <>
              {items.map(
                (item) =>
                  !item.hide && (
                    <Tooltip title={item.text} placement="top" key={item.key}>
                      <IconButton
                        key={item.key}
                        disableRipple
                        size="small"
                        onClick={item.onClick}
                        disabled={item.disabled}
                        data-cy={`ai-assistant-top-bar-${item.key}`}
                      >
                        <SvgIcon component={item.icon} fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )
              )}
            </>
          )
        }
        leftContent={
          !isFullScreen && (
            <>
              {items.slice(0, 2).map((item) => (
                <Tooltip title={item.text} placement="top" key={item.key}>
                  <IconButton
                    onClick={item.onClick}
                    size="small"
                    disabled={item.disabled}
                    data-cy={`ai-assistant-top-bar-${item.key}`}
                  >
                    <SvgIcon component={item.icon} fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              ))}
              <Stack
                minWidth={0}
                flexGrow={1}
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                <TextField
                  select
                  fullWidth
                  disabled={!threads || threads?.length < 1}
                  size="small"
                  label={!threadId ? t('assistant.selectChat') : null}
                  value={threadId}
                  onChange={handleSelectThread}
                  data-cy="ai-assistant-select-chat"
                >
                  {threads?.map((thread: Thread) => (
                    <MenuItem
                      key={thread.id}
                      value={thread.id}
                      data-cy={`ai-assistant-chat-option-${thread.id}`}
                    >
                      <Typography noWrap>
                        {thread.summary?.length > 0 ? thread.summary : t('assistant.newChat')}
                      </Typography>
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </>
          )
        }
      />
    </Stack>
  );
}
