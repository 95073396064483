import { Components, Theme } from '@mui/material';

const MuiTab: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      minWidth: 40,
      borderRadius: theme.shape.borderRadius,
    }),
  },
};

export default MuiTab;
