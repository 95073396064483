import { Components, Theme } from '@mui/material';

const MuiAlert: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
    }),
    action: ({ theme }) => ({
      padding: `0 0 0 ${theme.spacing(2)}`,
      flexShrink: 0,
    }),
  },
};

export default MuiAlert;
