import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { Training, useGetTrainingsQuery } from 'src/store/api/trainings/trainingsApi';
import { TrainingsMode, TrainingType } from 'src/features/editor/model';
import { useTranslation } from 'react-i18next';
import { SvgIcon, Typography } from '@mui/material';
import { WorkbaseAddContentIcon } from 'src/assets/icons/workbaseIcons';
import { ReactNode } from 'react';
import { TrainingModule } from 'src/store/api/trainings/trainingModulesApi';
import HighlightWrapper from '../highlight-wrapper';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';
import Content from './Content';

export interface TrainingsItemProps {
  onDelete: () => void;
  onDuplicate: () => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
  title: string;
  onTitleChange: (val: string) => void;
  trainings?: Training[];
  modules?: TrainingModule[];
  selectedItems: string[];
  onTrainingsChange: (val: string[]) => void;
  icon: string;
  onIconChange: (val: string) => void;
  mode: TrainingsMode;
  trainingsType: TrainingType;
  handleTrainingsTypeChange: (trainingsType: TrainingType) => void;
  columnsCountNum: number;
  id: string;
}
interface TrainingsVariables {
  title: string;
  contentReadOnly: boolean;
  selectedItems: string[];
  hideEditBar: boolean;
  placeholder?: ReactNode;
}

export default function TrainingsItem({
  onDelete,
  onDuplicate,
  readOnly = false,
  onResizeStop,
  dragging,
  onResizeStart,
  title,
  onTitleChange,
  trainings = [],
  modules = [],
  selectedItems = [],
  onTrainingsChange,
  icon,
  onIconChange,
  mode = 'default',
  trainingsType = 'trainings',
  handleTrainingsTypeChange,
  columnsCountNum,
  id,
}: TrainingsItemProps) {
  const { t } = useTranslation();
  const { data: trainingsData } = useGetTrainingsQuery();
  const trainingsVariableDict: Record<TrainingsMode, TrainingsVariables> = {
    default: {
      title,
      contentReadOnly: readOnly,
      selectedItems,
      hideEditBar: false,
      placeholder: (
        <Typography color="text.secondary" textAlign="center" fontWeight="medium">
          {t('editor.trainingsSection.trainingsSelectionMessage.useLabel')}{' '}
          <SvgIcon component={WorkbaseAddContentIcon} sx={{ verticalAlign: 'sub' }} />{' '}
          {t('editor.trainingsSection.trainingsSelectionMessage.selectTrainingLabel')}
        </Typography>
      ),
    },
    modules: {
      title,
      contentReadOnly: readOnly,
      selectedItems,
      hideEditBar: true,
    },
  };

  const allOptions =
    trainingsType === 'trainings'
      ? trainings.map(({ name, ...rest }) => ({
          label: name || t('unnamedTraining'),
          id: rest.id,
        }))
      : modules.map(({ name, ...rest }) => {
          const training = trainingsData?.find((tr) => tr.id === rest.trainingId);
          return {
            label: `${training?.name || t('unnamedTraining')} - ${name || t('unnamedModule')}`,
            id: rest.id,
          };
        });

  if (readOnly && trainingsVariableDict[mode]?.selectedItems.length === 0 && mode === 'default')
    return null;

  return (
    <HighlightWrapper
      dataCy="editor-widgets-trainings-item"
      id={id}
      hideEditbar={trainingsVariableDict[mode]?.hideEditBar}
      editBarProps={{
        baseActionsProps: { onDelete, onDuplicate },
        trainingsActionsProps: {
          selected: selectedItems,
          onChange: onTrainingsChange,
          options: allOptions,
          handleTrainingsTypeChange,
          selectedTrainingsType: trainingsType,
        },
      }}
      dragging={dragging}
      horizontalResize={{}}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      columnsCountNum={columnsCountNum}
      readOnly={readOnly}
    >
      {({ EditBarComponent, columnsCount }) => (
        <>
          <Content
            columnsCount={columnsCount}
            value={trainingsVariableDict[mode]?.title}
            onChange={onTitleChange}
            icon={icon}
            onIconChange={onIconChange}
            readOnly={trainingsVariableDict[mode]?.contentReadOnly}
            trainings={trainings}
            modules={modules}
            selectedItems={trainingsVariableDict[mode]?.selectedItems}
            trainingsType={trainingsType}
            placeholder={trainingsVariableDict[mode]?.placeholder}
          />
          {EditBarComponent}
        </>
      )}
    </HighlightWrapper>
  );
}
