import { Stack, Typography, Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { DefaultBufferingIndicator } from '@vidstack/react/player/layouts/default';

interface CustomBufferingIndicatorProps {
  src: string | null;
  encodingProgress: number;
  t: (key: string) => string;
}

function CustomBufferingIndicator({ src, encodingProgress, t }: CustomBufferingIndicatorProps) {
  return (
    <Stack
      position="absolute"
      left={0}
      top={0}
      right={0}
      bottom={0}
      justifyContent="center"
      alignItems="center"
      gap={2}
      bgcolor={({ palette }) => (src ? undefined : alpha(palette.common.black, 0.6))}
      zIndex={1}
      sx={{
        pointerEvents: 'none',
        '& > *:first-child > *:first-child': {
          position: 'relative !important',
          width: 'auto',
          height: 'auto',
          transform: 'none !important',
        },
      }}
    >
      <Box
        position="relative"
        zIndex={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <DefaultBufferingIndicator />
        {!src && (
          <Typography position="absolute" zIndex={1} variant="h6" color="white.main" align="center">
            {encodingProgress}%
          </Typography>
        )}
      </Box>
      {!src && (
        <Typography position="relative" zIndex={1} variant="h6" color="white.main" align="center">
          {t('editor.videoItem.videoProcessingLabel')}
        </Typography>
      )}
    </Stack>
  );
}

export default CustomBufferingIndicator;
