import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';
import {
  WorkbaseMotivationIcon,
  WorkbaseEmployeeCardsIcon,
  WorkbaseSettingsIcon,
} from 'src/assets/icons/workbaseIcons';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { useAppSelector } from 'src/store';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import {
  RecommendationNotificationAdminPreferences,
  useSettingsQuery,
} from 'src/store/api/settingsApi';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';

function useActionButtonsData(onRecommendationIconClick: () => void) {
  const { t } = useTranslation();
  const location = useLocation();
  const { data: currentUserData } = useCurrentUserQuery();
  const [, setSearchParams] = useSearchParams();
  const { data: settingsData } = useSettingsQuery(undefined, { refetchOnMountOrArgChange: true });
  const portalMode = useAppSelector((state) => state.userPortal.currentPortal);
  const hasAnyPermission = useHasAnyPermission();
  const defaultIconsData = [];

  if (
    (
      settingsData?.recommendationNotificationPreferences as RecommendationNotificationAdminPreferences
    )?.isEmailEnabled ||
    (
      settingsData?.recommendationNotificationPreferences as RecommendationNotificationAdminPreferences
    )?.isWebhookEnabled
  ) {
    defaultIconsData.push({
      key: 'recommendation',
      icon: { icon: WorkbaseMotivationIcon },
      onClick: onRecommendationIconClick,
      isActive: false,
      tooltip: t('header.employeeRecommendationIconTooltip'),
    });
  }

  if (currentUserData?.isOwner || hasAnyPermission([PermissionCodes.VIEW_EMPLOYEES])) {
    defaultIconsData.push({
      key: 'employee',
      icon: { icon: WorkbaseEmployeeCardsIcon },
      onClick: () => setSearchParams({ employee: '1' }),
      isActive: !!matchPath({ path: 'team', end: false }, location.pathname),
      tooltip: t('header.employeeIconTooltip'),
    });
  }

  const settingsVisible =
    portalMode === 'employeePortal'
      ? hasAnyPermission([
          PermissionCodes.MANAGE_DEPARTMENTS,
          PermissionCodes.MANAGE_ALL_COMPANY_SETTINGS,
          PermissionCodes.MANAGE_BILLING,
        ])
      : hasAnyPermission([
          PermissionCodes.MANAGE_CUSTOMER_PORTAL,
          PermissionCodes.MANAGE_CUSTOMER_GROUPS,
        ]);

  if (settingsVisible) {
    defaultIconsData.push({
      key: 'settings',
      icon: { icon: WorkbaseSettingsIcon },
      onClick: () =>
        setSearchParams({
          settings:
            currentUserData?.isOwner ||
            hasAnyPermission([
              PermissionCodes.MANAGE_DEPARTMENTS,
              PermissionCodes.MANAGE_ALL_COMPANY_SETTINGS,
            ])
              ? 'site-information'
              : 'employee-recommendations',
        }),
      isActive: !!matchPath({ path: 'settings', end: false }, location.pathname),
      tooltip: t('header.settingsIconTooltip'),
    });
  }

  return defaultIconsData;
}

export default useActionButtonsData;
