import { useBoolean } from 'src/lib/hooks/use-boolean';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { useConfirm } from 'material-ui-confirm';
import { useTranslation } from 'react-i18next';
import { ResourceType, usePatchLinkMutation, useRemoveLinkMutation } from 'src/store/api/linksApi';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import EditableLinkButton from './link-button/EditableLinkButton';
import AddEditLinkModal from './AddEditLinkModal';

interface Props {
  url: string;
  imageUrl?: string;
  readOnly: boolean;
  linkName: string;
  resourceId: string;
  resourceType: ResourceType;
  id: string;
}

function LinkEditMode({
  linkName,
  resourceType,
  resourceId,
  url,
  imageUrl,
  id,
}: Omit<Props, 'readOnly'>) {
  const { t } = useTranslation();
  const editLinkDialog = useBoolean();
  const deleteModalConfirm = useConfirm();
  const [patchLink] = usePatchLinkMutation();
  const [removeLink] = useRemoveLinkMutation();

  const handleFaviconChange = async (file: File) => {
    if (!file) return;

    if (file) {
      try {
        const response = await imageUpload(file);
        const { result } = response.data;
        patchLink({ resourceId, resourceType, imageId: result.id, id });
      } catch (_err) {
        showNotification('Error', 'error');
      }
    }
  };

  const handleRemoveFaviconIcon = () => {
    patchLink({ resourceId, resourceType, id, imageId: null });
  };

  return (
    <>
      <EditableLinkButton
        onFaviconChange={(e: any) => handleFaviconChange(e)}
        onRemoveFavicon={handleRemoveFaviconIcon}
        href={url ? ensureHttpProtocol(url) : url}
        faviconUrl={imageUrl || undefined}
        editable
        onEdit={() => editLinkDialog.onTrue()}
        onRemove={() =>
          deleteModalConfirm({
            title: t('links.removeLinkModal.title'),
            description: t('links.removeLinkModal.contentText', { name: linkName }),
          }).then(() => {
            removeLink({ resourceId, resourceType, id });
          })
        }
      >
        {linkName}
      </EditableLinkButton>
      <AddEditLinkModal
        linkId={id}
        resourceType={resourceType}
        resourceId={resourceId}
        editMode
        initialName={linkName}
        initialLink={url}
        open={editLinkDialog.value}
        onClose={() => editLinkDialog.onFalse()}
      />
    </>
  );
}

export default function Link({
  url,
  imageUrl,
  readOnly,
  linkName,
  resourceType,
  resourceId,
  id,
}: Props) {
  if (readOnly)
    return (
      <EditableLinkButton
        href={url ? ensureHttpProtocol(url) : url}
        faviconUrl={imageUrl || undefined}
      >
        {linkName}
      </EditableLinkButton>
    );

  return (
    <LinkEditMode
      id={id}
      resourceType={resourceType}
      resourceId={resourceId}
      linkName={linkName}
      url={url}
      imageUrl={imageUrl || undefined}
    />
  );
}
