import { useMemo } from 'react';
import data, { EmojiMartData } from '@emoji-mart/data';
import { useTranslation } from 'react-i18next';
import deData from 'src/locales/langs/emojis/data-de.json';

// Define the mapping between language and emoji data
const languageDataMap: Record<string, EmojiMartData> = {
  de: deData,
  en: data as EmojiMartData,
};

// Custom hook to get emoji data based on language with fallback to English
const useEmojiData = (): EmojiMartData => {
  const { i18n } = useTranslation();

  // Memoize emoji data selection based on current language with fallback to 'en'
  const emojiData = useMemo(() => {
    const currentLanguage = i18n.language || 'en'; // Fallback to 'en' if language is not defined
    return languageDataMap[currentLanguage] || data; // Default to English data if the current language is unsupported
  }, [i18n.language]);

  return emojiData;
};

export default useEmojiData;
