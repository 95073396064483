import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useIsMobile from 'src/hooks/useIsMobile';

interface EditViewProtectedWrapperProps {
  children: React.ReactNode;
  canEdit?: boolean;
  canView?: boolean;
}

const EditViewProtectedWrapper: React.FC<EditViewProtectedWrapperProps> = ({
  children,
  canEdit = false,
  canView = true,
}) => {
  const location = useLocation();
  const isEditPath = location.pathname.endsWith('/edit');
  let element = children;
  const isMobile = useIsMobile();
  const shouldRedirectToNonEditPath = isMobile && isEditPath ? true : !canEdit && isEditPath;
  if (!canView) {
    element = <Navigate to="/" replace />;
  } else if (shouldRedirectToNonEditPath) {
    element = <Navigate to={location.pathname.replace('/edit', '')} replace />;
  }
  return element;
};

export default EditViewProtectedWrapper;
