import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditorData } from '../model';
import { FileType } from '../view/grid/widgets/edit-bar/actions/UploadActions';

export interface UploadedMedia {
  id: string;
  mediaType: FileType;
}
export interface GridDataState {
  editorData?: EditorData;
  isDragging: boolean;
  resizingRowId: string | null;
  uploadingMedia: UploadedMedia[];
  recordingModalPayload?: RecordingModalPayload;
}

interface RecordingModalPayload {
  id: string;
  onLoading?: (val: boolean) => void;
  onUploadProgress?: (percentage: number) => void;
}

const initialState: GridDataState = {
  editorData: undefined,
  isDragging: false,
  resizingRowId: null,
  uploadingMedia: [],
  recordingModalPayload: undefined,
};

const EditorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setEditorData: (state, action: PayloadAction<EditorData | undefined>) => ({
      ...state,
      editorData: action.payload,
    }),
    setIsDragging: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isDragging: action.payload,
    }),
    setResizingRowId: (state, action: PayloadAction<string | null>) => ({
      ...state,
      resizingRowId: action.payload,
    }),
    addUploadingMedia: (state, action: PayloadAction<{ id: string; mediaType: FileType }>) => {
      state.uploadingMedia.push({ id: action.payload.id, mediaType: action.payload.mediaType });
    },
    clearUploadingMedia: (state, action: PayloadAction<{ id: string }>) => {
      state.uploadingMedia = state.uploadingMedia.filter(({ id }) => action.payload.id !== id);
    },
    setRecordingModalPayload: (state, action: PayloadAction<RecordingModalPayload | undefined>) => {
      state.recordingModalPayload = action.payload;
    },
  },
});

export const {
  setEditorData,
  setIsDragging,
  setResizingRowId,
  addUploadingMedia,
  clearUploadingMedia,
  setRecordingModalPayload,
} = EditorSlice.actions;
export default EditorSlice;
