import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  SvgIcon,
  Typography,
} from '@mui/material';
import { WorkbaseInfoIcon } from 'src/assets/icons/workbaseIcons';
import { bindHover, bindPopover, usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { useTranslation } from 'react-i18next';

const fieldMappings = [
  { placeholder: '{{WorkbaseUserID}}' },
  { placeholder: '{{WorkbaseUserEmail}}' },
  { placeholder: '{{WorkbaseUserFirstname}}' },
  { placeholder: '{{WorkbaseUserLastname}}' },
  { placeholder: '{{WorkbaseUserPhone}}' },
];

function LinkParametersTooltip() {
  const popupState = usePopupState({ variant: 'popover' });
  const { t } = useTranslation();

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="subtitle1">
                {t('embeddedApps.linkParameters.tableHeading')}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('embeddedApps.linkParameters.parameter')}</TableCell>
            <TableCell>{t('embeddedApps.linkParameters.description')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fieldMappings.map((row) => (
            <TableRow key={row.placeholder}>
              <TableCell>{row.placeholder}</TableCell>
              <TableCell>
                {t(
                  `embeddedApps.linkParameters.placeholders.${row.placeholder.replace(
                    /{{|}}/g,
                    ''
                  )}`
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="caption" color="text.secondary">
                {t('embeddedApps.linkParameters.example')}:{' '}
                <em>{'https://example.com/app?email={{WorkbaseUserEmail}}'}</em>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      <IconButton
        {...bindHover(popupState)}
        disableRipple
        disableTouchRipple
        disableFocusRipple
        data-cy="link-parameters-icon-button"
      >
        <SvgIcon component={WorkbaseInfoIcon} />
      </IconButton>
      <HoverPopover
        data-cy="link-parameters-icon-button-popover"
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {renderTable()}
      </HoverPopover>
    </>
  );
}

export default LinkParametersTooltip;
