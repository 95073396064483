import { Button, Stack } from '@mui/material';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import generateJitteredKeyBetween from 'src/lib/utils/generateJitteredKeyBetween';
import {
  TrainingLesson,
  useMoveTrainingLessonToNewPositionMutation,
  usePostTrainingLessonMutation,
} from 'src/store/api/trainings/trainingLessonsApi';
import DndIndicator from 'src/ui-components/custom/dnd-indicator/DndIndicator';

export default function AddingLessonButton({
  id,
  onAddLessonCallback,
  allLessons,
}: {
  id: string;
  onAddLessonCallback?: (lessonId: string) => void;
  allLessons: TrainingLesson[];
}) {
  const { t } = useTranslation();
  const [moveTrainingLessonToNewPosition] = useMoveTrainingLessonToNewPositionMutation();
  const [postTrainingLesson] = usePostTrainingLessonMutation();

  const [{ isOver }, drop] = useDrop({
    accept: 'editing-lesson-item',
    drop: (i: { id: string; sectionId: string }) => {
      const isSameSection = i.sectionId === id;

      const targetIndex = isSameSection ? allLessons.length : allLessons.length + 1;

      const aboveLesson = allLessons.length > 0 ? allLessons[allLessons.length - 1] : null;

      const aboveOrder = aboveLesson ? (aboveLesson.position as string) : null;
      const belowOrder = null;
      const newPosition = generateJitteredKeyBetween(aboveOrder, belowOrder);

      moveTrainingLessonToNewPosition({
        sectionId: id as string,
        idOfMovingLesson: i.id,
        order: targetIndex,
        position: newPosition,
      });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <Stack position="relative" ref={drop} pt={2}>
      {isOver && <DndIndicator top={-9} />}
      <Button
        data-cy="add-lesson-button"
        fullWidth
        color="inherit"
        onClick={() => {
          postTrainingLesson({ trainingSectionId: id }).then((resp: any) => {
            if (resp.data.id) {
              onAddLessonCallback?.(resp.data.id);
              setTimeout(() => {
                document.getElementById(resp.data.id)?.querySelector('input')?.focus();
              }, 100);
            }
          });
        }}
      >
        {t('modulePage.addLesson')}
      </Button>
    </Stack>
  );
}
