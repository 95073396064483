import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  GeneralAccessType,
  ResourceType,
  usePatchGeneralAccessMutation,
} from 'src/store/api/shareResourceApi';
import { WorkbaseLockIcon, WorkbaseBusinessPlusIcon } from 'src/assets/icons/workbaseIcons';
import { ElementType } from 'react';

interface GeneralAccessSwitchProps {
  resourceId: string;
  resourceType: ResourceType;
  generalAccessType: GeneralAccessType;
}
// TODO: confirmation dialog
function GeneralAccessSwitch({
  resourceId,
  resourceType,
  generalAccessType,
}: GeneralAccessSwitchProps): JSX.Element {
  const { t } = useTranslation();
  const [patchGeneralAccess] = usePatchGeneralAccessMutation();

  const generalAccessItems: Record<
    GeneralAccessType,
    { label: string; icon: ElementType; onClick: () => void }
  > = {
    restricted: {
      label: t('permissionsShare.restricted'),
      icon: WorkbaseLockIcon,
      onClick(): void {
        patchGeneralAccess({
          resourceType: resourceType as ResourceType,
          resourceId: resourceId as string,
          type: 'everyone',
        });
      },
    },
    everyone: {
      label: t('permissionsShare.everyone'),
      icon: WorkbaseBusinessPlusIcon,
      onClick(): void {
        patchGeneralAccess({
          resourceType: resourceType as ResourceType,
          resourceId: resourceId as string,
          type: 'restricted',
        });
      },
    },
  };

  const itemProps = generalAccessItems[generalAccessType];
  return (
    itemProps && (
      <Switch
        data-cy="general-access-switch"
        onChange={itemProps.onClick}
        size="small"
        checked={generalAccessType === 'everyone'}
      />
    )
  );
}

export default GeneralAccessSwitch;
