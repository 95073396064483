import { TabPanel } from '@mui/lab';
import { EmojiMartData, Emoji as EmojiMartEmoji, Category } from '@emoji-mart/data';
import Grid from '@mui/material/Grid2';
import IconOrEmojiGrid from './IconOrEmojiGrid';

interface PopulatedEmojiCategory {
  id: string;
  emojis: EmojiMartEmoji[];
}

interface EmojiCategoryPanelsProps {
  emojiData: EmojiMartData;
  selectedIcon: string;
  onClick: (emoji: string) => void;
}

export default function EmojiCategoryPanels({
  emojiData,
  selectedIcon,
  onClick,
}: EmojiCategoryPanelsProps) {
  const emojiCategories = emojiData.categories.filter(
    (category) => category.id !== 'frequently_used'
  );

  // Function to populate emoji categories with corresponding emoji objects
  const populateEmojiCategories = (
    categories: Category[],
    emojis: Record<string, EmojiMartEmoji>
  ): PopulatedEmojiCategory[] =>
    categories.map((category) => {
      const populatedEmojis = category.emojis
        .map((emojiName: string) => emojis[emojiName])
        .filter(Boolean);
      return {
        id: category.id,
        emojis: populatedEmojis,
      };
    });

  const populatedEmojiCategories = populateEmojiCategories(emojiCategories, emojiData.emojis);

  return (
    <>
      {populatedEmojiCategories.map((category) => (
        <TabPanel value={category.id} key={category.id}>
          <Grid container columns={9}>
            <IconOrEmojiGrid
              emojis={category.emojis}
              selectedIcon={selectedIcon}
              onClick={onClick}
            />
          </Grid>
        </TabPanel>
      ))}
    </>
  );
}
