import { SvgIcon, SvgIconProps } from '@mui/material';
import allIcons from 'src/assets/icons';

export type IconName = keyof typeof allIcons;

export interface SvgIconByNameProps extends Omit<SvgIconProps, 'component' | 'children'> {
  iconName: IconName;
}

export default function SvgIconByName({ iconName, ...restOfProps }: SvgIconByNameProps) {
  const selectedIcon = allIcons[iconName];

  if (!selectedIcon) {
    return null;
  }

  return <SvgIcon component={selectedIcon} {...restOfProps} />;
}
