import { Box, Stack, useTheme } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import { DropzoneProps } from 'react-dropzone';
import { motion } from 'framer-motion';
import Empty from 'src/ui-components/custom/empty/Empty';
import { WorkbaseContentNewIcon } from 'src/assets/icons/workbaseIcons';
import { Document, File } from 'src/store/api/driveApi';
import FilesTable from './table/FilesTable';
import { DocumentsTabData } from './types';
import DocumentsTable from './table/DocumentsTable';
import { useTranslation } from 'react-i18next';

interface DocumentsDataListProps {
  tabsData: DocumentsTabData[];
  dropZoneProps: DropzoneProps;
  isDragging: boolean;
  uploadCount: number;
}

export default function DocumentsDataList({
  tabsData,
  dropZoneProps,
  isDragging,
  uploadCount,
}: DocumentsDataListProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box pb={2}>
      <Stack gap={2}>
        <Box>
          {tabsData.map(({ key, rows }) => (
            <TabPanel key={key} value={key}>
              {key === 'files' ? (
                <>
                  {/* @ts-expect-error */}
                  <Box
                    component="div"
                    {...dropZoneProps}
                    position="relative"
                    sx={{
                      cursor: 'default',
                    }}
                  >
                    <FilesTable files={rows as File[]} uploadCount={uploadCount} />
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={isDragging ? { opacity: 1 } : { opacity: 0 }}
                      exit={{ opacity: 0 }}
                      transition={{ opacity: { duration: 0.3 } }}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        border: `1px solid ${theme.palette.divider}`,
                        bottom: 0,
                        display: isDragging ? 'flex' : 'none',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.action.hover,
                        backdropFilter: 'blur(10px)',
                        borderRadius: `${theme.shape.borderRadius}px`,
                        zIndex: 10,
                      }}
                    >
                      <Empty
                        description={t('documentsPage.dropZoneDescription')}
                        icon={WorkbaseContentNewIcon}
                      />
                    </motion.div>
                  </Box>
                </>
              ) : (
                <DocumentsTable rows={rows as Document[]} />
              )}
            </TabPanel>
          ))}
        </Box>
      </Stack>
    </Box>
  );
}
