import axiosInstance from 'src/axios';
import { API_URL } from 'src/global-config';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

interface ImageUploadResponseSchema {
  result: {
    id: string;
    url: string;
    filename: string;
    variants: string[];
  };
}

const imageUpload = async (
  file: File,
  config: AxiosRequestConfig = {},
  document?: string
): Promise<AxiosResponse<ImageUploadResponseSchema>> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('editorDocumentName', document ?? '');
  const headers = {
    'Content-Type': 'multipart/form-data',
    ...config.headers,
  };

  const finalConfig = {
    ...config,
    headers,
  };

  return axiosInstance.post(`${API_URL}/images/upload`, formData, finalConfig);
};

export { imageUpload };
