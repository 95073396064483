import { Box, Stack, SvgIcon, Typography } from '@mui/material';
import { usePatchCurrentUserMutation } from 'src/store/api/currentUserApi';
import { useTranslation } from 'react-i18next';
import { imageUpload } from 'src/lib/utils/imageUpload';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { WorkbaseEmployeeIcon } from 'src/assets/icons/workbaseIcons';

export default function UploadAvatar({ text, url }: { text: string; url: string | null }) {
  const { t } = useTranslation();
  const [patchCurrentUser] = usePatchCurrentUserMutation();

  const handleFileChange = async (file: File): Promise<unknown> => {
    if (file) {
      try {
        const response = await imageUpload(file);
        const { id, variants } = response.data.result;
        return await patchCurrentUser({ avatarId: id, avatarUrl: variants[0] });
      } catch (_err) {
        showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
      }
    }
    return undefined;
  };

  const removeImage = (): Promise<unknown> => patchCurrentUser({ avatarId: null, avatarUrl: '' });

  return (
    <Stack gap={2} flexDirection="row" alignItems="center">
      <Box width={92} height={92} minWidth={92}>
        <EditableImageUpload
          src={url || ''}
          altText={text}
          placeholder={<SvgIcon component={WorkbaseEmployeeIcon} />}
          onChange={handleFileChange}
          onClear={removeImage}
          dataCy="user-profile-menu"
        />
      </Box>
      <Box>
        <Typography variant="body1" fontWeight="bold">
          {t('generalSettings.information.profileInformation.avatarTitle')}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {t('generalSettings.information.profileInformation.avatarDescription')}
        </Typography>
      </Box>
    </Stack>
  );
}
