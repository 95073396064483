import { Components, Theme } from '@mui/material';

const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  variants: [
    {
      props: { size: 'medium' },
      style: {
        '& .MuiOutlinedInput-input': {
          paddingTop: 12.5,
          paddingBottom: 12.5,
        },
      },
    },
  ],
};

export default MuiOutlinedInput;
