import { Dialog } from '@mui/material';
import NewEmployeeDialogContent from './NewEmployeeDialogContent';

export default function NewEmployeeDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <NewEmployeeDialogContent open={open} onClose={onClose} />
    </Dialog>
  );
}
