import { ListItemAvatar, Avatar, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseDepartmentsIcon } from 'src/assets/icons/workbaseIcons';
import { ResourceType } from 'src/store/api/searchResourceApi';
import { ResourceInfo } from 'src/store/api/shareResourceApi';
import AccessControlItem from './AccessControlItem';
import PermissionSelect from '../PermissionSelect';
import RequiredSwitch from '../RequiredSwitch';

interface AccessDepartmentItemProps {
  resource: ResourceInfo;
  resourceId: string;
  resourceType: ResourceType;
  isTrainingResourceType: boolean;
}

export default function AccessDepartmentItem(props: AccessDepartmentItemProps) {
  const { resource, resourceId, resourceType, isTrainingResourceType } = props;
  const { t } = useTranslation();
  const { department, id, required, shareLevel } = resource;

  if (!department) {
    return null;
  }

  return (
    <AccessControlItem
      key={id}
      prefix={
        <ListItemAvatar>
          <Avatar size="large">
            <SvgIcon component={WorkbaseDepartmentsIcon} fontSize="medium" />
          </Avatar>
        </ListItemAvatar>
      }
      primaryText={`${t('permissionsShare.department')}: ${department.name}`}
      secondaryText={`${t('permissionsShare.role', {
        count: department.rolesCount,
      })} – ${t('permissionsShare.member', {
        count: department.companyUsersCount,
      })}`}
      suffix={
        <>
          <PermissionSelect
            resourceId={resourceId}
            resourceType={resourceType}
            id={id}
            shareLevel={shareLevel}
            dataCy="department-permision-select"
          />
          {isTrainingResourceType && (
            <RequiredSwitch
              resourceId={resourceId}
              resourceType={resourceType}
              id={id}
              isRequired={required ?? false}
              dataCy="department-permision-select"
            />
          )}
        </>
      }
    />
  );
}
