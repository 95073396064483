import { RefObject, useRef, useState } from 'react';
import { Box, Paper, Portal } from '@mui/material';
import { motion } from 'framer-motion';
import EditBarActions, { EditBarActionsProps } from './actions/EditBarActions';
import { useFixedPosition } from './useFixedPosition';

export type EditBarProps = Omit<
  EditBarActionsProps,
  'linkInputVisible' | 'setLinkInputVisible' | 'linkInputVal' | 'setLinkInputVal'
>;

export default function EditBar({ widgetContainerRef, ...restOfProps }: EditBarProps) {
  const editBarContainerRef = useRef<HTMLDivElement>(null);
  const editBarContentRef = useRef<HTMLDivElement>(null);
  const [linkInputVisible, setLinkInputVisible] = useState(false);
  const [linkInputVal, setLinkInputVal] = useState('');

  const { isFixed, isActiveGridInView, position } = useFixedPosition(
    widgetContainerRef as RefObject<HTMLDivElement>,
    editBarContainerRef,
    editBarContentRef
  );

  const fixedStyles = {
    position: 'fixed' as const,
    top: 140,
    opacity: isActiveGridInView ? '1' : '0',
    pointerEvents: isActiveGridInView ? 'auto' : 'none',
    zIndex: 20,
    left: position.left || undefined,
    right: position.right || undefined,
  };

  const paper = (
    <Paper
      ref={editBarContentRef}
      variant="outlined"
      component={Box}
      px={1}
      height={54}
      sx={{
        display: 'flex !important',
        alignContent: 'center',
        minWidth: 'max-content',
        ...(isFixed ? fixedStyles : {}),
      }}
      onMouseDown={(e) => e.preventDefault()}
    >
      <EditBarActions
        {...restOfProps}
        widgetContainerRef={widgetContainerRef}
        linkInputVisible={linkInputVisible}
        setLinkInputVisible={setLinkInputVisible}
        setLinkInputVal={setLinkInputVal}
        linkInputVal={linkInputVal}
      />
    </Paper>
  );

  return (
    <motion.div
      data-cy="edit-bar"
      ref={editBarContainerRef}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: -4 }}
      transition={{ duration: 0.2, ease: 'easeOut' }}
      style={{
        position: 'absolute',
        left: position.right ? undefined : 0,
        right: position.right ? 0 : undefined,
        bottom: '100%',
        zIndex: 10,
      }}
    >
      {isFixed ? <Portal>{paper}</Portal> : paper}
    </motion.div>
  );
}
