import {
  Popper,
  TextField,
  TextFieldProps,
  InputAdornment,
  useAutocomplete,
  Box,
  List,
  styled,
  ListItem,
  Theme,
  Grow,
  UseAutocompleteProps,
  PopperProps,
} from '@mui/material';
import { useRef } from 'react';
import LottieChevron from '../lottie/LottieChevron';

interface PropsWithChildren extends UseAutocompleteProps<any, any, any, any> {
  textFieldProps?: TextFieldProps;
  popperProps?: PopperProps;
  value: UseAutocompleteProps<any, any, any, any>['value'];
  dataCy?: string;
}

const Listbox = styled(List)(({ theme }: { theme: Theme }) => ({
  margin: 0,
  padding: 0,
  zIndex: theme.zIndex.modal + 1,
  position: 'relative',
  overflow: 'auto',
  maxHeight: 300,
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  '& li': {
    zIndex: 'inherit',
  },
  '& li.Mui-focused': {
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
  },
  '& li:active': {
    backgroundColor: theme.palette.action.selected,
    color: 'white',
  },
}));

export default function SearchableSelect({
  textFieldProps,
  popperProps,
  dataCy,
  ...useAutocompleteProps
}: PropsWithChildren) {
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getPopupIndicatorProps,
    groupedOptions,
    popupOpen,
    inputValue,
    getClearProps,
  } = useAutocomplete({
    id: 'searchable-select',
    ...useAutocompleteProps,
  });
  const textFieldRef = useRef<HTMLInputElement>(null);

  return (
    <Box width="100%">
      <Box
        width="100%"
        ref={textFieldRef}
        {...getRootProps()}
        onFocus={(e: any) => {
          getClearProps?.()?.onClick?.(e);
          getRootProps()?.onFocus?.(e);
        }}
      >
        <TextField
          data-cy={dataCy && `${dataCy}-textfield`}
          size="small"
          fullWidth
          placeholder={useAutocompleteProps.value?.label}
          value={inputValue}
          {...textFieldProps}
          slotProps={{
            ...textFieldProps?.slotProps,
            htmlInput: {
              ...getInputProps(),
            },
            input: {
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ cursor: 'pointer' }}
                  onClick={(e: any) => {
                    getPopupIndicatorProps()?.onClick?.(e);
                  }}
                >
                  <LottieChevron open={popupOpen} />
                </InputAdornment>
              ),
              ...textFieldProps?.slotProps?.input,
            },
          }}
        />
      </Box>

      <Popper
        open={popupOpen}
        anchorEl={textFieldRef.current}
        {...popperProps}
        placement="bottom-start"
        sx={{ zIndex: (theme) => theme.zIndex.modal + 1, width: textFieldRef.current?.clientWidth }}
      >
        <Grow in={popupOpen} style={{ transformOrigin: 'top center' }}>
          <Listbox {...getListboxProps()} data-cy={dataCy && `${dataCy}-list`}>
            {groupedOptions.map((option, index) => {
              const { key, ...optionProps } = getOptionProps({ option, index });
              return (
                <ListItem
                  key={key}
                  {...optionProps}
                  data-cy={dataCy && `${dataCy}-${option.label}-item`}
                >
                  {option.label}
                </ListItem>
              );
            })}
          </Listbox>
        </Grow>
      </Popper>
    </Box>
  );
}
