import { Box, SvgIcon } from '@mui/material';
import { WorkbasePauseIcon } from 'src/assets/icons/workbaseIcons';
import ColoredCircle from './ColoredCircle';

export default function CenteredPauseCircle() {
  return (
    <Box
      position="absolute"
      left="50%"
      color="warning.contrastText"
      top="50%"
      sx={{ transform: 'translate(-50%, -50%)' }}
    >
      <ColoredCircle backgroundColor="warning.light">
        <SvgIcon component={WorkbasePauseIcon} fontSize="large" />
      </ColoredCircle>
    </Box>
  );
}
