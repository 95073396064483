import { Avatar, AvatarGroup, Badge, Box, Stack, Tooltip } from '@mui/material';
import { useAppSelector } from 'src/store';
import { useTranslation } from 'react-i18next';
import Color from 'color';
import { AwarenessUserData } from './controller/awareness.slice';

const MAX_AVATARS_TO_DISPLAY_COUNT = 5;

const getAvatarColor = (mainColor: string, rotation: number): string =>
  Color(mainColor).rotate(rotation).toString();

export default function AwarenessAvatars() {
  const awarenessState = useAppSelector((state) => state.awareness.awarenessState);
  const remainingUsers = awarenessState.slice(MAX_AVATARS_TO_DISPLAY_COUNT - 1);
  const { t } = useTranslation();
  const getUserTitle = (user: AwarenessUserData, isEditing: boolean) =>
    `${user.firstName} ${user.lastName} – ${
      isEditing ? t('editor.topBar.avatars.editMode') : t('editor.topBar.avatars.readMode')
    }`;
  return (
    <AvatarGroup
      max={MAX_AVATARS_TO_DISPLAY_COUNT}
      renderSurplus={(surplus) => (
        <Tooltip
          title={
            <Stack>
              {remainingUsers.map(({ clientId, user, isEditing }) => (
                <span key={clientId}>{getUserTitle(user, isEditing)}</span>
              ))}
            </Stack>
          }
        >
          <span>+{surplus.toString()}</span>
        </Tooltip>
      )}
    >
      {awarenessState.map(({ clientId, user, isEditing }) => {
        if (!user) return null;
        const avatar = (
          <Box
            position="relative"
            borderRadius="100%"
            border={({ palette }) =>
              `2px solid ${
                isEditing
                  ? getAvatarColor(palette.primary.main, Number(clientId) % 180)
                  : 'transparent'
              }`
            }
          >
            {isEditing && (
              <Box
                position="absolute"
                bottom={-5}
                right={3}
                sx={{
                  '& .MuiBadge-badge': {
                    p: 0,
                    minWidth: 10,
                    width: 10,
                    height: 10,
                    border: ({ palette }) => `2px solid ${palette.background.paper}`,
                    backgroundColor: ({ palette }) =>
                      getAvatarColor(palette.primary.main, Number(clientId) % 180).toString(),
                  },
                }}
              >
                <Badge color="primary" badgeContent="" />
              </Box>
            )}
            <Avatar src={user.avatarUrl || undefined} data-cy={`awareness-avatar-${user.id}`}>
              {user.firstName[0]}
            </Avatar>
          </Box>
        );
        return (
          <Tooltip key={clientId} title={getUserTitle(user, isEditing)}>
            {avatar}
          </Tooltip>
        );
      })}
    </AvatarGroup>
  );
}
