import { SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  WorkbaseCheckmarkBigIcon,
  WorkbaseErrorIcon,
  WorkbaseInfoIcon,
  WorkbaseNotesIcon,
  WorkbaseQuestionBubbleIcon,
  WorkbaseWarningIcon,
} from '../../../../../../../assets/icons/workbaseIcons';

export interface InfoActionsProps {
  infoType: InfoType;
  onChangeActiveInfoType: (type: InfoType) => void;
}

export type InfoType = 'info' | 'question' | 'notes' | 'success' | 'warning' | 'error';

export default function InfoActions({ infoType, onChangeActiveInfoType }: InfoActionsProps) {
  const { t } = useTranslation();
  const items = [
    {
      key: 'info',
      onClick: () => onChangeActiveInfoType('info'),
      icon: WorkbaseInfoIcon,
      color: 'info' as const,
    },
    {
      key: 'question',
      onClick: () => onChangeActiveInfoType('question'),
      icon: WorkbaseQuestionBubbleIcon,
      color: 'standard' as const,
    },
    {
      key: 'notes',
      onClick: () => onChangeActiveInfoType('notes'),
      icon: WorkbaseNotesIcon,
      color: 'secondary' as const,
    },
    {
      key: 'success',
      onClick: () => onChangeActiveInfoType('success'),
      icon: WorkbaseCheckmarkBigIcon,
      color: 'success' as const,
    },
    {
      key: 'warning',
      onClick: () => onChangeActiveInfoType('warning'),
      icon: WorkbaseWarningIcon,
      color: 'warning' as const,
    },
    {
      key: 'error',
      onClick: () => onChangeActiveInfoType('error'),
      icon: WorkbaseErrorIcon,
      color: 'error' as const,
    },
  ];
  return (
    <>
      {items.map(({ key, onClick, icon, color }) => (
        <Tooltip key={key} title={t(`editor.topBar.tooltip.${key}`)} placement="top">
          <span>
            <ToggleButton
              data-cy={`editor-topbar-info-action-${key}-button`}
              size="small"
              value={key}
              color={color}
              selected={infoType === key}
              onMouseDown={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <SvgIcon component={icon} fontSize="small" />
            </ToggleButton>
          </span>
        </Tooltip>
      ))}
    </>
  );
}
