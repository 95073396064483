import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { ConnectDragPreview } from 'react-dnd';

export default function DndPreview({
  children,
  previewRef,
}: {
  children: ReactNode;
  previewRef: ConnectDragPreview;
}) {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        pointerEvents: 'none',
        zIndex: -10,
        overflow: 'hidden',
      }}
    >
      <Box ref={previewRef}>
        <Box sx={{ transform: 'scale(0.7)' }}>{children}</Box>
      </Box>
    </Box>
  );
}
