import { Components, Theme } from '@mui/material';

const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius * 0.5,
      backdropFilter: 'blur(8px)',
      backgroundColor: 'rgba(64, 64, 64, 0.6)',
    }),
  },
};

export default MuiTooltip;
