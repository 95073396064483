import { Components, Theme } from '@mui/material';

const MuiAccordionDetails: Components<Theme>['MuiAccordionDetails'] = {
  styleOverrides: {
    root: {
      paddingTop: 0,
    },
  },
};

export default MuiAccordionDetails;
