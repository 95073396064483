import { Components, Theme } from '@mui/material';

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    tiny: true;
  }
}

const MuiSvgIcon: Components<Theme>['MuiSvgIcon'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      width: '1em',
      height: '1em',
      ...(ownerState.fontSize === 'tiny' && {
        width: '12px',
        height: '12px',
      }),
    }),
  },
};

export default MuiSvgIcon;
