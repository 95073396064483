import { RefObject, useContext, useEffect } from 'react';
import { ChatContext } from '../../helpers/chatContext';
import { WrappedMessage } from './WrappedMessage';

export default function CurrentMessage({
  isMobile,
  messagesEndRef,
  isScrolled,
}: {
  isMobile: boolean;
  messagesEndRef: RefObject<HTMLDivElement>;
  isScrolled: boolean;
}) {
  const { state } = useContext(ChatContext);
  useEffect(() => {
    if (messagesEndRef.current && !isScrolled) {
      messagesEndRef.current.scrollIntoView({ behavior: 'instant', block: 'end' });
    }
  }, [state.waitingResponse, state.currentStream, messagesEndRef, isScrolled]);

  return state.currentStream || state.waitingResponse ? (
    <WrappedMessage
      message={{
        role: 'assistant',
        content: [{ type: 'text', text: state.currentStream }],
        createdAt: null,
      }}
      waitingResponse={state.waitingResponse}
      isMobile={isMobile}
    />
  ) : null;
}
