import { Stack } from '@mui/material';
import { TrainingLesson } from 'src/store/api/trainings/trainingLessonsApi';
import FilteredLessons from './FilteredLessons';
import AddingLessonButton from './AddingLessonButton';

interface Props {
  filteredLessons: TrainingLesson[];
  allLessons: TrainingLesson[];
  readOnly: boolean;
  id: string;
  dataCy?: string;
  mobileView: boolean;
  onAddLessonCallback?: (lessonId: string) => void;
}

export default function Lessons({
  id,
  dataCy,
  filteredLessons,
  readOnly,
  mobileView,
  allLessons,
  onAddLessonCallback,
}: Props) {
  return (
    <Stack id={`section-lessons-${id}`} direction="column">
      <FilteredLessons
        dataCy={dataCy}
        filteredLessons={filteredLessons}
        readOnly={readOnly}
        mobileView={mobileView}
        id={id}
      />
      {!readOnly && (
        <AddingLessonButton
          id={id}
          allLessons={allLessons}
          onAddLessonCallback={onAddLessonCallback}
        />
      )}
    </Stack>
  );
}
