import { Box } from '@mui/material';

export default function RowDashedVerticalLines() {
  const lines = (
    <Box
      sx={{
        border: '1px dashed',
        borderRightColor: 'divider',
        borderLeftColor: 'divider',
        borderBottom: 'none',
        borderTop: 'none',
        width: 16,
      }}
    />
  );

  return (
    <Box
      position="absolute"
      left={0}
      right={0}
      bottom={0}
      top={0}
      display="flex"
      zIndex={10}
      justifyContent="space-evenly"
      sx={{
        pointerEvents: 'none',
      }}
    >
      {lines}
      {lines}
      {lines}
    </Box>
  );
}
