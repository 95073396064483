import { Box, InputAdornment, Paper, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbaseEditIcon, WorkbaseLessonIcon } from 'src/assets/icons/workbaseIcons';
import { useNavigate } from 'react-router-dom';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import useSingleModuleResponsivness from 'src/features/editor/view/grid/widgets/trainings-item/modules/useSingleModuleResponsivness';
import useVerticalOrderedDnD from 'src/lib/hooks/useVerticalOrderedDnD';
import DndPreview from 'src/lib/components/util/DndPreviewWrapper';
import {
  TrainingModuleWithStatus,
  useMoveTrainingModuleToNewPositionMutation,
  usePatchTrainingModuleMutation,
} from 'src/store/api/trainings/trainingModulesApi';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';
import debounce from 'lodash.debounce';
import DraftModeMenu from 'src/core/DraftModeMenu';
import DndIndicator from 'src/ui-components/custom/dnd-indicator/DndIndicator';
import ModuleActions from 'src/core/ModuleActions';
import BrandedDefaultImage from 'src/ui-components/branded-default-image';
import StartCompleteProgress from 'src/ui-components/custom/progress/StartCompleteProgress';
import generateJitteredKeyBetween from 'src/lib/utils/generateJitteredKeyBetween';

interface Props extends Omit<TrainingModuleWithStatus, 'order' | 'position'> {
  index?: number;
  withDragLayer?: boolean;
  modules: TrainingModuleWithStatus[];
  estimatedTime: number;
  progress: number;
}

const draggingItemType = 'editing-module-item';

export default function EditingSingleModule({
  name,
  imageUrl,
  id,
  status,
  index = 0,
  withDragLayer = true,
  trainingId,
  modules,
  lessonsCount,
  sectionsCount,
  estimatedTime,
  progress,
  defaultImage,
}: Props) {
  const { t } = useTranslation();
  const [localModuleName, setLocalModuleName] = useState(name);

  const [patchModule] = usePatchTrainingModuleMutation();
  const [moveModuleToNewPosition] = useMoveTrainingModuleToNewPositionMutation();

  const { cardRef, isSmallVersion } = useSingleModuleResponsivness();
  const draggingRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const lessonsCountLabel = t('lesson', { count: lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: estimatedTime || 0 });
  useEffect(() => {
    setLocalModuleName(name);
  }, [name]);

  const { isDragging, hoverState, draggingStyles, previewRef } = useVerticalOrderedDnD({
    itemData: { id: id as string, index },
    moveItem: ({ id: i, index: hoverIndex }) => {
      if (i !== id) {
        const movedModuleIdx = modules.findIndex((module) => module.id === i);
        const aboveModule = hoverIndex > 0 ? modules[hoverIndex - 1] : null;
        const belowModule = hoverIndex < modules.length - 1 ? modules[hoverIndex + 1] : null;

        const aboveOrder = aboveModule ? (aboveModule.position as string) : null;
        const belowOrder = belowModule ? (belowModule.position as string) : null;

        const newPosition = generateJitteredKeyBetween(aboveOrder, belowOrder);

        moveModuleToNewPosition({
          trainingId: trainingId as string,
          moduleId: i,
          order: movedModuleIdx < hoverIndex ? hoverIndex : hoverIndex + 1,
          position: newPosition,
        });
      }
    },
    accept: draggingItemType,
    ref: draggingRef,
  });

  const imgChange = async (file: File): Promise<void> => {
    try {
      const response = await imageUpload(file);
      await patchModule({
        module: {
          trainingId: trainingId as string,
          id: id as string,
          imageId: response.data.result.id,
        },
        imageSrc: response.data.result.variants[0],
      });
    } catch (_err) {
      showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
    }
  };

  const removeImage = async (): Promise<void> => {
    await patchModule({
      module: { trainingId: trainingId as string, id: id as string, imageId: null },
      imageSrc: '',
    });
  };
  const dndIndicatorProps = hoverState.isOverUpperHalf ? { top: -12 } : { bottom: -12 };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedModuleTitleChange = useCallback(
    debounce((val: string) => {
      patchModule({
        module: {
          trainingId: trainingId as string,
          id: id as string,
          name: val,
        },
      });
    }, 1000),
    [trainingId, patchModule]
  );

  const actionsWithProgress = (
    <Stack
      direction="row"
      gap={isSmallVersion ? 1 : 2}
      alignItems="center"
      justifyContent="center"
      onClick={(e) => e.stopPropagation()}
      ml={isSmallVersion ? 'auto' : undefined}
    >
      <DraftModeMenu
        dataCy="editing-single-module"
        status={status}
        onStatusChange={(newStatus) => {
          patchModule({
            module: {
              status: newStatus,
              id,
              trainingId,
            },
          });
        }}
      />
      {id && (
        <ModuleActions moduleId={id} lessonsCount={lessonsCount} sectionsCount={sectionsCount} />
      )}
      <StartCompleteProgress value={progress || 0} />
    </Stack>
  );

  return (
    <Box
      pb={2.5}
      ref={draggingRef}
      onClick={() => {
        navigate(`/trainings/${trainingId}/${id}/edit`, { relative: 'route' });
      }}
    >
      <Paper
        ref={cardRef}
        variant="outlined"
        component={Box}
        width="100%"
        position="relative"
        overflow="visible"
        sx={{
          cursor: 'grab',
          '&:hover .dnd-button': {
            opacity: 1,
          },
        }}
      >
        {hoverState.isHovered && <DndIndicator {...dndIndicatorProps} />}
        {withDragLayer && (
          <DndPreview previewRef={previewRef}>
            <EditingSingleModule
              name={name}
              imageUrl={imageUrl}
              id={id}
              status={status}
              withDragLayer={false}
              trainingId={trainingId}
              modules={modules}
              lessonsCount={lessonsCount}
              sectionsCount={sectionsCount}
              estimatedTime={estimatedTime}
              progress={progress}
              defaultImage={defaultImage}
            />
          </DndPreview>
        )}
        <Stack
          spacing={2.5}
          direction={isSmallVersion ? 'column' : 'row'}
          p={isSmallVersion ? 1 : 2}
          width="100%"
          sx={{
            opacity: isDragging ? draggingStyles.placeholderOpacity : 1,
          }}
        >
          <Box
            sx={{
              height: isSmallVersion ? '150px' : '90px',
              width: isSmallVersion ? '100%' : '150px',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <EditableImageUpload
              dataCy="editing-single-module-upload-avatar"
              onChange={imgChange}
              src={imageUrl || undefined}
              placeholder={
                <BrandedDefaultImage defaultImage={defaultImage} dataCy="editing-single-module" />
              }
              onClear={removeImage}
            />
          </Box>
          <Stack
            direction={isSmallVersion ? 'column' : 'row'}
            width="100%"
            alignItems={isSmallVersion ? 'flex-start' : 'center'}
            justifyContent="space-between"
            gap={2}
          >
            <Stack width="100%" maxWidth="sm" gap={1} id={withDragLayer ? id : undefined}>
              <TextField
                value={localModuleName}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setLocalModuleName(e.target.value);
                  debouncedModuleTitleChange(e.target.value);
                }}
                fullWidth
                placeholder={t('editor.trainingsSection.moduleTitlePlaceholder')}
                onDragStart={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                slotProps={{
                  input: {
                    draggable: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SvgIcon component={WorkbaseEditIcon} fontSize="small" />
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <Stack direction="row" gap={1} alignItems="center">
                <SvgIcon component={WorkbaseLessonIcon} fontSize="small" />
                <Typography variant="body1">
                  {lessonsCountLabel} – {estimatedTimeLabel}
                </Typography>
                {isSmallVersion && actionsWithProgress}
              </Stack>
            </Stack>
            {!isSmallVersion && actionsWithProgress}
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}
