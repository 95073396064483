import { Button, SvgIcon } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbaseDownloadIcon } from 'src/assets/icons/workbaseIcons';

let deferredPrompt: any;

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  deferredPrompt = e;
  window.dispatchEvent(new CustomEvent('installpromptavailable'));
});

export default function InstallAppButton() {
  const [isInstallable, setIsInstallable] = useState(!!deferredPrompt);
  const { t } = useTranslation();

  useEffect(() => {
    const handleInstallPromptAvailable = () => {
      setIsInstallable(true);
    };

    window.addEventListener('installpromptavailable', handleInstallPromptAvailable);

    return () => {
      window.removeEventListener('installpromptavailable', handleInstallPromptAvailable);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      await deferredPrompt.userChoice;
      deferredPrompt = null;
      setIsInstallable(false);
    }
  };

  if (!isInstallable) {
    return null;
  }

  return (
    <Button
      data-cy="user-profile-menu-install-app-button"
      color="inherit"
      endIcon={<SvgIcon component={WorkbaseDownloadIcon} />}
      onClick={handleInstallClick}
      fullWidth
    >
      {t('installApp.buttonTriggerText')}
    </Button>
  );
}
