import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import replaceParamsInLink from '../helpers';
import { Box } from '@mui/material';
import ActionButtons from './action-button/ActionButtons';
import { useRef, useState } from 'react';

interface Props {
  src: string;
}

export default function Iframe({ src }: Props) {
  const { data: user } = useCurrentUserQuery();
  const link = replaceParamsInLink(src, user);
  const [historyStackLength, setHistoryStackLength] = useState<number>(-1);
  const [currentHistoryStackIndex, setCurrentHistoryStackIndex] = useState<number>(0);
  const [key, setKey] = useState(0);

  const shouldIframeUpdate = useRef(true);
  const iframeLoading = useRef(true);

  const goBack = () => {
    if (currentHistoryStackIndex !== 0 && !iframeLoading.current) {
      setCurrentHistoryStackIndex((prevIndex) => prevIndex - 1);
      shouldIframeUpdate.current = false;
      iframeLoading.current = true;
      window.history.back();
    }
  };

  const goForward = () => {
    if (!iframeLoading.current && historyStackLength !== currentHistoryStackIndex) {
      setCurrentHistoryStackIndex((prevIndex) => prevIndex + 1);
      shouldIframeUpdate.current = false;
      iframeLoading.current = true;
      window.history.forward();
    }
  };

  const reloadIframe = () => {
    if (!iframeLoading.current) {
      setCurrentHistoryStackIndex(0);
      setHistoryStackLength(0);
      shouldIframeUpdate.current = false;
      setKey((prevKey) => prevKey + 1);
      iframeLoading.current = true;
    }
  };

  const handleIframeLoad = () => {
    if (shouldIframeUpdate.current) {
      setHistoryStackLength((prevLength) => prevLength + 1);
      if (historyStackLength >= 0) {
        setCurrentHistoryStackIndex((prevIndex) => prevIndex + 1);
      }
      if (currentHistoryStackIndex < historyStackLength) {
        setHistoryStackLength(currentHistoryStackIndex + 1);
      }
    }
    shouldIframeUpdate.current = true;
    iframeLoading.current = false;
  };

  return (
    <>
      <object
        key={key}
        onLoad={handleIframeLoad}
        data={link}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      />

      <Box
        position="absolute"
        left="50%"
        bottom={30}
        zIndex={1000}
        sx={{ transform: 'translateX(-50%)' }}
      >
        <ActionButtons goBack={goBack} goForward={goForward} reloadIframe={reloadIframe} />
      </Box>
    </>
  );
}
