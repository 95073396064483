export default function getVideoAspectRatio(file: File): Promise<number> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const url = URL.createObjectURL(file);
    video.src = url;

    const onError = () => {
      reject(new Error('Error loading video metadata'));
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      video.removeEventListener('loadedmetadata', onMetadataLoaded);
      video.removeEventListener('error', onError);
      URL.revokeObjectURL(url);
    };

    const onMetadataLoaded = () => {
      const width = video.videoWidth;
      const height = video.videoHeight;
      const aspectRatio = width / height;

      URL.revokeObjectURL(url);
      video.removeEventListener('loadedmetadata', onMetadataLoaded);
      video.removeEventListener('error', onError);

      video.src = '';
      video.load();

      resolve(aspectRatio);
    };

    video.addEventListener('loadedmetadata', onMetadataLoaded);
    video.addEventListener('error', onError);
  });
}
