import { Box, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseRecordIcon } from 'src/assets/icons/workbaseIcons';

export default function RecordingPlaceholder() {
  const { t } = useTranslation();
  return (
    <Container component={Box} height="100%" maxWidth="xs">
      <Stack gap={0.75} alignItems="center" justifyContent="center" height="100%">
        <SvgIcon component={WorkbaseRecordIcon} fontSize="large" color="error" />
        <Typography color="primary" variant="subtitle1" align="center">
          {t('recording.noDevicePlaceholder.title')}
        </Typography>
        <Typography color="textSecondary" variant="body1" align="center">
          {t('recording.noDevicePlaceholder.description')}
        </Typography>
      </Stack>
    </Container>
  );
}
