import { useTranslation } from 'react-i18next';
import { UUID } from 'uuidjs';
import { TopBarWithIcons } from 'src/ui-components/layout/top-bar';
import AwarenessAvatars from 'src/features/awareness-avatars';
import EmbeddedItemModal from 'src/features/editor/view/grid/widgets/embedded/modal';
import { useEditor } from '../../controller/EditorContext';
import HistoryItems from './HistoryItems';
import useBaseEditorTopBarItems from './useBaseEditorTopBarItems';

export interface EditorTopBarProps {
  rightContent?: React.ReactNode;
}

export default function EditorTopBar({ rightContent }: EditorTopBarProps) {
  const { t } = useTranslation();
  const { handleAddItem } = useEditor();
  const { baseEditorTopBarItems, addEmbeddedDialogOpen, setAddEmbeddedDialogOpen } =
    useBaseEditorTopBarItems();

  return (
    <>
      <EmbeddedItemModal
        open={addEmbeddedDialogOpen}
        onClose={() => setAddEmbeddedDialogOpen(false)}
        saveButtonText={t('editor.embeddedItemModal.addConfirmButton')}
        onConfirm={({ content, contentType }) => {
          handleAddItem({
            id: UUID.generate(),
            columnEnd: 5,
            columnStart: 1,
            elementType: 'embedded',
            content,
            contentType,
          });
        }}
      />
      <TopBarWithIcons
        icons={baseEditorTopBarItems}
        rightContent={
          <>
            <AwarenessAvatars />
            <HistoryItems />
            {rightContent}
          </>
        }
      />
    </>
  );
}
