import axiosInstance from 'src/axios';

async function downloadVideo(id: string, fileName: string) {
  const response = await axiosInstance({
    url: `videos/${id}/download`,
    method: 'GET',
    responseType: 'blob',
  });

  const href = URL.createObjectURL(response.data);

  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.download = fileName;

  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
}

export default downloadVideo;
