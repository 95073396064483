import { Container, IconButton, Popover, Stack, ToggleButtonProps } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import SvgIconOrEmoji from 'src/ui-components/icon/SvgIconOrEmoji';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import useDebouncedValue from 'src/hooks/useDebouncedValue';
import IconSelectorTabContext from './IconSelectorTabContext';
import useEmojiData from './hooks/use-emojidata';
import { SearchTextField } from '../textfield/search';

export interface IconOrEmojiSelectorProps {
  value: string;
  onChange: (selectedIcon: string) => void;
  buttonSize?: ToggleButtonProps['size'];
  dataCy?: string;
}

function IconOrEmojiSelector({
  value,
  onChange,
  buttonSize = 'medium',
  dataCy,
}: IconOrEmojiSelectorProps) {
  const selectorState = usePopupState({ variant: 'popover', popupId: 'icon-selector' });
  const [searchVal, setSearchVal] = useState<string>('');
  const emojiData = useEmojiData();

  useEffect(() => {
    setSearchVal('');
  }, [selectorState.isOpen]);

  const handleIconOrEmojiClick = (newValue: string) => {
    onChange?.(newValue);
    selectorState.close();
  };

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchVal(e.target.value);
    },
    [setSearchVal]
  );

  const debouncedData = useDebouncedValue('', handleSearchChange);

  return (
    <>
      <IconButton
        data-cy={dataCy && `${dataCy}-icon-or-emoji-menu-button`}
        value="icon-or-emoji-selector-menu-button"
        size={buttonSize}
        variant="solid"
        color="default"
        {...bindTrigger(selectorState)}
      >
        <SvgIconOrEmoji content={value} fontSize="medium" />
      </IconButton>
      <Popover
        {...bindPopover(selectorState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Stack pt={2}>
          <Container maxWidth="xs">
            <SearchTextField
              data-cy={dataCy && `${dataCy}-search-textfield`}
              value={debouncedData.value}
              autoFocus
              fullWidth
              size="small"
              onChange={debouncedData.handleChange}
            />
            <IconSelectorTabContext
              dataCy={dataCy}
              emojiData={emojiData}
              icon={value}
              onIconClick={handleIconOrEmojiClick}
              clearSearchVal={() => setSearchVal('')}
              searchVal={searchVal}
            />
          </Container>
        </Stack>
      </Popover>
    </>
  );
}

export default IconOrEmojiSelector;
