import { Components, Theme } from '@mui/material';

const MuiTabs: Components<Theme>['MuiTabs'] = {
  styleOverrides: {
    indicator: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
    }),
  },
};

export default MuiTabs;
