import { Components, Theme } from '@mui/material';

const MuiListItemText: Components<Theme>['MuiListItemText'] = {
  defaultProps: {
    primaryTypographyProps: {
      noWrap: true,
      fontWeight: 'medium',
    },
  },
};

export default MuiListItemText;
