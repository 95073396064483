import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDateFromTimeStamp } from 'src/lib/utils/format-time';
import { Document } from 'src/store/api/driveApi';
import { useTranslation } from 'react-i18next';
import Empty from 'src/ui-components/custom/empty/Empty';
import { ShareLevel } from 'src/lib/types/share-level';
import ShareModal from 'src/core/share-modal/ShareModal';
import useIsMobile from 'src/hooks/useIsMobile';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import DocumentsActions from './DocumentsAction';
import DocumentNameCell from './DocumentNameCell';
import getManagerTitle from './getManagerTitle';
import DocumentsSwitch from './DocumentsSwitch';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';

interface DocumentsTableProps {
  rows: Document[];
}

function DocumentsTable({ rows }: DocumentsTableProps) {
  const { t } = useTranslation();

  const colsData: { name: string; cellProps?: Partial<TableCellProps> }[] = [
    { name: t('documentsPage.table.columnNames.name'), cellProps: { align: 'left' } },
    { name: t('documentsPage.table.columnNames.manager'), cellProps: { align: 'center' } },
    {
      name: t('documentsPage.table.columnNames.lastModified'),
      cellProps: { align: 'center' },
    },
    { name: t('documentsPage.table.columnNames.status'), cellProps: { align: 'center' } },
    { name: t('documentsPage.table.columnNames.actions'), cellProps: { align: 'center' } },
  ];

  const isMobile = useIsMobile();
  const hasAnyPermission = useHasAnyPermission();
  const havePermissionsToAddContent = hasAnyPermission([
    PermissionCodes.MANAGE_EMPLOYEES,
    PermissionCodes.ADD_PAGES,
    PermissionCodes.ADD_EMBEDDED_APPS,
    PermissionCodes.ADD_TRAININGS,
    PermissionCodes.ADD_WHITEBOARDS,
  ]);

  if (rows.length === 0)
    return (
      <Empty description={t('documentsPage.noDocumentsPlaceholder')}>
        <Typography color="textSecondary" variant="body1" align="center">
          {havePermissionsToAddContent
            ? t('documentsPage.noDocumentsPlaceholderText')
            : t('documentsPage.noPermissionsPlaceholderText')}
        </Typography>
      </Empty>
    );

  return (
    <TableContainer component={Paper} variant="outlined" sx={{ flexGrow: 1 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {colsData.map(({ name, cellProps }) => (
              <TableCell key={name} {...cellProps}>
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 ? (
            <TableRow>
              <TableCell colSpan={colsData.length} align="center">
                <Empty />
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Box sx={{ width: '100%', maxWidth: isMobile ? 200 : 400 }}>
                    <DocumentNameCell
                      icon={row.icon}
                      title={row.name}
                      sharedWithOthers={row.sharedWithOthers}
                      resourceType={row.resourceType}
                      id={row.id}
                    />
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box maxWidth={200}>{getManagerTitle(row, t)}</Box>
                </TableCell>
                <TableCell align="center">{formatDateFromTimeStamp(row.updatedAt)}</TableCell>
                <TableCell align="center">
                  <DocumentsSwitch
                    checkedInit={row.sidebar}
                    disabled={row.shareLevel === ShareLevel.view}
                    id={row.id}
                    resourceType={row.resourceType}
                  />
                </TableCell>
                <TableCell align="center">
                  <Stack direction="row" justifyContent="flex-end" gap={2}>
                    <ShareModal
                      name={row.name}
                      resourceId={row.id as string}
                      resourceType={row.resourceType}
                      shareLevel={row.shareLevel}
                      dataCy="documents-table"
                    />
                    <DocumentsActions id={row.id} resourceType={row.resourceType} />
                  </Stack>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DocumentsTable;
