import { IconButton, Stack, SvgIcon, Tooltip } from '@mui/material';
import { useState } from 'react';
import RecommendationPopup from 'src/features/recommendation-popup';
import useActionButtonsData from './useActionButtonsData';

function ActionButtons() {
  const [isRecommendationPopupActive, setIsRecommendationPopupActive] = useState(false);
  const data = useActionButtonsData(() => setIsRecommendationPopupActive(true));
  return (
    <Stack direction="row" gap={2} alignItems="center">
      {data.map((item) => (
        <Tooltip title={item.tooltip} key={item.key} disableFocusListener>
          <IconButton
            value={item.key}
            disableFocusRipple
            size="large"
            disableTouchRipple
            onClick={item.onClick}
            data-cy={`nav-icon-button-${item.key}`}
          >
            <SvgIcon component={item.icon.icon} />
          </IconButton>
        </Tooltip>
      ))}
      <RecommendationPopup
        active={isRecommendationPopupActive}
        setActive={setIsRecommendationPopupActive}
        dataCy="employee-recommendation"
      />
    </Stack>
  );
}

export default ActionButtons;
