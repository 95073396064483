import { Button, Stack, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseCompaniesIcon, WorkbaseLogoutIcon } from 'src/assets/icons/workbaseIcons';
import { AUTH_URL } from 'src/global-config';

export default function LogoutBtns({
  accessToMultipleCompanies,
  dataCy,
}: {
  accessToMultipleCompanies: boolean;
  dataCy?: string;
}) {
  const { t } = useTranslation();

  const menuData = [
    {
      key: 'logout',
      icon: { icon: WorkbaseLogoutIcon },
      onClick: () => {
        localStorage.removeItem('persist:auth');
        window.location.replace(`${AUTH_URL}/logout`);
      },
      text: t('generalSettings.actions.signOut'),
    },
  ];

  if (accessToMultipleCompanies) {
    menuData.push({
      key: 'switch-company',
      icon: { icon: WorkbaseCompaniesIcon },
      onClick: () => {
        window.location.replace(AUTH_URL);
      },
      text: t('generalSettings.actions.switchCompany'),
    });
  }

  return (
    <Stack gap={1.5} marginTop="auto">
      {menuData.map(({ key, icon, onClick, text }) => (
        <Button
          color="inherit"
          key={key}
          data-cy={dataCy && `${dataCy}-${key}-button`}
          onClick={onClick}
          endIcon={<SvgIcon component={icon.icon} fontSize="small" />}
        >
          {text}
        </Button>
      ))}
    </Stack>
  );
}
