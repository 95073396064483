import { Components, Theme } from '@mui/material';

const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontWeight: '500',
      borderRadius: theme.shape.borderRadius,
    }),
  },
};

export default MuiChip;
