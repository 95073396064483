import { DropOptions, NodeModel } from '@minoru/react-dnd-treeview';
import { TreeNodeData } from './types';

interface Props {
  tree: NodeModel<TreeNodeData>[];
  toggle: (id: number | string) => void;
  openedNodes: (number | string)[];
  onDrop: (tree: NodeModel<TreeNodeData>[], options: DropOptions<TreeNodeData>) => void;
}

export default function useOnDrop({ tree, toggle, openedNodes, onDrop }: Props) {
  const isDroppingIntoItself = (options: DropOptions<TreeNodeData>) =>
    options.dropTargetId === options.dragSourceId;

  const getSiblings = (parentId: string | number | null) =>
    tree.filter((node) => node.parent === parentId);

  const handleReorder = (
    newTree: NodeModel<TreeNodeData>[],
    options: DropOptions<TreeNodeData>
  ) => {
    const draggedNodeIndex = newTree.findIndex((node) => node.id === options.dragSourceId);
    const draggedNode = newTree[draggedNodeIndex];

    const filteredTree = newTree.filter((node) => node.id !== options.dragSourceId);

    const parentId = options.dropTarget?.parent || options.dropTargetId;
    const siblings = getSiblings(parentId);
    const siblingIndex = options.relativeIndex ?? siblings.length;

    filteredTree.splice(siblingIndex, 0, { ...draggedNode, parent: parentId });

    onDrop(filteredTree, options);
  };

  const handleDropIntoSubfolder = (
    newTree: NodeModel<TreeNodeData>[],
    options: DropOptions<TreeNodeData>
  ) => {
    const draggedNodeIndex = newTree.findIndex((node) => node.id === options.dragSourceId);
    const draggedNode = newTree[draggedNodeIndex];

    const filteredTree = newTree.filter((node) => node.id !== options.dragSourceId);

    const siblings = getSiblings(options.dropTargetId);
    const destinationIndex = options.destinationIndex ?? siblings.length;
    filteredTree.splice(destinationIndex, 0, { ...draggedNode, parent: options.dropTargetId });

    onDrop(filteredTree, options);

    if (!openedNodes.includes(options.dropTargetId)) {
      toggle(options.dropTargetId);
    }
  };

  const handleDrop = (newTree: NodeModel<TreeNodeData>[], options: DropOptions<TreeNodeData>) => {
    if (isDroppingIntoItself(options)) return;
    if (options.dropTarget) {
      handleDropIntoSubfolder(newTree, options);
    } else {
      handleReorder(newTree, options);
    }
  };

  return handleDrop;
}
