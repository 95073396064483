import ListItemButton from '@mui/material/ListItemButton';
import { matchPath, PathPattern, Link as RouterLink, useLocation } from 'react-router-dom';
import { ListItemIcon, ListItemText, SvgIcon } from '@mui/material';

interface Props {
  item: ItemData;
  labelVisible: boolean;
}

interface ItemData {
  title: string;
  to: string;
  icon: React.ElementType;
  pattern: PathPattern;
  id: string;
}

export default function NavItem({ item, labelVisible }: Props) {
  const { pathname } = useLocation();
  const { title, to, pattern, icon, id } = item;
  const isActive = !!matchPath(pattern, pathname);
  return (
    <ListItemButton
      data-cy={`sidebarNav.${id}`}
      dense
      selected={isActive}
      component={RouterLink}
      to={to}
      disableTouchRipple
    >
      <ListItemIcon>
        <SvgIcon fontSize="small" component={icon} color="inherit" />
      </ListItemIcon>
      <ListItemText
        sx={{ visibility: labelVisible ? 'visible' : 'hidden' }}
        primaryTypographyProps={{ noWrap: true, fontWeight: 'medium' }}
        primary={title}
      />
    </ListItemButton>
  );
}
