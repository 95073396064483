import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { API_URL } from 'src/global-config';

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as any;
    const token = state.auth.token;
    const currentPortal = state.userPortal.currentPortal;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    if (currentPortal) {
      headers.set('portal-mode', currentPortal);
    }
    return headers;
  },
});
