import { Components, Theme } from '@mui/material';

const MuiCardHeader: Components<Theme>['MuiCardHeader'] = {
  styleOverrides: {
    root: {
      padding: 20,
    },
  },
};

export default MuiCardHeader;
