import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UUID } from 'uuidjs';
import { CustomerGroup } from './types';

interface State {
  customerGroupsState: CustomerGroup[];
  checkedGroups: string[];
}

const initialState: State = {
  customerGroupsState: [],
  checkedGroups: [],
};

export const customerGroupsSlice = createSlice({
  name: 'customerGroupsSlice',
  initialState,
  reducers: {
    addPermissionToCustomerGroups: (
      state,
      action: PayloadAction<{ permissionToAdd: string; groupIds: string[] }>
    ) => {
      state.customerGroupsState = state.customerGroupsState.map((group) => ({
        ...group,
        permissionIds: action.payload.groupIds.includes(group.id)
          ? Array.from(new Set([...group.permissionIds, action.payload.permissionToAdd]))
          : group.permissionIds,
      }));
    },
    removePermissionFromCustomerGroups: (
      state,
      action: PayloadAction<{ permissionToRemove: string; groupIds: string[] }>
    ) => {
      state.customerGroupsState = state.customerGroupsState.map((group) => ({
        ...group,
        permissionIds: action.payload.groupIds.includes(group.id)
          ? group.permissionIds.filter((id) => id !== action.payload.permissionToRemove)
          : group.permissionIds,
      }));
    },
    addUserToCustomerGroup: (state, action: PayloadAction<{ groupId: string; userId: string }>) => {
      state.customerGroupsState = state.customerGroupsState.map((group) => ({
        ...group,
        customerIds:
          group.id === action.payload.groupId
            ? Array.from(new Set([...group.customerIds, action.payload.userId]))
            : group.customerIds,
      }));
    },
    removeUserFromCustomerGroup: (
      state,
      action: PayloadAction<{ groupId: string; userId: string }>
    ) => {
      state.customerGroupsState = state.customerGroupsState.map((group) => ({
        ...group,
        customerIds:
          group.id === action.payload.groupId
            ? group.customerIds.filter((id) => id !== action.payload.userId)
            : group.customerIds,
      }));
    },
    checkCustomerGroups: (state, action: PayloadAction<string[]>) => {
      const uniqueGroupIds = Array.from(new Set(action.payload));
      state.checkedGroups.push(...uniqueGroupIds.filter((id) => !state.checkedGroups.includes(id)));
    },
    uncheckCustomerGroups: (state, action: PayloadAction<string[]>) => {
      state.checkedGroups = state.checkedGroups.filter((id) => !action.payload.includes(id));
    },
    clearCheckedGroups: (state) => {
      state.checkedGroups = [];
    },
    setCustomerGroups: (state, action: PayloadAction<CustomerGroup[]>) => {
      state.customerGroupsState = action.payload;
    },
    addCustomerGroup: (state) => {
      const newGroup: CustomerGroup = {
        id: UUID.generate(),
        name: '',
        customerIds: [],
        permissionIds: [],
      };
      state.customerGroupsState.push(newGroup);
    },
    editCustomerGroupName: (
      state,
      action: PayloadAction<{ groupId: string; groupName: string }>
    ) => {
      const group = state.customerGroupsState.find((g) => g.id === action.payload.groupId);
      if (group) {
        group.name = action.payload.groupName;
      }
    },
    deleteCustomerGroup: (state, action: PayloadAction<string>) => {
      state.customerGroupsState = state.customerGroupsState.filter(
        (group) => group.id !== action.payload
      );
    },
    moveCustomerGroup: (
      state,
      action: PayloadAction<{ sourceIndex: number; destinationIndex: number }>
    ) => {
      const { sourceIndex, destinationIndex } = action.payload;
      const [movedGroup] = state.customerGroupsState.splice(sourceIndex, 1);
      state.customerGroupsState.splice(destinationIndex, 0, movedGroup);
    },
  },
});

export const {
  addPermissionToCustomerGroups,
  removePermissionFromCustomerGroups,
  addUserToCustomerGroup,
  removeUserFromCustomerGroup,
  checkCustomerGroups,
  uncheckCustomerGroups,
  clearCheckedGroups,
  setCustomerGroups,
  addCustomerGroup,
  editCustomerGroupName,
  deleteCustomerGroup,
  moveCustomerGroup,
} = customerGroupsSlice.actions;
