import { Box, TextField } from '@mui/material';
import validateUrl from 'src/lib/utils/validateUrl';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import { useEmbeddedAppQuery, usePatchEmbeddedAppMutation } from 'src/store/api/embeddedAppsApi';
import IconOrEmojiSelector from 'src/ui-components/custom/icon-selector/IconOrEmojiSelector';
import useDebouncedValue from 'src/hooks/useDebouncedValue';
import EmbeddedLinkValidationTextField, {
  ValidationResult,
} from './EmbeddedLinkValidationTextField';
import LinkParametersTooltip from './LinkParametersTooltip';

interface Props {
  id: string;
}

function EmbeddedAppInputs({ id }: Props) {
  const { t } = useTranslation();
  const { data } = useEmbeddedAppQuery(id);
  const [patchEmbeddedApp] = usePatchEmbeddedAppMutation();

  const onSubmitUrlInput = useCallback(
    async (val: string): Promise<ValidationResult> => {
      try {
        const resp: any = await patchEmbeddedApp({ id, url: ensureHttpProtocol(val) });

        if (resp?.error) {
          return { isError: true, message: t('cantBeEmbedded') };
        }
        return { isError: false };
      } catch (_error) {
        return { isError: true, message: t('invalidURL') };
      }
    },
    [patchEmbeddedApp, id, t]
  );

  const handleValidateFunction = (str: string) => ({
    isError: !validateUrl(str),
    message: !validateUrl(str) ? t('invalidURL') : '',
  });

  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (val !== data?.name) {
        patchEmbeddedApp({ id, name: val });
      }
    },
    [patchEmbeddedApp, data?.name, id]
  );

  const { value, handleChange } = useDebouncedValue(data?.name, handleNameChange);

  return (
    <>
      {data && (
        <IconOrEmojiSelector
          dataCy="embedded-app"
          value={data?.icon}
          buttonSize="small"
          onChange={(i) => {
            patchEmbeddedApp({ id, icon: i });
          }}
        />
      )}
      <Box
        width={({ breakpoints }) => breakpoints.values.sm}
        minWidth={0}
        display="flex"
        flexDirection="row"
        gap={1}
      >
        <TextField
          autoFocus
          value={value}
          placeholder={t('embeddedAppPage.nameTextFieldPlaceholder')}
          size="small"
          fullWidth
          onChange={handleChange}
          data-cy="embedded-app-textfield"
        />
        <EmbeddedLinkValidationTextField
          placeholder={t('embeddedAppPage.urlTextFieldPlaceholder')}
          value={data?.url || ''}
          validateFn={handleValidateFunction}
          onSubmit={onSubmitUrlInput}
        />
        <LinkParametersTooltip />
      </Box>
    </>
  );
}

export default EmbeddedAppInputs;
