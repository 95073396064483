import { Components, Theme } from '@mui/material';

const MuiTabPanel: Components<Theme>['MuiTabPanel'] = {
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
};

export default MuiTabPanel;
