import { Box } from '@mui/material';
import { useSettingsQuery } from 'src/store/api/settingsApi';
import { useState, useEffect } from 'react';
import LottieLogoSpinner from './LottieLogoSpinner';

export default function LottieFullScreenSpinner() {
  const { isSuccess } = useSettingsQuery();
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setVisible(false);
    }, 1500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);
  return (
    <Box
      position="fixed"
      left={0}
      top={0}
      right={0}
      bottom={0}
      bgcolor="background.default"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? 'auto' : 'none',
        transition: (theme) =>
          theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
          }),
      }}
      zIndex={99999}
    >
      {isSuccess && <LottieLogoSpinner />}
    </Box>
  );
}
