import { RefObject, useRef } from 'react';
import {
  createVerticalStrength,
  createHorizontalStrength,
  useDndScrolling,
} from 'react-dnd-scrolling';

const useScrollOnDrag = (
  verticalStrengthValue = 150,
  horizontalStrengthValue = 150
): RefObject<HTMLDivElement> => {
  const scrollRef = useRef(null);
  const vStrength = createVerticalStrength(verticalStrengthValue);
  const hStrength = createHorizontalStrength(horizontalStrengthValue);
  useDndScrolling(scrollRef, { verticalStrength: vStrength, horizontalStrength: hStrength });

  return scrollRef;
};

export default useScrollOnDrag;
