import { UUID } from 'uuidjs';
import { useEditor } from '../../controller';
import { ElementType, GridItemType } from '../../model';

export default function useGenerateEditorTopBarItem() {
  const { handleAddItem } = useEditor();

  const generateEditorTopBarItem = ({
    key,
    tooltip,
    elementType,
    icon,
    content,
    extraProps = {},
    onClickCallback,
  }: {
    key: string;
    tooltip: string;
    elementType: ElementType;
    icon: React.ElementType;
    extraProps?: Partial<GridItemType>;
    content?: string;
    onClickCallback?: () => void;
  }) => ({
    key,
    onClick: () => {
      handleAddItem({
        id: UUID.generate(),
        columnEnd: 5,
        columnStart: 1,
        elementType,
        content: content as string,
        ...extraProps,
      } as GridItemType);
      setTimeout(() => {
        const lastGridItem = document.querySelector('.editor-grid > :nth-last-child(2)');
        const remirror = lastGridItem?.querySelector(
          '.remirror-editor'
        ) as HTMLButtonElement | null;
        if (remirror) return remirror.focus();

        const button = lastGridItem?.querySelector(
          '.editor-item-button'
        ) as HTMLButtonElement | null;
        button?.focus();
        onClickCallback?.();
        return undefined;
      });
    },
    tooltip,
    icon,
  });

  return generateEditorTopBarItem;
}
