import { Box, Chip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  isStopped: boolean;
}

export default function Timer({ isStopped }: Props) {
  const [time, setTime] = useState(0);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const tick = () => {
      setTime((prevTime) => prevTime + 1);
    };

    if (!isStopped) {
      timer = setInterval(tick, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isStopped]);

  const formatTime = (timeNum: number) => {
    const hours = String(Math.floor(timeNum / 3600)).padStart(2, '0');
    const minutes = String(Math.floor((timeNum % 3600) / 60)).padStart(2, '0');
    const seconds = String(timeNum % 60).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <Box position="absolute" bottom={20} right={20} width={110}>
      <Chip
        color="primary"
        size="medium"
        label={<Typography variant="h6">{formatTime(time)}</Typography>}
      />
    </Box>
  );
}
