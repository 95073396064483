import { createApi } from '@reduxjs/toolkit/query/react';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { t } from 'i18next';
import { baseQuery } from './api';

export interface ApiKey {
  id: string;
  name: string;
  apiKey?: string;
  createdAt: string;
  expiredAt: string;
  status: 'active' | 'expired';
}

export interface ApiKeyPostData {
  name: string;
  expiredAt?: string;
}

export const apiKeysApi = createApi({
  reducerPath: 'apiKeysApi',
  tagTypes: ['ApiKeys'],
  baseQuery,
  endpoints: (builder) => ({
    apiKeys: builder.query<ApiKey[], void>({
      query: () => 'authn/company-api-keys',
      providesTags: ['ApiKeys'],
    }),
    generateApiKey: builder.mutation<ApiKey, ApiKeyPostData>({
      query: (data: ApiKeyPostData) => ({
        url: 'authn/company-api-keys',
        body: data,
        method: 'POST',
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.catch(() => {
          showNotification(t('companySettings.apiKeys.apiKeyGenerationError'), 'error');
        });
      },
      invalidatesTags: ['ApiKeys'],
    }),
    deleteApiKey: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `authn/company-api-keys/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.catch(() => {
          showNotification(t('companySettings.apiKeys.deleteError'), 'error');
        });
      },
      invalidatesTags: ['ApiKeys'],
    }),
    editApiKey: builder.mutation<ApiKey, { id: string; data: ApiKeyPostData }>({
      query: ({ id, data }) => ({
        url: `authn/company-api-keys/${id}`,
        body: data,
        method: 'PATCH',
      }),
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            showNotification(t('companySettings.apiKeys.updateSuccess'), 'success');
          })
          .catch(() => {
            showNotification(t('companySettings.apiKeys.updateError'), 'error');
          });
      },
      invalidatesTags: ['ApiKeys'],
    }),
  }),
});

export const {
  useApiKeysQuery,
  useGenerateApiKeyMutation,
  useDeleteApiKeyMutation,
  useEditApiKeyMutation,
} = apiKeysApi;
