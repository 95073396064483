import { Box } from '@mui/material';
import BrandedHeader from 'src/ui-components/branded-header';
import { ResourceType, useLinksQuery } from 'src/store/api/linksApi';
import Links from 'src/core/links/Links';
import { EditableIconText } from './icon-text';

interface Props {
  readOnly: boolean;
  name?: string;
  icon: string;
  resourceId: string;
  resourceType: ResourceType;
  onIconChange: (icon: string) => void;
  onNameChange: (name: string) => void;
  placeholder: string;
  imageUrl?: string;
  onImageChange: (val: string | null) => void;
}

export default function LinksTitleHeader({
  readOnly,
  name,
  icon,
  onIconChange,
  onNameChange,
  resourceId,
  resourceType,
  placeholder,
  imageUrl,
  onImageChange,
}: Props) {
  const { data: links, isSuccess } = useLinksQuery({ resourceId, resourceType });
  const shouldRenderSubHeader = isSuccess && !(readOnly && links?.length === 0);

  return (
    <BrandedHeader
      subHeader={
        shouldRenderSubHeader && (
          <Links resourceType={resourceType} resourceId={resourceId} readOnly={readOnly} />
        )
      }
      stickyBottomContent={readOnly}
      backgroundImage={{
        url: imageUrl,
        onChange: onImageChange,
      }}
    >
      <Box margin="auto" width="80%">
        <EditableIconText
          placeholder={placeholder}
          text={name}
          isEditable={!readOnly}
          backdrop={!!imageUrl}
          icon={icon}
          textFieldProps={{
            fullWidth: true,
            color: imageUrl ? 'primary' : 'contrastText',
          }}
          onTextChange={onNameChange}
          onIconChange={onIconChange}
        />
      </Box>
    </BrandedHeader>
  );
}
