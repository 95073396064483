// Required config vars.
export const API_URL = import.meta.env.VITE_API_URL;
export const AUTH_URL = import.meta.env.VITE_AUTH_URL;

// Optional config vars.
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

if (!API_URL) throw new Error('VITE_API_URL is not defined');
if (!AUTH_URL) throw new Error('VITE_AUTH_URL is not defined');

export const NODE_ENV = process.env.NODE_ENV as 'development' | 'production';

export const MODE = import.meta.env.MODE as 'development' | 'production' | 'ephemeral' | 'localdev';
