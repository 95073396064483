import { Components, Theme } from '@mui/material';

const MuiListItem: Components<Theme>['MuiListItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      '.MuiListItem-gutters': {
        paddingLeft: 12,
        paddingRight: 12,
      },
      marginBottom: '2px',
      '&:last-child': {
        marginBottom: 0,
      },
    }),
  },
};

export default MuiListItem;
