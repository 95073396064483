import { ListItem, ListItemText, Stack } from '@mui/material';
import { ReactNode } from 'react';

interface AccessControlItemProps {
  prefix?: ReactNode;
  suffix?: ReactNode;
  primaryText: string | ReactNode;
  secondaryText?: string | ReactNode;
}

function AccessControlItem({ prefix, suffix, primaryText, secondaryText }: AccessControlItemProps) {
  return (
    <ListItem component={Stack} disableGutters direction="row" gap={1}>
      {prefix}
      <ListItemText
        primaryTypographyProps={{
          noWrap: true,
        }}
        secondaryTypographyProps={{
          noWrap: true,
        }}
        primary={primaryText}
        secondary={secondaryText}
      />
      <Stack direction="row" gap={1}>
        {suffix}
      </Stack>
    </ListItem>
  );
}

export default AccessControlItem;
