import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './api';

interface VideosApi {
  previewThumbnailVttUrl: string | null;
  chapters: string | null;
  transcriptions: Transcription[];
}

interface Transcription {
  languageCode: string;
  url: string;
}

interface VideoEncodingProgress {
  progress: number;
  status: 'upload_finished' | 'finished';
}

export const videosApi = createApi({
  reducerPath: 'videosApi',
  baseQuery,
  endpoints: (builder) => ({
    videoMetadata: builder.query<VideosApi, string>({
      query: (id) => ({
        url: `/videos/${id}`,
      }),
    }),
    videoEncodingProgress: builder.query<VideoEncodingProgress, string>({
      query: (id) => ({
        url: `/videos/${id}/encoding-progress`,
      }),
    }),
  }),
});

export const { useLazyVideoMetadataQuery, useLazyVideoEncodingProgressQuery } = videosApi;
