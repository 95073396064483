import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../api';
import { trainingLessonsApi } from './trainingLessonsApi';

export interface VideoProgress {
  watchedDuration: number;
  isWatched: boolean;
}

export const videoProgressApi = createApi({
  reducerPath: 'videoProgressApi',
  baseQuery,
  endpoints: (builder) => ({
    getVideoProgress: builder.query<VideoProgress, string>({
      query: (id) => ({
        url: `/videos/${id}/user-progress`,
      }),
    }),
    postVideoProgress: builder.mutation<
      void,
      { id: string; data: Partial<VideoProgress>; lessonId?: string }
    >({
      query: ({ id, data }) => ({
        url: `/videos/${id}/user-progress`,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: ({ id, data, lessonId }, { dispatch, queryFulfilled }) => {
        const disp = dispatch(
          videoProgressApi.util.updateQueryData('getVideoProgress', id, (draft) => ({
            ...draft,
            ...data,
          }))
        );
        queryFulfilled
          .then(() => {
            dispatch(
              trainingLessonsApi.util.invalidateTags([
                { type: 'TrainingLessonCanMarkAsDone', id: lessonId },
              ])
            );
          })
          .catch(disp.undo);
      },
    }),
  }),
});

export const { useGetVideoProgressQuery, usePostVideoProgressMutation } = videoProgressApi;
