import { Box } from '@mui/material';
import { memo } from 'react';
import useIsMobile from 'src/hooks/useIsMobile';
import Row from './Row';
import DroppableBottomRow from './droppable-items/DroppableBottomRow';
import OneColRow from './one-col-row/OneColRow';
import { GridRow } from '../../model/types';

interface GridProps {
  readOnly?: boolean;
  grid: GridRow[];
}

function Grid({ readOnly = false, grid }: GridProps) {
  const isMobile = useIsMobile();

  const lastRowIndex = (grid.length || 1) - 1;
  const lastGridItem = grid[lastRowIndex]?.items[(grid[lastRowIndex]?.items.length || 1) - 1]?.id;

  return (
    <Box
      className={`editor-grid ${isMobile ? 'editor-grid-one-col-view' : ''}`}
      mt={2}
      flexShrink={1}
      minHeight={0}
      flexGrow={1}
      mx={isMobile ? 0 : 1}
      maxHeight="100%"
      height="calc(100% + 64px)"
    >
      {grid.map((row, index) =>
        isMobile ? (
          <OneColRow key={row.id} row={row} readOnly={readOnly} />
        ) : (
          <Row key={row.id} row={row} index={index} readOnly={readOnly} />
        )
      )}
      <DroppableBottomRow
        oneColMode={isMobile}
        lastGridItemId={lastGridItem}
        lastRowIndex={lastRowIndex}
      />
    </Box>
  );
}

export default memo(Grid);
