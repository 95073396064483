import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, Link as RouterLink } from 'react-router-dom';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';
import { useTrainingLessonQuery } from 'src/store/api/trainings/trainingLessonsApi';
import { useGetSingleTrainingQuery } from 'src/store/api/trainings/trainingsApi';

interface Props {
  moduleName: string;
  dataCy: string;
}

export default function ModuleOverviewBreadcrumbs({ moduleName, dataCy }: Props) {
  const { t } = useTranslation();
  const params = useParams();
  const { lessonId, trainingId, moduleId } = params;
  const { data: trainingData } = useGetSingleTrainingQuery(trainingId as string);
  const { data: lessonData } = useTrainingLessonQuery(lessonId as string, {
    skip: lessonId === undefined,
  });

  const path = useMaintainEditPath();

  const lessonItem = lessonId
    ? [
        {
          key: 'lesson',
          label: lessonData?.name || t('modulePage.unnamedLesson'),
          to: `/trainings/${trainingId}/${moduleId}/${lessonData?.trainingSectionId}/${lessonId}`,
        },
      ]
    : [];

  const trainingsItem = !lessonId
    ? [
        {
          key: 'trainings',
          label: t('sidebar.tabTitleTrainings'),
          to: `/trainings${path}`,
        },
      ]
    : [];

  const data = [
    ...trainingsItem,
    {
      key: 'training',
      label: trainingData?.name || t('trainingDetailPage.unnamedTraining'),
      to: `/trainings/${trainingId}${path}`,
    },
    {
      key: 'module',
      label: moduleName || t('trainingDetailPage.unnamedModule'),
      to: null,
    },
    ...lessonItem,
  ];

  return (
    <Breadcrumbs>
      {data.map(({ key, label, to }) =>
        to ? (
          <Link
            key={key}
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={to}
            data-cy={dataCy && `${dataCy}-${key}`}
          >
            {label}
          </Link>
        ) : (
          <Typography key={key} color="textPrimary">
            {label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
}
