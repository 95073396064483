import { useRef } from 'react';
import { MediaPlayRequestEvent } from '@vidstack/react';

function useInitializePlayer(canPlay: boolean) {
  const canPlayVideoRef = useRef<boolean>(canPlay);

  const handlePlayRequest = (event: MediaPlayRequestEvent) => {
    if (!canPlayVideoRef.current) {
      event.preventDefault();
    }
  };

  const handleMouseDown = () => {
    canPlayVideoRef.current = canPlay;
  };

  return { handlePlayRequest, handleMouseDown };
}

export default useInitializePlayer;
