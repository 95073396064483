import { useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Stack } from '@mui/material';
import ItemDndWrapper from './ItemDndWrapper';
import { SidebarItem } from '../types';
import Item from './Item';

interface Props {
  data: SidebarItem[];
  activeKey: string;
  onMove?: ({ id, hoverIndex }: { id: string; hoverIndex: number }) => void;
  dataCy?: string;
  enableDnd: boolean;
  disableVirtualization?: boolean;
}

export default function ItemList({
  data,
  activeKey,
  onMove,
  dataCy,
  enableDnd,
  disableVirtualization = false, // Default is virtualization enabled
}: Props) {
  const visibleData = useMemo(() => data.filter((item) => !item.hide), [data]);
  const renderItem = (index: number) => {
    const item = visibleData[index];
    return (
      item && (
        <Stack
          position="relative"
          key={item.key}
          px={2}
          sx={{
            mb: 0.5,
            '& > *': {
              mb: '0 !important',
            },
          }}
        >
          {enableDnd ? (
            <ItemDndWrapper
              data={item}
              dataCy={dataCy}
              activeKey={activeKey}
              index={index}
              onMove={
                onMove ? ({ id, index: hoverIndex }) => onMove?.({ id, hoverIndex }) : undefined
              }
            />
          ) : (
            <Item data={item} dataCy={dataCy} isActive={activeKey === item?.key} />
          )}
        </Stack>
      )
    );
  };

  return disableVirtualization ? (
    <Stack style={{ height: '100%', width: '100%' }}>
      {visibleData.map((_, index) => renderItem(index))}
    </Stack>
  ) : (
    <Virtuoso
      style={{ height: '100%', width: '100%' }}
      initialItemCount={5}
      totalCount={visibleData.length}
      initialTopMostItemIndex={
        activeKey ? visibleData.findIndex((item) => item.key === activeKey) : 0
      }
      itemContent={renderItem}
      overscan={200}
    />
  );
}
