import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  TrainingModuleWithStatus,
  usePostTrainingModuleMutation,
} from 'src/store/api/trainings/trainingModulesApi';
import { TrainingModulesProgress } from 'src/store/api/trainings/trainingsApi';
import EditingSingleModule from './EditingSingleModule';

interface Props {
  modules: TrainingModuleWithStatus[];
  progressData?: TrainingModulesProgress[];
}

export default function EditingModules({ modules, progressData }: Props) {
  const [postTrainingModule] = usePostTrainingModuleMutation();
  const params = useParams();
  const { t } = useTranslation();
  return (
    <>
      {modules.map((module, idx) => (
        <EditingSingleModule
          key={module.id}
          modules={modules}
          {...module}
          index={idx}
          lessonsCount={module.lessonsCount}
          estimatedTime={module.estimatedTimeToComplete || 0}
          progress={
            progressData?.find((item) => item.trainingModuleId === module.id)?.progress || 0
          }
        />
      ))}
      <Button
        color="inherit"
        sx={{ mt: 2 }}
        onClick={() => {
          postTrainingModule({ trainingId: params.trainingId as string }).then((resp: any) => {
            if (resp?.data.id) {
              setTimeout(() => {
                document.getElementById(resp?.data.id)?.querySelector('input')?.focus();
              }, 100);
            }
          });
        }}
      >
        {t('trainingDetailPage.addModule')}
      </Button>
    </>
  );
}
