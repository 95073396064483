import { LocalMediaStorage } from '@vidstack/react';

class CustomMediaStorage extends LocalMediaStorage {
  // eslint-disable-next-line class-methods-use-this
  async setTime() {
    return Promise.resolve();
  }
}

export default new CustomMediaStorage();
