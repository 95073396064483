import WorkbaseTree from 'src/ui-components/custom/hierarchical-tree/Tree';
import { TreeNodeModel } from 'src/ui-components/custom/hierarchical-tree/types';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import useIsMobile from 'src/hooks/useIsMobile';
import useOnDropTree from './useOnDropTree';
import { memo } from 'react';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';

interface Props {
  data: TreeNodeModel[];
}

export default memo(({ data }: Props) => {
  const hasAnyPermission = useHasAnyPermission();
  const isMobile = useIsMobile();
  const canDragTree = hasAnyPermission([
    PermissionCodes.ADMIN_ACCESS_WHITEBOARDS,
    PermissionCodes.ADMIN_ACCESS_EMBEDDED_APPS,
    PermissionCodes.ADMIN_ACCESS_DASHBOARD_AND_PAGES,
  ]);

  const onDrop = useOnDropTree();

  if (data.length === 0) return null;
  return <WorkbaseTree tree={data} onDrop={onDrop} canDrag={() => !!canDragTree && !isMobile} />;
});
