import { alpha, Box, Container, useTheme } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
  stickyBottomContent: boolean;
}

export default function SubHeader({ children, stickyBottomContent }: Props) {
  const theme = useTheme();

  return (
    <Box
      position={stickyBottomContent ? 'sticky' : 'static'}
      mb={2}
      pt={4.5}
      pb={2.5}
      mt={-2}
      sx={{
        backdropFilter: 'blur(8px)',
        borderBottom: `1px solid ${theme.palette.divider}`,
        bgcolor: alpha(theme.palette.background.paper, 0.6),
      }}
      top={-16}
      zIndex={stickyBottomContent ? 15 : undefined}
    >
      <Container maxWidth="lg">{children}</Container>
    </Box>
  );
}
