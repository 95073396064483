import { alpha, Box, CircularProgressProps, SvgIcon } from '@mui/material';
import { WorkbaseCheckmarkIcon, WorkbasePlayIcon } from 'src/assets/icons/workbaseIcons';
import CircularProgressWithLabel from './CircularProgressWithLabel';

export default function StartCompleteProgress({
  value,
  circularProgressProps,
  backdropCircularProgressProps,
  onClick,
}: {
  value?: number;
  circularProgressProps?: CircularProgressProps;
  backdropCircularProgressProps?: CircularProgressProps;
  onClick?: () => void;
}) {
  if (value === 100)
    return (
      <Box
        width={40}
        height={40}
        bgcolor={(theme) =>
          alpha(theme.palette.success.main, theme.palette.action.activatedOpacity)
        }
        borderRadius="100%"
        display="flex"
        flexShrink={0}
        alignItems="center"
        justifyContent="center"
        color="success.main"
      >
        <SvgIcon component={WorkbaseCheckmarkIcon} color="inherit" fontSize="small" />
      </Box>
    );

  if (value === 0)
    return (
      <Box
        width={40}
        height={40}
        border={1}
        borderColor="primary.main"
        borderRadius="100%"
        display="flex"
        flexShrink={0}
        alignItems="center"
        justifyContent="center"
        color="primary.main"
        gap={0}
        flexDirection="column"
        onClick={onClick}
        sx={{
          '&:hover': {
            backgroundColor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          },
        }}
      >
        <SvgIcon component={WorkbasePlayIcon} color="inherit" fontSize="tiny" />
      </Box>
    );

  return (
    <CircularProgressWithLabel
      value={value || 0}
      circularProgressProps={circularProgressProps}
      backdropCircularProgressProps={backdropCircularProgressProps}
    />
  );
}
