import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseDomainIcon } from 'src/assets/icons/workbaseIcons';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import EditViewProtectedWrapper from 'src/core/EditViewProtectedWrapper';
import ModeSwitch from 'src/core/ModeSwitch';
import { useEmbeddedAppQuery } from 'src/store/api/embeddedAppsApi';
import { ShareLevel } from 'src/lib/types/share-level';
import Empty from 'src/ui-components/custom/empty/Empty';
import TopBarLayout from 'src/layouts/TopBarLayout';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import EmbeddedAppTopBar from './topbar';
import Iframe from './Iframe';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';

export default function EmbeddedApp({ id }: { id: string }) {
  const { data, isLoading, error } = useEmbeddedAppQuery(id);

  const { t } = useTranslation();
  const { data: shareLevelData, error: shareLevelError } = useShareLevelQuery({
    resourceType: 'embeddedApp',
    resourceId: id,
  });
  const hasAnyPermission = useHasAnyPermission();
  const hasAddEmbeddedAppsPermission = hasAnyPermission([PermissionCodes.ADD_EMBEDDED_APPS]);

  const hasEditingPermissions =
    shareLevelData?.shareLevel !== ShareLevel.view &&
    shareLevelData?.shareLevel !== ShareLevel.none;

  if (isLoading)
    return (
      <Stack justifyContent="center" alignItems="center" height="100%" position="relative">
        <LottieLogoSpinner />
      </Stack>
    );

  return (
    <EditViewProtectedWrapper
      canEdit={hasEditingPermissions}
      canView={shareLevelData?.shareLevel !== ShareLevel.none && !shareLevelError && !error}
    >
      <ModeSwitch switchButtonVisible={hasEditingPermissions}>
        {(isEditing) => (
          <TopBarLayout
            topBarVisible={isEditing}
            topBar={
              hasEditingPermissions && (
                <EmbeddedAppTopBar
                  id={id}
                  name={data?.name}
                  sidebar={data?.sidebar}
                  shareLevel={shareLevelData?.shareLevel}
                  hasAddPermission={hasAddEmbeddedAppsPermission}
                />
              )
            }
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              height="100%"
              position="relative"
              overflow="hidden"
            >
              {data && data.url ? (
                <Iframe key={data.url} src={data.url} />
              ) : (
                <Empty icon={WorkbaseDomainIcon} description={t('embeddedAppPage.noUrlLabel')} />
              )}
            </Stack>
          </TopBarLayout>
        )}
      </ModeSwitch>
    </EditViewProtectedWrapper>
  );
}
