import { Switch } from '@mui/material';
import { useState } from 'react';
import { DocumentResourceType } from 'src/store/api/driveApi';
import { usePatchEmbeddedAppMutation } from 'src/store/api/embeddedAppsApi';
import { usePatchPageMutation } from 'src/store/api/pagesApi';
import { usePatchWhiteboardMutation } from 'src/store/api/whiteboardsApi';

interface Props {
  checkedInit: boolean;
  disabled: boolean;
  id: string;
  resourceType: DocumentResourceType;
}

export default function DocumentsSwitch({ checkedInit, disabled, id, resourceType }: Props) {
  const [checked, setChecked] = useState<boolean>(checkedInit);
  const [patchPage] = usePatchPageMutation();
  const [patchEmbeddedApp] = usePatchEmbeddedAppMutation();
  const [patchWhiteboard] = usePatchWhiteboardMutation();

  const patchMap: {
    [key in DocumentResourceType]: (args: { id: string; sidebar: boolean }) => void;
  } = {
    page: patchPage,
    embeddedApp: patchEmbeddedApp,
    whiteboard: patchWhiteboard,
  };

  function handleSwitchChange(val: boolean) {
    setChecked(val);
    patchMap[resourceType]({ id, sidebar: val });
  }

  return (
    <Switch
      data-cy="documents-switch"
      size="small"
      checked={checked}
      disabled={disabled}
      onChange={(_, state) => handleSwitchChange(state)}
    />
  );
}
