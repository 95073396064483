import { Box, TextField } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useDebouncedValue from 'src/hooks/useDebouncedValue';
import { usePatchWhiteboardMutation, useWhiteboardQuery } from 'src/store/api/whiteboardsApi';
import IconOrEmojiSelector from 'src/ui-components/custom/icon-selector/IconOrEmojiSelector';

interface Props {
  id: string;
}

export default function WhiteboardIconNameSelector({ id }: Props) {
  const { t } = useTranslation();
  const { data } = useWhiteboardQuery(id);
  const [patchWhiteboard] = usePatchWhiteboardMutation();

  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      if (val !== data?.name) {
        patchWhiteboard({ id, name: val });
      }
    },
    [patchWhiteboard, data?.name, id]
  );

  const { value, handleChange } = useDebouncedValue(data?.name, handleNameChange);

  if (!data) return null;

  return (
    <>
      <IconOrEmojiSelector
        dataCy="whiteboard"
        value={data.icon}
        onChange={(i) => {
          patchWhiteboard({ id, icon: i });
        }}
      />
      <Box width={({ breakpoints }) => breakpoints.values.sm} minWidth={0}>
        <TextField
          data-cy="whiteboard-name-textfield"
          autoFocus
          placeholder={t('whiteboards.nameTextFieldPlaceholder')}
          value={value}
          onChange={handleChange}
          size="small"
          fullWidth
        />
      </Box>
    </>
  );
}
