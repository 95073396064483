import HighlightWrapper from '../highlight-wrapper';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';
import BorderWrapper from '../border-wrapper';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  readOnly?: boolean;
  height?: number;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: any;
  onResizeStart?: () => void;
  id: string;
}

export default function SpacerItem({
  onDelete,
  onDuplicate,
  readOnly,
  onResizeStop,
  height,
  dragging,
  onResizeStart,
  id,
}: Props) {
  return (
    <HighlightWrapper
      dataCy="editor-widgets-spacer-item"
      id={id}
      editBarProps={{ baseActionsProps: { onDelete, onDuplicate } }}
      horizontalResize={{}}
      verticalResize={{}}
      dragging={dragging}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      height={height}
      readOnly={readOnly}
    >
      {({ EditBarComponent }) => (
        <>
          {EditBarComponent}
          <BorderWrapper
            readOnly={readOnly}
            bgcolor={readOnly ? 'transparent' : 'background.default'}
            dashed
            {...(readOnly ? { height } : {})}
          />
        </>
      )}
    </HighlightWrapper>
  );
}
