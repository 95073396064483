import { RefObject, useState } from 'react';
import { ConnectDragPreview, useDrag, useDrop } from 'react-dnd';
import type { Identifier, XYCoord } from 'dnd-core';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface ItemData {
  id: string;
  index: number;
  [key: string]: any;
}

interface Props {
  moveItem?: (props: ItemData) => void;
  accept: string;
  ref: RefObject<HTMLDivElement | HTMLUListElement>;
  itemData: ItemData;
}

interface ReturnVal {
  draggingStyles: {
    placeholderOpacity: number;
    previewStyles: {
      transform: 'scale(0.7)';
    };
  };
  isDragging: boolean;
  hoverState: {
    isHovered: boolean;
    isOverUpperHalf: boolean;
  };
  previewRef: ConnectDragPreview;
}

export default function useVerticalOrderedDnD({
  itemData,
  moveItem,
  accept,
  ref,
}: Props): ReturnVal {
  const [hoverState, setHoverState] = useState<{
    isHovered: boolean;
    isOverUpperHalf: boolean;
  }>({ isHovered: false, isOverUpperHalf: false });

  const [, dropRef] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept,
    collect(monitor) {
      const isCurrentlyOver = monitor.isOver({ shallow: true });
      let isOverUpperHalf = false;

      if (isCurrentlyOver && ref.current) {
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();

        if (clientOffset) {
          const hoverClientY = clientOffset.y - hoverBoundingRect.top;
          isOverUpperHalf = hoverClientY < hoverMiddleY;
        }
      }

      // Only update the state if there's a change
      if (
        isCurrentlyOver !== hoverState.isHovered ||
        isOverUpperHalf !== hoverState.isOverUpperHalf
      ) {
        setHoverState({ isHovered: isCurrentlyOver, isOverUpperHalf });
      }
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const hoverIndex = itemData.index;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      const isOverUpperHalf = hoverClientY < hoverMiddleY;

      const isCurrentlyOver = monitor.isOver({ shallow: true });

      if (
        isCurrentlyOver !== hoverState.isHovered ||
        isOverUpperHalf !== hoverState.isOverUpperHalf
      ) {
        setHoverState({ isHovered: isCurrentlyOver, isOverUpperHalf });
      }
      // eslint-disable-next-line no-param-reassign
      item.index = isOverUpperHalf ? hoverIndex : hoverIndex + 1;
    },
    drop: (item) => {
      moveItem?.(item);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: accept,
    item: () => itemData,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  dragRef(dropRef(ref));

  return {
    draggingStyles: {
      placeholderOpacity: 0.5,
      previewStyles: {
        transform: 'scale(0.7)',
      },
    },
    hoverState,
    isDragging,
    previewRef,
  };
}
