import { Stack } from '@mui/material';
import { useRef } from 'react';
import useVerticalOrderedDnD from 'src/lib/hooks/useVerticalOrderedDnD';
import DndIndicator from 'src/ui-components/custom/dnd-indicator/DndIndicator';
import Item from './Item';
import { SidebarItem } from '../types';

interface Props {
  data: SidebarItem;
  activeKey: string;
  divider?: boolean;
  onMove?: ({ id, index }: { id: string; index: number }) => void;
  index: number;
  dataCy?: string;
  scrollItemRef?: React.RefObject<HTMLDivElement>;
}

export default function ItemDndWrapper({ data, activeKey, onMove, index, dataCy }: Props) {
  const dragRef = useRef<HTMLDivElement>(null);
  const { hoverState, previewRef } = useVerticalOrderedDnD({
    itemData: { id: data?.key, index },
    moveItem: ({ id, index: idx }) => onMove?.({ id, index: idx }),
    accept: 'secondary-sidebar-item',
    ref: onMove ? dragRef : { current: null },
  });
  // component we have breaks in safari, using this as a workaround
  const preview = (
    <Stack
      borderRadius={1}
      position="absolute"
      left={0}
      top={0}
      bottom={0}
      right={0}
      zIndex={-10}
      overflow="hidden"
      bgcolor="background.paper"
      ref={previewRef}
    >
      <Item data={data} isActive={activeKey === data?.key} />
    </Stack>
  );
  const dndPosition = hoverState?.isOverUpperHalf ? { top: -1 } : { bottom: -1 };
  return (
    <>
      {data && (
        <>
          <Item data={data} dataCy={dataCy} isActive={activeKey === data?.key} dragRef={dragRef} />
          {hoverState?.isHovered && <DndIndicator {...dndPosition} />}
        </>
      )}
      {preview}
    </>
  );
}
