import { styled } from '@mui/material';
import { MediaPlayer } from '@vidstack/react';

// eslint-disable-next-line no-restricted-syntax
const StyledMediaPlayer = styled(MediaPlayer)<{ $progress?: number }>`
  --video-border: none;
  --video-border-radius: ${({ theme }) => theme.shape.borderRadius - 2}px;

  video,
  img {
    object-fit: cover;
    height: 100%;
  }
  --video-font-family: Base;

  * {
    font-weight: 500 !important;
  }

  .vds-menu-items {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;

    .vds-menu-item-icon {
      width: 20px !important;
      height: 20px !important;
    }
  }

  .vds-video-layout[data-size='lg'] .vds-settings-menu-items {
    top: unset !important;
    bottom: 80px;
  }
  .vds-menu-item,
  .vds-menu-section-body,
  .vds-chapter-radio,
  .vds-thumbnail,
  .vds-captions > div > div,
  .vds-captions-radio-group .vds-caption-radio {
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    border: none;
  }

  .vds-tooltip-content,
  .vds-volume-slider .vds-slider-value {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 0.5}px;
    border: none;
  }

  .vds-chapters-radio-group {
    gap: 2px;
  }

  .vds-settings-menu-items {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
  }

  .vds-accessibility-menu > .vds-menu-items > .vds-menu-section:last-child {
    display: none;
  }

  --media-cue-bg: ${({ theme }) => theme.palette.primary.main};
  --media-cue-color: ${({ theme }) => theme.palette.primary.contrastText};

  ${({ $progress }) =>
    $progress &&
    `.vds-time-slider .vds-slider-progress {
      width: ${$progress * 100}% !important;
    }`}
`;

export default StyledMediaPlayer;
