export default function getFileIcon(fileType: string): string {
  const fileTypeMap = {
    'image/bmp': '📷', // bmp - Camera icon
    'text/csv': '📄', // csv - Document icon
    'application/vnd.oasis.opendocument.text': '📝', // odt - Notepad icon
    'application/msword': '📄', // doc - Word Document icon
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '📄', // docx - Word Document icon
    'image/gif': '🖼️', // gif - Picture icon
    'text/htm': '🌐', // htm - Web icon
    'text/html': '🌐', // html - Web icon
    'image/jpg': '🖼️', // jpg - Picture icon
    'image/jpeg': '🖼️', // jpeg - Picture icon
    'application/pdf': '📄', // pdf - PDF Document icon
    'image/png': '🖼️', // png - Picture icon
    'application/vnd.ms-powerpoint': '📊', // ppt - Presentation icon
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': '📊', // pptx - Presentation icon
    'image/tiff': '🖼️', // tiff - Picture icon
    'text/plain': '📄', // txt - Text Document icon
    'application/vnd.ms-excel': '📊', // xls - Excel Spreadsheet icon
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '📊', // xlsx - Excel Spreadsheet icon
    'video/mp4': '🎥', // mp4 - Video icon
    'video/quicktime': '🎥', // mp4 - Video icon
    'image/webp': '🖼️', // webp - Picture icon
    default: 'ℹ️',
  } as const;

  return (fileTypeMap as Record<string, string>)[fileType] || '📁'; // Default to a folder icon if type is unknown
}
