import { Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseModuleIcon } from 'src/assets/icons/workbaseIcons';

export default function Title() {
  const { t } = useTranslation();
  return (
    <Stack gap={1} alignItems="center" direction="row" padding={1.5}>
      <SvgIcon component={WorkbaseModuleIcon} fontSize="medium" />
      <Typography variant="h6">{t('editor.trainingsSection.modulesTitle')}</Typography>
    </Stack>
  );
}
