import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { TrainingSectionStatus } from 'src/store/api/trainings/trainingSectionsApi';
import {
  TrainingLesson,
  useMoveTrainingLessonToNewPositionMutation,
} from 'src/store/api/trainings/trainingLessonsApi';
import { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import DndIndicator from 'src/ui-components/custom/dnd-indicator/DndIndicator';
import Lessons from './Lessons';
import SectionTitle from './SectionTitle';
import LottieChevron from 'src/ui-components/custom/lottie/LottieChevron';
import generateJitteredKeyBetween from 'src/lib/utils/generateJitteredKeyBetween';

interface Props {
  id: string;
  dataCy?: string;
  name: string;
  status: TrainingSectionStatus;
  trainingModuleId: string;
  readOnly: boolean;
  icon: string;
  totalEstimatedLessonsTime: number;
  mobileView: boolean;
  filteredLessons: TrainingLesson[];
  allLessons: TrainingLesson[];
  isCurrentSectionLessonActive: boolean;
  onAddLessonCallback?: (lessonId: string) => void;
}

export default function SectionAccordion({
  dataCy,
  id,
  mobileView,
  readOnly,
  name,
  trainingModuleId,
  status,
  icon,
  filteredLessons,
  isCurrentSectionLessonActive,
  totalEstimatedLessonsTime,
  onAddLessonCallback,
  allLessons,
}: Props) {
  const [expanded, setExpanded] = useState(true);
  const [moveTrainingLessonToNewPosition] = useMoveTrainingLessonToNewPositionMutation();
  const [{ isOver }, drop] = useDrop({
    accept: 'editing-lesson-item',
    drop: (i: { id: string }) => {
      const targetOrder = 1;

      const belowLesson = allLessons.length > 0 ? allLessons[0] : null;

      const aboveOrder = null;
      const belowOrder = belowLesson ? (belowLesson.position as string) : null;

      const newPosition = generateJitteredKeyBetween(aboveOrder, belowOrder);

      moveTrainingLessonToNewPosition({
        sectionId: id,
        idOfMovingLesson: i.id,
        order: targetOrder,
        position: newPosition,
      });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  const showFlicker = isOver && !expanded;

  useEffect(() => {
    if (showFlicker) {
      const timeoutId = setTimeout(() => {
        if (isOver) {
          setExpanded(true);
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [showFlicker, isOver]);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      variant="outlined"
      component={Box}
      overflow="hidden"
      data-cy={dataCy}
    >
      <AccordionSummary expandIcon={<LottieChevron open={expanded} />} ref={drop}>
        {isOver && <DndIndicator bottom={7} />}
        <SectionTitle
          dataCy={dataCy}
          sectionName={name}
          trainingModuleId={trainingModuleId}
          readOnly={readOnly}
          lessonsCount={filteredLessons?.length}
          sectionId={id}
          sectionStatus={status}
          mobileView={mobileView}
          icon={icon}
          isCurrentSectionLessonActive={isCurrentSectionLessonActive}
          totalEstimatedLessonsTime={totalEstimatedLessonsTime}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Lessons
          dataCy={dataCy}
          readOnly={readOnly}
          id={id}
          filteredLessons={filteredLessons}
          mobileView={mobileView}
          onAddLessonCallback={onAddLessonCallback}
          allLessons={allLessons}
        />
      </AccordionDetails>
    </Accordion>
  );
}
