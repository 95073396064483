import { ReactNode } from 'react';
import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

interface CircularProgressWithContentProps {
  children: ReactNode;
  circularProgressProps?: CircularProgressProps;
}

function CircularProgressWithContent({
  children,
  circularProgressProps,
}: CircularProgressWithContentProps) {
  return (
    <Box display="inline-flex" position="relative">
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
      <CircularProgress {...circularProgressProps} />
    </Box>
  );
}

export default CircularProgressWithContent;
