import { Components, Theme } from '@mui/material';

const MuiMenu: Components<Theme>['MuiMenu'] = {
  styleOverrides: {
    paper: {
      paddingLeft: 8,
      paddingRight: 8,
      maxHeight: 350,
    },
  },
};

export default MuiMenu;
