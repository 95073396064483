import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './api';

export interface IframePost {
  url: string;
}

export interface IframeResponse {
  success: boolean;
}

export const iframeApi = createApi({
  reducerPath: 'iframeApi',
  baseQuery,
  endpoints: (builder) => ({
    postIframe: builder.mutation<IframeResponse, IframePost>({
      query: (iframeData) => ({
        url: `/iframe`,
        method: 'POST',
        body: iframeData,
        keepUnusedDataFor: 0,
      }),
    }),
  }),
});

export const { usePostIframeMutation } = iframeApi;
