import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, Link as RouterLink } from 'react-router-dom';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';
import { useGetSingleTrainingQuery } from 'src/store/api/trainings/trainingsApi';

export default function TrainingPageBreadcrumbs() {
  const { t } = useTranslation();
  const params = useParams();
  if (!params?.trainingId) throw new Error('Training id param expected');

  const { data: trainingData } = useGetSingleTrainingQuery(params.trainingId);

  const path = useMaintainEditPath();

  const data = [
    {
      key: 'trainings',
      label: t('sidebar.tabTitleTrainings'),
      to: `/trainings${path}`,
    },
    {
      key: 'training',
      label: trainingData?.name || t('trainingDetailPage.unnamedTraining'),
    },
  ];

  return (
    <Breadcrumbs sx={{ my: 1 }}>
      {data.map(({ key, label, to }) =>
        to ? (
          <Link
            key={key}
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={to}
            data-cy={`breadcrumb-${key}`}
          >
            {label}
          </Link>
        ) : (
          <Typography key={key} color="textPrimary">
            {label}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
}
