import { useNavigate } from 'react-router-dom';
import { Box, Card, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseLessonIcon } from 'src/assets/icons/workbaseIcons';
import { useTrainingModulesProgressQuery } from 'src/store/api/trainings/trainingModulesApi';
import ImageWithDefaultFallback from 'src/ui-components/image/ImageWithDefaultFallback';
import { DefaultImage } from 'src/ui-components/branded-default-image/BrandedDefaultImage';
import useIsMobile from 'src/hooks/useIsMobile';
import useSingleModuleResponsivness from './useSingleModuleResponsivness';
import StartCompleteProgress from 'src/ui-components/custom/progress/StartCompleteProgress';

interface SingleModuleProps {
  name?: string;
  imageUrl?: string | null;
  readOnly?: boolean;
  id?: string;
  trainingId?: string;
  lessonsCount?: number;
  estimatedTime?: number;
  progress?: number;
  defaultImage: DefaultImage;
}

const IMAGE_BOX_CLASS_NAME = 'image-box';

export default function SingleModule({
  name,
  imageUrl,
  id,
  trainingId,
  lessonsCount,
  estimatedTime,
  defaultImage,
  readOnly,
  progress,
}: SingleModuleProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { cardRef, isOneColVersion } = useSingleModuleResponsivness();

  const lessonsCountLabel = t('lesson', { count: lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: estimatedTime || 0 });

  const { data: progressData } = useTrainingModulesProgressQuery(id as string);
  const handleClick = () => {
    navigate(`/trainings/${trainingId}/${id}${readOnly ? '' : '/edit'}`);
  };

  const isMobile = useIsMobile();

  return (
    <Card
      data-cy="editor-single-module-navigate-button"
      ref={cardRef}
      onClick={handleClick}
      component={Box}
      variant="outlined"
      overflow="hidden"
      sx={{
        '&:hover img, &:hover .default-svg': {
          transform: 'scale(1.2)',
        },
        cursor: 'pointer',
        [`.${IMAGE_BOX_CLASS_NAME} img, .${IMAGE_BOX_CLASS_NAME} svg`]: {
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.short,
              easing: theme.transitions.easing.easeInOut,
            }),
        },
        [`&:hover .${IMAGE_BOX_CLASS_NAME} img, &:hover .${IMAGE_BOX_CLASS_NAME} svg`]: {
          transform: 'scale(1.2)',
        },
      }}
    >
      <Stack direction={isOneColVersion ? 'column' : 'row'} p={2} spacing={2.5} alignItems="center">
        <Box
          flexShrink={0}
          overflow="hidden"
          width={isMobile ? '100%' : 'auto'}
          className={IMAGE_BOX_CLASS_NAME}
        >
          <ImageWithDefaultFallback
            height={isMobile ? 130 : 90}
            width={isMobile ? '100%' : 190}
            src={imageUrl}
            alt={name || ''}
            defaultImage={defaultImage as any}
          />
        </Box>
        <Stack
          direction="row"
          width="100%"
          minWidth={0}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack gap={1} minWidth={0}>
            <Typography variant="h5" color="text.primary" noWrap>
              {name || t('unnamedModule')}
            </Typography>
            <Stack direction="row" gap={1} alignItems="center">
              <SvgIcon component={WorkbaseLessonIcon} fontSize="small" />
              <Typography variant="body1" color="text.secondary">
                {lessonsCountLabel} – {estimatedTimeLabel}
              </Typography>
            </Stack>
          </Stack>
          <StartCompleteProgress value={progressData?.progress || progress || 0} />
        </Stack>
      </Stack>
    </Card>
  );
}
