import { useTranslation } from 'react-i18next';
import { useParams, Link as RouterLink } from 'react-router-dom';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';
import { useTrainingLessonQuery } from 'src/store/api/trainings/trainingLessonsApi';
import { useGetSingleTrainingQuery } from 'src/store/api/trainings/trainingsApi';
import { Box, Breadcrumbs, Link, Typography } from '@mui/material';
import { useTrainingModuleQuery } from 'src/store/api/trainings/trainingModulesApi';

export default function LessonBreadcrumbs() {
  const { t } = useTranslation();
  const params = useParams();
  const trainingId = params.trainingId as string;
  const moduleId = params.moduleId as string;
  const lessonId = params.lessonId as string;
  const { data: trainingData } = useGetSingleTrainingQuery(trainingId);
  const { data: moduleData } = useTrainingModuleQuery(moduleId);
  const { data: lessonData } = useTrainingLessonQuery(lessonId);
  const path = useMaintainEditPath();

  const data = [
    {
      key: 'trainings',
      label: t('trainings'),
      to: `/trainings`,
    },
    {
      key: 'training',
      label: trainingData?.name || t('trainingDetailPage.unnamedTraining'),
      to: `/trainings/${params.trainingId}${path}`,
    },
    {
      key: 'module',
      label: moduleData?.name || t('trainingDetailPage.unnamedModule'),
      to: `/trainings/${trainingId}/${moduleId}${path}`,
    },
    {
      key: 'lesson',
      label: lessonData?.name || t('modulePage.unnamedLesson'),
      to: null,
    },
  ];

  return (
    <Box pt={2} maxWidth="100%">
      <Breadcrumbs>
        {data.map(({ key, label, to }) =>
          to ? (
            <Link
              data-cy={`${label}-lesson-breadcrumb`}
              underline="hover"
              color="inherit"
              key={key}
              component={RouterLink}
              to={to}
            >
              {label}
            </Link>
          ) : (
            <Typography key={key} color="textPrimary">
              {label}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </Box>
  );
}
