import { Divider, FormControlLabel, Switch, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ResourceType,
  usePatchGeneralAccessMutation,
  usePatchSharedResourceMutation,
} from 'src/store/api/shareResourceApi';

interface RequiredSwitchProps {
  id?: string;
  isRequired: boolean;
  isGeneralAccess?: boolean;
  resourceId: string;
  resourceType: ResourceType;
  dataCy?: string;
}

function RequiredSwitch({
  id = '',
  isRequired,
  isGeneralAccess = false,
  resourceId,
  resourceType,
  dataCy,
}: RequiredSwitchProps): ReactElement {
  const [patchResource] = usePatchSharedResourceMutation();
  const [patchGeneralAccess] = usePatchGeneralAccessMutation();
  const { t } = useTranslation();

  const handlePatchAccess = () => {
    if (isGeneralAccess) {
      patchGeneralAccess({ resourceId, resourceType, required: !isRequired });
    } else {
      patchResource({ id, resourceId, resourceType, required: !isRequired });
    }
  };

  return (
    <>
      <Divider orientation="vertical" flexItem />
      <FormControlLabel
        data-cy={dataCy && `${dataCy}-training-required-switch`}
        labelPlacement="start"
        control={<Switch size="small" checked={isRequired} />}
        onChange={handlePatchAccess}
        label={
          <Typography
            variant="caption"
            sx={{
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            {t(`permissionsShare.${isRequired ? 'required' : 'optional'}`)}
          </Typography>
        }
      />
    </>
  );
}

export default RequiredSwitch;
