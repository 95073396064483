import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';
import { userPermissionsApi } from 'src/store/api/userPermissionsApi';

import { rolesApi } from 'src/store/api/roles.api';
import { driveApi } from 'src/store/api/driveApi';
import { Department } from '../model/types';
import { setDepartments } from './departments.slice';

export const departmentsApi = createApi({
  reducerPath: 'departmentsApi',
  tagTypes: ['Departments'],
  baseQuery,
  endpoints: (builder) => ({
    departments: builder.query<Department[], void>({
      query: () => '/departments',
      providesTags: ['Departments'],
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((resp) => {
          dispatch(setDepartments(resp.data));
        });
      },
    }),
    postDepartmentData: builder.mutation<void, Department[]>({
      query: (data) => ({
        url: `/departments`,
        body: {
          departments: data,
        },
        method: 'PUT',
      }),
      invalidatesTags: ['Departments'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(rolesApi.util.invalidateTags(['Roles', 'PermissionRoleCount']));
        dispatch(userPermissionsApi.util.invalidateTags(['UserPermissions']));
        dispatch(driveApi.util.invalidateTags(['Documents']));
      },
    }),
  }),
});

export const { useDepartmentsQuery, usePostDepartmentDataMutation } = departmentsApi;
