import Lottie, { LottieComponentProps } from 'lottie-react';
import { flatten } from 'lottie-colorify';
import { Box, BoxProps, useTheme } from '@mui/material';
import { memo } from 'react';
import Color from 'color';
import { PaletteColorKeys } from 'src/mui/palette/colors';

export interface LottieContainerProps extends Omit<LottieComponentProps, 'color'> {
  color?: PaletteColorKeys;
  containerProps?: BoxProps;
}

export default memo(function LottieContainer({
  animationData,
  color,
  containerProps,
  ...props
}: LottieContainerProps) {
  const theme = useTheme();
  const colorified = flatten(
    new Color(color ? theme.palette[color].main : theme.palette.text.primary).hex(),
    animationData
  );
  const containerStyles = {
    height: '100%',
    transform: 'none !important',
    flexShrink: 0,
    '& .lottie-animation': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    '& g': {
      transform: 'scale(1.3) translate(5%, 10%)',
    },
  };
  return (
    <Box sx={containerStyles} {...containerProps}>
      <Lottie
        animationData={colorified}
        className="lottie-animation"
        {...props}
        style={{
          ...props.style,
        }}
      />
    </Box>
  );
});
