import { Components, Theme } from '@mui/material';

declare module '@mui/material/ListItemButton' {
  interface ListItemButtonOwnProps {
    disableActiveShrink?: boolean;
  }
}

const scaleStyles = {
  transition: 'transform 0.2s ease-out',
  '&:active': {
    transform: 'scale(0.975)',
  },
};

const MuiListItemButton: Components<Theme>['MuiListItemButton'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme, disableActiveShrink }) => ({
      borderRadius: theme.shape.borderRadius,
      paddingLeft: 12,
      paddingRight: 12,
      marginBottom: '2px',
      ...(disableActiveShrink ? {} : scaleStyles),
      '&:last-child': {
        marginBottom: 0,
      },
    }),
  },
};

export default MuiListItemButton;
