import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  SvgIcon,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  WorkbaseArrowDownIcon,
  WorkbaseImageIcon,
  WorkbaseImageNewIcon,
  WorkbaseImageRemoveIcon,
} from 'src/assets/icons/workbaseIcons';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

interface Props {
  removeImage: boolean;
  inputProps: any;
  open: () => void;
  onDelete: () => void;
}

export default function BackgroundImageActions({ removeImage, inputProps, open, onDelete }: Props) {
  const { t } = useTranslation();
  const menu = usePopupState({ variant: 'popover' });

  const menuProps = bindMenu(menu);

  return (
    <Paper
      component={Box}
      variant="elevation"
      sx={{
        position: 'absolute',
        left: 10,
        bottom: 10,
        borderRadius: 1,
        opacity: menuProps.open ? '1 !important' : undefined,
      }}
    >
      <Button
        data-cy="background-image-action-button"
        color="inherit"
        {...bindTrigger(menu)}
        onClick={removeImage ? bindTrigger(menu).onClick : open}
        startIcon={<SvgIcon component={removeImage ? WorkbaseImageIcon : WorkbaseImageNewIcon} />}
        endIcon={removeImage ? <SvgIcon component={WorkbaseArrowDownIcon} /> : undefined}
      >
        {t('image')}
      </Button>
      <Menu {...menuProps}>
        <MenuItem
          data-cy="background-image-upload-action-button"
          onClick={() => {
            open();
            menuProps.onClose();
          }}
        >
          <ListItemIcon>
            <SvgIcon component={WorkbaseImageNewIcon} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('upload')} />
        </MenuItem>
        <MenuItem
          data-cy="background-image-remove-action-button"
          onClick={() => {
            onDelete();
            menuProps.onClose();
          }}
        >
          <ListItemIcon>
            <SvgIcon component={WorkbaseImageRemoveIcon} fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('remove')} />
        </MenuItem>
      </Menu>
      <input {...inputProps} />
    </Paper>
  );
}
