import { Components, Theme } from '@mui/material';

const MuiCircularProgress: Components<Theme>['MuiCircularProgress'] = {
  styleOverrides: {
    circleDeterminate: () => ({
      strokeLinecap: 'round',
    }),
  },
};

export default MuiCircularProgress;
