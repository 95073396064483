import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import LinksTitleHeader from 'src/core/links-title-header';
import { usePageQuery, usePatchPageMutation } from 'src/store/api/pagesApi';

interface Props {
  readOnly: boolean;
  pageId: string;
}

export default function CustomPageHeader({ pageId, readOnly }: Props) {
  const { t } = useTranslation();
  const { data } = usePageQuery(pageId);
  const [patchPage] = usePatchPageMutation();

  const handlePageNameChange = useCallback(
    (name: string) => {
      patchPage({ name, id: pageId });
    },
    [patchPage, pageId]
  );

  return (
    <LinksTitleHeader
      placeholder={t('customSites.unnamedPage')}
      readOnly={readOnly}
      name={data?.name}
      icon={data?.icon || 'WorkbaseEmptyContentPageIcon'}
      imageUrl={data?.imageUrl || ''}
      resourceId={pageId}
      resourceType="page"
      onIconChange={(icon: string) => patchPage({ id: pageId, icon })}
      onNameChange={handlePageNameChange}
      onImageChange={(imageId: string | null) => patchPage({ id: pageId, imageId })}
    />
  );
}
