import { Button, Stack } from '@mui/material';
import {
  TrainingSection,
  usePostTrainingSectionMutation,
} from 'src/store/api/trainings/trainingSectionsApi';
import { useTranslation } from 'react-i18next';
import useScrollOnDrag from 'src/lib/hooks/use-scroll-on-drag';
import Section from './Section';

export default function SectionList({
  data,
  searchTerm,
  readOnly,
  mobileView,
  moduleId,
  onAddLessonCallback,
  dataCy,
}: {
  data: TrainingSection[];
  searchTerm: string;
  readOnly: boolean;
  mobileView: boolean;
  moduleId: string;
  onAddLessonCallback?: (id: string) => void;
  dataCy?: string;
}) {
  const { t } = useTranslation();
  const [postTrainingSection] = usePostTrainingSectionMutation();
  const scrollRef = useScrollOnDrag();

  return (
    <Stack ref={scrollRef} gap={2} pb={2.5}>
      {data?.map((section) => (
        <Section
          {...section}
          dataCy={dataCy}
          mobileView={mobileView}
          readOnly={readOnly}
          key={section.id}
          searchTerm={searchTerm}
          onAddLessonCallback={onAddLessonCallback}
        />
      ))}
      {!readOnly && (
        <Button
          color="inherit"
          data-cy={dataCy && `${dataCy}-add-section`}
          onClick={() => {
            postTrainingSection({ trainingModuleId: moduleId }).then((resp: any) => {
              if (resp.data.id) {
                setTimeout(() => {
                  document.getElementById(resp.data.id)?.querySelector('input')?.focus();
                }, 250);
              }
            });
          }}
        >
          {t('modulePage.addSection')}
        </Button>
      )}
    </Stack>
  );
}
