import { Divider, Grid2, Stack } from '@mui/material';

interface Props {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  leftContentWidth?: Record<string, number>;
}

function SplitPaneLayout({ leftContent, rightContent, leftContentWidth = { md: 350 } }: Props) {
  return (
    <Grid2 container height="100%" overflow="hidden">
      <Grid2 height="100%" maxHeight="100%" overflow="hidden" width={leftContentWidth}>
        {leftContent}
      </Grid2>
      <Divider orientation="vertical" flexItem />
      <Grid2
        size="grow"
        height="100%"
        position="relative"
        component={Stack}
        overflow="auto"
        flexDirection="column"
      >
        {rightContent}
      </Grid2>
    </Grid2>
  );
}

export default SplitPaneLayout;
