import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  WorkbaseDownloadIcon,
  WorkbaseDragAndDropIcon,
  WorkbaseIosShare,
} from 'src/assets/icons/workbaseIcons';
import useThemeLogos from 'src/hooks/useThemeLogos';
import Image from 'src/ui-components/image';

const isSafari = window.safari !== undefined;
const isFirefox = typeof navigator !== 'undefined' && /firefox/i.test(navigator.userAgent);
const linkUrl = 'https://addons.mozilla.org/en-US/firefox/addon/pwas-for-firefox/';
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

export default function InstallAppButtonForOtherBrowsers() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { favicon } = useThemeLogos();

  const handleInstallClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        data-cy={`user-profile-menu-install-app-button-for-${isSafari ? 'safari' : 'firefox'}`}
        color="inherit"
        endIcon={<SvgIcon component={WorkbaseDownloadIcon} />}
        onClick={handleInstallClick}
        fullWidth
      >
        {isSafari
          ? t('installApp.buttonTriggerSafariText')
          : t('installApp.buttonTriggerFirefoxText')}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Stack direction="row" alignItems="center" gap={2}>
            <Box borderRadius={1} bgcolor="background.default">
              <Image {...favicon} width={40} height={40} />
            </Box>
            {t('installApp.dialogTitle')}
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack gap={1}>
            <Typography variant="subtitle1" gutterBottom>
              {t('installApp.instructionsTitle')}
            </Typography>
            <Box>
              <Stack direction="row" gap={1}>
                <Typography variant="subtitle1" gutterBottom fontWeight="bold">
                  {t('installApp.instructionsSteps')}
                </Typography>
              </Stack>
              {isSafari && (
                <>
                  <Stack direction="row" gap={1}>
                    <Typography variant="body1">{t('installApp.stepOne')}</Typography>
                    <SvgIcon component={WorkbaseIosShare} fontSize="small" />
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography variant="body1">{t('installApp.stepTwo')}</Typography>
                    {isIOS ? (
                      <Typography variant="body1" fontWeight="bold">
                        {t('installApp.addToHomeScreen')}
                      </Typography>
                    ) : (
                      <Typography variant="body1" fontWeight="bold">
                        {t('installApp.addToDock')}
                      </Typography>
                    )}
                  </Stack>
                </>
              )}
              {isFirefox && (
                <>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography variant="body1">{t('installApp.stepOne')}</Typography>
                    <SvgIcon component={WorkbaseDragAndDropIcon} fontSize="small" />
                    <Typography variant="body1">{t('installApp.stepOneTwo')}</Typography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography variant="body1">{t('installApp.stepTwo')}</Typography>
                    <Typography variant="body1" fontWeight="bold">
                      {t('installApp.addToHomeScreen')}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="baseline" gap={1}>
                    <Typography variant="caption">{t('installApp.firefoxInfo')}</Typography>
                    <Link href={linkUrl}>
                      <Typography variant="caption">{t('installApp.readMore')}</Typography>
                    </Link>
                  </Stack>
                </>
              )}
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            color="inherit"
            data-cy="install-app-cancel-button"
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
