import { Components, Theme } from '@mui/material';

const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    disableElevation: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      textTransform: 'unset',
      minWidth: 0,
      transition: '0.2s ease-out',
      '&:active': {
        transform: 'scale(0.975)',
      },
    },
    startIcon: {
      marginLeft: 0,
      svg: {
        fontSize: '16px !important',
      },
    },
    endIcon: {
      svg: {
        fontSize: '16px !important',
      },
    },
  },
  variants: [
    {
      props: { disableElevation: false },
      style: ({ theme }) => ({
        boxShadow: theme.shadows[1],
      }),
    },
    {
      props: { variant: 'text', size: 'small' },
      style: () => ({
        paddingLeft: 8,
        paddingRight: 8,
      }),
    },
    {
      props: { variant: 'text', size: 'medium' },
      style: () => ({
        paddingLeft: 12,
        paddingRight: 12,
      }),
    },
  ],
};

export default MuiButton;
