import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from './api';

export interface RecommendationNotificationPreferences {
  descriptionText: string;
  email: string;
  webhookUrl: string;
}

export interface RecommendationNotificationAdminPreferences
  extends RecommendationNotificationPreferences {
  email: string;
  isEmailEnabled: boolean;
  webhookUrl: string;
  isWebhookEnabled: boolean;
}
export type AdminNotificationPreferenceKeys = keyof RecommendationNotificationAdminPreferences;

export interface BaseSettings {
  siteName: string;
  id: string;
  description: string;
  siteLogoUrl: string | null;
  siteLogoDarkUrl: string | null;
  siteFaviconUrl: string | null;
  siteFaviconDarkUrl: string | null;
  primaryColor: string;
  borderRadius: number;
  defaultLanguage: string;
}

interface UserSettingsAdditionalProperties {
  recommendationNotificationPreferences: RecommendationNotificationPreferences;
}

export interface UserSettings extends BaseSettings, UserSettingsAdditionalProperties {}

interface UserSettingsToPatch extends BaseSettings, UserSettingsAdditionalProperties {}

interface AdminSettingsAdditionalProperties {
  recommendationNotificationPreferences: RecommendationNotificationAdminPreferences;
}

export interface AdminSettings extends BaseSettings, AdminSettingsAdditionalProperties {}

interface AdminSettingsToPatch extends BaseSettings, AdminSettingsAdditionalProperties {}

export type Settings = UserSettings | AdminSettings;
type SettingsToPatch = DeepPartial<UserSettingsToPatch | AdminSettingsToPatch>;

export type Logo = 'siteLogo' | 'siteLogoDark' | 'siteFavicon' | 'siteFaviconDark';

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};
export const settingsApi = createApi({
  reducerPath: 'settings',
  tagTypes: ['Settings'],
  baseQuery,
  endpoints: (builder) => ({
    settings: builder.query<Settings, void>({
      query: () => ({
        url: '/settings',
      }),
    }),
    patchSettings: builder.mutation<Settings, SettingsToPatch>({
      query: (data) => ({
        url: `/settings`,
        body: data,
        method: 'PATCH',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(settingsApi.util.updateQueryData('settings', undefined, () => response.data));
        });
      },
    }),
  }),
});

export const { useSettingsQuery, usePatchSettingsMutation } = settingsApi;
