import { ListItemText, MenuItem, MenuItemProps } from '@mui/material';
import { ResultData } from 'src/store/api/searchApi';
import HtmlContent from './HtmlContent';
import { useSearchResultsDict } from '../controller/useSearchResultsDict';

interface Props extends MenuItemProps {
  item: ResultData;
  dataCy?: string;
}

function SingleSearchResult({ item, onClick, dataCy, ...props }: Props) {
  const { searchResultDict, handleClick } = useSearchResultsDict(item);
  return (
    <MenuItem
      {...props}
      onClick={(e) => {
        handleClick();
        onClick?.(e);
      }}
      data-cy={dataCy && `${dataCy}-${item.id}`}
    >
      {searchResultDict[item.source].prefix}
      <ListItemText
        primary={<HtmlContent content={searchResultDict[item.source].name as string} />}
        secondary={<HtmlContent content={searchResultDict[item.source].description as string} />}
      />
    </MenuItem>
  );
}

export default SingleSearchResult;
