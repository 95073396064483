import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { SearchTextField } from 'src/ui-components/custom/textfield/search';
import { TopBar } from 'src/ui-components/layout/top-bar';
import { SidebarHeaderIcon } from './types';

interface Props {
  searchVal: string;
  handleSearchValChange: (val: string) => void;
  iconData?: SidebarHeaderIcon;
  disableSearchBar?: boolean;
  dataCy?: string;
}

export default function Header({
  iconData,
  searchVal,
  handleSearchValChange,
  disableSearchBar,
  dataCy,
}: Props) {
  return (
    <TopBar
      leftContent={
        <SearchTextField
          disabled={disableSearchBar}
          fullWidth
          size="small"
          value={searchVal}
          data-cy={dataCy && `${dataCy}-search-input`}
          onChange={(e) => handleSearchValChange(e.target.value)}
        />
      }
      rightContent={
        iconData && (
          <Tooltip title={iconData.text} placement="top">
            <IconButton
              onClick={iconData.onClick}
              size="medium"
              disabled={iconData.disabled}
              data-cy={dataCy && `${dataCy}-header-button`}
            >
              <SvgIcon fontSize="small" component={iconData.icon} />
            </IconButton>
          </Tooltip>
        )
      }
    />
  );
}
