import {
  Box,
  Divider,
  Typography,
  useTheme,
  IconButton,
  SvgIcon,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark, materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { WorkbaseCopyDuplicateIcon } from 'src/assets/icons/workbaseIcons';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { memo } from 'react';

function extractPathname(url: string) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname;
  } catch (_error) {
    return '';
  }
}

function Paragraph({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="body1" component="p">
      {children}
    </Typography>
  );
}

function Heading1({ children }: { children: React.ReactNode }) {
  return <Typography variant="h4">{children}</Typography>;
}

function Heading2({ children }: { children: React.ReactNode }) {
  return <Typography variant="h5">{children}</Typography>;
}

function Heading3({ children }: { children: React.ReactNode }) {
  return <Typography variant="h6">{children}</Typography>;
}

function Strong({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="body1" component="strong" fontWeight="bold">
      {children}
    </Typography>
  );
}

function Emphasis({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="body1" fontStyle="italic">
      {children}
    </Typography>
  );
}

function Anchor({ href, children }: { href?: string; children: React.ReactNode }) {
  const navigate = useNavigate();
  return (
    <Typography
      component="span"
      onClick={() => navigate(extractPathname(href as string))}
      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
    >
      {children}
    </Typography>
  );
}

function Blockquote({ children }: { children: React.ReactNode }) {
  return (
    <Box component="blockquote" borderLeft={2} pl={2}>
      {children}
    </Box>
  );
}

function CodeBlock({
  className,
  children,
  ...props
}: {
  className?: string;
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      showNotification(t('copiedToClipboard'));
    });
  };
  const match = /language-(\w+)/.exec(className || '');
  const codeString = String(children).replace(/\n$/, '');
  return match ? (
    <Box
      position="relative"
      sx={{
        '& .code-block': {
          maxWidth: '100%',
          borderRadius: `${theme.shape.borderRadius}px !important`,
        },
      }}
    >
      <Box position="absolute" top={1} right={1} zIndex={1}>
        <IconButton
          size="small"
          onClick={() => handleCopy(codeString)}
          data-cy="ai-assistant-copy-code"
        >
          <SvgIcon component={WorkbaseCopyDuplicateIcon} />
        </IconButton>
      </Box>
      <SyntaxHighlighter
        style={theme.palette.mode === 'dark' ? materialDark : materialLight}
        language={match[1]}
        useInlineStyles
        className="code-block"
        PreTag="div"
        {...props}
        codeTagProps={{
          style: { fontSize: '.8em' },
        }}
      >
        {codeString}
      </SyntaxHighlighter>
    </Box>
  ) : (
    <code {...props}>{children}</code>
  );
}

function HorizontalRule() {
  return <Divider />;
}

function UnorderedList({ children }: { children: React.ReactNode }) {
  return <Box component="ul">{children}</Box>;
}

function OrderedList({ children }: { children: React.ReactNode }) {
  return <Box component="ol">{children}</Box>;
}

function MdTable({ children }: { children: React.ReactNode }) {
  return (
    <TableContainer component={Box} borderRadius={1} p={1} bgcolor="action.hover">
      <Table size="small">{children}</Table>
    </TableContainer>
  );
}

function MdTableHead({ children }: { children: React.ReactNode }) {
  return <TableHead>{children}</TableHead>;
}

function MdTableBody({ children }: { children: React.ReactNode }) {
  return <TableBody>{children}</TableBody>;
}

function MdTableRow({ children }: { children: React.ReactNode }) {
  return <TableRow>{children}</TableRow>;
}

function MdTableCell({ children }: { children: React.ReactNode }) {
  return (
    <TableCell size="small" align="left">
      <Typography noWrap overflow="hidden" maxWidth={215}>
        {children}
      </Typography>
    </TableCell>
  );
}
// eslint-disable-next-line no-restricted-syntax
const StyledMarkdown = styled(ReactMarkdown)(({ theme }) => ({
  maxWidth: `calc(100% - ${theme.spacing(5)})`,
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  '& p': {
    paddingBottom: theme.spacing(1),
  },
}));

function MarkdownMessage({ message }: { message: string }) {
  const components = {
    p: Paragraph,
    h1: Heading1,
    h2: Heading2,
    h3: Heading3,
    strong: Strong,
    em: Emphasis,
    a: Anchor,
    blockquote: Blockquote,
    code: CodeBlock,
    hr: HorizontalRule,
    ul: UnorderedList,
    ol: OrderedList,
    table: MdTable,
    thead: MdTableHead,
    tbody: MdTableBody,
    tr: MdTableRow,
    td: MdTableCell,
  };

  return (
    <StyledMarkdown remarkPlugins={[remarkGfm, remarkBreaks]} components={components as any}>
      {message}
    </StyledMarkdown>
  );
}

export default memo(MarkdownMessage);
