import { createApi } from '@reduxjs/toolkit/query/react';
import { ShareLevel } from 'src/lib/types/share-level';
import { FetchResponse } from 'src/lib/types/paginatedResponse';
import { GeneralAccessType } from 'src/lib/types/generalAccessType';
import { baseQuery } from './api';

export type Documents = FetchResponse<Document>;

export interface Document {
  id: string;
  resourceType: DocumentResourceType;
  position: string;
  name: string;
  icon: string;
  updatedAt: string;
  sidebar: boolean;
  shareLevel: ShareLevel;
  managersCount: number;
  url: string | null;
  generalAccess: GeneralAccess;
  sharedWithOthers: boolean;
}

export type DocumentResourceType = 'embeddedApp' | 'page' | 'whiteboard';

export interface GeneralAccess {
  type: GeneralAccessType;
  required: string;
  shareLevel: ShareLevel;
}

export type FileUploadStatus = 'uploaded' | 'failed';

export interface File {
  id: string;
  filename: string;
  url: string;
  fileType: string;
  fileSize: number;
  resourceType: 'file';
  createdAt: string;
  updatedAt: string;
  shareLevel: ShareLevel;
  managersCount: number;
  generalAccess: GeneralAccess;
  sharedWithOthers: boolean;
}

export const driveApi = createApi({
  reducerPath: 'driveApi',
  tagTypes: ['Documents', 'Files'],
  baseQuery,
  endpoints: (builder) => ({
    documents: builder.query<Documents, void>({
      query: () => ({
        url: '/documents',
      }),
      providesTags: ['Documents'],
    }),

    getFiles: builder.query<File[], void>({
      query: () => ({
        url: '/files',
        method: 'GET',
      }),
      providesTags: ['Files'],
    }),

    uploadFile: builder.mutation<{ uploadUrl: string }, { filename: string }>({
      query: (body) => ({
        url: '/files/upload',
        method: 'POST',
        body,
      }),
    }),

    downloadFile: builder.mutation<{ downloadUrl: string }, string>({
      query: (id) => ({
        url: `/files/download/${id}`,
        method: 'POST',
      }),
    }),

    patchFile: builder.mutation<
      void,
      { id: string; filename?: string; uploadStatus?: FileUploadStatus }
    >({
      query: ({ id, filename, uploadStatus }) => ({
        url: `/files/${id}`,
        method: 'PATCH',
        body: { filename, uploadStatus },
      }),
      invalidatesTags: ['Files'],
    }),

    deleteFile: builder.mutation<void, string>({
      query: (id) => ({
        url: `/files/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Files'],
    }),
  }),
});

export const {
  useDocumentsQuery,
  useGetFilesQuery,
  useUploadFileMutation,
  useDownloadFileMutation,
  usePatchFileMutation,
  useDeleteFileMutation,
} = driveApi;
