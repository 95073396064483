export function getCurrentFrameFromVideoElement(
  videoElement: HTMLVideoElement,
  aspectRatio = 1.777777
): Promise<Blob | null> {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    canvas.height = 720;
    canvas.width = canvas.height * aspectRatio;
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg');
  });
}

export function getFirstFrameFromVideoFile(file: File, aspectRatio?: number): Promise<Blob | null> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const url = URL.createObjectURL(file);
    video.src = url;

    video.muted = true;
    video.playsInline = true;
    video.currentTime = 0;

    const onMetadataLoaded = async () => {
      try {
        video
          .play()
          .then(() => {
            video.pause();

            setTimeout(async () => {
              const frame = await getCurrentFrameFromVideoElement(video, aspectRatio);
              resolve(frame);

              video.removeEventListener('loadedmetadata', onMetadataLoaded);
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              video.removeEventListener('error', onError);
              video.src = '';
              video.load();
              URL.revokeObjectURL(url);
            }, 100);
          })
          .catch(() => {
            reject(new Error('Error playing the video'));
            URL.revokeObjectURL(url);
          });
      } catch (_error) {
        reject(new Error('Error capturing the first frame'));

        video.removeEventListener('loadedmetadata', onMetadataLoaded);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        video.removeEventListener('error', onError);
        URL.revokeObjectURL(url);
      }
    };

    const onError = () => {
      reject(new Error('Error loading video metadata'));

      video.removeEventListener('loadedmetadata', onMetadataLoaded);
      video.removeEventListener('error', onError);
      URL.revokeObjectURL(url);
    };

    video.addEventListener('loadedmetadata', onMetadataLoaded);
    video.addEventListener('error', onError);
  });
}
