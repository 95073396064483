import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';
import { driveApi } from './driveApi';
import { sidebarApi } from './sidebarApi';

export interface EmbeddedApp {
  id: string;
  url: string;
  name: string;
  icon: string;
  sidebar: boolean;
}

export const embeddedAppsApi = createApi({
  reducerPath: 'embeddedAppsApi',
  baseQuery,
  endpoints: (builder) => ({
    // queries
    embeddedApp: builder.query<EmbeddedApp, string>({
      query: (id) => `/embedded-apps/${id}`,
    }),
    // mutations
    patchEmbeddedApp: builder.mutation<void, Partial<EmbeddedApp>>({
      query: ({ icon, id, name, url, sidebar }) => ({
        url: `/embedded-apps/${id}`,
        body: { icon, name, url, sidebar },
        method: 'PATCH',
      }),
      onQueryStarted: async (payload, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          queryFulfilled.then(() => {
            dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
            dispatch(driveApi.util.invalidateTags(['Documents']));
          });
          dispatch(
            embeddedAppsApi.util.updateQueryData('embeddedApp', payload.id as string, (draft) => ({
              ...draft,
              ...payload,
            }))
          );
        });
      },
    }),
    postEmbeddedApp: builder.mutation<void, Partial<Omit<EmbeddedApp, 'id'>> | void>({
      query: (payload) => ({
        url: `/embedded-apps`,
        body: payload,
        method: 'POST',
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
      },
    }),
    deleteEmbeddedApp: builder.mutation<void, string>({
      query: (id) => ({
        url: `/embedded-apps/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
      },
    }),
    duplicateEmbeddedApp: builder.mutation<void, string>({
      query: (id) => ({
        url: `/embedded-apps/${id}/duplicate`,
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(sidebarApi.util.invalidateTags(['Sidebar']));
          dispatch(driveApi.util.invalidateTags(['Documents']));
        });
      },
    }),
  }),
});

export const {
  useEmbeddedAppQuery,
  usePatchEmbeddedAppMutation,
  usePostEmbeddedAppMutation,
  useDeleteEmbeddedAppMutation,
  useDuplicateEmbeddedAppMutation,
} = embeddedAppsApi;
