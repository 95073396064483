import { Components, Theme } from '@mui/material';

const MuiCardContent: Components<Theme>['MuiCardContent'] = {
  styleOverrides: {
    root: {
      padding: '20px !important',
    },
  },
};

export default MuiCardContent;
