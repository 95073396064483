import {
  Box,
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import { WorkbaseAddContentIcon } from 'src/assets/icons/workbaseIcons';
import Empty from 'src/ui-components/custom/empty/Empty';

export interface TrainingsSelectionProps {
  options: { id: string; label: string }[];
  selected: string[];
  onChange: (val: string[]) => void;
}

export default function TrainingsSelection({
  options = [],
  selected,
  onChange,
}: TrainingsSelectionProps) {
  const { t } = useTranslation();
  const popoverState = usePopupState({ variant: 'popper', popupId: 'roles-popover' });

  const handleToggle = (id: string) => {
    if (selected.includes(id)) {
      onChange(selected.filter((item) => item !== id));
    } else {
      onChange([...selected, id]);
    }
  };

  return (
    <>
      <Tooltip title={t(`editor.topBar.tooltip.addContent`)} placement="top">
        <span>
          <IconButton {...bindTrigger(popoverState)} data-cy="editor-bar-trainings-add-button">
            <SvgIcon component={WorkbaseAddContentIcon} fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
      <Menu {...bindPopover(popoverState)} data-cy="editor-bar-trainings-menu">
        {options.length === 0 && (
          <Box width={300}>
            <Empty />
          </Box>
        )}
        {options.map(({ id, label }) => {
          const isSelectedOption = selected.includes(id);
          return (
            <MenuItem
              dense
              key={id}
              onClick={() => handleToggle(id)}
              selected={isSelectedOption}
              data-cy={`editor-bar-trainings-menu-${label}-item`}
            >
              <Checkbox
                checked={isSelectedOption}
                data-cy={`editor-bar-trainings-menu-${label}-checkbox`}
              />
              <ListItemText
                primary={label}
                primaryTypographyProps={{ noWrap: true, maxWidth: 300 }}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
