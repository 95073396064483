import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './api';

export interface MessageContent {
  type: 'text' | 'image_url'; // Expandable for other types of content
  text?: string; // Only present if the type is 'text'
  image_url?: {
    url: string;
  }; // Present if the type is 'image_file'
  file_name?: string; // Present if the type is 'image_file'
}

// Define the type for individual message object
export interface ChatMessage {
  id?: string;
  assistant_id?: string;
  attachments?: Array<any>;
  content: MessageContent[];
  createdAt: number | null;
  metadata?: Record<string, any>;
  object?: string;
  role: 'assistant' | 'user';
  run_id?: string;
  thread_id: string;
}

export interface Thread {
  id: string;
  created_at: Date;
  updated_at: Date;
  summary: string;
}

// Define the main thread response structure
export interface ChatThread {
  id: string; // Thread ID
  messages: ChatMessage[]; // Array of messages
  created_at: Date;
  updated_at: Date;
  summary: string;
}

const aiChatApi = createApi({
  reducerPath: 'aiChatApi',
  tagTypes: ['ChatThread', 'Threads', 'Assistant-settings'],
  baseQuery,
  endpoints: (builder) => ({
    fetchMessages: builder.query<ChatThread, string>({
      query: (threadId) => `ai-assistant/threads-v2/${threadId}`,
      transformResponse: (response: any) => ({
        ...response,
        messages: response.messages.map((message: ChatMessage) => ({
          ...message,
          content: [
            {
              type: 'text',
              // @ts-ignore open ai types
              text: message.content[0].text?.value ?? message.content[0].text,
            },
          ],
        })),
      }),
      providesTags: ['ChatThread'],
    }),
    fetchThreads: builder.query<ChatThread[], void>({
      query: () => 'ai-assistant/threads',
      providesTags: ['Threads'],
    }),
    deleteThread: builder.mutation<void, string>({
      query: (threadId) => ({
        url: `ai-assistant/threads/${threadId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Threads'],
    }),
    createThread: builder.mutation<Thread, void>({
      query: () => ({
        url: 'ai-assistant/threads',
        method: 'POST',
      }),
      invalidatesTags: ['Threads', 'ChatThread'],
    }),
    fetchSettings: builder.query<{ name: string; customInstructions: string }, void>({
      query: () => '/companies/ai-assistant/settings',
      providesTags: ['Assistant-settings'],
    }),
    updateSettings: builder.mutation<void, { name: string; customInstructions: string }>({
      query: (settings) => ({
        url: '/companies/ai-assistant/settings',
        method: 'PATCH',
        body: settings,
      }),
      invalidatesTags: ['Assistant-settings'],
    }),
  }),
});

export const {
  useFetchMessagesQuery,
  useCreateThreadMutation,
  useFetchThreadsQuery,
  useDeleteThreadMutation,
  useFetchSettingsQuery,
  useUpdateSettingsMutation,
} = aiChatApi;

export default aiChatApi;
