import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/de';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { defaultLang } from 'src/locales/config-lang';
import { useSettingsQuery } from 'src/store/api/settingsApi';

type Props = {
  children: React.ReactNode;
};

export default function DefaultLanguageProvider({ children }: Props) {
  const { data } = useSettingsQuery();
  const { i18n } = useTranslation();

  const language = data?.defaultLanguage;

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language || defaultLang.value);
    }
  }, [language, i18n]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      {children}
    </LocalizationProvider>
  );
}
