import { IconButton, Stack, SvgIcon } from '@mui/material';
import {
  WorkbaseArrowLeftIcon,
  WorkbaseArrowRightIcon,
  WorkbaseHomeIcon,
} from 'src/assets/icons/workbaseIcons';

interface Props {
  reloadIframe: () => void;
  goBack: () => void;
  goForward: () => void;
}

export default function ActionButtons({ reloadIframe, goBack, goForward }: Props) {
  return (
    <Stack justifyContent="center" alignItems="center" flexDirection="row" gap={1.5}>
      <IconButton
        value="embedded-app-home-action-button"
        color="primary"
        variant="soft"
        onClick={reloadIframe}
        data-cy="embedded-app-home-button"
      >
        <SvgIcon component={WorkbaseHomeIcon} fontSize="small" />
      </IconButton>
      <IconButton
        onClick={goBack}
        value="embedded-app-go-back-action-button"
        color="primary"
        variant="soft"
        data-cy="embedded-app-go-back-button"
      >
        <SvgIcon component={WorkbaseArrowLeftIcon} fontSize="small" />
      </IconButton>
      <IconButton
        onClick={goForward}
        value="embedded-app-go-forward-action-button"
        color="primary"
        variant="soft"
        data-cy="embedded-app-go-forward-button"
      >
        <SvgIcon component={WorkbaseArrowRightIcon} fontSize="small" />
      </IconButton>
    </Stack>
  );
}
