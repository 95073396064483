import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import {
  useDeleteTrainingsMutation,
  useDuplicateTrainingsMutation,
  useGetSingleTrainingQuery,
} from 'src/store/api/trainings/trainingsApi';
import ShareModal from 'src/core/share-modal/ShareModal';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import useTrainingPermissions from 'src/lib/hooks/useTrainingPermissions';
import { ShareLevel } from 'src/lib/types/share-level';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import TrainingCourseHeader from './header';
import { PermissionCodes } from 'src/store/api/userPermissionsApi';
import EditViewProtectedWrapper from 'src/core/EditViewProtectedWrapper';
import ModeSwitch from 'src/core/ModeSwitch';
import TopBarLayout from 'src/layouts/TopBarLayout';
import { TopBar } from 'src/ui-components/layout/top-bar';
import ModulesList from './modules-list';
import { Container } from '@mui/material';
import TrainingPageBreadcrumbs from './TrainingPageBreadcrumbs';

export default function TrainingCourseView() {
  const param = useParams();
  if (!param.trainingId) throw new Error('Training id param missing');
  const navigate = useNavigate();
  const hasAnyPermission = useHasAnyPermission();
  const hasAddTrainingsPermission = hasAnyPermission([PermissionCodes.ADD_TRAININGS]);
  const [handleDeleteTraining] = useDeleteTrainingsMutation();
  const [handleDuplicateTraining] = useDuplicateTrainingsMutation();
  const { t } = useTranslation();

  const { data } = useGetSingleTrainingQuery(param.trainingId as string);

  const trainingName = data?.name || t('trainingDetailPage.unnamedTraining');

  const { data: shareLevelData, error } = useShareLevelQuery({
    resourceType: 'training',
    resourceId: param.trainingId as string,
  });

  const { hasEditingPermissions } = useTrainingPermissions();

  const hasManagePermission = shareLevelData?.shareLevel === ShareLevel.manage;

  return (
    <EditViewProtectedWrapper
      canEdit={hasEditingPermissions}
      canView={shareLevelData?.shareLevel !== ShareLevel.none && !error}
    >
      <ModeSwitch>
        {(isEditMode) => (
          <TopBarLayout
            topBarVisible={isEditMode}
            topBar={
              <TopBar
                rightContent={
                  <>
                    <ShareModal
                      dataCy="training-course-view"
                      name={trainingName}
                      resourceId={param.trainingId as string}
                      resourceType="training"
                      shareLevel={shareLevelData?.shareLevel}
                    />
                    <DeleteDuplicateMenu
                      onDuplicate={
                        hasAddTrainingsPermission
                          ? () => {
                              handleDuplicateTraining(param.trainingId as string).then(
                                (resp: any) => {
                                  if (resp?.error) {
                                    showNotification(t('notifications.duplicateError'), 'error');
                                  } else {
                                    showNotification(t('notifications.duplicateSuccess'));
                                    navigate(`/trainings/${resp.data.id}/edit`);
                                  }
                                }
                              );
                            }
                          : undefined
                      }
                      deleteDialogDescription={t(
                        'editor.topBar.customQuestionsOnSettingIcon.deleteTrainingQuestion'
                      )}
                      onDelete={
                        hasManagePermission
                          ? () => {
                              handleDeleteTraining(param.trainingId as string).then((resp: any) => {
                                if (resp?.error) {
                                  showNotification(t('notifications.deleteError'), 'error');
                                } else {
                                  showNotification(t('notifications.deleteSuccess'));
                                  navigate('/edit');
                                }
                              });
                            }
                          : undefined
                      }
                    />
                  </>
                }
              />
            }
          >
            <Container>
              <TrainingPageBreadcrumbs />
            </Container>
            <TrainingCourseHeader readOnly={!isEditMode} trainingId={param.trainingId as string} />
            <Container sx={{ mb: 4 }}>
              <ModulesList readOnly={!isEditMode} />
            </Container>
          </TopBarLayout>
        )}
      </ModeSwitch>
    </EditViewProtectedWrapper>
  );
}
