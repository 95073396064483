import { useTheme } from '@mui/material/styles';
import { colorify } from 'lottie-colorify';
import Color from 'color';
import { WorkbaseAILoadingLottie } from 'src/assets/lottie';
import Lottie from 'lottie-react';
import { Box } from '@mui/material';
import { useMemo } from 'react';

function DynamicLottie() {
  const theme = useTheme();

  const colors = useMemo(() => {
    const newColor = Color(theme.palette.primary.main).hex();
    return Array(8).fill(newColor);
  }, [theme.palette.primary.main]);

  return (
    <Box position="relative">
      <Lottie
        style={{
          height: '50px',
          width: '40px',
          position: 'absolute',
          top: -10,
        }}
        animationData={theme ? colorify(colors, WorkbaseAILoadingLottie) : WorkbaseAILoadingLottie}
        data-cy="ai-assistant-dynamic-lottie"
      />
    </Box>
  );
}

export default DynamicLottie;
