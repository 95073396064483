import {
  WorkbaseCopyDuplicateIcon,
  WorkbaseDeleteSquareIcon,
  WorkbaseThreePointsIcon,
} from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

const styles = { pointerEvents: 'all' };

interface Props {
  onDelete?: () => void;
  onDuplicate?: () => void;
  deleteDialogDescription?: string;
  dataCy?: string;
}

export default function DeleteDuplicateMenu({
  onDuplicate,
  onDelete,
  deleteDialogDescription,
  dataCy,
}: Props) {
  const { t } = useTranslation();
  const menuState = usePopupState({ variant: 'popover' });
  const menuProps = bindPopover(menuState);
  const confirm = useConfirm();

  const duplicateItem = onDuplicate
    ? [
        {
          value: 'duplicate',
          label: t('duplicate'),
          startIcon: WorkbaseCopyDuplicateIcon,
          onClick: onDuplicate,
        },
      ]
    : [];

  const deleteItem = onDelete
    ? [
        {
          value: 'delete',
          label: t('delete'),
          startIcon: WorkbaseDeleteSquareIcon,
          onClick: () => {
            confirm({
              title: t('areYouSure'),
              description: deleteDialogDescription,
              confirmationButtonProps: {
                // @ts-expect-error selector
                'data-cy': 'confirmation-dialog-confirm',
              },
              confirmationText: t('delete'),
              cancellationButtonProps: {
                // @ts-expect-error selector
                'data-cy': 'confirmation-dialog-cancel',
              },
            }).then(() => {
              onDelete();
            });
          },
        },
      ]
    : [];

  const menuItems = [...duplicateItem, ...deleteItem];

  if (menuItems.length === 0) return null;

  return (
    <>
      <Tooltip title={t('options')}>
        <IconButton
          variant="soft"
          value="delete-duplicate"
          {...bindTrigger(menuState)}
          data-cy="deleteDuplicateIconButton"
          sx={styles}
        >
          <SvgIcon component={WorkbaseThreePointsIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu {...menuProps} data-cy={dataCy && `${dataCy}-delete-duplicate-menu`}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.value}
            data-cy={`${item.value}MenuItem`}
            disabled={!item.onClick}
            onClick={() => {
              item.onClick?.();
              menuProps.onClose();
            }}
          >
            <ListItemIcon>
              <SvgIcon component={item.startIcon} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
