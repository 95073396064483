import { useEffect } from 'react';
import { useLazyVideoEncodingProgressQuery } from 'src/store/api/videos';

function useEncodingProgress(videoId: string, src: string | null) {
  const [fetchVideoEncodingProgress, { data: encodingProgressData }] =
    useLazyVideoEncodingProgressQuery();

  useEffect(() => {
    if (src) return undefined;
    fetchVideoEncodingProgress(videoId);
    const intervalId = setInterval(() => {
      fetchVideoEncodingProgress(videoId);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [videoId, src, fetchVideoEncodingProgress]);

  const encodingProgress = encodingProgressData?.progress || 0;
  return encodingProgress;
}

export default useEncodingProgress;
