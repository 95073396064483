import { alpha, Components, Theme } from '@mui/material';

const MuiDialog: Components<Theme>['MuiDialog'] = {
  defaultProps: {
    slotProps: {
      backdrop: {
        sx: ({ palette }) => ({
          backgroundColor: alpha(palette.common.black, 0.9),
        }),
      },
    },
  },
};

export default MuiDialog;
