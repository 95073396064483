import { useCallback } from 'react';
import { PermissionCode, useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';

export default function useHasAnyPermission() {
  const { data } = useUserPermissionsQuery();
  const hasAnyPermission = useCallback(
    (codes: PermissionCode[]) =>
      codes.some((code) => data?.some((permission) => permission.code === code)),
    [data]
  );
  return hasAnyPermission;
}
