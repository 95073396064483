import { Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseCheckmarkBigFilledIcon } from 'src/assets/icons/workbaseIcons';

export default function EmptyContent() {
  const { t } = useTranslation();
  return (
    <Stack alignItems="center" justifyContent="center" height={200} gap={1}>
      <SvgIcon component={WorkbaseCheckmarkBigFilledIcon} fontSize="large" color="success" />
      <Typography variant="body1" fontWeight="bold">
        {t('dashboardPage.header.message')}
      </Typography>
    </Stack>
  );
}
