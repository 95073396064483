import { Components, Theme } from '@mui/material';

const MuiBottomNavigation: Components<Theme>['MuiBottomNavigation'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
    }),
  },
};

export default MuiBottomNavigation;
