import { RefObject, useCallback, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';

interface Props {
  screenStream: MediaStream | null;
  setScreenStream: (val: MediaStream | null) => void;
  setSelectedCamera: (val: string) => void;
}

type HTMLVideoElementWithPrsentationMode = HTMLVideoElement & {
  webkitSupportsPresentationMode?: boolean;
  webkitSetPresentationMode?(mode: string): void;
};

const requestPictureInPicture = (webcamRef: RefObject<Webcam>) => {
  const video = webcamRef.current?.video as HTMLVideoElementWithPrsentationMode;
  if (
    video.webkitSupportsPresentationMode &&
    typeof video.webkitSetPresentationMode === 'function'
  ) {
    video.webkitSetPresentationMode('picture-in-picture');
  } else {
    video.requestPictureInPicture();
  }
};

const handleRequestingPictureInPicture = (webcamRef: RefObject<Webcam>) => {
  setTimeout(() => {
    if (webcamRef.current?.video) {
      const video = webcamRef.current?.video;
      const checkVideoTrackAndRequestPiP = () => {
        const mediaStream = video.srcObject as MediaStream;
        if (video.readyState >= 2 && mediaStream) {
          const tracks = mediaStream.getVideoTracks();
          if (tracks.length > 0) {
            requestPictureInPicture(webcamRef);
          }
        }
      };
      const onLoadedData = () => {
        video.removeEventListener('loadeddata', onLoadedData);
        checkVideoTrackAndRequestPiP();
      };

      if (video.readyState >= 2 && video.srcObject) {
        checkVideoTrackAndRequestPiP();
      } else {
        video.addEventListener('loadeddata', onLoadedData);
      }
    }
  }, 100);
};

export default function usePictureInPictureCamera({
  screenStream,
  setSelectedCamera,
  setScreenStream,
}: Props) {
  const webcamRef = useRef<Webcam>(null);

  const handleCameraSelect = useCallback(
    (val: string) => {
      setSelectedCamera(val);
      if (val && val !== 'noDevice' && screenStream) {
        const track = screenStream.getVideoTracks()[0];
        const settings = track.getSettings();
        if (settings.displaySurface !== 'monitor') {
          setScreenStream(null);
          return;
        }
        handleRequestingPictureInPicture(webcamRef);
      } else if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      }
    },
    [setSelectedCamera, screenStream, webcamRef, setScreenStream]
  );

  const handleScreenStreamChange = useCallback(
    (val: MediaStream | null) => {
      setScreenStream(val);
      if (val && webcamRef.current) {
        const track = val.getVideoTracks()[0];
        const settings = track.getSettings();
        if (settings.displaySurface === 'monitor' && webcamRef.current?.video) {
          handleRequestingPictureInPicture(webcamRef);
        } else {
          setSelectedCamera('noDevice');
        }
      } else if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      }
    },
    [setScreenStream, webcamRef, setSelectedCamera]
  );

  useEffect(() => {
    const onLeavePictureInPicture = () => {
      if (screenStream) {
        setSelectedCamera('noDevice');
      }
    };
    document.addEventListener('leavepictureinpicture', onLeavePictureInPicture);

    return () => {
      document.removeEventListener('leavepictureinpicture', onLeavePictureInPicture);
    };
  }, [setSelectedCamera, screenStream]);

  useEffect(
    () => () => {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      }
    },
    []
  );

  return { handleCameraSelect, handleScreenStreamChange, webcamRef };
}
