import { Components, Theme } from '@mui/material';

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    contrastText: true;
  }
}

const MuiTextField: Components<Theme>['MuiTextField'] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      '.MuiSelect-icon': {
        top: 'calc(50% - 0.75em)',
        right: 14,
      },
      ...(ownerState.color === 'contrastText' && {
        color: theme.palette.primary.contrastText,
        '& .MuiInputBase-input': {
          color: theme.palette.primary.contrastText,
        },
        '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.contrastText,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.contrastText,
        },
        '& .MuiOutlinedInput-notchedOutline:hover': {
          borderColor: theme.palette.primary.contrastText,
        },
        '& .MuiInputAdornment-root': {
          color: theme.palette.primary.contrastText,
        },
        '& .MuiInputLabel-root': {
          color: theme.palette.primary.contrastText,
        },
      }),
    }),
  },
  variants: [
    {
      props: { select: true },
      style: {
        '& .MuiSelect-select': {
          paddingRight: '48px !important',
          fontWeight: 500,
        },
      },
    },
    {
      props: { multiline: true },
      style: {
        '& .MuiInputBase-inputMultiline': {
          paddingTop: '0 !important',
          paddingBottom: '0 !important',
        },
        '& .MuiInputBase-multiline': {
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
    },
  ],
};

export default MuiTextField;
